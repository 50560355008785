/* import __COLOCATED_TEMPLATE__ from './preview-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { preferredDevices } from 'embercom/models/data/outbound/constants';

export default class PreviewSwitcher extends Component {
  get shouldShowDeviceSwitcher() {
    return this.args.preferredDevices.length > 1;
  }

  get preferredDeviceSwitcherOptions() {
    let selectedPreferredDevices = this.args.preferredDevices;
    let availableOptions = selectedPreferredDevices.map((option) => {
      switch (option) {
        case preferredDevices.web:
          return { device: 'web', icon: 'computer' };
        case preferredDevices.ios:
          return { device: 'ios', icon: 'apple' };
        case preferredDevices.android:
          return { device: 'android', icon: 'android' };
      }
    });
    return availableOptions;
  }

  get selectedDevice() {
    let selectedPreferredDevice;
    let currentDevice = this.args.currentDevice;
    if (
      preferredDevices[currentDevice] &&
      this.args.preferredDevices.includes(preferredDevices[currentDevice])
    ) {
      selectedPreferredDevice = preferredDevices[currentDevice];
    } else {
      selectedPreferredDevice = this.args.preferredDevices.firstObject;
    }
    switch (selectedPreferredDevice) {
      case preferredDevices.ios:
        return 'ios';
      case preferredDevices.android:
        return 'android';
      default:
        return 'web';
    }
  }

  @action
  updatePreferredDeviceOptions() {
    this.args.updateCurrentDevice(this.selectedDevice);
  }
}
