/* import __COLOCATED_TEMPLATE__ from './controls-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-side-effects */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { A } from '@ember/array';
import Component from '@ember/component';
import EmberObject, { action, computed } from '@ember/object';
import { and, equal, gt, not, notEmpty, or, readOnly } from '@ember/object/computed';
import { next, run, schedule, scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty, isNone } from '@ember/utils';
import { throttleTask } from 'ember-lifeline';
import InboundActions from 'ember-component-inbound-actions/inbound-actions';
import ENV from 'embercom/config/environment';
import { queryCache } from 'embercom/components/conversation/controls/inserters/article-popover-content';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import { SNOOZED_UNTIL_MAP } from 'embercom/lib/inbox/constants';
import UserContentSeenState from 'embercom/lib/user-content-seen-state';
import SavedReply from 'embercom/models/saved-reply';
import $ from 'jquery';
import moment from 'moment-timezone';

let MAX_UPLOAD_SIZE_FOR_EMAIL_ATTACHMENTS = 20000000;

export default Component.extend(InboundActions, EventTracking, {
  intercomEventService: service(),
  notificationsService: service(),
  activityTrackingSamplingService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  currentComposerBlocks: [],
  publicAPI: computed(
    'conversation',
    'activePaneIdentifier',
    'isInbox',
    'isFocused',
    'composerComponent',
    'composerHasContent',
    'composerIsUploading',
    'isFetchingSavedReply',
    'hasOpenInserters',
    'composerHasMacroActions',
    'conversationHasInvalidFields',
    'sendInProgress',
    'sendInProgressType',
    function () {
      return {
        app: this.app,
        admin: this.admin,
        conversation: this.conversation,
        activePaneIdentifier: this.activePaneIdentifier,
        isInbox: this.isInbox,
        isFocused: this.isFocused,
        composerComponent: this.composerComponent,
        composerHasContent: this.composerHasContent,
        composerIsUploading: this.composerIsUploading,
        isFetchingSavedReply: this.isFetchingSavedReply,
        hasOpenInserters: this.hasOpenInserters,
        composerHasMacroActions: this.composerHasMacroActions,
        conversationHasInvalidFields: this.conversationHasInvalidFields,
        sendInProgress: this.sendInProgress,
        sendInProgressType: this.sendInProgressType,
        actions: {
          insertBlock: (...args) => this.send('insertBlock', ...args),
          insertBlockInCommentPane: (...args) => this.send('insertBlockInCommentPane', ...args),
          insertSavedReply: (...args) => this.send('insertSavedReply', ...args),
          uploadImage: (...args) => this.send('uploadImage', ...args),
          annotateImage: (...args) => this.send('annotateImage', ...args),
          uploadAttachment: (...args) => this.send('uploadAttachment', ...args),
          paste: (...args) => this.send('paste', ...args),
          clearSuggestions: (...args) => this.send('clearSuggestions', ...args),
          createSavedReply: (...args) => this.send('createSavedReply', ...args),
          registerOpenInserter: (...args) => this.send('registerOpenInserter', ...args),
          clearOpenInserter: (...args) =>
            !this.isDestroying && this.send('clearOpenInserter', ...args),
          scrollStreamToBottom: (...args) => this.send('scrollStreamToBottom', ...args),
          selectPane: (...args) => this.send('changeActivePaneAndShowComposer', ...args),
          showMessageTemplatesSelector: (...args) =>
            this.send('showMessageTemplatesModal', ...args),
          onComposerChange: (...args) => {
            this.setCurrentComposerBlocks();
            if (this.onComposerChange) {
              let blocks = this.fetchBlocksFromComposer();
              this.onComposerChange(blocks);
            }
            if (this.onMacroActionsUpdate) {
              let composerMacro = this.fetchMacroFromComposer();
              this.onMacroActionsUpdate(composerMacro);
            }
            return this.send('onComposerChange', ...args);
          },
          onComposerKeyDown: (...args) => this.send('onComposerKeyDown', ...args),
          createPart: (...args) => {
            if (this.onSendPress) {
              let blocks = this.fetchBlocksFromComposer();
              let composerMacro = this.fetchMacroFromComposer();
              return this.onSendPress(blocks, composerMacro, ...args);
            } else {
              return this.send('createPart', ...args);
            }
          },
          createPartAndSnoozeWithPresetTimer: (...args) => {
            if (this.onSendAndSnoozeWithPresetTimerPress) {
              let blocks = this.fetchBlocksFromComposer();
              let composerMacro = this.fetchMacroFromComposer();
              return this.onSendAndSnoozeWithPresetTimerPress(blocks, composerMacro, ...args);
            } else {
              return this.send('createPartAndSnoozeWithPresetTimer', ...args);
            }
          },
          createPartAndSnoozeWithCustomTimer: (...args) => {
            if (this.onSendAndSnoozeWithCustomTimerPress) {
              let blocks = this.fetchBlocksFromComposer();
              let composerMacro = this.fetchMacroFromComposer();
              return this.onSendAndSnoozeWithCustomTimerPress(blocks, composerMacro, ...args);
            } else {
              return this.send('createPartAndSnoozeWithCustomTimer', ...args);
            }
          },
          expandComposer: (...args) => this.send('expandComposer', ...args),
          focusComposer: (...args) => this.send('focusComposer', ...args),
          onMacroActionsUpdate: (...args) => {
            if (this.onMacroActionsUpdate) {
              let composerMacro = this.fetchMacroFromComposer();
              this.onMacroActionsUpdate(composerMacro);
            }
            return this.send('onMacroActionsUpdate', ...args);
          },
        },
      };
    },
  ),
  realTimeEventService: service('realTimeEventService'),
  conversationsService: service(),
  conversationCloseService: service(),
  store: service(),

  savedReplyInsertionsService: service(),

  onboardingHomeExternalStepService: service(),
  notInExternalTaskMode: readOnly('onboardingHomeExternalStepService.externalStepModeDisabled'),

  classNames: ['conversation__card__content-expanded__controls', 'o__vertical-nav'],
  attributeBindings: ['data-intercom-target'],
  'data-intercom-target': 'conversation-controls',

  activePaneIdentifier: 'comment',
  composerHasContent: false, // Two way bound into composer component
  isNotFocused: not('isFocused'),
  composerHasNoContent: not('composerHasContent'),
  hasOpenInserters: notEmpty('openInserters'),

  hasArticleSuggestions: gt('conversation.articleSuggestionCount', 0),

  replySelected: equal('activePaneIdentifier', 'comment'),
  noteSelected: equal('activePaneIdentifier', 'note'),

  preventAdminReply: and('replySelected', 'conversation.preventAdminReply'),

  isUploadingOrFetchingSavedReply: or('composerIsUploading', 'isFetchingSavedReply'),

  disableTooltip: computed('isComposerCollapsed', 'adminCanReply', function () {
    if (this.isComposerCollapsed) {
      return this.adminCanReply;
    } else {
      return true;
    }
  }),
  adminCanReply: computed('conversation', function () {
    if (this.conversation.isRequest) {
      return true;
    }
    if (!this.app.hasMultipleSeatTypes || !this.conversation.isInboundConversation) {
      return true;
    }
    return this.app.currentAdmin.currentAppPermissions.can_reply_to_inbound_conversations;
  }),

  isFocused: false,

  hasTotalAttachmentSizeExceeded: false,

  savedReplyBeingCreated: false,

  composerHasMacroActions: readOnly('composerComponent.macro.hasAnyActions'),

  conversationIsDraft: readOnly('conversation.isDraft'),
  showSmsSegmentInfo: and('conversation.isSms', 'composerHasContent', 'replySelected'),

  emptyBlocks: true,
  emptyActions: true,

  messageTemplatesModalVisible: false,

  defaultMacroType: computed('conversationIsDraft', 'noteSelected', function () {
    if (this.conversationIsDraft) {
      return 'opener';
    } else if (this.noteSelected) {
      return 'note';
    } else {
      return 'reply';
    }
  }),

  init() {
    this._super(...arguments);
    this.set('openInserters', []);
  },

  didInsertElement() {
    this._super(...arguments);
    this.onConversationChange();
  },

  escapePressed: action(function (_event, kbEvent) {
    kbEvent.stopPropagation();
    this.send('removeFocus');
  }),

  cmdEnterPressed: action(function (_event, kbEvent) {
    kbEvent.stopPropagation();
    if (
      this.isUploadingOrFetchingSavedReply ||
      !this.get('app.canUseMessenger') ||
      this.preventAdminReply
    ) {
      return;
    }
    if (this.conversationIsDraft) {
      return this.publicAPI.actions.createPart();
    }

    let action = this.activePaneIdentifier;
    if (action === 'comment' && this.get('conversation.isClosed')) {
      action = 'open';
    }
    this.handleKeyDown(action);
    this.addAnalyticsEvent('sent', this.get('conversation.lastPart'), 'with_keyboard_shortcut', [
      this.conversation,
    ]);
    this.createPartFromShortcut(action);
  }),

  cmdShiftEnterPressed: action(function (_event, kbEvent) {
    kbEvent.stopPropagation();

    if (
      this.isUploadingOrFetchingSavedReply ||
      this.get('conversation.isClosed') ||
      this.get('conversation.isSnoozed') ||
      !this.get('app.canUseMessenger') ||
      this.get('composerComponent.macro.hasCloseOrSnoozeAction') ||
      this.preventAdminReply
    ) {
      return;
    }
    if (this.conversationIsDraft) {
      return this.publicAPI.actions.createPart('close');
    }
    this.handleCloseEvent(this.activePaneIdentifier);
  }),

  cmdAltEnterPressed: action(function (_event, kbEvent) {
    kbEvent.stopPropagation();

    if (
      this.isUploadingOrFetchingSavedReply ||
      this.get('conversation.isClosed') ||
      this.get('conversation.isSnoozed') ||
      this.get('composerComponent.macro.hasCloseOrSnoozeAction') ||
      this.preventAdminReply
    ) {
      return;
    }
    if (this.conversationIsDraft) {
      return this.publicAPI.actions.createPartAndSnoozeWithPresetTimer('snooze', 1);
    }
    this.handleSnoozeEvent();
  }),

  createPartFromShortcut(action) {
    let macro = this.fetchMacroFromComposer();
    if (
      this.isComposerFocused() &&
      (this.composerHasContent || this.shouldExecuteMacro(action, macro))
    ) {
      this.send('createPart', action, false);
      this.send('scrollStreamToBottom');
    }
  },

  createEventPartFromShortcut(action) {
    if (this.isComposerFocused() && this.composerHasContent) {
      this.send('createPartAndSnoozeWithPresetTimer', action, 1, false);
    }
  },

  handleKeyDown(action) {
    this.intercomEventService.trackEvent(`keyboard-shortcut-reply-${action}`);
  },

  keyPress(event) {
    this.maybeUpdateUserContentSeenState();
  },

  handleSnoozeEvent() {
    let action = this.activePaneIdentifier;
    this.handleKeyDown('snoozed');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'snoozed',
      object: this.conversation,
      context: 'with_send_keyboard_shortcut',
      snoozed_until: this.get('conversationsService.snoozedUntilDefaultText'),
    });
    if (action === 'comment') {
      this.createEventPartFromShortcut(action);
    } else {
      this.createPartFromShortcut(action);
    }
  },

  handleCloseEvent(action) {
    if (action === 'comment') {
      action = 'close';
    }
    this.handleKeyDown(action);
    this.addAnalyticsEvent('closed', this.conversation, 'with_send_keyboard_shortcut');
    this.createPartFromShortcut(action);
  },

  focusIn() {
    next(this, function () {
      if (!this.isDestroying) {
        if (this.activityTrackingSamplingService.shouldSampleActivity) {
          this.intercomEventService.trackAnalyticsEvent({
            action: 'focusin',
            object: 'composer',
          });
        }
        this.set('isFocused', true);
      }
    });
  },

  input() {
    throttleTask(this, '_trackInput', 10 * ENV.APP._1S);
  },

  _trackInput() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'input',
      object: 'composer',
    });
  },

  paste() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'paste',
      object: 'composer',
    });
  },

  focusOut() {
    next(this, function () {
      if (!this.isDestroying) {
        if (this.activityTrackingSamplingService.shouldSampleActivity) {
          this.intercomEventService.trackAnalyticsEvent({
            action: 'focusout',
            object: 'composer',
          });
        }
        this.set('isFocused', false);
      }
    });
  },

  willDestroyElement() {
    // clear up the article inserters cache
    queryCache.filteredContent = [];
    this.cancelTimers();
    this.set('openInserters', []);
    this._super(...arguments);
  },

  didUpdateAttrs() {
    this._super(...arguments);
    if (this.conversation && this.conversation !== this.lastConversation) {
      this.onConversationChange();
    }

    if (this.conversationIsDraft) {
      let blocksBecomingEmpty = this.conversation.blocks?.length === 0;
      let actionsBecomingEmpty = this.conversation.actions.length === 0;
      if (
        (!this.emptyBlocks && blocksBecomingEmpty) ||
        (!this.emptyActions && actionsBecomingEmpty)
      ) {
        this.onConversationChange();
      }
      this.emptyBlocks = blocksBecomingEmpty;
      this.emptyActions = actionsBecomingEmpty;
    }
  },

  onConversationChange() {
    this.cancelTimers();
    this.set('lastConversation', this.conversation);
    let collapseComposer =
      this.get('conversation.hasNoDrafts') && !this.isInbox && this.adminCanReply;
    this.set('isComposerCollapsed', collapseComposer);
    this.setup();
  },

  setup() {
    this.initPaneCache();
    this.setActivePaneIdentifier();
    if (!this.conversationIsDraft) {
      this.syncDraftClassAndActivePane();
    }
    this.copyDraftToPaneCache();
    if (this.focusComposerOnSetup) {
      this.loadPaneCacheIntoComposerAndFocus();
    } else {
      this.loadPaneCacheIntoComposerWithoutFocusing();
    }
    this.loadMessageText();
  },

  initPaneCache() {
    this.set(
      'paneCache',
      EmberObject.create({
        comment: EmberObject.create({ blocks: [], uploads: A() }),
        note: EmberObject.create({ blocks: [], uploads: A() }),
        send: EmberObject.create({ blocks: [], uploads: A() }),
      }),
    );
  },

  setActivePaneIdentifier() {
    if (
      (this.get('conversation.lastPartWithContentExcludingOperator.isNotOperatorPart') &&
        this.get('conversation.lastPartWithContentExcludingOperator.isNote') &&
        this.get('app.inboxIsActive')) ||
      !this.get('conversation.canReply') ||
      !this.adminCanReply
    ) {
      this.set('activePaneIdentifier', 'note');
    } else {
      this.set('activePaneIdentifier', 'comment');
    }
  },

  activePane: computed('activePaneIdentifier', 'conversation', 'paneCache', function () {
    let panesPath = `paneCache.${this.activePaneIdentifier}`;
    return this.get(panesPath);
  }),

  loadMessageText() {
    schedule('afterRender', this, this._loadMessage);
  },

  loadPaneCacheIntoComposerAndFocusAndInsertBlock(type, block) {
    // Schedule after render so we definitely have a live composer
    schedule('afterRender', this, this._loadAndFocusAndInsertBlock, type, block);
  },

  loadPaneCacheIntoComposerAndFocus() {
    // Schedule after render so we definitely have a live composer
    schedule('afterRender', this, this._loadAndFocus);
  },

  loadPaneCacheIntoComposerWithoutFocusing() {
    // Schedule after render so we definitely have a live composer
    schedule('afterRender', this, this._load);
  },

  _loadAndFocusAndInsertBlock(type, block) {
    run(() => {
      let composerComponent = this.composerComponent;
      if (composerComponent) {
        composerComponent.send('loadAndFocus', this.get('activePane.blocks'));
        composerComponent.send('createBlock', type, block);
        this.send('focusComposer');
        this.doScroll();
      }
    });
  },

  _loadMessage() {
    run(() => {
      this.setCurrentComposerBlocks();
    });
  },

  _loadAndFocus() {
    run(() => {
      let composerComponent = this.composerComponent;
      if (composerComponent) {
        composerComponent.send('loadAndFocus', this.get('activePane.blocks'));
      }
    });
  },

  _load() {
    run(() => {
      let composerComponent = this.composerComponent;
      if (composerComponent) {
        composerComponent.send('load', this.get('activePane.blocks'));
      }
    });
  },

  isComposerFocused() {
    return (
      $(':focus').hasClass('embercom-composer-editor') ||
      $(':focus').hasClass('embercom-prosemirror-composer-editor')
    );
  },

  maybeUpdateUserContentSeenState() {
    if (
      !this.conversationIsDraft &&
      this.isComposerFocused() &&
      !this.composerHasContent &&
      this.replySelected
    ) {
      UserContentSeenState.updateConversationForAdmin(this.conversation, this.realTimeEventService);
    }
  },

  doScroll() {
    next(this, function () {
      if (this.isComposerFocused()) {
        this.send('scrollStreamToBottom');
      }
    });
  },

  cancelTimers() {
    if (this.draftComponent) {
      this.draftComponent.send('cancelTimers');
    }
  },

  loadDraft() {
    return this.conversationsService.getDraft(this.conversation);
  },

  syncDraftClassAndActivePane() {
    if (!this.get('conversation.canReply')) {
      return;
    }

    let draft = this.loadDraft();
    if (draft.get('isEmpty')) {
      draft.set('subclass', this.activePaneIdentifier);
    } else {
      this.set('activePaneIdentifier', draft.get('subclass'));
    }
  },

  copyDraftToPaneCache() {
    if (this.conversationIsDraft) {
      let blocks = this.conversation.blocks;
      this.set('activePane.blocks', blocks);
      this.emptyBlocks = blocks?.length === 0;
      if (this.app.canUseMacros) {
        let actions = this.conversation.actions;
        for (let action of actions) {
          if (action.action_data) {
            action.actionData = action.action_data;
            delete action.action_data;
          }
        }
        this.send('loadMacrosActions', actions);
        this.emptyActions = actions.length === 0;
      }
    } else {
      let draft = this.loadDraft();
      this.set('activePane.blocks', draft.get('blocks'));
      if (this.app.canUseMacros) {
        this.send('loadMacrosActions', draft.get('actions'));
      }

      if (this.forceActivePaneToNote) {
        this.set('forceActivePaneToNote', false);
        this.send('focusComposer');
      }
    }
  },

  handleArticleSend(blocks) {
    if (!blocks) {
      return;
    }
    let linkBlocks = blocks.filterBy('type', 'link');
    if (linkBlocks.length > 0) {
      this.trackArticleSend(linkBlocks);
    }
  },

  trackArticleSend(articleBlocks) {
    this.intercomEventService.trackEvent('educate-event', {
      action: 'sent_in_conversation',
      object: 'article',
      place: 'conversation',
      owner: 'educate',
      conversation_id: this.get('conversation.id'),
      article_ids: articleBlocks.mapBy('articleId'),
    });
  },

  trackRepliedFromLocation() {
    if (this.isInbox) {
      this.intercomEventService.trackEvent('replied-from-inbox', {
        action: 'replied',
        object: 'conversation',
        place: 'inbox',
        owner: 'growth',
        conversation_id: this.conversation.id,
        isNote: this.get('conversation.lastPart.isNote') || false,
        hasArticle: this.get('conversation.lastPart.hasArticle'),
        hasMention: this.get('conversation.lastPart.hasMention'),
      });
    } else {
      this.intercomEventService.trackEvent('replied-from-conversations-list', {
        action: 'replied',
        object: 'conversation',
        place: 'conversations_list',
        owner: 'growth',
      });
    }
  },

  notifyNexus() {
    // never send a notification if the composer is empty or conversation is a draft
    if (!this.composerHasContent || this.conversationIsDraft) {
      return;
    }
    let admin = this.admin;
    let conversation = this.conversation;
    if (this.replySelected) {
      this.realTimeEventService.sendAdminIsTypingEvent(admin, conversation);
    } else {
      this.realTimeEventService.sendAdminIsTypingANoteEvent(admin, conversation);
    }
  },

  fetchBlocksFromComposer() {
    return this.composerComponent.getBlocks();
  },

  setCurrentComposerBlocks() {
    let blocks = this.fetchBlocksFromComposer();
    this.set('currentComposerBlocks', blocks);
  },

  fetchMacroFromComposer() {
    return this.composerComponent.macro;
  },

  fetchTotalAttachmentSize() {
    let blocks = this.fetchBlocksFromComposer();
    let attachmentListBlock = blocks.find(({ type }) => type === 'attachmentList');
    if (!attachmentListBlock) {
      return 0;
    }
    return attachmentListBlock.attachments.reduce(
      (total, attachment) => total + parseInt(attachment.size, 10),
      0,
    );
  },

  afterPartCreate(blocks) {
    this.setActivePaneIdentifier();
    this.handleArticleSend(blocks);
  },

  beforePartCreate(conversation) {
    this.cancelTimers();
    this.conversationsService.clearDraft(conversation);
    this.initPaneCache();
    if (!this.conversationIsDraft) {
      this.syncDraftClassAndActivePane();
    }
    this.copyDraftToPaneCache();
    this.loadPaneCacheIntoComposerAndFocus();
  },

  addAnalyticsEvent(action, object, context, models) {
    let data = {
      action,
      object,
      context,
    };
    if (models) {
      data.models = models;
    }
    this.intercomEventService.trackAnalyticsEvent(data);
  },

  _createPartAndSnooze(type, snoozeOptions, trackEvents, analytics) {
    let convo = this.conversation;
    let blocks = this.fetchBlocksFromComposer();
    let uploads = this.get('activePane.uploads');
    let macro = this.fetchMacroFromComposer();

    this.beforePartCreate(convo);

    if (this.shouldExecuteMacro(type, macro)) {
      let composerMacro = this.store.createRecord('composer-macro');
      composerMacro.createActionsFragments(macro.actions);

      composerMacro.addSnoozeAction(snoozeOptions);

      if (blocks.length === 0) {
        this._applyMacro(composerMacro, convo);
      } else {
        this.conversationsService
          .addPartAndSave(convo, type, blocks, uploads)
          .then(() => this._applyMacro(composerMacro, convo));
      }
    } else {
      this.conversationsService.addPartAndSnooze(convo, type, blocks, uploads, null, snoozeOptions);
    }

    if (trackEvents) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'snoozed',
        object: convo,
        context: 'with_send',
        ...analytics,
      });
    }
    this.trackRepliedFromLocation();
    this.afterPartCreate(blocks);
  },

  focusComposerCallback() {
    this.composerComponent.send('focus');
  },

  loadMacrosActionsCallback(actions) {
    this.composerComponent.setMacroOfActivePane(this.activePaneIdentifier);
    if (actions) {
      this.composerComponent.clearMacros();
      this.fetchMacroFromComposer().createActionsFragments(actions);
    } else {
      this.composerComponent.clearMacros();
    }
  },

  shouldExecuteMacro(type, macro) {
    let shouldExecute =
      this.app.canUseMacros && (type === 'comment' || type === 'note' || type === 'close');

    if (shouldExecute) {
      return !!macro && macro.actions.length > 0;
    }
    return false;
  },

  _updateDraft() {
    if (!this.conversationIsDraft && this.draftComponent) {
      this.draftComponent.send('updateDraft');
    }
  },

  _handleAttachmentChange() {
    if (this.app.canUseEmailAttachments && this.conversation.isDraft) {
      this.set(
        'hasTotalAttachmentSizeExceeded',
        this.fetchTotalAttachmentSize() > MAX_UPLOAD_SIZE_FOR_EMAIL_ATTACHMENTS,
      );
    }
  },

  _applyMacro(macro, conversation) {
    let previousAdminAssigneeId = conversation.admin_assignee_id;
    macro.applyToConversation(conversation);
    // If the macro assigned the conversation away from the current admin
    // and the admin hasn't left the converrsation,
    // we should switch to the note pane
    if (
      conversation.id === this.conversation.id &&
      this.admin.id !== this.conversation.admin_assignee_id &&
      previousAdminAssigneeId === this.admin.id
    ) {
      this.send('changeToNotePane');
    }
  },

  _insertMacroActions(actionsData) {
    let macro = this.composerComponent.macro;
    macro.createActionsFragments(actionsData);
    this.notifyPropertyChange('composerHasMacroActions');
    if (this.onMacroActionsUpdate) {
      this.onMacroActionsUpdate(macro);
    }
    if (actionsData.length > 0) {
      this.intercomEventService.trackEvent('macro_shared_in_conversations');
      this.intercomEventService.trackEvent('used_macro');
    }
  },

  actions: {
    insertBlock(type, block) {
      this.composerComponent.send('createBlock', type, block);
      this.send('focusComposer');
      this.doScroll();
    },

    insertBlockInCommentPane(type, block) {
      this.set('activePane.blocks', this.fetchBlocksFromComposer());
      this.set('activePaneIdentifier', 'comment');
      this.set('isComposerCollapsed', false);
      this.loadPaneCacheIntoComposerAndFocusAndInsertBlock(type, block);
    },

    paste(text) {
      this.composerComponent.send('paste', text, 'plain');
      this.doScroll();
    },

    removeFocus() {
      this.composerComponent.send('removeFocus');
    },

    uploadImage(files) {
      this.intercomEventService.trackEvent('inbox-image-inserted');
      this.composerComponent.send('uploadImage', files);
    },

    uploadAttachment(files) {
      this.intercomEventService.trackEvent('inbox-attachment-added');

      if (this.app.canUseEmailAttachments && this.conversation.isDraft) {
        this.intercomEventService.trackEvent('inbox-attachment-for-email');
        let currentFilesTotalSize = files.reduce((total, { size }) => total + size, 0);
        if (
          currentFilesTotalSize + this.fetchTotalAttachmentSize() >
          MAX_UPLOAD_SIZE_FOR_EMAIL_ATTACHMENTS
        ) {
          this.composerComponent.send('insertInvalidAttachments', files, 'Failed');
          this.set('hasTotalAttachmentSizeExceeded', true);
        } else {
          this.composerComponent.send('uploadAttachment', files);
          this.set('hasTotalAttachmentSizeExceeded', false);
        }
      } else {
        this.composerComponent.send('uploadAttachment', files);
      }
    },

    focusComposer() {
      scheduleOnce('afterRender', this, this.focusComposerCallback);
    },

    loadMacrosActions(actions) {
      scheduleOnce('afterRender', this, this.loadMacrosActionsCallback, actions);
    },

    focusComposerTab(tab) {
      if (!isEmpty(tab)) {
        this.send('changeActivePaneAndShowComposer', tab);
        this.send('focusComposer');
      }
    },

    createSavedReply() {
      this.set('savedReplyBeingCreated', true);
    },

    createSavedReplyFinished() {
      this.set('savedReplyBeingCreated', false);
    },

    createPart(type, trackEvents = true) {
      let convo = this.conversation;
      let blocks = this.fetchBlocksFromComposer();
      let uploads = this.get('activePane.uploads');
      let macro = this.fetchMacroFromComposer();

      this.beforePartCreate(convo);

      if (this.shouldExecuteMacro(type, macro)) {
        let composerMacro = this.store.createRecord('composer-macro');
        composerMacro.createActionsFragments(macro.actions);

        if (type === 'close') {
          composerMacro.addCloseAction();
          // we change the type to comment so that the close is performed by the macro
          type = 'comment';
        }

        if (blocks.length === 0) {
          this._applyMacro(composerMacro, convo);
        } else {
          this.conversationsService
            .addPartAndSave(convo, type, blocks, uploads)
            .then(() => this._applyMacro(composerMacro, convo));
        }
      } else if (convo.hasRequiredAttributesWithEmptyValue && type === 'close') {
        let sendCommentAndThenClose = async () => {
          if (blocks?.length || uploads?.length) {
            await this.conversationsService.addPartAndSave(convo, 'comment', blocks, uploads);
          }
          this.conversationCloseService.closeConversation(convo);
        };
        sendCommentAndThenClose();
      } else {
        this.conversationsService.addPartAndSave(convo, type, blocks, uploads);
      }

      if (trackEvents) {
        if (type === 'close') {
          this.addAnalyticsEvent('closed', convo, 'with_send');
        } else if (this.conversation.isAssignedTo(this.admin)) {
          this.addAnalyticsEvent('sent', convo.get('lastPart'), 'from_composer_button', [convo]);
        } else {
          this.addAnalyticsEvent('opened', convo, 'with_send');
        }
      }
      this.trackRepliedFromLocation();
      this.afterPartCreate(blocks);
    },

    createPartAndSnoozeWithPresetTimer(type, snoozedUntil, trackEvents = true) {
      let snoozeOptions = {
        snoozedUntil,
        customSnoozedUntil: SNOOZED_UNTIL_MAP[snoozedUntil].absoluteUnsnoozeTime,
      };
      let analytics = {
        snoozed_until: SNOOZED_UNTIL_MAP[snoozedUntil].key,
      };
      this._createPartAndSnooze(type, snoozeOptions, trackEvents, analytics);
    },

    createPartAndSnoozeWithCustomTimer(type, customSnoozedUntil, trackEvents = true) {
      let snoozeTime = moment(customSnoozedUntil);
      let timeOfDay = snoozeTime.format('HH:mm');
      let analytics = {
        snoozed_until: 'custom_timer',
        days_snoozed: snoozeTime.startOf('day').diff(moment().startOf('day'), 'days'),
        time_of_day: timeOfDay,
      };
      this._createPartAndSnooze(
        type,
        { snoozedUntil: 'custom_timer', customSnoozedUntil },
        trackEvents,
        analytics,
      );
    },

    onComposerChange() {
      this._updateDraft();
      this._handleAttachmentChange();
    },

    onComposerKeyDown() {
      scheduleOnce('afterRender', this, this.notifyNexus);
    },

    changeToNotePane() {
      if (!this.composerHasContent) {
        this.set('isComposerCollapsed', false);
        this.set('forceActivePaneToNote', true);
        this.set('activePaneIdentifier', 'note');
      }
      this.send('focusComposer');
    },

    changeActivePaneAndShowComposer(newPaneIdentifier) {
      if (newPaneIdentifier === 'comment' && !this.adminCanReply) {
        return;
      }
      this._updateDraft();
      this.set('activePane.blocks', this.fetchBlocksFromComposer());
      this.set('activePaneIdentifier', newPaneIdentifier);
      this.set('isComposerCollapsed', false);
      this.loadPaneCacheIntoComposerAndFocus();
      if (newPaneIdentifier === 'comment') {
        this.loadMessageText(); // reload message after switching back for correct SMS length (sms-info-component)
      }
    },

    showMessageTemplatesModal() {
      this.set('messageTemplatesModalVisible', true);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'opened',
        object: 'message_templates_modal',
        conversation_id: this.conversation.id,
      });
    },

    scrollStreamToBottom() {
      this.scrollStreamToBottom?.();
    },

    insertSavedReply(savedReply) {
      if (isNone(savedReply)) {
        return;
      }
      let conversationId = this.get('conversation.id');
      let savedReplyId = savedReply.get('id');
      if (this.conversation.isDraft) {
        this.composerComponent.send('insertBlocks', savedReply.blocks);
        if (this.app.canUseMacros) {
          this._insertMacroActions(savedReply.actions);
        }
        this.set('isSavedRepliesPopoverOpen', false);
        this.doScroll();
      } else {
        SavedReply.getRendered(savedReplyId, this.get('app.id'), conversationId).then((data) => {
          if (conversationId !== this.conversation.id) {
            // If a teammate switches conversation while rendering the reply, don't insert
            return;
          }
          this.composerComponent.send('insertBlocks', data.blocks);
          if (this.app.canUseMacros) {
            this._insertMacroActions(savedReply.actions);
          }
          this.set('isSavedRepliesPopoverOpen', false);
          this.intercomEventService.trackEvent('saved-reply-popover-inserted-saved-reply', {
            id: savedReplyId,
            conversation_id: conversationId,
          });
          this.savedReplyInsertionsService.recordInsertion(
            this.app,
            this.admin,
            savedReply,
            conversationId,
          );
          this.doScroll();
        });
      }
    },

    onMacroActionsUpdate() {
      this._updateDraft();
    },

    clearSuggestions() {
      let articleSuggestionsComponent = this.articleSuggestionsComponent;
      if (articleSuggestionsComponent) {
        articleSuggestionsComponent.send('clearSuggestions');
      }
    },

    registerOpenInserter(inserterName) {
      this.openInserters.pushObject(inserterName);
    },

    clearOpenInserter(inserterName) {
      if (!this.isDestroying) {
        this.openInserters.removeObject(inserterName);
      }
    },

    expandComposer() {
      if (this.adminCanReply) {
        this.set('isComposerCollapsed', false);
      }
    },
  },
});
