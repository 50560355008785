/* import __COLOCATED_TEMPLATE__ from './dropoff-node.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';

export default class DropoffNode extends Component {
  get dropoffPercentage() {
    return (this.args.dropoff / this.args.views) * 100;
  }

  get roundDropoffPercentage() {
    return Math.round(this.dropoffPercentage) || 0;
  }
}
