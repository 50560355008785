/* import __COLOCATED_TEMPLATE__ from './required-attribute-form.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { dasherize } from '@ember/string';

export default class RequiredAttributeForm extends Component {
  get descriptorName() {
    return this.args.descriptor.name;
  }

  get normalizedDataType() {
    return dasherize(this.args.descriptor.dataType);
  }

  get isSelectionType() {
    return ['boolean', 'list'].includes(this.normalizedDataType);
  }

  get selectedValue() {
    let value = this.args.attributeValue.value;
    if (value === '' || value === null) {
      return undefined;
    }
    return value;
  }

  get options() {
    if (this.normalizedDataType === 'boolean') {
      return [
        { text: 'True', value: true },
        { text: 'False', value: false },
      ];
    }

    return this.args.descriptor.listOptions
      .filter((option) => !option.archived || option.id === this.args.attributeValue.value)
      .map((option) => ({
        text: option.label,
        value: option.id,
      }));
  }

  @action
  itemSelected(newValue) {
    this.args.attributeValue.value = newValue;
  }
}
