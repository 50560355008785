/* import __COLOCATED_TEMPLATE__ from './insert-app-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  intercomEventService: service(),
  classNames: ['inbox__conversation-controls__inserter'],

  actions: {
    insertMessengerCard(cardJSON) {
      this.get('controlsAPI.actions.insertBlock')('messengerCard', cardJSON);
    },
    onPopoverOpen(opener) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: this.analyticsObject,
        conversation_id: this.get('controlsAPI.conversation.id'),
        app_package_id: this.get('app.app_package_id'),
        messenger_app_id: this.get('app.id'),
      });
    },
    closePopover() {},
  },
});
