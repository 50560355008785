/* import __COLOCATED_TEMPLATE__ from './admin-link-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { not, empty, reads } from '@ember/object/computed';
import ConversationPartComponentBase from 'embercom/components/conversation/stream/conversation-part-component-base';

export default ConversationPartComponentBase.extend({
  taggingsToDisplay: reads('part.nonEmptyTaggings'),
  hasNoTaggingsToDisplay: empty('taggingsToDisplay'),
  hasTaggingsToDisplay: not('hasNoTaggingsToDisplay'),
});
