/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type Profile from 'embercom/models/ai-agent/profile';

interface Args {
  section?: $TSFixMe; // section from Common::AccordionComponent - not typed
  index?: number;
  config?: $TSFixMe; // config object from embercom/objects/content-editor/component-configuration - not typed
}

interface Signature {
  Args: Args;
}

export default class BotIntroBody extends Component<Signature> {
  @service declare contentEditorService: any;
  @service declare appService: any;
  @service declare store: Store;

  get behavior(): Profile | undefined {
    return this.contentEditorService.activeObject;
  }

  get previewConfiguration() {
    return this.store.peekRecord('messenger-settings/all', this.appService.app.id)
      .previewConfiguration;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Panels::Custom::AiAgentProfile::BotIntro::Body': typeof BotIntroBody;
    'content-editor/panels/custom/ai-agent-profile/bot-intro/body': typeof BotIntroBody;
  }
}
