/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class UseTourAnywhereBody extends Component {
  @service contentEditorService;
  @tracked isInsertable = this.tour.clientData.insertable;

  get tour() {
    return this.contentEditorService.activeObject;
  }

  @action
  setInsertable(newValue) {
    if (!this.tour.clientData) {
      this.tour.clientData = {};
    }
    this.isInsertable = newValue;
    this.tour.clientData.insertable = newValue;
  }
}
