/* import __COLOCATED_TEMPLATE__ from './step-analysis.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Task from 'embercom/models/checklists/task';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { post } from 'embercom/lib/ajax';
import { action } from '@ember/object';

interface Args {}

export default class StepAnalysis extends Component<Args> {
  @service declare contentEditorService: any;
  @service declare appService: any;
  @service declare intl: IntlService;

  @tracked stepData?: any;

  WARNING_DATE = '2022-12-09';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.fetchStepStats).perform();
  }

  get app() {
    return this.appService.app;
  }

  get checklist() {
    return this.contentEditorService.activeObject;
  }

  get rulesetLink() {
    return this.contentEditorService.activeRulesetLink;
  }

  get receiptCount() {
    // todo: Add types for the content-statistic object?
    let receiptStat = this.rulesetLink.objectStats.findBy('key', statisticKeys.receipts);
    return receiptStat.value;
  }

  get showWarningBanner() {
    if (!this.rulesetLink.isLive || !this.rulesetLink.wentLiveAt) {
      return false;
    }

    let wentLiveAt = new Date(this.rulesetLink.wentLiveAt).getTime();
    let warningDate = new Date(this.WARNING_DATE).getTime();
    return wentLiveAt < warningDate;
  }

  get tableData() {
    return this.checklist.tasks.map((task: Task) => ({
      step: task.humanReadableOrder,
      stepId: task.id,
      title: task.humanReadableTitle || this.intl.t('outbound.checklists.step-anaylysis.untitled'),
      viewed: this.stepData?.stat_counts_by_step_id[task.id]?.stats_open,
      actionClicks: this.stepData?.stat_counts_by_step_id[task.id]?.stats_click,
      completed: this.stepData?.stat_counts_by_step_id[task.id]?.stats_completion,
      completionType: {
        manual: this.stepData?.completion_type_by_step_id?.[task.id]?.[0] || 0,
        auto: this.stepData?.completion_type_by_step_id?.[task.id]?.[1] || 0,
      },
    }));
  }

  @task
  *fetchStepStats(): TaskGenerator<void> {
    let response = yield post('/ember/checklists/step_analysis_stats', {
      app_id: this.app.id,
      admin_id: this.app.currentAdmin.id,
      id: this.checklist.id,
    });
    this.stepData = response;
  }

  @action changeView(view: string, filter: string) {
    this.contentEditorService.changeActiveViewTo(view);
    this.contentEditorService.changeActiveFilterTo(filter);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::ViewMode::Checklist::StepAnalysis': typeof StepAnalysis;
    'content-editor/view-mode/checklist/step-analysis': typeof StepAnalysis;
  }
}
