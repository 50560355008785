/* import __COLOCATED_TEMPLATE__ from './getting-started-guide.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { compareBlocks } from 'embercom/lib/developer-hub/diff-calculator';

export default class GettingStartedGuide extends Component {
  @service developerHub;
  @service appService;
  @service store;

  @tracked composerComponent;
  @tracked startingGuideConfig =
    this.appPackage.changeRequest.appPackageConfig.startingGuide ||
    this.store.createFragment('developer-hub/starting-guide');

  get app() {
    return this.appService.app;
  }

  get appPackage() {
    return this.developerHub.appPackage;
  }

  @action update() {
    let blocks = this.composerComponent.getBlocks();
    let { hasDifference } = compareBlocks(blocks, this.startingGuideConfig.content);
    if (hasDifference) {
      this.startingGuideConfig.content = blocks;
      this.args.onUpdate(this.startingGuideConfig);
    }
  }
}
