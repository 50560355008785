/* import __COLOCATED_TEMPLATE__ from './edit-mode.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class EditMode extends Component {
  @service contentEditorService;

  @action markClientDataAsOpened() {
    if (!this.contentEditorService.ruleset.isMemberOfSeries) {
      return;
    }
    this.contentEditorService.ruleset.clientData.openedSeriesEditor = true;
  }
}
