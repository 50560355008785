/* import __COLOCATED_TEMPLATE__ from './client-predicates-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { copy } from 'ember-copy';

export default class ClientPredicatesEditor extends Component {
  @action onUpdateClientPredicates(predicates) {
    this.args.ruleset.get('clientPredicateGroup.predicates', copy(predicates));
  }
}
