/* import __COLOCATED_TEMPLATE__ from './app-package-details.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class AppPackageDetails extends Component {
  @action
  redirectToApp() {
    let { id, ownerAppId } = this.args.row;
    window.location.href = `/a/apps/${ownerAppId}/developer-hub/app-packages/${id}`;
  }
}
