/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CUSTOM_BOT_CONFIG } from 'embercom/objects/operator/configuration/configuration';

export default class Summary extends Component {
  @service contentEditorService;
  @service appService;

  @tracked customBot = this.contentEditorService.activeObject;
  @tracked app = this.appService.app;

  triggerPredicates = this.customBot?.config?.triggerPredicates;

  get previewDeviceType() {
    if (['android', 'ios'].includes(this.customBot.deliveryChannel?.type)) {
      return this.customBot.deliveryChannel.type;
    }

    if (['mobile'].includes(this.customBot.deliveryChannel?.type)) {
      return 'ios';
    }

    return 'web';
  }

  get editorConfiguration() {
    let { editorConfig } = CUSTOM_BOT_CONFIG[this.customBot.target];
    if (!editorConfig) {
      throw new Error(
        `Cannot resolve path configuration class for custom bot type=${this.customBot.type}`,
      );
    }
    return editorConfig;
  }

  get selectedTriggerPredicates() {
    if (!this.triggerPredicates || !this.isLogicalTriggerPredicate(this.triggerPredicates.type)) {
      return [];
    }

    // Assumed that there is only one predicate group
    let selectedPredicates =
      this.customBot.triggerPredicateGroup.predicates.firstObject?.predicates?.map(
        (predicate) => predicate.value,
      );

    if (!selectedPredicates) {
      return [this.triggerPredicates.options.firstObject];
    }

    return this.triggerPredicates.options.filter((option) =>
      selectedPredicates.includes(option.value),
    );
  }

  isLogicalTriggerPredicate(type) {
    return ['or', 'and'].includes(type);
  }
}
