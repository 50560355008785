/* import __COLOCATED_TEMPLATE__ from './composer-suggestions.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import renderPreview from '@intercom/embercom-composer/lib/render-preview';
import { htmlToTextContent } from 'embercom/lib/html-unescape';

export default Component.extend({
  classNames: [
    'messenger-preview__home__scrolling-area flex-auto custom-bots__preview__composer-suggestions__container',
  ],
  prompt: computed('firstSteps', function () {
    let blockText = this.firstSteps.get('firstObject.serializedBlocks.firstObject.text') || '';
    let previewHtml = renderPreview(blockText, {});
    return htmlToTextContent(previewHtml);
  }),
  suggestions: computed('firstSteps', function () {
    return this.firstSteps.get('lastObject.controls').mapBy('buttonLabel');
  }),
});
