/* import __COLOCATED_TEMPLATE__ from './article-suggestions.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly, and, gt, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import InboundActions from 'ember-component-inbound-actions/inbound-actions';
import { task } from 'ember-concurrency';

export default Component.extend(InboundActions, {
  intercomEventService: service(),
  appService: service(),
  store: service(),
  appstoreService: service(),

  app: readOnly('appService.app'),
  isKnowledgebaseSupported: and('replySelected', 'conversation.isNotSocial'),
  hasKnowledgebaseArticles: gt('app.articleCount', 0),
  hasArticles: readOnly('app.hasEmbeddedArticles'),
  doesNotHaveArticles: not('hasArticles'),
  inserterAppInstalled: false,

  isArticlesPaywalled: and(
    'isKnowledgebaseSupported',
    'hasKnowledgebaseArticles',
    'doesNotHaveArticles',
  ),

  didReceiveAttrs() {
    this._super(...arguments);
    let conversation = this.conversation;
    if (conversation !== this.previousConversation) {
      this.set('previousConversation', conversation);
      this.retrieveSuggestions.perform();
    }
  },

  checkInserterInstalledTask: task(function* () {
    let record = yield this.appstoreService.peekOrFindAppPackage('article-inserter');
    this.set('inserterAppInstalled', record && record.isInstalled);
  }),

  retrieveSuggestions: task(function* () {
    this.get('controlsAPI.actions.clearSuggestions')();

    yield this.checkInserterInstalledTask.perform();

    if (this.inserterAppInstalled) {
      let response = yield this.conversation.fetchSuggestions();
      let store = this.store;
      let suggestions = response.suggestions.map((s) => {
        return store.push(store.normalize('article', s));
      });
      this.set('suggestions', suggestions);

      let ids = suggestions.map((s) => s.id);
      if (ids.length > 0) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'received',
          object: 'operator_reply',
          place: 'conversation',
          conversation_id: this.get('conversation.id'),
          article_ids: ids,
        });
      }
    }
  }).restartable(),

  insertCardTask: task(function* (article) {
    return yield article.createMessengerCard('en', this.get('controlsAPI.conversation.id'));
  }).drop(),

  insertArticleIntoComposerTask: task(function* (article) {
    let card = yield this.insertCardTask.perform(article);
    this.get('controlsAPI.actions.insertBlock')('messengerCard', card);
  }).drop(),

  actions: {
    clearSuggestions() {
      this.set('suggestions', []);
    },
  },
});
