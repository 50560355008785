/* import __COLOCATED_TEMPLATE__ from './created-by-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { inject as service } from '@ember/service';
import type Admin from 'embercom/models/admin';

interface Args {
  contentWrapper: ContentWrapper;
}

export default class CreatedByCell extends Component<Args> {
  @service store: any;

  get createdBy(): Admin | undefined {
    return this.store.peekRecord(
      'admin',
      this.args.contentWrapper.contents.firstObject?.createdById,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::ContentReviews::CreatedByCell': typeof CreatedByCell;
  }
}
