/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import Component from '@glimmer/component';
import AiAgentProfileEditorConfig from 'embercom/objects/visual-builder/configuration/editor/ai-agent-profile';

export default class GenericTriageHeader extends Component {
  @service contentEditorService;
  @service intl;

  constructor() {
    super(...arguments);
    this.setEditorConfigs();
  }

  setEditorConfigs() {
    let genericTriageLocalizations =
      this.contentEditorService.activeObject.genericTriageLocalizations;

    if (genericTriageLocalizations) {
      genericTriageLocalizations.forEach((localization) => {
        if (localization.visualBuilderObject) {
          localization.visualBuilderObject.editorConfig = this.editorConfig;
        }
      });
    }
  }

  get editorConfig() {
    return new AiAgentProfileEditorConfig({
      targetChannels: this.contentEditorService.activeObject.targetChannels,
    });
  }

  get description() {
    if (!this.contentEditorService.activeObject.modeEligibleForGenericTriage) {
      return this.intl.t('ai-agent.profiles.handover.disabled');
    }

    let allLocalizations = this.contentEditorService.activeObject.genericTriageLocalizations;

    let enabledLocalizations = allLocalizations
      .filter((localization) => localization.enabled === true)
      .map((localization) =>
        capitalize(this.intl.t(`app.lib.languages.language-code.${localization.locale}`)),
      );

    if (allLocalizations.length === 1 && enabledLocalizations.length === 1) {
      return this.intl.t('ai-agent.profiles.handover.enabled');
    } else if (enabledLocalizations.length === 0) {
      return this.intl.t('ai-agent.profiles.handover.disabled');
    } else {
      return this.intl.t('ai-agent.profiles.bot-only.enabled-localizations', {
        list: this.intl.formatList(enabledLocalizations, { style: 'short', type: 'unit' }),
      });
    }
  }
}
