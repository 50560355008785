/* import __COLOCATED_TEMPLATE__ from './no-trigger-bot-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import {
  matchBehaviors,
  objectTypes,
  permissionsMap,
  states,
} from 'embercom/models/data/matching-system/matching-constants';

import Component from '@glimmer/component';
import { NO_TRIGGER_TARGET } from 'embercom/lib/operator/custom-bots/constants';
import Ruleset from 'embercom/models/matching-system/ruleset';
import { TRIGGERABLE_BOT_TYPE } from 'embercom/lib/operator/custom-bots/constants';
import { action } from '@ember/object';
import { isBlank } from '@ember/utils';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class NoTriggerBotSelector extends Component {
  @service appService;
  @service intl;
  @service notificationsService;
  @service permissionsService;
  @service router;
  @service store;

  @tracked showBotCreationModal = false;

  noTriggerObjectType = objectTypes.triggerableCustomBot;
  noTriggerTarget = NO_TRIGGER_TARGET;
  permissionKey = permissionsMap[objectTypes.triggerableCustomBot];

  get app() {
    return this.appService.app;
  }

  get hasWorkflows() {
    return this.args.workflows.length > 0;
  }

  get items() {
    if (this.args.isLoading) {
      return [
        {
          value: null,
          component: 'operator-flows/editor/trigger-workflow/loading-state',
          componentShouldReplaceItem: true,
        },
      ];
    }
    if (!this.hasWorkflows) {
      return [
        {
          value: null,
          component: 'operator-flows/editor/trigger-workflow/empty-state',
          componentShouldReplaceItem: true,
        },
      ];
    }
    return [
      {
        items: this.args.workflows.map((wf) => ({
          component: 'operator-flows/editor/trigger-workflow/no-trigger-bot-option',
          text: wf.title,
          value: wf.workflowId,
          icon: 'bot-filled',
          botId: wf.botId,
          state: wf.state,
          live: wf.state === states.live,
          isDisabled: wf.state !== states.live,
          tooltipText:
            wf.state !== states.live
              ? this.intl.t(
                  'operator.custom-bot.editor.no-trigger-bot-selector.require-set-bot-live',
                )
              : undefined,
        })),
      },
    ];
  }

  @action
  async createCustomBot() {
    if (!this.canCreateCustomBot()) {
      await this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(this.permissionKey);
      this.toggleBotCreationModal();
      return;
    }

    let params = {
      app_id: this.app.id,
      object_type: objectTypes.triggerableCustomBot,
      match_behavior: matchBehaviors.transient,
      object_data: { type: TRIGGERABLE_BOT_TYPE },
    };

    try {
      let ruleset = await Ruleset.createForType(this.store, params);
      safeWindowOpen(
        this.router.urlFor('apps.app.automation.workflows.edit', ruleset.id, {
          queryParams: { mode: 'edit' },
        }),
      );
      this.showBotCreationModal = false;
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'operator.custom-bot.editor.no-trigger-bot-selector.could-not-create-bot',
        ),
      });
    }
  }

  @action
  toggleBotCreationModal() {
    this.showBotCreationModal = !this.showBotCreationModal;
  }

  @action
  onSelectItem(workflowId) {
    let selectedWorkflow = this.args.workflows.find((wf) => wf.workflowId === workflowId);
    this.args.onSelectItem(selectedWorkflow);
  }

  canCreateCustomBot() {
    if (isBlank(this.permissionKey)) {
      return true;
    }

    return this.permissionsService.currentAdminCan(this.permissionKey);
  }
}
