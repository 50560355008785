/* import __COLOCATED_TEMPLATE__ from './date-with-timezone-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { not, notEmpty, or } from '@ember/object/computed';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';

export default Component.extend({
  tagName: '',

  date: null,
  timezone: 'America/Los_Angeles',
  disableTooltip: false,

  _sameRelativeUtcOffset: computed(function () {
    // Exposed for testing
    return moment.tz(this.timezone).utcOffset() === moment().utcOffset();
  }),

  init() {
    this._super(...arguments);
    this.set('sameRelativeUtcOffset', this._sameRelativeUtcOffset);
  },

  tooltipDisabled: or('notSameRelativeUtcOffset', 'disableTooltip'),

  sameRelativeUtcOffset: false,
  notSameRelativeUtcOffset: not('sameRelativeUtcOffset'),

  formattedDateOnly: computed('hasDate', 'date', function () {
    return moment(this.date)
      .tz(this.timezone)
      .format(dateAndTimeFormats.fullDateAndTimeWithoutTimezoneWithDayName);
  }),

  formattedTimezoneName: computed('timezone', function () {
    return this.timezone.slice(this.timezone.lastIndexOf('/') + 1).replace(/_/g, ' ');
  }),
  formattedUtcOffset: computed('timezone', function () {
    return moment
      .tz(this.timezone)
      .format('[GMT]Z')
      .replace(/0(\d:)/, '$1')
      .replace(':00', ''); //Should look like GMT+1 or GMT+5:30
  }),
  formattedTimezone: computed('formattedTimezoneName', 'formattedUtcOffset', function () {
    return `${this.formattedTimezoneName} time (${this.formattedUtcOffset})`;
  }),

  formattedDateWithTimezone: computed('formattedDateOnly', 'formattedTimezone', function () {
    return `${this.formattedDateOnly} ${this.formattedTimezone}`;
  }),
  formattedValidDate: ternaryToProperty(
    'sameRelativeUtcOffset',
    'formattedDateOnly',
    'formattedDateWithTimezone',
  ),

  hasDate: notEmpty('date'),
  missingDateString: 'Unknown time',
  formattedDate: ternaryToProperty('hasDate', 'formattedValidDate', 'missingDateString'),
});
