/* import __COLOCATED_TEMPLATE__ from './conversation-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { CONVERSATION_STATES } from 'embercom/lib/inbox/constants';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';

export default Component.extend({
  classNames: ['conversation__mini-card__app-sidebar'],
  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),

  formattedToolTipDate: computed('conversation.latest_update_at', function () {
    return moment(this.get('conversation.latest_update_at')).format(
      dateAndTimeFormats.fullDateAndTimeWithoutTimezone,
    );
  }),
  actionSubject: computed('conversation.lastActionPart', 'conversation.assignee', function () {
    if (
      this.get('conversation.lastActionPart.isCloser') ||
      this.get('conversation.lastActionPart.isSnoozer')
    ) {
      return this.get('conversation.lastActionPart.admin');
    } else {
      return this.get('conversation.assignee');
    }
  }),
  statePreposition: computed('conversation.state', function () {
    if (this.get('conversation.state') === CONVERSATION_STATES.open) {
      return 'with';
    } else {
      return 'by';
    }
  }),
  partToBeDisplayed: computed(
    'conversation.isClosed',
    'conversation.firstUserPart',
    'conversation.lastPartWithContentExcludingOperator',
    function () {
      if (this.conversation.isClosed && this.conversation.firstUserPart) {
        return this.conversation.firstUserPart;
      } else {
        return this.conversation.lastPartWithContentExcludingOperator;
      }
    },
  ),
});
