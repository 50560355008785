/* import __COLOCATED_TEMPLATE__ from './gif-popover-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: ['gif__popover'],
  query: '',

  intercomEventService: service(),
  onboardingHomeExternalStepService: service(),

  didInsertElement() {
    this._super(...arguments);

    if (this.onboardingHomeExternalStepService.isExternalStepRunning('resolve_a_conversation')) {
      this.runSearchTask('Great');
    } else {
      this.runSearchTask('');
    }

    this.intercomEventService.trackAnalyticsEvent({
      owner: 'inbox',
      action: 'clicked',
      object: 'gif_inserter',
      place: 'inbox',
      context: 'gif_inserter',
    });
  },

  search(query) {
    let appId = this.get('controlsAPI.app.id');
    return ajax({
      url: '/ember/gifs',
      data: {
        query,
        app_id: appId,
      },
    }).catch(() => {
      return { results: [] };
    });
  },

  searchTask: task(function* (query) {
    this.set('query', query);
    let response = yield this.search(query);
    this.set('results', response.results);
    this.set('isLoading', false);
  }).drop(),

  runSearchTask(query) {
    this.searchTask.perform(query);
  },

  selectImage: action(function (image) {
    this.get('controlsAPI.actions.insertBlock')('image', image);
    this.get('inserterAPI.actions.close')();
  }),

  updateResults: action(function (event) {
    let query = event.target.value;
    this.set('isLoading', true);
    debounce(this, this.runSearchTask, query, 1000);
  }),

  clearSearch: action(function () {
    this.set('query', '');
    this.set('isLoading', true);
    debounce(this, this.runSearchTask, '', 1000);
  }),
});
