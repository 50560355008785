/* import __COLOCATED_TEMPLATE__ from './segments-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
/*  */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import type AiContentSegment from 'embercom/models/ai-content-segment';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';

interface Args {
  contentWrapper: ContentWrapper & { relations: any & { content_segment_ids: Array<number> } };
}

const NUMBER_OF_SEGMENTS_TO_DISPLAY = 2;

export default class SegmentsCell extends Component<Args> {
  @service declare aiContentSegmentsService: AiContentSegmentsService;

  @computed(
    'args.contentWrapper.relations.content_segment_ids.[]',
    'aiContentSegmentsService.segments.[]',
  )
  get names(): Array<string> {
    let segmentIds = this.args.contentWrapper.relations.content_segment_ids ?? [];
    return segmentIds
      .map((id: number) => {
        return this.aiContentSegmentsService.segments.find(
          (segment: AiContentSegment) => Number(segment.id) === Number(id),
        )?.nameWithFallback;
      })
      .filter((value: string | undefined) => value);
  }

  get namesToDisplay(): Array<string> {
    return this.names.slice(0, NUMBER_OF_SEGMENTS_TO_DISPLAY);
  }

  get moreCount(): number {
    return this.names.length - NUMBER_OF_SEGMENTS_TO_DISPLAY;
  }

  get moreNames() {
    return this.names.slice(NUMBER_OF_SEGMENTS_TO_DISPLAY, this.names.length);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Cells::SegmentsCell': typeof SegmentsCell;
  }
}
