/* import __COLOCATED_TEMPLATE__ from './root.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitForProperty } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';

const ALL_BOTS_ITEM_VALUE = '*';
const MANUAL_TRIGGER_ITEM_VALUE = 'manual_trigger';

export default class Root extends Component {
  @service appService;
  @service notificationsService;
  @service store;
  @service intercomEventService;
  @service intl;

  @tracked dropdownItems;
  @tracked dropdownParentWorkflowItems;
  @tracked selectedSnapshotId;
  @tracked selectedSnapshot;
  @tracked selectedParentWorkflowInstanceId;
  @tracked journeyMap;

  constructor() {
    super(...arguments);
    this.loadJourneysForCurrentSnapshot.perform();
  }

  get app() {
    return this.appService.app;
  }

  get startingJourney() {
    return [this.startingPath._id];
  }

  get startingPath() {
    return this.selectedSnapshot.paths.firstObject;
  }

  get hasSelectedSnapshotId() {
    return this.selectedSnapshotId !== undefined;
  }

  get hasSelectedParentWorkflowInstanceId() {
    return this.selectedParentWorkflowInstanceId !== undefined;
  }

  get headerDataHasLoaded() {
    if (this.showParentWorkflowDropdown) {
      return (
        this.hasSelectedSnapshotId &&
        this.dropdownItems !== undefined &&
        this.hasSelectedParentWorkflowInstanceId &&
        this.dropdownParentWorkflowItems !== undefined
      );
    }

    return this.hasSelectedSnapshotId && this.dropdownItems !== undefined;
  }

  get bodyDataHasLoaded() {
    return this.journeyMap !== undefined && this.selectedSnapshot !== undefined;
  }

  get showParentWorkflowDropdown() {
    return this.args.customBot.isNoTrigger;
  }

  buildJourneyMap(journeys) {
    let map = new Map();

    journeys.map((journey) => {
      map.set(journey.paths.join(','), journey.control_executions);
    });

    return map;
  }

  buildVersionDropdownItems(summaries) {
    let dropdownItems = [];
    let initialDataTimestamp = new Date('2019-10-02');

    summaries.forEach((summary) => {
      dropdownItems.push({
        text: summary.created_at,
        value: summary.id,
        component: 'custom-bots/path-analysis/root/version-dropdown-item',
      });
    });

    // Add a note at the bottom of the dropdown if we are showing a snapshot prior to
    // October 2nd 2019 (the first full day of Path analysis data in ElasticSearch)
    if (new Date(summaries.lastObject.created_at) < initialDataTimestamp) {
      dropdownItems.push({
        text: this.intl.t(
          'operator.custom-bot.path-analysis.root.dropdown-snapshot-versions-item-data-collection-date',
        ),
        value: '0',
        component: 'custom-bots/path-analysis/root/version-dropdown-data-note',
        isDisabled: true,
      });
    }

    return [{ items: dropdownItems }];
  }

  buildParentWorkflowStatsDropdownItems(stats) {
    let dropdownItems = [
      {
        items: [
          {
            text: this.intl.t(
              'operator.custom-bot.path-analysis.root.dropdown-parent-workflows-item-all-bots',
            ),
            value: ALL_BOTS_ITEM_VALUE,
            count: stats.total_count.toString(),
          },
          {
            text: this.intl.t(
              'operator.custom-bot.path-analysis.root.dropdown-parent-workflows-item-manually-triggered',
            ),
            value: MANUAL_TRIGGER_ITEM_VALUE,
            count: stats.workflow_instances
              .reduce(
                (total, workflowInstance) => total - workflowInstance.count,
                stats.total_count,
              )
              .toString(),
          },
        ],
      },
    ];

    let parentWorkflowItems = stats.workflow_instances.map((workflowInstance) => ({
      text: workflowInstance.title,
      value: workflowInstance.id.toString(),
      count: workflowInstance.count.toString(),
    }));
    if (parentWorkflowItems.length > 0) {
      dropdownItems.push({
        heading: this.intl.t('operator.custom-bot.path-analysis.filter-by-bot'),
        items: parentWorkflowItems,
      });
    }

    return dropdownItems;
  }

  @action updateSelectedSnapshotId(snapshotId) {
    this.journeyMap = undefined;
    this.selectedSnapshotId = snapshotId;
    this.fetchSnapshot.perform();
    if (this.showParentWorkflowDropdown) {
      this.selectedParentWorkflowInstanceId = ALL_BOTS_ITEM_VALUE;
      this.loadParentWorkflowStats.perform();
    }
    this.loadJourneysForSelectedSnapshot.perform();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'path-analysis-version-dropdown',
      custom_bot_id: this.args.customBot.id,
      custom_bot_target: this.args.customBot.target,
      custom_bot_snapshot_id: snapshotId,
    });
  }

  @action updateSelectedParentWorkflowInstanceId(parentWorkflowInstanceId) {
    this.journeyMap = undefined;
    this.selectedParentWorkflowInstanceId = parentWorkflowInstanceId;
    this.loadJourneysForSelectedSnapshot.perform();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'path-analysis-parent-workflow-dropdown',
      custom_bot_id: this.args.customBot.id,
      custom_bot_target: this.args.customBot.target,
      custom_bot_snapshot_id: this.selectedSnapshotId,
      custom_bot_parent_workflow_id: this.selectedParentWorkflowInstanceId,
    });
  }

  @task({ restartable: true })
  *loadJourneysForCurrentSnapshot() {
    this.loadSnapshotSummaries.perform();
    yield waitForProperty(this.loadSnapshotSummaries, 'isIdle');
    if (this.showParentWorkflowDropdown) {
      this.loadParentWorkflowStats.perform();
      yield waitForProperty(this.loadParentWorkflowStats, 'isIdle');
    }
    this.loadJourneysForSelectedSnapshot.perform();
  }

  @task({ restartable: true })
  *loadSnapshotSummaries() {
    try {
      let data = {
        custom_bot_id: this.args.customBot.id,
        app_id: this.app.id,
      };
      let summaries = yield ajax({
        data,
        type: 'GET',
        noRedirectOn401: true,
        url: `/ember/operator_custom_bots/${this.args.customBot.id}/path_analysis_versions`,
      });
      this.selectedSnapshotId = summaries.firstObject.id.toString();
      this.dropdownItems = this.buildVersionDropdownItems(summaries);
      this.fetchSnapshot.perform();
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('operator.custom-bot.path-analysis.error.cant-fetch-data'),
      );
    }
  }

  @task({ restartable: true })
  *loadParentWorkflowStats() {
    try {
      let data = {
        custom_bot_id: this.args.customBot.id,
        app_id: this.app.id,
        snapshot_id: this.selectedSnapshotId,
      };
      let stats = yield ajax({
        data,
        type: 'GET',
        noRedirectOn401: true,
        url: `/ember/operator_custom_bots/${this.args.customBot.id}/path_analysis_parent_workflow_stats`,
      });
      this.selectedParentWorkflowInstanceId = ALL_BOTS_ITEM_VALUE;
      this.dropdownParentWorkflowItems = this.buildParentWorkflowStatsDropdownItems(stats);
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('operator.custom-bot.path-analysis.error.cant-fetch-parent'),
      );
    }
  }

  @task({ restartable: true })
  *loadJourneysForSelectedSnapshot() {
    try {
      let data = {
        snapshot_id: this.selectedSnapshotId,
        app_id: this.app.id,
      };

      if (this.showParentWorkflowDropdown) {
        if (this.selectedParentWorkflowInstanceId === MANUAL_TRIGGER_ITEM_VALUE) {
          data['parent_workflow_instance_exists'] = false;
        } else if (this.selectedParentWorkflowInstanceId !== ALL_BOTS_ITEM_VALUE) {
          data['parent_workflow_instance_exists'] = true;
          data['parent_workflow_instance_id'] = this.selectedParentWorkflowInstanceId;
        }
      }

      let journeys = yield ajax({
        data,
        type: 'GET',
        noRedirectOn401: true,
        url: `/ember/operator_custom_bots/${this.args.customBot.id}/path_analysis`,
      });
      this.journeyMap = this.buildJourneyMap(journeys);
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('operator.custom-bot.path-analysis.error.cant-fetch-data'),
      );
    }
  }

  @task({ restartable: true })
  *fetchSnapshot() {
    let snapshot = yield this.store.findRecord(
      'operator/custom-bot-snapshot',
      this.selectedSnapshotId,
    );
    this.selectedSnapshot = snapshot;
    this.selectedSnapshot.setInverseRelationships();
  }
}
