/* import __COLOCATED_TEMPLATE__ from './created-by.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  createdBy?: string;
  doSearch: (createdBy?: string) => void;
  activeFilter?: string;
  showDelete: boolean;
  isReviewMode: boolean;
  removeFilter: () => void;
}

export default class CreatedBy extends Component<Args> {
  @service declare appService: any;
  @service intl!: IntlService;

  get app(): any {
    return this.appService.app;
  }

  get selectedAdmin(): any {
    return this.admins.find((admin: any) => admin.value === this.args.createdBy);
  }

  get selectedName() {
    return this.selectedAdmin?.text;
  }

  get filterName() {
    return this.args.isReviewMode
      ? this.intl.t(`ai-content-library.content-reviews.filters.suggested-by`)
      : this.intl.t(`ai-content-library.filters.created-by`);
  }

  get isOpen() {
    return this.args.activeFilter === 'createdBy' && !this.args.createdBy;
  }

  get admins() {
    let admins: any[] = [this.app.operatorBot, ...this.app.humanAdmins];

    return admins.flat().map((admin: any) => {
      return {
        text: admin.name,
        value: admin.id,
        avatarData: admin.avatarData,
        component: 'common/avatar-and-name-dropdown-item',
        componentAttrs: {
          model: admin,
          modelDisplayName: admin.name,
          avatarSize: 'xs',
        },
      };
    });
  }

  @action removeFilter() {
    this.args.removeFilter();
    this.args.doSearch();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::Filters::CreatedBy': typeof CreatedBy;
  }
}
