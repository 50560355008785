/* import __COLOCATED_TEMPLATE__ from './clicked-stat-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { makeStatsParametersForTooltip } from 'embercom/helpers/stats-system/stats-parameters';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { tracked } from '@glimmer/tracking';
export default class ClickedStatList extends Component {
  @service contentEditorService;
  @service appService;

  @tracked statsParameters;

  constructor() {
    super(...arguments);

    this.statsParameters = this.createStatParameters(this.contentEditorService.range);
  }

  @action
  changeFilter(_, filter) {
    this.contentEditorService.changeActiveFilterTo(filter);
    this.statsParameters = this.createStatParameters(this.contentEditorService.range);
  }

  get tooltipGroup() {
    return this.contentEditorService.activeObject;
  }

  @action updateDateRange(range) {
    this.contentEditorService.updateRange(range);
    this.statsParameters = this.createStatParameters(range);
  }

  createStatParameters(range) {
    let ruleset = this.contentEditorService.ruleset;
    let rulesetLink = this.contentEditorService.activeRulesetLink;

    let tooltip;
    if (this.contentEditorService.activeFilter !== stats.receipt) {
      tooltip = this.tooltipGroup.tooltips.find(
        (tooltip) => Number(tooltip.id) === Number(this.contentEditorService.activeFilter),
      );
    } else {
      tooltip = this.tooltipGroup.tooltips.filter((tooltip) => tooltip.hasLinks)[0];
    }

    return makeStatsParametersForTooltip(
      tooltip,
      ruleset,
      rulesetLink,
      range,
      this.appService.app.timezone,
    );
  }
}
