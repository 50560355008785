/* import __COLOCATED_TEMPLATE__ from './user-part-metadata.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  intercomEventService: service(),
  classNames: ['conversation__bubble__meta', 't__light-text', 'u__cf', 'u__left'],
  attributeBindings: ['data-test-user-meta-data'],
  'data-test-user-meta-data': true,
  router: service(),
  appService: service(),

  actions: {
    viewEmailAndTrackEvent() {
      let inboundEmailRoute = 'apps.app.inbound-email';
      let inboundEmailId = this.get('part.inbound_email_id');
      let appId = this.get('part.app_id');
      let conversationId = this.get('part.conversation_id');
      let partId = this.get('part.partId');
      let queryParams = { queryParams: { conversationId } };
      let event = {
        action: 'clicked',
        object: 'respond_view_raw_email_link',
        conversation_id: conversationId,
        inbound_email_id: inboundEmailId,
      };
      if (this.get('part.isInitialMessage')) {
        event.user_message_id = partId;
      } else {
        event.part_id = partId;
      }
      this.intercomEventService.trackAnalyticsEvent(event);
      this.router.transitionTo(inboundEmailRoute, appId, inboundEmailId, queryParams);
    },
  },
});
