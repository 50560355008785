/* import __COLOCATED_TEMPLATE__ from './side-drawer-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class SideDrawerConversation extends Component {
  @action
  changeToNotePane() {
    this.streamAndControls.send('changeToNotePane');
  }
}
