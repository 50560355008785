/* import __COLOCATED_TEMPLATE__ from './conversation-stream.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { inject as service } from '@ember/service';

export default class ConversationStream extends Component {
  @service attributeService;

  get attributeInfoResolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorMetadataAndConversationAttributes,
      customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
      includeAppAttributes: true,
      includeCustomObjectAttributes: true,
    });
  }
}
