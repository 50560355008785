/* import __COLOCATED_TEMPLATE__ from './messenger-settings-validation-error.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import { action } from '@ember/object';

interface Args {
  content: string;
}

export default class MessengerSettingsValidationError extends Component<Args> {
  @service declare router: Router;
  @service messengerSettingsTargetUserProvider: any;

  @action
  goToMessengerSettingsPage() {
    this.messengerSettingsTargetUserProvider.setUserTarget();
    this.router.transitionTo('apps.app.messenger');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::MessengerSettingsValidationError': typeof MessengerSettingsValidationError;
    'content-editor/messenger-settings-validation-error': typeof MessengerSettingsValidationError;
  }
}
