/* import __COLOCATED_TEMPLATE__ from './empty-versions-card.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class EmptyVersionsCard extends Component {
  @service contentEditorService;

  get allRulesetLinksAreDraft() {
    return this.contentEditorService.parentRulesetLinks.every((rulesetLink) => rulesetLink.isDraft);
  }
}
