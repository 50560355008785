/* import __COLOCATED_TEMPLATE__ from './admin-unknown-participant-for-email-conversation-note-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-component-inheritance */

import ConversationPartComponentBase from 'embercom/components/conversation/stream/conversation-part-component-base';
import { task } from 'ember-concurrency';
import { readOnly } from '@ember/object/computed';

export default ConversationPartComponentBase.extend({
  didInsertElement() {
    this._super(...arguments);
    this.loadConversation.perform();
  },

  get isSplitConversation() {
    return this.part.event_data.conversation_id < this.part.conversation_id;
  },

  originalConversation: readOnly('loadConversation.lastSuccessful.value'),

  loadConversation: task(function* () {
    let id = this.part.event_data.conversation_id;
    let promise =
      this.store.peekRecord('conversation', id) ||
      this.store.findRecord('conversation', id, { backgroundReload: false });

    return yield promise;
  }),
});
