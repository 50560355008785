/* import __COLOCATED_TEMPLATE__ from './installation-events.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';

export default class InstallationEvents extends Component {
  @service developerHub;
  @service store;
  @tracked report = [];
  currentPage = 1;
  perPage = 10;
  @tracked canLoadMore = true;

  constructor() {
    super(...arguments);
    this.fetchReport.perform();
  }

  get displayInstallationEvents() {
    if (!this.report) {
      return false;
    }
    return this.report.length > 0;
  }

  get eventsData() {
    return this.report;
  }

  @action
  onLoadMore() {
    this.fetchReport.perform();
  }

  @task
  *fetchReport(page) {
    let { id: appPackageId } = this.developerHub.appPackage;
    let response = yield this.store.queryRecord(
      'developer-hub/app-package/reports/installation-events',
      {
        appPackageId,
        page: this.currentPage,
        per_page: this.perPage,
      },
    );
    this.report = [...this.report, ...response.report];
    this.currentPage++;
    if (response.report.length < this.perPage) {
      this.canLoadMore = false;
    }
  }
}
