/* import __COLOCATED_TEMPLATE__ from './standard-tags-tab.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class StandardTagsTab extends Component {
  @service outboundHomeService;
  @service router;

  @action
  async removeTag(tag) {
    await this.args.ruleset.updateTaggingsTask.perform([], [tag]);
    this.args.ruleset.reload();
  }

  @action
  transitionToOutboundHome(tag) {
    this.outboundHomeService.resetFilters();
    this.outboundHomeService.selectedTagValues = [tag.id];
    this.router.transitionTo('apps.app.outbound.all');
  }
}
