/* import __COLOCATED_TEMPLATE__ from './title-input.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ProductTourTitleInput extends Component {
  @service contentEditorService;

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get tour() {
    return this.contentEditorService.activeObject;
  }

  @action
  trimContentTitle() {
    this.ruleset.clientData.title = this.ruleset.clientData.title.trim();
    this.tour.title = this.tour.title.trim();
  }

  @action
  setTitle(newValue) {
    this.ruleset.clientData.title = newValue;
    this.tour.title = newValue;
  }
}
