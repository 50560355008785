/* import __COLOCATED_TEMPLATE__ from './test-activation-controls.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class TestActivationControls extends Component {
  @service contentEditorService;
  @service intl;

  get disabledPauseRulesetLink() {
    let ruleset = this.contentEditorService.ruleset;
    if (!ruleset.isMemberOfSeries) {
      return false;
    }
    let liveRulesetLinks = ruleset.rulesetLinks.filter((rulesetLink) => rulesetLink.isLive).length;
    return liveRulesetLinks <= 1;
  }

  get disabledActivateRulesetLinkMessage() {
    let message = '';
    let ruleset = this.contentEditorService.ruleset;
    let activeRulesetLink = this.contentEditorService.activeRulesetLink;
    if (ruleset.isMemberOfSeries && ruleset.isPaused) {
      message = this.intl.t(
        'outbound.content-editor.headers.test-activation-controls.series-not-live',
      );
    }
    if (!activeRulesetLink.isValid) {
      message = activeRulesetLink.object.validations.errors.firstObject.message;
    }
    return message;
  }

  get disabledActivateRulesetLink() {
    return Boolean(this.disabledActivateRulesetLinkMessage);
  }
}
