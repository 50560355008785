/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { debounce } from '@ember/runloop';
import $ from 'jquery';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { not, and, or, equal, reads, empty, mapBy, alias } from '@ember/object/computed';
import EmberObject, { computed } from '@ember/object';
import emoji from 'embercom/lib/emoji';
import { EXTERNAL_REPLY_SEND_STATES } from 'embercom/lib/conversation-part/external-reply-send-states';
import { action } from '@ember/object';

function isMessengerCardBlock(block) {
  return block.type === 'messengerCard';
}

export const BubbleGroup = EmberObject.extend({
  blocks: null,
  isMessengerCardOnly: computed('blocks', function () {
    let blocks = this.blocks;
    return blocks.every(isMessengerCardBlock) && blocks.length > 0;
  }),

  init() {
    this._super(...arguments);
    this.set('blocks', []);
  },

  addBlock(block) {
    this.blocks.pushObject(block);
    if (isMessengerCardBlock(block)) {
      this.setupCanvasModel();
    }
  },

  setupCanvasModel() {
    let canvasJSON = this.get('blocks.lastObject.canvas');
    let canvas = this.store.push({
      data: {
        id: canvasJSON.id,
        type: 'app-framework/canvas',
        attributes: {
          content: null,
          ...canvasJSON,
        },
      },
    });
    this.set('canvas', canvas);
  },
});

export default Component.extend({
  tagName: 'span',
  classNameBindings: [
    'part.compact:m__conversation-stream__compact',
    'part.isFirstCompactedPart:m__conversation-stream__compact-first',
    'part.isLastCompactedPart:m__conversation-stream__compact-last',
  ],
  attributeBindings: [
    'data-test-conversation-part-component-base',
    'data-test-conversation-part',
    'data-test-conversation-part-id',
  ],
  'data-test-conversation-part-component-base': true,
  realTimeEventService: service(),
  store: service(),
  tracing: service(),
  hasNativeEmojiSupport: computed(function () {
    return emoji.hasNativeSupport();
  }),
  creatorDisplayAs: computed('part.creator.display_as', 'part.github_metadata_name', function () {
    if (this.get('part.github_metadata_name')) {
      if (this.get('part.isOperatorPart')) {
        return 'Operator';
      }
      return `${this.get('part.github_metadata_name')} via GitHub`;
    }
    return this.get('part.creator.display_as');
  }),
  channelText: computed(
    'part.conversation.initiator.label',
    'part.isInitialPartByHumanAdmin',
    'part.isChannelEmail',
    'part.isGithubBotPart',
    'part.isNote',
    'part.isOperatorPart',
    function () {
      if (this.get('part.isInitialPartByHumanAdmin')) {
        if (this.get('part.conversation.initiator.label')) {
          return this.get('part.conversation.initiator.label');
        } else if (this.get('part.isChannelEmail')) {
          return 'Email';
        } else {
          return 'In-app';
        }
      } else if (this.get('part.isChannelEmail')) {
        return 'Email';
      } else if (this.get('part.isGithubBotPart')) {
        return 'Operator';
      } else if (this.get('part.isNote')) {
        return 'Note';
      } else if (this.get('part.isInitialPartAndStartedByCustomBot')) {
        return 'Custom Bot';
      } else if (this.get('part.isOperatorPart')) {
        return 'Auto reply';
      } else {
        return '';
      }
    },
  ),
  isHighlighted: computed('highlightedConversationPartId', 'part.id', function () {
    let partId = this.get('part.id');
    return isPresent(partId) && this.highlightedConversationPartId === partId;
  }),
  isExternalChannel: and('part.external_reply_send_state', 'part.isNotNote'),
  isNotExternalChannel: not('isExternalChannel'),
  isExternalChannelDeliveryInProgress: equal(
    'part.external_reply_send_state.send_state',
    EXTERNAL_REPLY_SEND_STATES.pending,
  ),
  isExternalChannelDeliverySuccessful: equal(
    'part.external_reply_send_state.send_state',
    EXTERNAL_REPLY_SEND_STATES.success,
  ),
  hasExternalChannelDeliveryFailed: equal(
    'part.external_reply_send_state.send_state',
    EXTERNAL_REPLY_SEND_STATES.error,
  ),
  doesNotHaveNativeEmojiSupport: not('hasNativeEmojiSupport'),
  shouldShowSticker: reads('part.isSticker'),
  shouldNotShowChannel: empty('channelText'),
  shouldShowImageSticker: and('shouldShowSticker', 'doesNotHaveNativeEmojiSupport'),
  partNotEmail: not('part.isChannelEmail'),
  firstPart: equal('part.type', 'message'),
  firstPartSentViaEmail: and('part.isChannelEmail', 'firstPart'),
  firstPartSentViaEmailOrNotEmail: or('firstPartSentViaEmail', 'partNotEmail'),
  leadSharesEmailWithUserWarningOn: reads('conversation.lead_shares_email_with_user'),
  leadIdentifiedViaEmailWarningOn: alias('conversation.lead_identified_via_email'),
  hasImpersonationWarning: or('leadSharesEmailWithUserWarningOn', 'part.unauthenticated'),
  showImpersonationWarning: and('hasImpersonationWarning', 'firstPartSentViaEmailOrNotEmail'),

  bubbleGroups: computed('part.blocks', function () {
    let bubbleGroups = [this._createBubbleGroup()];
    let blocks = this.get('part.blocks');

    blocks &&
      blocks.forEach((block, i) => {
        let lastGroup = bubbleGroups[bubbleGroups.length - 1];

        if (this._shouldDiscardBlock(block, lastGroup)) {
          return;
        }

        if (this._shouldSplitBlockIntoNewBubble(block, lastGroup)) {
          lastGroup = this._createBubbleGroup();
          bubbleGroups.push(lastGroup);
        }

        lastGroup.addBlock(block);
      });

    return bubbleGroups;
  }),
  lastNonMessengerCardOnlyBubbleGroupIndex: computed(
    'bubbleGroups.@each.isMessengerCardOnly',
    function () {
      for (let index = this.bubbleGroups.length - 1; index >= 0; index--) {
        if (!this.bubbleGroups.objectAt(index).isMessengerCardOnly) {
          return index;
        }
      }
    },
  ),

  messengerCardTopics: mapBy('part.messengerCardBlocks', 'uri'),

  didInsertElement() {
    this._super(...arguments);
    if (this.isHighlighted) {
      this.scrollToConversationPart($(this.element));
    }
    if (this.get('part.hasMessengerCards') && this.get('part.isPersisted')) {
      this.subscribeMessengerCardRealtime();
      this.part.reload();
    }

    this.instrumentImages(this.element);
  },

  instrumentImages(element) {
    let images = element.getElementsByTagName('img');

    for (let i = 0; i < images.length; i++) {
      this.tracing.trackImageLoad(images[i]);
    }
  },

  willDestroyElement() {
    this._super(...arguments);
    if (this.get('part.hasMessengerCards')) {
      this.unsubscribeMessengerCardRealtime();
    }
  },

  handleMessengerCardUpdate(realtimeEventData) {
    let cardUri = realtimeEventData.cardUri;
    let part = this.part;
    if (part.hasMessengerCard(cardUri)) {
      part.reload();
    }
  },

  subscribeMessengerCardRealtime() {
    this.realTimeEventService.subscribeTopics(this.messengerCardTopics);
    this.realTimeEventService.on('MessengerCardUpdated', this, 'handleMessengerCardUpdate');
  },

  unsubscribeMessengerCardRealtime() {
    this.realTimeEventService.unsubscribeTopics(this.messengerCardTopics);
    this.realTimeEventService.off('MessengerCardUpdated', this, 'handleMessengerCardUpdate');
  },

  _shouldDiscardBlock(block, currentBubbleGroup) {
    return block.type === 'paragraph' && block.text === '' && block.class !== 'no-margin';
  },

  _shouldSplitBlockIntoNewBubble(currentBlock, currentBubbleGroup) {
    if (currentBubbleGroup.blocks.length === 0) {
      return false;
    }
    if (this.get('part.isPostStyleAutoMessage') || this.get('part.isNoteStyleAutoMessage')) {
      return false;
    }

    let previousBlock = currentBubbleGroup.blocks[currentBubbleGroup.blocks.length - 1];
    return isMessengerCardBlock(currentBlock) || isMessengerCardBlock(previousBlock);
  },

  _createBubbleGroup() {
    return BubbleGroup.create({
      store: this.store,
      part: this.part,
      isHighlighted: this.isHighlighted,
    });
  },

  partSaveCallback() {
    this.part.save().catch((error) => {
      this.part.updateError(error);
    });
  },

  retry: action(function () {
    if (!this.get('part.isRetriable')) {
      return;
    }
    this.set('part.hasErrored', false);
    debounce(this, this.partSaveCallback, 500);
  }),

  actions: {
    toggleSharing() {
      this.part.toggleSharing();
    },
  },
});
