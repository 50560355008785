/* import __COLOCATED_TEMPLATE__ from './locale-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import type Store from '@ember-data/store';

interface Args {
  doSearch: (locale?: string) => void;
  selectedLocaleValue: string | null;
  activeFilter?: string;
  removeFilter: (locale?: string) => void;
  selectableLocales: Array<string>;
}

export default class LocaleFilter extends Component<Args> {
  @service declare intl: IntlService & { languageNameFromCode: (code: string) => string };
  @service declare appService: any;
  @service declare store: Store;

  get isOpen() {
    return this.args.activeFilter === 'locale';
  }

  get app() {
    return this.appService.app;
  }

  get locales() {
    return [
      {
        text: this.intl.t('ai-content-library.filters.locales.all'),
        value: null,
      },
      ...this.args.selectableLocales.map((locale: string) => ({
        text: this.intl.languageNameFromCode(locale),
        value: locale,
      })),
    ];
  }

  get selectedLocaleName() {
    let locale = this.locales.find((locale) => locale.value === this.args.selectedLocaleValue);
    return !locale
      ? this.intl.t('ai-content-library.filters.locales.all-languages')
      : this.intl.t('ai-content-library.filters.locales.language-is', {
          language: locale.text,
        });
  }

  @action removeFilter() {
    this.args.doSearch();
    this.args.removeFilter();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Filters::LocaleFilter': typeof LocaleFilter;
  }
}
