/* import __COLOCATED_TEMPLATE__ from './total-installs-chart.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class TotalInstallsChart extends Component {
  @service developerHub;
  @service store;
  @tracked cumulativeTotalInstalls;
  @tracked previousCumulativeTotalInstalls;

  constructor() {
    super(...arguments);
    this.getTotalInstalls();
  }

  @action
  getTotalInstalls() {
    this.getCumulativeTotalInstalls().then((response) => {
      this.cumulativeTotalInstalls = response;
    });
    this.getPreviousCumulativeTotalInstalls().then((response) => {
      this.previousCumulativeTotalInstalls = response;
    });
  }

  getCumulativeTotalInstalls() {
    let { start, end } = this.args.range;

    return this.store.queryRecord('developer-hub/app-package/reports/cumulative-total-installs', {
      appPackageId: this.developerHub.appPackage.id,
      start,
      end,
    });
  }

  getPreviousCumulativeTotalInstalls() {
    let { comparisonStartMoment, comparisonEndMoment } = this.args.range;
    return this.store.queryRecord('developer-hub/app-package/reports/cumulative-total-installs', {
      appPackageId: this.developerHub.appPackage.id,
      start: comparisonStartMoment.toISOString(),
      end: comparisonEndMoment.toISOString(),
    });
  }
}
