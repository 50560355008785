/* import __COLOCATED_TEMPLATE__ from './copy-to-clipboard-field.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  textToCopy: string;
  buttonLabel: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const CopyToClipboardField = templateOnlyComponent<Signature>();
export default CopyToClipboardField;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CopyToClipboardField: typeof CopyToClipboardField;
    'copy-to-clipboard-field': typeof CopyToClipboardField;
  }
}
