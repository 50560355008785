/* import __COLOCATED_TEMPLATE__ from './summary-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { not, and, notEmpty, or, reads } from '@ember/object/computed';
import Component from '@ember/component';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  isInbox: true,
  isNotInbox: not('isInbox'),
  classNames: ['conversation__list__item__summary', 'o__clamped', 't__conversation-summary'],
  classNameBindings: ['isNotInbox:o__in-card'],

  isLastMentionedPartAvailable: notEmpty('conversation.lastPartWhereCurrentAdminIsMentioned'),
  showLastMentionedPart: and('isMentionsInbox', 'isLastMentionedPartAvailable'),
  showConversationPriority: and('conversation.isPrioritised', 'app.isNotOnFree'),
  hasActiveTimeTarget: and('conversation.hasActiveTimeTarget', 'app.canUseInboxSlas'),
  hasBadge: or(
    'showConversationPriority',
    'conversation.last_snoozed_at',
    'isUnread',
    'hasActiveTimeTarget',
  ),

  summaryPart: ternaryToProperty(
    'showLastMentionedPart',
    'conversation.lastPartWhereCurrentAdminIsMentioned',
    'conversation.lastPartWithContentExcludingOperator',
  ),

  anotherAdminIsTyping: or(
    'conversation.anotherAdminIsTypingAComment',
    'conversation.anotherAdminIsTypingANote',
  ),
  summaryIsNote: ternaryToProperty(
    'conversation.someoneIsTyping',
    'conversation.anotherAdminIsTypingANote',
    'summaryPart.isNote',
  ),
  summaryIsForAdmin: ternaryToProperty(
    'conversation.someoneIsTyping',
    'anotherAdminIsTyping',
    'summaryPart.isAdminPart',
  ),
  adminToDisplay: or('conversation.typingAdmin', 'summaryPart.admin'),

  noOneIsTyping: not('conversation.someoneIsTyping'),
  summaryIsForOperator: and('noOneIsTyping', 'conversation.assignedToOperator', 'isAllInbox'),
  fromAddressText: reads('summaryPart.event_data.event_references.from_email.label'),
  parsedSummary: reads('summaryPart.parsedSummary'),
  displayedSummaryText: or('fromAddressText', 'parsedSummary'),
});
