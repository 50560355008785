/* import __COLOCATED_TEMPLATE__ from './title.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class Title extends Component {
  @service appService;
  @service conversationsService;
  @service notificationsService;
  @service intercomEventService;

  @tracked isEditing = false;
  @tracked isFocused = false;
  @tracked isHovering = false;

  get app() {
    return this.appService.app;
  }

  get mainParticipantName() {
    if (!this.args.conversation) {
      return;
    }

    if (this.args.conversation.isGroupConversation) {
      return this.args.conversation.main_participant?.firstName;
    } else {
      return this.args.conversation.main_participant?.displayAs;
    }
  }

  get titleValue() {
    if (this.isEditing) {
      return this.titleInput;
    }
    return this.args.conversation?.title;
  }

  set titleValue(value) {
    this.isEditing = true;
    this.titleInput = value;
  }

  get titlePlaceholder() {
    return 'Add conversation title';
  }

  get entityType() {
    return this.args.conversation.requestType ? 'Ticket' : 'Conversation';
  }

  @action
  updateConversationTitle() {
    let previousTitle = this.args.conversation?.title === null ? '' : this.args.conversation?.title;
    this.titleValue = this.titleValue?.trim();
    if (this.titleValue === previousTitle) {
      this.isEditing = false;
      this.isFocused = false;
      return;
    }
    this.conversationsService
      .addEventPartAndSave(this.args.conversation, 'title_changed', {
        title: this.titleInput,
      })
      .catch(() =>
        this.notificationsService.notifyError(
          'An error occurred while trying to change title. Please try again.',
        ),
      );

    this.isEditing = false;
    this.isFocused = false;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated',
      object: 'conversation_title',
      place: 'inbox',
    });
  }

  @action
  handleWheel(event) {
    if (!this.isFocused) {
      event.preventDefault();
    }
  }
}
