/* import __COLOCATED_TEMPLATE__ from './preview-button.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { dependentKeyCompat } from '@ember/object/compat';

export default class PreviewButton extends Component {
  @service contentEditorService;
  @service intercomEventService;
  @service router;

  @tracked showPreviewModal = false;
  @tracked dismissedUrlPreview = false;

  @dependentKeyCompat
  get isPreviewDisabled() {
    let workflowable = this.contentEditorService.activeObject;
    if (this.contentEditorService.isEditMode) {
      return (
        workflowable.hasUnsavedChanges ||
        !workflowable.canActivate ||
        workflowable.visualBuilderObject?.isCustomerFacing === false
      );
    }
    if (this.contentEditorService.isViewMode) {
      return (
        !workflowable.canActivate || workflowable.visualBuilderObject?.isCustomerFacing === false
      );
    }
    return true;
  }

  get disabledMessage() {
    let workflowable = this.contentEditorService.activeObject;
    return workflowable.previewDisabledMessage;
  }

  get showPreviewFromUrl() {
    return (
      !this.dismissedUrlPreview && this.router?.currentRoute?.queryParams?.show_preview === 'true'
    );
  }

  @action
  togglePreviewModal() {
    this.showPreviewModal = !this.showPreviewModal;
    this.dismissedUrlPreview = true;

    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.config.analyticEvent,
      action: 'clicked',
      section: 'operator',
      object: 'preview_button',
    });
  }
}
