/* import __COLOCATED_TEMPLATE__ from './stream-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { scheduleOnce, later } from '@ember/runloop';
import $ from 'jquery';
import { computed, observer } from '@ember/object';
import { readOnly, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import InboundActions from 'ember-component-inbound-actions/inbound-actions';
import ENV from 'embercom/config/environment';
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';
import { addEventListener, debounceTask, runTask, throttleTask } from 'ember-lifeline';
import DidChangeAttrs from 'ember-did-change-attrs';
import UserContentSeenState from 'embercom/lib/user-content-seen-state';
import { task, timeout } from 'ember-concurrency';
import { copy } from 'ember-copy';

const HIGHLIGHTED_CONVERSATION_PART_SCROLL_OFFSET = 30;

export default Component.extend(InboundActions, DidChangeAttrs, {
  intercomEventService: service(),
  notificationsService: service(),
  appService: service(),

  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),

  realTimeEventService: service(),
  frontendStatsService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  autoScroll: true,

  shouldScroll: computed('isInbox', 'autoScroll', 'highlightedConversationPartId', function () {
    if (this.highlightedConversationPartId) {
      return false;
    } else {
      return this.isInbox || this.autoScroll;
    }
  }),

  midThreadScrollAmount: 78,
  autoScrollThreshold: 200,
  paginationThresholdPercentage: 10,
  highlightedConversationPartId: null,
  canLoadMoreParts: not('conversation.hasInitialMessagePart'),

  didChangeAttrsConfig: {
    attrs: ['conversation'],
  },
  attributeBindings: ['data-test-conversation-stream'],
  'data-test-conversation-stream': true,

  savedReplyBeingCreated: false,
  savedReplyInitialBlocks: null,

  init() {
    this._super(...arguments);

    if (window.location.hash) {
      this.handleUrlFragment(window.location.hash);
    }
  },

  handleUrlFragment(urlFragmentStr) {
    if (urlFragmentStr.startsWith('#')) {
      urlFragmentStr = urlFragmentStr.substring(1);
    }
    let fragments = urlFragmentStr.split('&');
    for (let fragment of fragments) {
      let keyValuePair = fragment.split('=');
      let key = keyValuePair[0];

      if (keyValuePair.length === 2 && key === 'part_id') {
        let value = keyValuePair[1];
        this.set('highlightedConversationPartId', value);
        this.intercomEventService.trackAnalyticsEvent({
          action: 'loaded',
          object: 'permalink',
          place: 'inbox',
        });
      }
    }
  },

  didChangeAttrs(changes) {
    this._super(...arguments);

    if (changes.conversation) {
      this.conversationChanged.perform(changes.conversation.previous);
    }
  },

  didInsertElement() {
    this._super(...arguments);
    let $scrollContainer = this.findScrollContainer();

    if (!$scrollContainer.length) {
      console.error('No scrollContainer found');
      return;
    }
    this.set('$scrollContainer', $scrollContainer);
    addEventListener(this, $scrollContainer.get(0), 'scroll', this.handleScrollChange.bind(this));
    addEventListener(this, document, 'visibilitychange', () => {
      let task = this.setConversationSeen;
      this.lastMouseMoveTime = Date.now();
      if (document.visibilityState === 'visible') {
        task.perform();
      } else {
        task.cancelAll();
      }
    });

    this.conversationChanged.perform();
  },

  mouseMove() {
    this.lastMouseMoveTime = Date.now();
    if (
      !this.get('conversation.lastUserPart.isSeenByCurrentAdmin') &&
      !this.get('setConversationSeen.isRunning')
    ) {
      this.setConversationSeen.perform();
    }
  },

  wheel() {
    throttleTask(this, '_trackWheel', 5 * ENV.APP._1S);
  },

  _trackWheel() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'scroll',
      object: 'conversation_stream',
    });
  },

  willDestroyElement() {
    if (this.$scrollContainer) {
      this.set('$scrollContainer', null);
    }

    if (ENV.environment !== 'test') {
      this.get('conversation.syncedPartList').stopPoll();
    }

    this._super(...arguments);
  },

  willClearRender() {
    this._super(...arguments);
    let $el = $('.conversation__text img', this.element);
    if ($el) {
      $el.off('load');
    }
  },

  conversationChanged: task(function* (previousConversation) {
    this.frontendStatsService.start('ember_ux_conversationStreamRenderTime_syncing');

    this.resetTypingIndicators();

    this.setConversationSeen.cancelAll();

    try {
      if (previousConversation && ENV.environment !== 'test') {
        previousConversation.get('syncedPartList').stopPoll();
      }

      if (this.conversation.disableFetchParts) {
        return;
      }

      if (this.highlightedConversationPartId) {
        yield this.get('conversation.syncedPartList').loadMoreUntilPart(
          this.highlightedConversationPartId,
        );
      } else {
        yield this.get('conversation.syncedPartList').sync();
      }

      if (ENV.environment !== 'test') {
        this.get('conversation.syncedPartList').startPoll();
      }

      this.setConversationSeen.perform();
      this.scrollToBottom();
    } catch (e) {
      this.notificationsService.notifyError('There was a problem loading this conversation');
    }
  }).restartable(),

  displayableParts: readOnly('conversation.sortedParts'),

  lastAdminItemWithSeenMetadata: computed('displayableParts', function (part) {
    return copy(this.displayableParts).reverse().findBy('hasSeenMetadata');
  }),

  setConversationSeen: task(function* () {
    yield timeout(ENV.APP.userContentSeenStateDelay);

    this.frontendStatsService.stop('ember_ux_conversationStreamRenderTime_syncing', 5);

    let conversation = this.conversation;
    let tabIsActive = Date.now() - this.lastMouseMoveTime < ENV.APP.userContentSeenStateDelay;
    if (conversation.get('hasAdminCommentOrMessage') && !document.hidden && tabIsActive) {
      UserContentSeenState.updateConversationForAdmin(conversation, this.realTimeEventService);
    }
  }).drop(),

  conversationPartsDidChange: observer({
    dependentKeys: ['conversation.parts.@each.isPersisted'],

    fn() {
      this.setConversationSeen.perform();
    },

    sync: false,
  }),

  findScrollContainer() {
    return $(this.element).closest('.conversation__stream');
  },

  handleScrollChange() {
    if (this.isDestroying) {
      return;
    }
    debounceTask(this, '_handleScrollChange', ENV.APP._50MS);
  },

  _handleScrollChange() {
    let $scrollContainer = this.$scrollContainer;
    if (!$scrollContainer || $scrollContainer.length === 0) {
      return;
    }

    let scrollContainer = $scrollContainer.get(0);
    if (!scrollContainer) {
      return;
    }
    let scrollTop = scrollContainer.scrollTop;
    let scrollHeight = scrollContainer.scrollHeight;
    let distanceFromBottom = scrollHeight - (scrollTop + scrollContainer.offsetHeight);
    this.set('autoScroll', distanceFromBottom <= this.autoScrollThreshold);

    if (this._shouldFetchEarlierParts(scrollHeight, scrollTop)) {
      this._savePreviousScrollPosition();
      this.get('conversation.syncedPartList')
        .loadMore()
        .then(() => {
          scheduleOnce('afterRender', this, this._restoreScrollPositionAndScrollAfterImages);
        });
    }
  },

  _shouldFetchEarlierParts(scrollHeight, scrollTop) {
    let isWithinPaginationArea = scrollTop <= scrollHeight / this.paginationThresholdPercentage;

    let isLoadingMore = this.get('conversation.syncedPartList.isLoadingMore');
    return isWithinPaginationArea && this.canLoadMoreParts && !isLoadingMore;
  },

  scrollToBottom() {
    if (this.shouldScroll) {
      scheduleOnce('afterRender', this, this._scrollToBottom);
      scheduleOnce('afterRender', this, this._scrollAfterImages, this._scrollToBottom);
    }
  },

  _scrollToBottom() {
    let scrollContainer = this.$scrollContainer;
    if (scrollContainer && scrollContainer.length) {
      let scrollHeight = scrollContainer.get(0).scrollHeight;
      scrollContainer.scrollTop(scrollHeight);
    }
  },

  _animateScrollChange(scrollHeight) {
    let scrollContainer = this.$scrollContainer;
    if (!scrollContainer) {
      return;
    }
    scrollContainer.animate(
      {
        scrollTop: scrollHeight,
      },
      500,
    );
    this.set('autoScroll', true);
  },

  _scrollAfterImages(callback) {
    if (this.element) {
      let image = this.element.querySelector('.conversation__text img');
      if (image) {
        addEventListener(this, image, 'load', callback.bind(this));
      }
    }
  },

  anotherAdminIsTypingACommentChanged: observer({
    dependentKeys: ['conversation.anotherAdminIsTypingAComment', 'showingParts'],

    fn() {
      scheduleOnce(
        'afterRender',
        this,
        this._anotherAdminIsTypingACommentChanged,
        this.conversation,
      );
    },

    sync: true,
  }),

  anotherAdminIsTypingANoteChanged: observer({
    dependentKeys: ['conversation.anotherAdminIsTypingANote', 'showingParts'],

    fn() {
      scheduleOnce('afterRender', this, this._anotherAdminIsTypingANoteChanged, this.conversation);
    },

    sync: true,
  }),

  userIsTypingChanged: observer({
    dependentKeys: ['conversation.userIsTyping', 'showingParts'],

    fn() {
      scheduleOnce(
        'afterRender',
        this,
        this._userIsTypingChanged,
        this.get('conversation.userIsTyping'),
      );
    },

    sync: true,
  }),

  showAdminTypingTooltip: computed(
    'conversation.{typingAdmin,anotherAdminIsTypingAComment,anotherAdminIsTypingANote}',
    function () {
      return (
        this.conversation.typingAdmin &&
        (this.conversation.anotherAdminIsTypingAComment ||
          this.conversation.anotherAdminIsTypingANote)
      );
    },
  ),

  _userIsTypingChanged(userIsTyping) {
    let $spacer = $('.o__user-is-typing-spacer', this.element);
    let $part = $('.o__user-is-typing-part', this.element);
    this._hideOrShowSpacerAndPart(userIsTyping, $spacer, $part);
  },

  _anotherAdminIsTypingACommentChanged(conversation) {
    let $spacer = $('.o__admin-is-typing-spacer.js__admin-comment', this.element);
    let $part = $('.o__admin-is-typing-part.js__admin-comment', this.element);
    if ($part) {
      this._setAdminAvatar($part.find('img.avatar__media'), conversation);
    }
    this._hideOrShowSpacerAndPart(conversation.get('anotherAdminIsTypingAComment'), $spacer, $part);
  },

  _anotherAdminIsTypingANoteChanged(conversation) {
    let $spacer = $('.o__admin-is-typing-spacer.js__admin-note', this.element);
    let $part = $('.o__admin-is-typing-part.js__admin-note', this.element);
    if ($part) {
      this._setAdminAvatar($part.find('img.avatar__media'), conversation);
    }
    this._hideOrShowSpacerAndPart(conversation.get('anotherAdminIsTypingANote'), $spacer, $part);
  },

  _hideOrShowSpacerAndPart(isTyping, $spacer, $part) {
    if ($spacer && $part) {
      if (isTyping) {
        this._showSpacerAndPart($spacer, $part);
      } else if ($spacer.is(':visible') && $part.is(':visible')) {
        this._hideSpacerAndPart($spacer, $part);
      }
    }
  },

  _showSpacerAndPart($spacer, $part) {
    $spacer.show();
    $part.show();
    $part
      .find('.conversation__part__avatar, .conversation__bubble')
      .removeClass('o__is-typing-off')
      .addClass('o__is-typing-on');
    if (this.isScrolledNearBottom()) {
      this.scrollToBottom();
    }
  },

  _hideSpacerAndPart($spacer, $part) {
    $part
      .find('.conversation__part__avatar, .conversation__bubble')
      .removeClass('o__is-typing-on')
      .addClass('o__is-typing-off');
    runTask(
      this,
      () => {
        $spacer.slideUp(150);
        $part.slideUp(150);
        if (this.isScrolledNearBottom()) {
          this.scrollToBottom();
        }
      },
      150,
    );
  },

  isScrolledNearBottom() {
    let scrollContainer = this.$scrollContainer;
    if (scrollContainer && scrollContainer.length) {
      let scrollHeight = scrollContainer.get(0).scrollHeight;
      let scrollTop = scrollContainer.scrollTop();
      let containerHeight = scrollContainer.outerHeight();
      return scrollHeight - scrollTop - containerHeight < this.autoScrollThreshold;
    }
    return false;
  },

  _setAdminAvatar($avatarImage, conversation) {
    if (conversation.get('typingAdmin')) {
      let typingAdminAvatarURL = conversation.get('typingAdmin.avatar.image_urls.square_50');
      if (typingAdminAvatarURL) {
        $avatarImage.attr('src', typingAdminAvatarURL);
      } else {
        $avatarImage.attr('src', DefaultAvatars.admins[128]);
      }
    }
  },

  _hideAllTypingIndicatorsImmediately() {
    let selectors =
      '.o__user-is-typing-spacer, .o__admin-is-typing-spacer, .o__user-is-typing-part, .o__admin-is-typing-part';
    let $selectors = $(selectors, this.element);
    if ($selectors && $selectors.length !== 0) {
      $selectors.hide();
    }
  },

  _showTypingIndicatorsIfAnyoneIsTyping() {
    let conversation = this.conversation;
    if (!conversation) {
      return;
    }
    if (conversation.get('userIsTyping')) {
      this._userIsTypingChanged(true);
    }
    if (conversation.get('anotherAdminIsTypingAComment')) {
      this._anotherAdminIsTypingACommentChanged(conversation);
    }
    if (conversation.get('anotherAdminIsTypingANote')) {
      this._anotherAdminIsTypingANoteChanged(conversation);
    }
  },

  resetTypingIndicators() {
    scheduleOnce('afterRender', this, this._resetTypingIndicators);
  },

  _resetTypingIndicators() {
    this._hideAllTypingIndicatorsImmediately();
    this._showTypingIndicatorsIfAnyoneIsTyping();
  },

  _savePreviousScrollPosition() {
    let scrollContainer = this.$scrollContainer;
    if (scrollContainer && scrollContainer.length) {
      this.set(
        'previousScrollPosition',
        scrollContainer.get(0).scrollHeight - scrollContainer.get(0).scrollTop,
      );
    }
  },

  _restoreScrollPosition() {
    let scrollContainer = this.$scrollContainer;
    if (scrollContainer && scrollContainer.length) {
      this.$scrollContainer.scrollTop(
        scrollContainer.get(0).scrollHeight - this.previousScrollPosition,
      );
    }
  },

  _restoreScrollPositionAndScrollAfterImages() {
    this._restoreScrollPosition();
    this._scrollAfterImages(this._restoreScrollPosition);
  },

  clearConversationPartHighlight: task(function* () {
    yield timeout(ENV.APP._5000MS);
    this.set('highlightedConversationPartId', null);
  }),

  _scrollToConversationPart($conversationPart) {
    if (this.hasScrolledToConversationPart || this.isDestroyed) {
      return;
    }
    let $scrollContainer = this.$scrollContainer || this.findScrollContainer();
    let scrollTo =
      $conversationPart.offset().top -
      $scrollContainer.offset().top +
      $scrollContainer.scrollTop() -
      HIGHLIGHTED_CONVERSATION_PART_SCROLL_OFFSET;
    this._animateScrollChange(scrollTo);
    this.set('hasScrolledToConversationPart', true);
  },

  isTypingParts: [
    {
      for: 'user',
      partType: 'user-comment',
      side: 'left',
    },
    {
      for: 'admin',
      partType: 'admin-comment',
      side: 'right',
    },
    {
      for: 'admin',
      partType: 'admin-note',
      side: 'right',
    },
  ],

  _conversationPartCallback($conversationPart) {
    later(() => this._scrollToConversationPart($conversationPart), 500);
  },

  actions: {
    scrollToBottom() {
      this.scrollToBottom();
    },
    resetTypingIndicators() {
      this.resetTypingIndicators();
    },
    toggleSharing(part) {
      part.toggleSharing();
    },

    createSavedReply(blocks) {
      this.set('savedReplyInitialBlocks', blocks);
      this.set('savedReplyBeingCreated', true);
    },

    createSavedReplyFinished() {
      this.set('savedReplyInitialBlocks', null);
      this.set('savedReplyBeingCreated', false);
    },

    openModal() {
      this.modalService.openModal(...arguments);
    },
    scrollToConversationPart($conversationPart) {
      scheduleOnce(
        'afterRender',
        this._scrollAfterImages,
        this._conversationPartCallback.bind(this, $conversationPart),
      );
    },
  },
});
