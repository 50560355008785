/* import __COLOCATED_TEMPLATE__ from './state-switch.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    isOn: boolean;
    onClick: () => void;
    toggleIsEnabled: boolean;
    disabledToggleExplanation: string;
  };
}

const StateSwitch = templateOnlyComponent<Signature>();
export default StateSwitch;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::View::StateSwitch': typeof StateSwitch;
  }
}
