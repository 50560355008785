/* import __COLOCATED_TEMPLATE__ from './path-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly, alias, not, and } from '@ember/object/computed';
import { computed } from '@ember/object';
import PromptComposerConfig from 'embercom/lib/operator/custom-bots/prompt-composer-config';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';

const PROMPT_CONFIG = new PromptComposerConfig();

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  classNames: ['u__padt__20 custom-bots__editor__composer-suggestion-layout__path-editor'],
  step: readOnly('selectedPath.steps.firstObject'),
  promptBlocks: alias('step.blocks'),

  isStepValid: not('step.validations.isValid'),
  hasError: and('publicAPI.shouldShowValidations', 'isStepValid'),

  composerConfig: PROMPT_CONFIG,

  promptBlocksDocument: computed('promptBlocks', function () {
    return new BlocksDocument(this.promptBlocks);
  }),

  actions: {
    composerReady(composer) {
      this.set('composer', composer);
    },

    composerChanged(newBlocksDocument) {
      this.set('promptBlocks', newBlocksDocument.blocks);
    },
  },
});
