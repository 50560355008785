/* import __COLOCATED_TEMPLATE__ from './article-suggestion-preview-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';

export default class ArticleSuggestionPreviewModal extends Component {
  @service intercomEventService;
  @service store;
  @tracked fullyLoadedArticle = null;

  @action
  onDidInsert() {
    this.fetchFullArticle.perform();
    this.intercomEventService.trackEvent('educate-event', {
      action: 'previewed',
      object: 'article',
      place: 'conversation',
      owner: 'educate',
      url: this.args.article.publicUrl,
      article_id: this.args.article.id,
      state: this.args.article.public ? 'in_collection' : 'in_default',
      source: 'recommendation',
    });
  }

  @task({ drop: true })
  *fetchFullArticle() {
    if (this.args.article.notFullyLoaded) {
      this.fullyLoadedArticle = yield this.store.findRecord('article', this.args.article.id, {
        reload: true,
      });
    } else {
      this.fullyLoadedArticle = this.args.article;
    }
  }
}
