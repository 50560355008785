/* import __COLOCATED_TEMPLATE__ from './install-buttons.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { readOnly } from '@ember/object/computed';
import { task } from 'ember-concurrency';

export default Component.extend({
  intercomEventService: service(),
  notificationsService: service(),
  developerHub: service(),
  appPackage: readOnly('developerHub.appPackage'),
  appPackageInstall: readOnly('row'),
  addAnalyticsEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'app_package',
      target_workspace: this.get('appPackageInstall.appId'),
      app_package_id: this.get('appPackage.id'),
      app_metadata: this.get('appPackage.changeRequest'),
      action,
    });
  },

  installAppPackageTask: task(function* () {
    try {
      yield this.appPackageInstall.install();
      this.addAnalyticsEvent('installed');
      this.notificationsService.notifyConfirmation(
        `${this.get('appPackage.name')} was successfully installed.`,
      );
    } catch (e) {
      this.notificationsService.notifyError(
        `There was a problem installing ${this.get('appPackage.name')}`,
      );
    }
  }),
  uninstallAppPackageTask: task(function* () {
    try {
      yield this.appPackageInstall.uninstall();
      this.addAnalyticsEvent('uninstalled');
      this.set('showUninstallModal', false);
      this.notificationsService.notifyConfirmation(
        `You have successfully uninstalled ${this.get('appPackage.name')}.`,
      );
    } catch (e) {
      this.notificationsService.notifyError(
        `There was a problem uninstalling ${this.get('appPackage.name')}`,
      );
    }
  }).drop(),
});
