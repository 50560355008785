/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { readOnly, equal, or } from '@ember/object/computed';
import { computed } from '@ember/object';
import { task, waitForProperty } from 'ember-concurrency';
import { inject as service } from '@ember/service';

import {
  COMPOSER_SUGGESTION_LAYOUT,
  CONVERSATION_LAYOUT,
} from 'embercom/models/operator/custom-bot';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  store: service(),

  init() {
    this._super(...arguments);
    this.getPreviewConfiguration.perform();
  },

  deliveryChannel: computed('isInbound', 'customBot.deliveryChannel', function () {
    if (this.isInbound) {
      return this.customBot.deliveryChannel.type;
    }

    return 'web'; // Default to showing web
  }),

  wrapperComponent: computed('isIos', 'isAndroid', function () {
    if (this.isIos) {
      return 'custom-bots/preview/wrappers/ios';
    } else if (this.isAndroid) {
      return 'custom-bots/preview/wrappers/android';
    } else {
      return 'custom-bots/preview/wrappers/web';
    }
  }),

  isIos: equal('deliveryChannel', 'ios'),
  isAndroid: equal('deliveryChannel', 'android'),

  isMobile: or('isIos', 'isAndroid'),

  operator: readOnly('app.operatorBot'),

  isButton: readOnly('customBot.isButton'),
  isInbound: readOnly('customBot.isInbound'),
  isTriggerable: readOnly('customBot.isTriggerable'),
  previewLayout: computed('isButton', 'isTriggerable', function () {
    return this.isButton || this.isTriggerable ? CONVERSATION_LAYOUT : COMPOSER_SUGGESTION_LAYOUT;
  }),
  hasDisabledComposer: readOnly('customBot.data.composerDisabled'),

  composerSuggestionLayout: equal('previewLayout', COMPOSER_SUGGESTION_LAYOUT),
  conversationLayout: equal('previewLayout', CONVERSATION_LAYOUT),

  firstSteps: computed('customBot.paths.firstObject.steps.[]', function () {
    let parts = [];
    let firstPathSteps = this.get('customBot.paths.firstObject.steps');

    for (let i = 0; i < firstPathSteps.get('length'); i++) {
      let part = firstPathSteps.objectAt(i);
      parts.push(part);

      if (part.hasControls && !part.isChatMessage) {
        break;
      }
    }

    return parts;
  }),

  showComposer: computed(
    'customBot.paths.firstObject.steps.[]',
    'hasDisabledComposer',
    function () {
      if (this.hasDisabledComposer) {
        return false;
      }

      for (let step of this.firstSteps) {
        if (step.isAttributeCollector) {
          return false;
        }
        if (step.isFreeInput) {
          return true;
        }
      }
      return false;
    },
  ),

  previewConfiguration: null,
  getPreviewConfiguration: task(function* () {
    yield waitForProperty(this, 'app.id');
    let settings = yield this.store.findRecord('messenger-settings/all', this.get('app.id'));
    let previewConfiguration = settings.get('previewConfiguration');
    this.set('previewConfiguration', previewConfiguration);
  }).drop(),
});
