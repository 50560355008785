/* import __COLOCATED_TEMPLATE__ from './pane-selector-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { readOnly, or, equal } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

const channelSelectorDropdownItemClass =
  'inbox__conversation-controls__pane-selector__dropdown-item';

export default Component.extend({
  appService: service(),
  draftConversationsService: service(),
  app: readOnly('appService.app'),
  allowNotes: or('app.inboxIsActive', 'app.requiresInboxSeatAccess'),
  classNames: ['inbox__conversation-controls__pane-selector', 'tabs', 'u__padl__15', 'u__padb__10'],
  classNameBindings: ['noteSelected:o__note-selected'],
  attributeBindings: ['data-test-pane-selector'],
  'data-test-pane-selector': true,
  replyIsDisabled: computed(
    'disabled',
    'adminCanReply',
    'controlsAPI.conversation.canReply',
    function () {
      return this.disabled || !this.adminCanReply || !this.controlsAPI.conversation.canReply;
    },
  ),

  replySelected: equal('controlsAPI.activePaneIdentifier', 'comment'),
  noteSelected: equal('controlsAPI.activePaneIdentifier', 'note'),

  channelSelectorOptions: [
    {
      text: 'Chat',
      value: 'chat',
      componentClasses: channelSelectorDropdownItemClass,
    },
    {
      text: 'Email',
      value: 'email',
      componentClasses: channelSelectorDropdownItemClass,
    },
  ],

  actions: {
    selectPane(paneIdentifier) {
      this.controlsAPI.actions.selectPane(paneIdentifier);
    },
    selectNewConversationChannel(channel) {
      this.controlsAPI.conversation.initial_channel = channel;
      this.draftConversationsService.latestChannel = channel;
      this.onChannelChange(channel);
    },
  },
});
