/* import __COLOCATED_TEMPLATE__ from './stats-tile.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { statisticTypes } from 'embercom/models/data/outbound/constants';

export default class Stat extends Component {
  get statistic() {
    let stat;
    if (this.args.stats) {
      let statsKey = this.args.config.statisticKey;
      stat = this.args.stats.filter(function (stat) {
        if (stat.key === statsKey) {
          return stat;
        }
      }).firstObject;
    }
    return stat;
  }

  get statisticTypes() {
    return statisticTypes;
  }
}
