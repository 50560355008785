/* import __COLOCATED_TEMPLATE__ from './active-view.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { isNone } from '@ember/utils';

export default Component.extend({
  tagName: '',
  contentEditorService: service(),
  activeConfig: computed('config', 'contentEditorService.activeView', function () {
    let config = this.config[this.contentEditorService.activeView];
    if (isNone(config)) {
      throw new Error(
        `No configuration found to render a view for ${this.contentEditorService.activeView}`,
      );
    }
    return config;
  }),
});
