/* import __COLOCATED_TEMPLATE__ from './event-part-user-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';

export default Component.extend({
  userCacheService: service(),
  tagName: 'span',

  didReceiveAttrs() {
    this._super(...arguments);
    this.fetchAndSetUser.perform();
  },

  fetchAndSetUser: task(function* () {
    let userId = this.get('token.id');
    let user = yield this.userCacheService.getUser(userId);

    if (isPresent(user)) {
      this.setProperties({
        userHasLoaded: true,
        user,
      });
    }
  }),
});
