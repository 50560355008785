/* import __COLOCATED_TEMPLATE__ from './content-snippet-body.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type ContentSnippet from 'embercom/models/content-service/content-snippet';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { isLocaleSupported } from 'embercom/components/content-service/ai-content-library/helpers';

interface Args {
  contentSnippet?: ContentSnippet;
  onUpdate: () => void;
}

export default class ContentSnippetBody extends Component<Args> {
  @service declare store: Store;
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare intl: IntlService & { languageNameFromCode: (code: string) => string };
  @tracked selectedContentPreviewType = 'readable';
  @tracked openSectionId: string | null = 'details';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.args.contentSnippet?.isPendingReview) {
      this.openSectionId = null;
    }
  }

  get toggleIsEnabled(): boolean {
    if (!this.args.contentSnippet) {
      return false;
    }

    return isLocaleSupported(this.args.contentSnippet.locale);
  }

  get disabledToggleExplanation() {
    return this.intl.t(
      'ai-content-library.content-snippet.view.body.toggle-disabled-explanation.unsupported-language',
    );
  }

  get teammate(): any | undefined {
    return this.args.contentSnippet?.lastUpdatedByTeammate;
  }

  get createdByTeammate(): any | undefined {
    return this.args.contentSnippet?.createdByTeammate;
  }

  get description() {
    return this.intl.t('ai-content-library.content-snippet.view.empty-state.description');
  }

  get serializedJsonBlocks(): any | undefined {
    let serializedBlocks = this.args.contentSnippet?.serializedBlocks;

    if (!serializedBlocks) {
      return;
    }
    return serializedBlocks;
  }

  @action onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action async onToggleClick() {
    if (!this.args.contentSnippet) {
      return;
    }

    await this.args.contentSnippet.toggleAllAvailabilitiesAndSave();
    this.args.onUpdate();
    this.trackAnalyticsEvent('toggle', 'fin_state');
  }

  private trackAnalyticsEvent(action: string, object: string, metadata?: any): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'operator',
      context: 'snippet_sidedrawer_viewmode',
      place: 'fin_content_page',
      ...metadata,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::View::ContentSnippetBody': typeof ContentSnippetBody;
    'content-service/ai-content-library/view/content-snippet-body': typeof ContentSnippetBody;
  }
}
