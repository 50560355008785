/* import __COLOCATED_TEMPLATE__ from './version-summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import Admin from 'embercom/models/admin';
import { inject as service } from '@ember/service';
import fullRelativeTimeAgo from 'embercom/lib/full-relative-time-ago';
import { states } from 'embercom/models/data/matching-system/matching-constants';
import { action } from '@ember/object';
export default class VersionSummary extends Component {
  @service store;
  @service contentEditorService;
  @service intercomEventService;

  get versionNumber() {
    if (this._rulesetVersion.get('uiIndex')) {
      return `Version ${this._rulesetVersion.get('uiIndex')}`;
    } else if (this._allRulesetLinksAreInDraftState) {
      return 'Version 1';
    } else {
      return 'Latest Version';
    }
  }

  get stateChangeText() {
    if (this.contentEditorService.isViewingVersionSnapshot || this._hasAnyLiveRulesetLink) {
      return `set live ${fullRelativeTimeAgo(this._createdAt).toLowerCase()} by `;
    } else if (this._hasAnyPausedRulesetLink) {
      return `paused ${fullRelativeTimeAgo(this._rulesetLastUpdated).toLowerCase()} by `;
    } else if (this._allRulesetLinksAreInDraftState) {
      return 'waiting to be set live';
    } else {
      return 'changed';
    }
  }

  get creator() {
    if (this._allRulesetLinksAreInDraftState) {
      return;
    }

    if (
      this._hasAnyPausedRulesetLink &&
      !this.contentEditorService.isViewingVersionSnapshot &&
      this._rulesetVersion.get('lastStateChangeAdmin')
    ) {
      return this._rulesetVersion.get('lastStateChangeAdmin');
    }

    return this._rulesetVersion.get('creator') || this._rulesetLastUpdatedBy;
  }

  get _rulesetVersion() {
    return (
      this.contentEditorService.selectedVersion || this.contentEditorService.ruleset.currentVersion
    );
  }

  get _createdAt() {
    if (this._rulesetVersion.get('createdAt')) {
      return this._rulesetVersion.get('createdAt');
    } else {
      return this._rulesetLastUpdated;
    }
  }

  get _rulesetLastUpdated() {
    return this.contentEditorService.activeRulesetLink.lastStateChange.get('createdAt');
  }

  get _rulesetLinksState() {
    return this.contentEditorService.parentRulesetLinks.map((link) => link.state).uniq();
  }

  get _allRulesetLinksAreInDraftState() {
    return (
      this._rulesetLinksState.indexOf(states.draft) > -1 && this._rulesetLinksState.length === 1
    );
  }

  get _hasAnyLiveRulesetLink() {
    return this._rulesetLinksState.indexOf(states.live) > -1;
  }

  get _hasAnyPausedRulesetLink() {
    return this._rulesetLinksState.indexOf(states.paused) > -1;
  }

  get _rulesetLastUpdatedBy() {
    return Admin.peekAndMaybeLoad(
      this.store,
      this.contentEditorService.activeRulesetLink.lastStateChange.get('changedById'),
    );
  }

  @action onClickVersionList() {
    this.args.showVersionList();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'version_label',
      context: 'version_summary',
      place: 'content_editor',
      ruleset_id: this.contentEditorService.parentRulesetId,
      ruleset_version_id: this._rulesetVersion.get('id'),
    });
  }
}
