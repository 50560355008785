/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';

export default class Editor extends Component {
  @service store;
  @service appService;
  @tracked conversationSlas = [];
  @tracked officeHoursSchedules = [];
  @tracked app = this.appService.app;
  @tracked targetChannels = this.args.customBot.targetChannels;

  constructor() {
    super(...arguments);
    this.args.customBot.beforeEdit();
  }

  get showFooter() {
    return (
      this.args.customBot.isOutbound ||
      (this.args.customBot.isInbound && (this.app.hasAndroidSdk || this.app.hasIOSSdk))
    );
  }

  @dropTask
  *getConversationSlas() {
    this.conversationSlas = yield this.store.findAll('inbox/conversation-sla');
  }

  @dropTask
  *getOfficeHoursSchedules() {
    this.officeHoursSchedules = yield this.store.findAll('office-hours-schedule');
  }
}
