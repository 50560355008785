/* import __COLOCATED_TEMPLATE__ from './sms-segment-info-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { SegmentedMessage } from 'sms-segments-calculator';

export default class SmsSegmentInfoComponent extends Component {
  get segmentedMessage() {
    return new SegmentedMessage(
      this.args.blocks
        .map((block, index) => {
          if (block.type === 'paragraph' && index !== this.args.blocks.length - 1) {
            return `${block.text}\n`;
          } else {
            return block.text;
          }
        })
        .join(''),
    );
  }

  get numberSegments() {
    return this.segmentedMessage.segmentsCount;
  }

  get textLength() {
    return this.segmentedMessage.numberOfCharacters;
  }
}
