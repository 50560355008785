/* import __COLOCATED_TEMPLATE__ from './content-snippet-editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';

class ContentSnippetComposerConfig extends BaseConfig {
  autoFocus = false;
  allowedBlocks = ['paragraph', 'heading', 'subheading', 'small'];
  allowTextAlignment = false;
  allowedInline = ['bold', 'italic', 'anchor'];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  textDirection = 'ltr';
  tools = [{ name: 'text-formatter' }, { name: 'anchor-editor' }];

  constructor({ eventService, intl, placeholder }) {
    super();
    this.analytics = {
      trackAnalyticsEvent: (eventName, attrs) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'text-snippet',
          place: 'composer',
          ...attrs,
        });
      },
    };

    (this.eventService = eventService), (this.intl = intl), (this.placeholder = placeholder);
  }
}

export default class ContentSnippetEditor extends Component {
  @service appService;
  @service intl;
  @service store;
  @service intercomEventService;
  blocksDoc;

  constructor() {
    super(...arguments);
    this.blocksDoc = new BlocksDocument(this.serializedContentSnippetBlocks);
  }

  get app() {
    return this.appService.app;
  }

  get textSnippetComposerConfig() {
    return new ContentSnippetComposerConfig({
      eventService: this.intercomEventService,
      intl: this.intl,
      placeholder:
        this.args.placeholder ||
        this.intl.t('ai-content-library.content-snippet.editor.placeholder'),
    });
  }

  get serializedContentSnippetBlocks() {
    return this.args.contentSnippet?.jsonBlocks.serialize();
  }

  @action updateBlocks(blocksDoc) {
    this.blocksDoc = blocksDoc;
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.args.contentSnippet.jsonBlocks = blockFragments;
  }
}
