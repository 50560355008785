/* import __COLOCATED_TEMPLATE__ from './preview-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Args {}

interface Signature {
  Args: Args;
}

export default class PreviewButton extends Component<Signature> {
  @service declare contentEditorService: any;
  @service declare appService: any;
  @service declare intl: IntlService;
  @tracked showPreviewModal = false;

  get survey(): $TSFixMe | undefined {
    return this.contentEditorService.activeObject;
  }

  get canPreviewSurvey() {
    return (
      this.survey.isValid &&
      (this.contentEditorService.isViewMode || !this.survey.hasUnsavedChanges)
    );
  }

  get previewErrorMessage(): string | undefined {
    if (!this.survey.isValid) {
      return this.intl.t('outbound.surveys.preview.errors-in-survey');
    }

    if (!(this.contentEditorService.isViewMode || !this.survey.hasUnsavedChanges)) {
      return this.intl.t('outbound.surveys.preview.save-survey');
    }

    return undefined;
  }

  @action
  togglePreviewModal() {
    this.showPreviewModal = !this.showPreviewModal;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Headers::Surveys::PreviewButton': typeof PreviewButton;
    'content-editor/headers/surveys/preview-button': typeof PreviewButton;
  }
}
