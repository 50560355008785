/* import __COLOCATED_TEMPLATE__ from './prosemirror-step-text-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import BaseComposerConfig from 'embercom/objects/workflows/graph-editor/chat-message/composer-config';

class ComposerConfig extends BaseComposerConfig {
  autoFocus = false;
  textDirection = 'ltr';

  constructor({ app, eventService, resolver, placeholder }) {
    super({ app, resolver, placeholder });

    this.analytics = {
      trackAnalyticsEvent: (eventName, attrs) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'step-text-editor',
          place: 'composer',
          ...attrs,
        });
      },
    };
  }
}

export default class ProsemirrorChatComposer extends Component {
  blocksDoc;
  @service appService;
  @service attributeService;
  @service intercomEventService;
  @service store;

  @tracked composerApi;
  @tracked openInserters;

  constructor() {
    super(...arguments);
    this.blocksDoc = new BlocksDocument(this.args.step.blocks);
    this.openInserters = [];
  }

  get app() {
    return this.appService.app;
  }

  get composerConfig() {
    return new ComposerConfig({
      app: this.app,
      eventService: this.intercomEventService,
      resolver: this.resolver,
      placeholder: this.placeholder,
    });
  }

  get placeholder() {
    return this.args.placeholder ? this.args.placeholder : 'Enter text here…';
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorMetadataAndConversationAttributes,
      customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
      includeAppAttributes: true,
      includeCustomObjectAttributes: true,
    });
  }

  get serializedBlocks() {
    return this.args.step.blocks.serialize();
  }

  get inserters() {
    let inserters = [
      {
        name: 'emoji',
        icon: 'lwr-happy',
        descriptor: 'Add an emoji',
        hasPopoverContentComponent: true,
      },
      {
        name: 'article',
        icon: 'article',
        descriptor: 'Add an article',
        hasPopoverContentComponent: true,
      },
    ];
    if (this.app.teammateConversationGifsEnabled) {
      inserters.insertAt(1, {
        name: 'gif',
        icon: 'gif',
        descriptor: 'Add a GIF',
        hasPopoverContentComponent: true,
      });
    }
    if (this.app.teammateConversationAttachmentsEnabled) {
      inserters.push({
        name: 'image',
        icon: 'picture',
        descriptor: 'Add an image',
        hasPopoverContentComponent: false,
      });
      inserters.push({
        name: 'attachment',
        icon: 'attachment',
        descriptor: 'Add an attachment',
        hasPopoverContentComponent: false,
      });
    }
    if (this.args.canShowVideoBot) {
      inserters.push({
        name: 'video',
        icon: 'video-camera',
        descriptor: 'Add a video clip (40mb max)',
        hasPopoverContentComponent: false,
      });
    }
    return inserters;
  }

  get insertersClass() {
    if (this.openInserters.length > 0) {
      return 'custom-bots__editor__conversation-layout__path-editor__step-text-editor__inserters__active';
    } else {
      return 'custom-bots__editor__conversation-layout__path-editor__step-text-editor__inserters';
    }
  }

  @action updateBlocks(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.args.step.blocks = blockFragments;
  }

  get controlsAPI() {
    return {
      app: this.app,
      actions: {
        registerOpenInserter: (inserterName) => {
          this.openInserters.pushObject(inserterName);
        },
        clearOpenInserter: (inserterName) => {
          if (!this.isDestroying) {
            this.openInserters.removeObject(inserterName);
          }
        },
        paste: (text) => {
          this.composerApi.composer.commands.insertText(text);
        },
        insertBlock: (type, block) => {
          block['type'] = type;
          this.composerApi.composer.commands.insertBlock(block);
        },
        uploadVideo: (files) => {
          let file = files && files.length > 0 && files[0];
          this.trackUploadEvent('upload_started', file.type, file.size);
          this.composerApi.composer.commands.insertVideoFile(file);
        },
        uploadImage: (files) => {
          files.reverse().forEach((file) => {
            this.composerApi.composer.commands.insertImageFile(file);
            this.composerApi.composer.commands.collapseSelection();
          });
        },
        uploadAttachment: (files) => {
          files.forEach((file) => {
            this.composerApi.composer.commands.addAttachment(file);
          });
        },
      },
    };
  }

  trackUploadEvent(action, file_type, file_size, message) {
    let place = 'custom_bot_editor';
    let object = 'prosemirror-path';
    this.intercomEventService.trackAnalyticsEvent({
      action,
      place,
      object,
      file_size,
      file_type,
      message,
    });
  }
}
