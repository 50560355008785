/* import __COLOCATED_TEMPLATE__ from './long-description.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { BaseConfig } from '@intercom/embercom-prosemirror-composer';
import {
  INLINE_CONTROL_ALIGNMENT,
  INLINE_CONTROL_BLOCK_TYPES,
} from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import { inject as service } from '@ember/service';

export class AppStoreComposerConfig extends BaseConfig {
  autoFocus = false;
  allowTextAlignment = false;
  enableAutoLink = true;
  allowImplicitMarginParagraphs = true;
  hideFromTextFormatter = [INLINE_CONTROL_BLOCK_TYPES, INLINE_CONTROL_ALIGNMENT];
  tools = [{ name: 'text-formatter' }, { name: 'anchor-editor' }];
  allowedBlocks = ['paragraph', 'orderedList', 'unorderedList'];
  allowedInline = ['bold', 'italic', 'anchor'];

  constructor(config) {
    super();
    if (config.placeholder) {
      this.placeholder = config.placeholder;
    }
  }
}

export default class Editor extends Component {
  @service intl;

  @tracked blocksDocument = [];

  constructor() {
    super(...arguments);

    this.blocksDocument = new BlocksDocument(this.longDescription);
  }

  get longDescription() {
    return this.args.appPackage.appStoreListing.longDescription;
  }

  get composerConfig() {
    return new AppStoreComposerConfig({
      placeholder: this.intl.t(
        'apps.app.developer-hub.app-packages.app-package.edit.long-description.placeholder',
      ),
    });
  }

  @action
  onComposerChange(newBlocksDoc) {
    this.args.appPackage.appStoreListing.longDescription = newBlocksDoc.blocks;
  }
}
