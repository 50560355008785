/* import __COLOCATED_TEMPLATE__ from './event-part-admin-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
import EmberObject from '@ember/object';
import displayAdminAs from 'embercom/lib/inbox/display-admin-as';

export default Component.extend({
  tagName: 'span',

  hasAdmin: computed('token.id', function () {
    return this.token.id && this.token.id.toString() !== '0';
  }),

  value: computed('admin', 'token', 'tokens', 'part', function () {
    let currentAdmin = this.admin;

    let tokenAdmin = EmberObject.create({
      id: this.token.id.toString(),
      display_as: this.token.value,
    });

    let isAssignee = /assignee/.test(this.token.reference);
    let hasAdminInTokens = this.tokens && this.tokens.any((token) => token.reference === 'admin');

    let isReflexive =
      this.part.hasHumanAssignee &&
      this.part.isReflexiveAssignment &&
      isAssignee &&
      hasAdminInTokens;

    return displayAdminAs(
      currentAdmin,
      tokenAdmin,
      isReflexive, // reflexive
      isReflexive, // lowercase
      false,
    );
  }),
});
