/* import __COLOCATED_TEMPLATE__ from './event-part-snoozed-until-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
import moment from 'moment-timezone';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';

export default Component.extend({
  tagName: 'span',
  value: computed('token.{id,value,custom_snoozed_until_time}', function () {
    if (this.get('token.id') === 'custom_timer') {
      let customSnoozedUntilTime = moment(this.get('token.custom_snoozed_until_time'));
      if (customSnoozedUntilTime.isValid()) {
        return `until ${customSnoozedUntilTime.format(dateAndTimeFormats.timeOnDay)}`;
      }
    }
    return this.get('token.value');
  }),
});
