/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { matchingLocations } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

export default class Header extends Component {
  @service contentEditorService;

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get isDisabled() {
    return this.ruleset.rulesetLinks.any((rulesetLink) =>
      rulesetLink.matchingLocations.includes(matchingLocations.TRIGGER_FROM_CODE),
    );
  }
}
