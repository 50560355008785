/* import __COLOCATED_TEMPLATE__ from './standard-header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dependentKeyCompat } from '@ember/object/compat';

export default class StandardHeader extends Component {
  @service contentEditorService;
  @service seriesEditorService;
  @tracked showConfirmModal = false;

  @dependentKeyCompat
  get isSaveButtonDisabled() {
    if (this.args.config.isValid !== undefined) {
      return !this.args.config.isValid;
    }
    if (this.contentEditorService.ruleset.isLive) {
      return (
        this.contentEditorService.isUploadingFile ||
        !this.contentEditorService.ruleset.validations.isValid
      );
    } else {
      return this.contentEditorService.isUploadingFile;
    }
  }

  get objectName() {
    return this.contentEditorService.activeRulesetLink.humanReadableObjectName;
  }

  @dependentKeyCompat
  get errorMessage() {
    if (this.args.config.errorMessage) {
      return this.args.config.errorMessage;
    }
    return this.contentEditorService.ruleset.validations.errors?.firstObject?.message;
  }

  @action
  async saveChanges() {
    let result = await this.contentEditorService.saveRuleset.perform();

    if (result?.success) {
      this.seriesEditorService.closeRulesetEditor();
    }
  }

  @action
  async cancelChanges() {
    await this.contentEditorService.cancelRulesetChanges.perform();
    this.seriesEditorService.closeRulesetEditor();
  }

  @action
  setTitle(text) {
    this.contentEditorService.ruleset.clientData.title = text;
    if (this.contentEditorService.activeRulesetLink.object.title !== undefined) {
      this.contentEditorService.activeRulesetLink.object.title = text;
    }
  }
}
