/* import __COLOCATED_TEMPLATE__ from './workspace.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { containsByProperty } from '@intercom/pulse/lib/computed-properties';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  regionService: service(),

  appId: alias('row.app.id'),
  ownerAppId: alias('row.appPackage.ownerAppId'),
  appPackageId: alias('row.appPackage.id'),
  protectedAppIds: computed('regionService.intercomAppCode', function () {
    return ['cw4dqqot', this.regionService.intercomAppCode];
  }),
  isOwner: computed('appId', 'ownerAppId', function () {
    return this.appId === this.ownerAppId;
  }),
  isInstalled: alias('row.installState'),
  isProtectedWorkspace: containsByProperty('protectedAppIds', 'appId'),
});
