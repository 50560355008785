/* import __COLOCATED_TEMPLATE__ from './standard-tab.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class StandardTab extends Component {
  @service contentEditorService;
  @service intercomEventService;
  @service router;

  get isActive() {
    return this.args.isActive || this.args.config.view === this.contentEditorService.activeView;
  }

  @action
  changeView() {
    let ruleset = this.contentEditorService.ruleset; // Nil if content is part of a selection set instead.
    if (!this.isActive) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: `${this.args.config.view}_tab`,
        ruleset_id: ruleset?.id,
      });
      if (ruleset) {
        this.contentEditorService.changeActiveViewTo(this.args.config.view);
      } else {
        this.router.transitionTo({ queryParams: { view: this.args.config.view, filter: '' } });
      }
    }
  }
}
