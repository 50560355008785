/* import __COLOCATED_TEMPLATE__ from './help-center-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { tracked } from '@glimmer/tracking';

interface Args {
  helpCenterSites: HelpCenterSite[];
  selectedHelpCenterIds?: Array<string>;
  doSearch: (helpCenterIds?: Array<string>) => void;
  removeFilter?: (status?: string) => void;
  activeFilter?: string;
}

type HelpCenterSelectOption = {
  value: string;
  text: string;
  isSelected: boolean;
};

const NUMBER_OF_HELP_CENTERS_TO_DISPLAY = 2;

export default class HelpCenterFilter extends Component<Args> {
  @service intl!: IntlService;
  @tracked selectedHelpCenters: string[] = [];

  get isOpen() {
    return this.args.activeFilter === 'helpCenterIds';
  }

  get items(): HelpCenterSelectOption[] {
    let items: HelpCenterSelectOption[] = [];
    this.args.helpCenterSites.forEach((helpCenterSite) => {
      items.pushObject({
        text: helpCenterSite.name,
        value: helpCenterSite.id,
        isSelected: this.args.selectedHelpCenterIds?.includes(helpCenterSite.id) || false,
      });
    });
    return items;
  }

  get selectedHelpCentersLabel() {
    if (this.args.selectedHelpCenterIds && this.args.selectedHelpCenterIds.length > 0) {
      if (this.args.selectedHelpCenterIds.length > NUMBER_OF_HELP_CENTERS_TO_DISPLAY) {
        return this.intl.t(
          'ai-content-library.filters.help-center.display-selected-help-center-names-and-count',
          {
            selectedHelpCenterNames: this.selectedHelpCenterNamesToDisplay,
            count: this.args.selectedHelpCenterIds.length - NUMBER_OF_HELP_CENTERS_TO_DISPLAY,
          },
        );
      }
      return this.intl.t(
        'ai-content-library.filters.help-center.display-selected-help-center-names',
        {
          selectedHelpCenterNames: this.selectedHelpCenterNamesToDisplay,
        },
      );
    }

    return this.intl.t('ai-content-library.filters.help-center.all');
  }

  get selectedHelpCenterNamesToDisplay() {
    return this.args.selectedHelpCenterIds
      ?.slice(0, NUMBER_OF_HELP_CENTERS_TO_DISPLAY)
      .map(this.nameForHelpCenter, this)
      .join(', ');
  }

  private nameForHelpCenter(helpCenterId: string) {
    let helpCenter = this.args.helpCenterSites.find((helpCenter) => helpCenter.id === helpCenterId);
    return helpCenter?.name;
  }

  @action
  doSearch(helpCenterIds?: Array<string>) {
    this.args.doSearch(helpCenterIds);
  }

  get showDelete() {
    return !!this.args.removeFilter;
  }

  @action
  removeFilter() {
    this.args.removeFilter!();
    this.args.doSearch();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Filters::HelpCenterFilter': typeof HelpCenterFilter;
  }
}
