/* import __COLOCATED_TEMPLATE__ from './follow-up-rules-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class FollowUpRulesEditor extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get extraSimpleActions() {
    return [
      'send-app-without-introduction',
      'close-conversation',
      'disable-end-user-follow-up-replies',
    ];
  }

  get extraRuleActions() {
    return [
      'send-app-with-introduction',
      'close-conversation',
      'send-follow-up-reply',
      'disable-end-user-follow-up-replies',
    ];
  }
}
