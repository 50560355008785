/* import __COLOCATED_TEMPLATE__ from './screenshot-row.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';

const SCREENSHOT_ROW_URL_LENGTH = 35;

export default Component.extend({
  imageName: computed('imageUrl', function () {
    let imageUrl = this.imageUrl;
    if (!imageUrl) {
      return;
    }

    let imageUrlParts = this.imageUrl.split('/');
    if (imageUrlParts.length < 1) {
      return;
    }

    let imageName = imageUrlParts.pop();
    let trimmedImageName =
      imageName.length > SCREENSHOT_ROW_URL_LENGTH
        ? `${imageName.substring(0, SCREENSHOT_ROW_URL_LENGTH - 3)}...`
        : imageName;

    return trimmedImageName;
  }),
});
