/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { edgeSplitTypes } from 'embercom/models/data/matching-system/matching-constants';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { isPresent } from '@ember/utils';

const CONTROL_GROUP_INDEX = 5;

export default class SplitterComponent extends Component {
  @service seriesEditorService;
  @service contentEditorService;
  @service store;

  defaultGoalTimeConstraint = 720; // 30 days in hours
  goalEntityType = objectTypes.splitter;

  constructor(...args) {
    super(...args);
  }

  get splitter() {
    return this.contentEditorService.activeObject;
  }

  get activeNode() {
    return this.seriesEditorService.activeSeriesNode;
  }

  @action
  addTest() {
    let edgeSplit = this.store.createRecord('series/edge-split', {
      splitType: edgeSplitTypes.test,
      percentage: 0,
      index: this._lastIndex() + 1,
      showRemoveButton: false,
    });
    this.splitter.edgeSplits.pushObject(edgeSplit);
  }

  @action
  addControl() {
    let edgeSplit = this.store.createRecord('series/edge-split', {
      splitType: edgeSplitTypes.control,
      percentage: 0,
      index: CONTROL_GROUP_INDEX,
      showRemoveButton: false,
    });
    this.splitter.edgeSplits.pushObject(edgeSplit);
  }

  @action
  removeSplit(edgeSplit) {
    if (!edgeSplit.hasEdge) {
      this.splitter.edgeSplits.removeObject(edgeSplit);
      this.splitter.setEdgeSplitIndex();
    }
  }

  @action
  splitEqually() {
    let percentageSplit = Math.round((100 / this.splitter.edgeSplits.length) * 100) / 100;
    this.splitter.edgeSplits.forEach((edgeSplit) => {
      edgeSplit.percentage = percentageSplit;
    });

    if (100 % percentageSplit !== 0) {
      this.splitter.edgeSplits.lastObject.percentage = percentageSplit + (100 % percentageSplit);
    }
  }

  _lastIndex() {
    if (isPresent(this.splitter.edgeSplitTests.lastObject)) {
      return this.splitter.edgeSplitTests.lastObject.index;
    }

    return -1;
  }
}
