/* import __COLOCATED_TEMPLATE__ from './tags.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Component.extend({
  tagName: '',
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),

  didReceiveAttrs() {
    this._super(...arguments);
    this.conversation.taggings.reload();
  },

  tagsAreLoading: computed('conversation.taggings.isPending', function () {
    return (
      this.conversation.taggings.isPending && this.conversation.hasMany('taggings').value() === null
    );
  }),

  openTaggingModal: action(function (part) {
    this.modalService.openModal('inbox/inbox/conversations/modals/tagging', {
      taggable: this.conversation,
    });
  }),
});
