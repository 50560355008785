/* import __COLOCATED_TEMPLATE__ from './object-type-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { aiContentEntities } from 'embercom/lib/ai-content-library/constants';
import {
  objectIcons,
  humanReadableObjectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';
import { type EntityType } from 'embercom/models/data/entity-types';

interface Args {
  selectedObjectTypes?: Array<EntityType>;
  doSearch: (contentTypes?: Array<EntityType>) => void;
}

const NUMBER_OF_OBJECT_TYPES_TO_DISPLAY = 2;

export default class ObjectTypeFilter extends Component<Args> {
  @service intl!: IntlService;
  @service declare appService: any;

  get items() {
    let items: any = [];
    aiContentEntities().forEach((type) => {
      items.pushObject({
        text: capitalize(humanReadableObjectNames[type]),
        icon: objectIcons[type],
        value: type,
        isSelected: this.args.selectedObjectTypes?.includes(type),
      });
    });

    return [{ items }];
  }

  get selectedContentType() {
    if (this.args.selectedObjectTypes && this.args.selectedObjectTypes?.length > 0) {
      if (this.args.selectedObjectTypes.length > NUMBER_OF_OBJECT_TYPES_TO_DISPLAY) {
        return this.intl.t('ai-content-library.search.display-selected-item-and-count', {
          selectedItems: this.selectedItemsToDisplay,
          count: this.args.selectedObjectTypes.length - NUMBER_OF_OBJECT_TYPES_TO_DISPLAY,
        });
      }
      return this.intl.t('ai-content-library.search.display-selected-items', {
        selectedItems: this.selectedItemsToDisplay,
      });
    }

    return this.intl.t('ai-content-library.search.all-content-types');
  }

  get selectedItemsToDisplay() {
    return this.args.selectedObjectTypes
      ?.slice(0, NUMBER_OF_OBJECT_TYPES_TO_DISPLAY)
      .map((type) => capitalize(humanReadableObjectNames[type]))
      .join(', ');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Filters::ObjectTypeFilter': typeof ObjectTypeFilter;
  }
}
