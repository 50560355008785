/* import __COLOCATED_TEMPLATE__ from './is-typing-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly, reads, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  classNames: ['flex', 'items-center', 'relative'],
  isInbox: false,
  isUser: reads('conversation.userIsTyping'),
  isAdmin: or('isAdminComment', 'isAdminNote'),
  isAdminComment: reads('conversation.anotherAdminIsTypingAComment'),
  isAdminNote: reads('conversation.anotherAdminIsTypingANote'),
  shouldDisplayAvatar: computed('isUser', 'conversation.lastPart', function () {
    if (this.isUser) {
      return (
        !this.isInbox &&
        (this.get('conversation.lastPart.isAdminPart') ||
          this.get('conversation.lastPart.hasLightweightResponse'))
      );
    }
    return true;
  }),
});
