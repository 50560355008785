/* import __COLOCATED_TEMPLATE__ from './customer-workspaces.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class CustomerWorkspaces extends Component {
  @service developerHub;

  get appPackage() {
    return this.developerHub.appPackage;
  }

  get showReviewBanner() {
    return this.developerHub.appPackageHasChanges;
  }
}
