/* import __COLOCATED_TEMPLATE__ from './video-opener.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { ref } from 'ember-ref-bucket';

export default class ConversationControlInserterVideoOpener extends Component {
  @service intercomEventService;
  @service store;

  analyticsName = 'video_inserter';

  @ref('inputElement') inputElement;

  @action
  handleClick(event) {
    this.intercomEventService.trackAnalyticsEvent({
      owner: 'inbox',
      action: 'clicked',
      object: this.analyticsName,
      place: 'inbox',
      context: this.analyticsName,
    });
  }

  @action
  handleChange(event) {
    let files = Array.from(event.target.files);
    if (files.length > 0) {
      this.inputElement.value = '';
      this.args.controlsAPI?.actions.uploadVideo(files);
      this.args.inserterAPI?.actions.close();
    }
  }
}
