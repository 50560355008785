/* import __COLOCATED_TEMPLATE__ from './series-mode.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class SeriesMode extends Component {
  @service contentEditorService;

  @action markClientDataAsOpened() {
    this.contentEditorService.ruleset.clientData.openedSeriesEditor = true;
  }
}
