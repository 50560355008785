/* import __COLOCATED_TEMPLATE__ from './redact-reply-quick-action.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable promise/prefer-await-to-then */
import QuickActionBaseComponent from './quick-action-base-component';
import { inject as service } from '@ember/service';

export default QuickActionBaseComponent.extend({
  permissionsService: service(),

  icon: 'delete',
  message: 'Delete',

  actions: {
    openRedactPartModal(part) {
      this.permissionsService.checkPermission('can_redact_conversation_parts').then(() => {
        this.openModal('inbox/inbox/conversations/modals/redact-part', {
          part,
        });
      });
    },
  },
});
