/* import __COLOCATED_TEMPLATE__ from './buttons-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { typeOf } from '@ember/utils';
import { computed } from '@ember/object';
import { readOnly, not, alias, equal, and, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import platform from 'embercom/lib/browser-platform';

export default Component.extend({
  appService: service(),
  router: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  conversation: readOnly('controlsAPI.conversation'),
  bodyEmpty: not('controlsAPI.composerHasContent'),
  noMacroActions: not('controlsAPI.composerHasMacroActions'),
  classNames: ['u__3dfix', 'flex'],
  isOpen: alias('conversation.isOpen'),
  notShowSocialComposerBanner: not('conversation.showSocialComposerBanner'),

  replySelected: equal('controlsAPI.activePaneIdentifier', 'comment'),
  replyNotSelected: not('replySelected'),
  preventAdminReply: and('conversation.preventAdminReply', 'replySelected'),
  shouldPreventSending: or(
    'controlsAPI.composerIsUploading',
    'controlsAPI.isFetchingSavedReply',
    'preventAdminReply',
    'controlsAPI.conversationHasInvalidFields',
    'controlsAPI.sendInProgress',
  ),
  nothingToSend: computed(
    'app.canUseMacros',
    'bodyEmpty',
    'noMacroActions',
    'replySelected',
    function () {
      if (this.replySelected || this.get('app.canUseMacros')) {
        return this.bodyEmpty && this.noMacroActions;
      }
      return this.bodyEmpty;
    },
  ),
  primaryIsDisabled: computed(
    'conversation.{showSocialInactiveConversationBanner,showWhatsappWarningBanner}',
    'nothingToSend',
    'replySelected',
    'shouldPreventSending',
    function () {
      if (this.conversation.showSocialInactiveConversationBanner) {
        return false;
      }
      if (this.replySelected && this.conversation.showWhatsappWarningBanner) {
        return false;
      }
      return this.nothingToSend || this.shouldPreventSending;
    },
  ),
  buttonNameForSnoozed: computed('admin', 'conversation', 'conversation.isDraft', function () {
    if (this.get('conversation.isDraft') || this.conversation.isAssignedTo(this.admin)) {
      return 'Send';
    } else {
      return 'Send and reopen';
    }
  }),
  macroHasCloseOrSnoozeAction: readOnly(
    'controlsAPI.composerComponent.macro.hasCloseOrSnoozeAction',
  ),
  macroHasNoCloseOrSnoozeAction: not('macroHasCloseOrSnoozeAction'),
  composerHasContentOrMacroActions: or(
    'controlsAPI.composerHasContent',
    'controlsAPI.composerHasMacroActions',
  ),
  showSecondary: and(
    'replySelected',
    'composerHasContentOrMacroActions',
    'isOpen',
    'macroHasNoCloseOrSnoozeAction',
    'primarySendNotInProgress',
    'notShowSocialComposerBanner',
  ),
  secondaryIsDisabled: computed(
    'composerHasContentOrMacroActions',
    'shouldPreventSending',
    function () {
      if (this.shouldPreventSending) {
        return true;
      }

      return !this.composerHasContentOrMacroActions;
    },
  ),
  primarySendInProgress: computed('controlsAPI.{sendInProgress,sendInProgressType}', function () {
    return this.controlsAPI.sendInProgress && this.controlsAPI.sendInProgressType === 'send';
  }),
  primarySendNotInProgress: not('primarySendInProgress'),

  primaryText: computed(
    'primaryButtonTextSet',
    'controlsAPI.activePaneIdentifier',
    'primarySendInProgress',
    function () {
      if (this.primarySendInProgress) {
        return 'Sending...';
      } else {
        let textSet = this.primaryButtonTextSet;
        return textSet[this.get('controlsAPI.activePaneIdentifier')];
      }
    },
  ),

  primaryTooltip: computed(function () {
    return platform.isMac ? '⌘+Enter' : 'CTRL+Enter';
  }),

  primaryButtonTextSet: computed(
    'buttonNameForSnoozed',
    'conversation.{showSocialInactiveConversationBanner,showWhatsappWarningBanner,state,isDraft,initial_channel}',
    'isOpen',
    'replySelected',
    function () {
      if (this.replySelected && this.get('conversation.showSocialInactiveConversationBanner')) {
        return { comment: 'Reply to latest conversation', note: 'Add note' };
      }
      if (this.replySelected && this.get('conversation.showWhatsappWarningBanner')) {
        return { comment: 'Choose template', note: 'Add note' };
      }
      if (this.replySelected && this.conversation.isDraft) {
        return { comment: `Send ${this.conversation.initial_channel}`, note: 'Add note' };
      }

      let open = { comment: 'Send', note: 'Add note' };
      let closed = { comment: 'Send and reopen', note: 'Add note' };
      let snoozed = { comment: this.buttonNameForSnoozed, note: 'Add note' };

      let conversationState = this.get('conversation.state');

      if (typeOf(conversationState) === 'number') {
        return { 0: open, 1: closed, 2: snoozed }[conversationState];
      } else {
        return open;
      }
    },
  ),

  submit(type) {
    this.get('controlsAPI.actions.createPart')(type, true);
    this.get('controlsAPI.actions.scrollStreamToBottom')();
  },

  actions: {
    submit(type) {
      if (this.get('app.canUseMessenger')) {
        if (this.replySelected && this.conversation.showSocialInactiveConversationBanner) {
          this.router.transitionTo(
            this.app.conversationRedirectRoute,
            this.conversation.social_latest_conversation,
          );
          return;
        }
        if (this.replySelected && this.conversation.showWhatsappWarningBanner) {
          this.controlsAPI.actions.showMessageTemplatesSelector();
          return;
        }
        let submitWithType = this.submit.bind(this, type);
        submitWithType();
      }
    },
  },
});
