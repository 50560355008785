/* import __COLOCATED_TEMPLATE__ from './saved-reply-typeahead.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
// import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { type TypeaheadMatchResult } from '@intercom/embercom-prosemirror-composer';
import { action } from '@ember/object';
// @ts-ignore
import Triejs from 'triejs';
import latinize from 'latinize';
import type Composer from '@intercom/embercom-prosemirror-composer/lib/composer';
import SavedReply from '../../../models/saved-reply';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  composer: Composer;
  matchResult: TypeaheadMatchResult | null;
  registerDelegate(f: Function): void;
  deregisterDelegate(f: Function): void;
  attrs: { conversation: any; macro: any; activePane: string; bulkInsert: boolean };
}

export default class SavedReplyInserter extends Component<Args> {
  @service declare appService: any;
  @service savedReplySearchService: any;
  @service intercomEventService: any;
  @service savedReplyInsertionsService: any;
  @tracked filteredReplies: Array<any> = [];

  get app() {
    return this.appService.app;
  }

  get macroTypeFilter() {
    if (this.args.attrs.activePane === 'note') {
      return 'note';
    } else if (this.args.attrs.conversation?.isDraft) {
      return 'opener';
    } else {
      return 'reply';
    }
  }

  get filterableSavedReplies() {
    return this.app.visibleSavedReplies.filter(
      ({ types }: { types: Array<string> }) =>
        types.length === 0 || types.includes(this.macroTypeFilter),
    );
  }

  @action
  updateFilteredReplies() {
    if (!this.args.matchResult) {
      this.filteredReplies = [];
      return;
    }
    if (this.args.matchResult.text.length === 1) {
      this.filteredReplies = this.filterableSavedReplies.map((s: any) => s);
      return;
    }

    let query = this.args.matchResult.text.replace('#', '');
    taskFor(this.searchSavedReplies).perform(query);
  }

  @task({ restartable: true })
  // eslint-disable-next-line require-yield
  *searchSavedReplies(query: string) {
    // This timeout was designed to introduced debouncing in response to this issue:
    // https://github.com/intercom/intercom/issues/198443
    // But disabled after reports of slow typeahead performance and issues like this:
    // https://github.com/intercom/intercom/issues/198830
    //
    // yield timeout(150);

    let results = [];
    if (this.app.canUseSavedRepliesFuzzySearch) {
      results = this.savedReplySearchService.typeaheadSearch(
        query.substring(1),
        this.macroTypeFilter,
      );
    } else {
      results = this.items.find(query) || [];
    }

    let regexResults = this.savedReplySearchService.searchUsingRegex(
      this.filterableSavedReplies,
      query,
      this.savedReplySearchService.testName,
    );

    let trieIds = results.mapBy('id');
    let uniqueRegexResults = regexResults.filter(
      (regexResult: any) => !trieIds.includes(regexResult.id),
    );

    results = results.concat(uniqueRegexResults.slice(0, 5));
    this.filteredReplies = results;

    this.intercomEventService.trackAnalyticsEvent({
      owner: 'inbox',
      action: 'searched',
      object: 'saved_reply',
      context: 'saved_reply_typeahead',
      place: 'inbox',
      query_keywords: query,
      number_of_results: results.length,
    });
  }

  get items() {
    let sortKeys = [{ key: 'sortInsertionsCount', reverse: true }, { key: 'name' }];
    let getSortFunction = this.getSortFunction;

    let trie = new Triejs({
      sort() {
        if (sortKeys.length) {
          this.sort(getSortFunction(sortKeys));
        }
      },
    });

    this.filterableSavedReplies.forEach((savedReply: any) => {
      trie.add(this.normalizeQuotes(latinize(savedReply.get('name'))), savedReply);
    });

    return trie;
  }

  @task({ restartable: true })
  *insertSavedReply(savedReply: any): any {
    if (!this.args.matchResult) {
      return;
    }

    let prosemirrorState = this.args.composer.state.prosemirrorState;
    let tr = prosemirrorState.tr;

    tr.delete(this.args.matchResult.range.from, this.args.matchResult.range.to);
    this.args.composer.commands.dispatch(tr);

    let conversation = this.args.attrs.conversation;
    let conversationId = conversation?.id;
    let savedReplyId = savedReply.id;
    let marker = this.args.composer.commands.insertMarker();
    let blocks;

    if (this.args.attrs.bulkInsert || conversation.isDraft) {
      blocks = savedReply.blocks;
    } else {
      let rendered = yield SavedReply.getRendered(savedReplyId, this.app.id, conversationId);
      blocks = rendered.blocks;
    }

    if (this.app.canUseMacros) {
      this.args.attrs.macro.createActionsFragments(savedReply.actions);
    }

    if (blocks.length > 0) {
      this.args.composer.commands.insertAtMarker(marker, blocks);
      this.args.composer.commands.collapseSelectionToEnd();
      this.args.composer.commands.insertText(' ');
    }

    this.args.composer.commands.deleteMarker(marker);
    if (this.args.attrs.bulkInsert || !conversation.isDraft) {
      this.trackInsertion(savedReply, conversationId);
    }
    this.args.composer.commands.focus();
  }

  trackInsertion(savedReply: any, conversationId: string) {
    let actionTypes = savedReply.actions?.map((a: any) => a.type) || [];
    let insertedFrom =
      this.args.attrs.activePane === 'comment'
        ? 'saved_reply_typeahead'
        : 'saved_reply_note_typeahead';
    let place = this.args.attrs.bulkInsert ? 'bulk-modal' : 'inbox';
    this.intercomEventService.trackAnalyticsEvent({
      owner: 'inbox',
      action: 'added_to_reply',
      object: 'saved_reply',
      place,
      context: 'saved_reply_typeahead',
      saved_reply_id: savedReply.id,
      inserted_from: insertedFrom,
      conversation_id: conversationId,
      assign: actionTypes.any(
        (a: string) => a === 'assign-conversation' || a === 'assign-conversation-to-owner',
      ),
      close: actionTypes.any((a: string) => a === 'close-conversation'),
      snooze: actionTypes.any((a: string) => a === 'snooze-conversation'),
      tag: actionTypes.any((a: string) => a === 'add-tag-to-conversation'),
      priority: actionTypes.any((a: string) => a === 'change-conversation-priority'),
      cda: actionTypes.any((a: string) => a === 'set-conversation-data-attribute'),
      has_text: (savedReply.blocks?.length || 0) > 0,
      visibility: savedReply.visibility,
    });
    if (!this.args.attrs.bulkInsert) {
      this.savedReplyInsertionsService.recordInsertion(
        this.app,
        this.app.currentAdmin,
        savedReply,
        conversationId,
      );
    }
    if (actionTypes.length > 0) {
      this.intercomEventService.trackEvent('macro_shared_in_conversations');
      this.intercomEventService.trackEvent('used_macro');
    }
  }

  normalizeQuotes(string: string): string {
    return string.replace('‘', "'").replace('’', "'").replace('“', '"').replace('”', '"');
  }

  getSortFunction(sortKeys: Array<{ key: string; reverse?: boolean }>) {
    return function (a: any, b: any): number {
      let comparisonResult = 0;

      for (let sortKey of sortKeys) {
        let left = sortKey.reverse ? b : a;
        let right = sortKey.reverse ? a : b;

        let leftValue = left.get(sortKey.key);
        let rightValue = right.get(sortKey.key);

        if (typeof leftValue === 'string' && typeof rightValue === 'string') {
          comparisonResult = leftValue.localeCompare(rightValue);
        } else if (typeof leftValue === 'number' && typeof rightValue === 'number') {
          comparisonResult = leftValue - rightValue;
        }

        if (comparisonResult !== 0) {
          return comparisonResult;
        }
      }

      return 0;
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Conversation::Composer::SavedReplyTypeahead': typeof SavedReplyInserter;
    'conversation/composer/saved-reply-typeahead': typeof SavedReplyInserter;
  }
}
