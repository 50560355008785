/* RESPONSIBLE TEAM: team-workflows */
import { FIN_SUPPORTED_LANGUAGES, type Language } from 'embercom/lib/ai-content-library/constants';

export function isLocaleSupported(locale: string): boolean {
  let standardLocale = locale.substring(0, 2);

  return FIN_SUPPORTED_LANGUAGES.some(
    (language: Language) => language.locale === locale || language.locale === standardLocale,
  );
}
