/* import __COLOCATED_TEMPLATE__ from './away-mode-warning.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import storage from 'embercom/vendor/intercom/storage';

export default Component.extend({
  intercomEventService: service(),
  notificationsService: service(),
  conversationsService: service(),
  dontAskAgain: false,

  persistDontAskAgain() {
    let value = this.dontAskAgain;
    let storageKey = this.get('conversationsService.SILENCE_AWAY_WARNING_KEY');
    if (value) {
      storage.set(storageKey, value);
    } else {
      storage.remove(storageKey);
    }
  },

  actions: {
    close() {
      this.persistDontAskAgain();
      this.closeModal();
    },
    disableAwayMode() {
      let admin = this.admin;
      let initialValues = admin.getProperties(['away_mode_enabled', 'reassign_conversations']);
      admin.setProperties({
        away_mode_enabled: false,
        reassign_conversations: false,
      });
      this.intercomEventService.trackAnalyticsEvent({
        action: 'disabled',
        object: 'reassign_conversation_setting',
        context: 'by_replying',
      });
      admin
        .save()
        .then(() => {
          this.notificationsService.notifyConfirmation('You’re active again!');
          this.send('close');
        })
        .catch(() => {
          this.notificationsService.notifyError('There was a problem turning off away mode');
          admin.setProperties(initialValues);
        });
    },
  },
});
