/* import __COLOCATED_TEMPLATE__ from './test-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { post } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';

export default class TestButton extends Component {
  @service contentEditorService;
  @service appService;
  @tracked selectedUser;
  @tracked postResult;
  @tracked isLoading = false;
  @tracked showModal = false;

  get testEnabled() {
    return this.webhook.url;
  }

  get webhook() {
    return this.contentEditorService.activeObject;
  }

  @action setUser(user) {
    this.selectedUser = user;
  }

  @action
  async runTest() {
    this.isLoading = true;
    let app = this.appService.app;
    let { id, ...params } = this.webhook.serialize();
    params['app_id'] = app.id;
    params['user_id'] = this.selectedUser.id;

    try {
      let result = await post(`/ember/outbound_webhooks/${id}/send_test`, params);
      this.postResult = result.status;
      if (result.body) {
        this.postResult += `: ${result.body}`;
      }
    } catch (e) {
      this.postResult =
        'An error occurred while performing this request. Please check your parameters and try again later.';
    }
    this.isLoading = false;
  }

  @action
  closeModal() {
    this.selectedUser = undefined;
    this.postResult = undefined;
    this.showModal = false;
    this.isLoading = false;
  }
}
