/* import __COLOCATED_TEMPLATE__ from './stat-tab.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { dependentKeyCompat } from '@ember/object/compat';

export default class StatTab extends Component {
  @service contentEditorService;

  @dependentKeyCompat
  get hasReactionStats() {
    return this.args.config.statistic?.subaggregations?.length > 0;
  }

  get reactionStats() {
    let reactionSet = this.contentEditorService.activeObject.reactionSet;
    let subaggregations = this.args.config.statistic.subaggregations;

    let stats = reactionSet.map(function (reaction) {
      let reactionStat = subaggregations.filterBy('value', reaction.unicodeEmoticon).firstObject;
      return {
        unicodeEmoticon: reaction.unicodeEmoticon,
        count: reactionStat?.count || 0,
      };
    });
    return stats.sortBy('count').reverse();
  }

  get selectedVersionStats() {
    let contentStats = this.contentEditorService.selectedVersion.contentStats.filter(
      (object) => object.content_id === this.contentEditorService.activeObjectId,
    ).firstObject.stats;
    return contentStats.findBy('key', this.args.config.statisticKey);
  }
}
