/* import __COLOCATED_TEMPLATE__ from './locale-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';

interface Args {
  contentWrapper: ContentWrapper;
}

export default class LocaleCell extends Component<Args> {
  @service declare intl: IntlService & { languageNameFromCode: (code: string) => string };

  get language(): string | void {
    if (this.args.contentWrapper.contents.firstObject!.locale) {
      return this.intl.languageNameFromCode(this.args.contentWrapper.contents.firstObject!.locale);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Cells::LocaleCell': typeof LocaleCell;
  }
}
