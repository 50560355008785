/* import __COLOCATED_TEMPLATE__ from './learn.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { LEARN_MORE_CONTENT_IDS } from 'embercom/lib/ai-content-library/constants';

export default class Learn extends Component {
  @service intercomEventService;
  @service intl;

  trackAnalytics(buttonText, id) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'learn_dropdown',
      place: 'fin_content_page',
      button_text: buttonText,
      link_to: id,
    });
  }

  showSnippetArticle = () => {
    window.Intercom('showArticle', LEARN_MORE_CONTENT_IDS.snippet);
    this.trackAnalytics('snippet_article', LEARN_MORE_CONTENT_IDS.snippet);
  };

  showPDFArticle = () => {
    window.Intercom('showArticle', LEARN_MORE_CONTENT_IDS.pdf);
    this.trackAnalytics('pdf_article', LEARN_MORE_CONTENT_IDS.pdf);
  };

  showControlContentArticle = () => {
    window.Intercom('showArticle', LEARN_MORE_CONTENT_IDS.controlContent);
    this.trackAnalytics('control_content_article', LEARN_MORE_CONTENT_IDS.controlContent);
  };

  showBestPracticesArticle = () => {
    window.Intercom('showArticle', LEARN_MORE_CONTENT_IDS.bestPractices);
    this.trackAnalytics('best_practices_article', LEARN_MORE_CONTENT_IDS.bestPractices);
  };

  trackLearningOpened = () => {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'learn_button',
    });
  };
}
