/* import __COLOCATED_TEMPLATE__ from './state-change-summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import fullRelativeTimeAgo from 'embercom/lib/full-relative-time-ago';
import Admin from 'embercom/models/admin';
import { states } from 'embercom/models/data/matching-system/matching-constants';

export default Component.extend({
  tagName: '',
  store: service(),
  contentEditorService: service(),
  appService: service(),
  rulesetLink: readOnly('contentEditorService.activeRulesetLink'),
  stateChangeAdmin: computed('rulesetLink.lastStateChange.changedById', function () {
    return this.rulesetLink.lastStateChange.get('changedById')
      ? Admin.peekAndMaybeLoad(this.store, this.rulesetLink.lastStateChange.get('changedById'))
      : null;
  }),
  app: readOnly('appService.app'),
  stateChangeText: computed('rulesetLink.state', function () {
    switch (this.rulesetLink.state) {
      case states.live:
        return `Set live ${fullRelativeTimeAgo(
          this.rulesetLink.lastStateChange.get('createdAt'),
        ).toLowerCase()} by`;
      case states.paused:
        return `Paused ${fullRelativeTimeAgo(
          this.rulesetLink.lastStateChange.get('createdAt'),
        ).toLowerCase()} by`;
      case states.scheduled:
        return 'Scheduled by';
      default:
        return 'Changed by';
    }
  }),
});
