/* import __COLOCATED_TEMPLATE__ from './reached-stat-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  makeStatsParametersForRulesetLink,
  makeStatsParametersForTooltip,
} from 'embercom/helpers/stats-system/stats-parameters';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { tracked } from '@glimmer/tracking';
export default class ReachedStatList extends Component {
  @service contentEditorService;
  @service appService;

  @tracked statsParameters;

  constructor() {
    super(...arguments);

    this.statsParameters = this.createStatParameters(this.contentEditorService.range);
  }

  @action
  changeFilter(_, filter) {
    this.contentEditorService.changeActiveFilterTo(filter);
    this.statsParameters = this.createStatParameters(this.contentEditorService.range);
  }

  get tooltipGroup() {
    return this.contentEditorService.activeObject;
  }

  @action updateDateRange(range) {
    this.contentEditorService.updateRange(range);
    this.statsParameters = this.createStatParameters(range);
  }

  createStatParameters(range) {
    let ruleset = this.contentEditorService.ruleset;
    let rulesetLink = this.contentEditorService.activeRulesetLink;

    // Get the stats for an individual tooltip
    if (
      Number(this.contentEditorService.activeFilter) !== 0 &&
      this.contentEditorService.activeFilter !== stats.receipt
    ) {
      let tooltip = this.tooltipGroup.tooltips.find(
        (tooltip) => Number(tooltip.id) === Number(this.contentEditorService.activeFilter),
      );

      return makeStatsParametersForTooltip(
        tooltip,
        ruleset,
        rulesetLink,
        range,
        this.appService.app.timezone,
      );
    }

    return makeStatsParametersForRulesetLink(
      ruleset,
      rulesetLink,
      range,
      this.appService.app.timezone,
    );
  }
}
