/* import __COLOCATED_TEMPLATE__ from './article-popover-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { debounce } from '@ember/runloop';
import { readOnly, notEmpty, not, gt, reads, oneWay } from '@ember/object/computed';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import KeyboardSelectable from 'embercom/components/mixins/keyboard-selectable-dropdown';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import { task } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';
import { ARTICLES_ID } from 'embercom/lib/billing';
import defaultTo from '@intercom/pulse/lib/default-to';
import { ARTICLES_PRO_ID } from 'embercom/lib/billing';

let queryCache = {
  query: '',
  filteredContent: [],
  conversationId: null,
};

export { queryCache };

export default Component.extend(KeyboardSelectable, EventTracking, {
  intercomEventService: service(),
  paywallService: service(),
  appService: service(),
  helpCenterService: service(),
  router: service(),
  store: service(),

  classNames: ['article-inserter'],
  app: readOnly('appService.app'),
  hasArticlesProduct: readOnly('app.articlesProduct.active'),
  content: [],
  hasArticles: true,
  filteredContent: defaultTo([]),
  query: '',
  articlesProPromoKey: 'articles-pro-inserter-upgrade',
  inserterIsInstalled: false,

  hasQuery: notEmpty('query'),
  hasNoQuery: not('hasQuery'),
  hasFilteredContent: gt('filteredContent.length', 0),
  selectableItems: reads('filteredContent'),
  selectedLocaleId: oneWay('previewDropdownItems.firstObject.value'),
  previewDropdownItems: computed(
    'site.selectedLocalesOrdered',
    'previewArticle.publishedContentsLocaleIds',
    function () {
      // This null check shouldn't be needed since this property should only be called when 'previewArticle' exist
      // This is an ember bug and has been fixed in later update: https://github.com/emberjs/ember.js/issues/19162
      if (!this.previewArticle) {
        return [];
      }

      let items = this.site.selectedLocalesOrdered.map((locale) => {
        let hasPublishedArticle =
          this.previewArticle.publishedContentsLocaleIds.find(
            (localeId) => localeId === locale.localeId,
          ) !== undefined;

        return {
          text: hasPublishedArticle ? locale.name : `${locale.name} (Not translated)`,
          value: locale.localeId,
          component: 'articles/inbox-inserter/locale-dropdown-item',
          id: locale.localeId,
          isDisabled: !hasPublishedArticle,
        };
      });

      items.sort((a, b) => {
        if (a.isDisabled && !b.isDisabled) {
          return 1;
        }
        if (!a.isDisabled && b.isDisabled) {
          return -1;
        }
        return 0;
      });

      return items;
    },
  ),
  site: computed(function () {
    return this.store.peekAll('help-center-site').firstObject;
  }),
  sites: readOnly('helpCenterService.allSites'),
  helpCenterItems: computed('sites', function () {
    let result = this.sites?.map((s) => ({ name: s.name, id: s.id }));
    result.push({ name: 'Unlisted Articles', id: 'unlisted' });

    return result;
  }),
  supportedLocales: readOnly('site.supportedLocales'),
  selectedLocaleName: computed('supportedLocales', 'selectedLocaleId', function () {
    return this._getLocaleName(this.selectedLocaleId);
  }),

  _getLocaleName(localeId) {
    return this.supportedLocales.findBy('localeId', localeId).name;
  },

  didInsertElement() {
    this._super(...arguments);
    this.checkInserterInstalledTask.perform();
    this.loadResultsFromCache();
    this.intercomEventService.trackEvent('knowledgebase-popover-opened');
    this.trackEducateEvent({
      action: 'clicked',
      object: 'article_inserter',
      place: 'inbox',
      context: 'article_inserter',
    });
    if (this.hasFilteredContent) {
      this.set('displayQuery', this.query);
    } else {
      this.runSearchTask('');
    }

    this.helpCenterService.forceFetchSite(undefined);
  },

  loadResultsFromCache() {
    let conversationId = this.get('controlsAPI.conversation.id');
    if (queryCache.conversationId === conversationId) {
      this.setProperties({
        filteredContent: queryCache.filteredContent.slice(0),
        query: queryCache.query,
      });
    } else {
      queryCache.conversationId = conversationId;
    }
  },

  checkInserterInstalledTask: task(function* () {
    let record = yield this.store.findRecord('appstore/app-package', 'article-inserter', {
      reload: true,
    });
    if (record && record.isInstalled) {
      this.set('inserterIsInstalled', true);
    } else {
      this.set('inserterIsInstalled', false);
    }
  }),

  search(query) {
    return this.helpCenterService
      .maybeFetchSite()
      .then(() => {
        let url = '/ember/articles/inserter_search';
        return ajax({
          url,
          data: {
            phrase: query,
            app_id: this.get('controlsAPI.app.id'),
            conversation_id: this.get('controlsAPI.conversation.id'),
          },
        });
      })
      .catch(() => {
        return { articles: [] };
      });
  },

  searchTask: task(function* (query) {
    this.set('query', query);
    queryCache.query = query;
    let response = yield this.search(query);
    this.trackEducateEvent({
      action: 'searched',
      object: 'article_inserter',
      place: 'inbox',
      context: 'article_inserter',
    });
    this.setFilteredContent(response);
  }).drop(),

  setFilteredContent(response) {
    let records = response.articles;

    if (this.hasNoQuery && records.length === 0) {
      this.set('hasArticles', false);
    }
    this.set('filteredContent', records);
    queryCache.filteredContent = records;
  },

  runSearchTask(query) {
    this.searchTask.perform(query);
  },

  fetchFullArticle: task(function* (article, helpCenterId) {
    let previewArticle = article;
    if (!article.fullyLoaded) {
      previewArticle = yield this.store.findRecord('article-multilingual', article.id, {
        reload: true,
      });
    }
    this.trackEducateEvent({
      action: 'clicked',
      object: 'article',
      place: 'inbox',
      context: 'article_inserter',
      article_id: previewArticle.id,
      locale_id: previewArticle.selectedLocaleId,
    });
    this.set('previewArticle', previewArticle);
    this.set('chosenHelpCenterId', helpCenterId);
    this.set('restrictedForUser', !article.visible_to_user);
  }).drop(),

  openArticlesProUpgradeModal: action(function () {
    this.paywallService
      .open({ planId: ARTICLES_PRO_ID, place: this.articlesProPromoKey })
      .catch(this.paywallService.handleError);
  }),

  actions: {
    insertArticleIntoComposer(article) {
      this.get('controlsAPI.actions.insertBlock')('link', article.get('articleCard'));
      this.get('inserterAPI.actions.close')();
    },
    startArticlesTrial() {
      this.paywallService
        .open({ productIds: [ARTICLES_ID], place: 'inbox_inserter' })
        .catch(this.paywallService.handleError);
    },
    updateResults(query) {
      if (query !== this.query) {
        this.setProperties({
          displayQuery: query,
          isLoading: true,
        });
        debounce(this, this.runSearchTask, query, 500);
      }
    },
    goToArticleList() {
      this.trackEducateEvent({
        action: 'clicked',
        object: 'articles',
        place: 'inbox',
        context: 'article_inserter',
      });
      this.router.transitionTo('apps.app.articles.articles');
    },
    goToAppStore() {
      this.router.transitionTo('apps.app.appstore', {
        queryParams: { app_package_code: 'article-inserter' },
      });
    },
    close() {
      this.get('inserterAPI.actions.close')();
    },
    switchLocale(locale) {
      this.trackEducateEvent({
        action: 'clicked',
        object: 'locale_picker',
        place: 'inbox',
        context: 'article_inserter',
        article_id: this.previewArticle.id,
        locale_id: locale,
      });
      this.set('selectedLocaleId', locale);
    },
  },
});
