/* import __COLOCATED_TEMPLATE__ from './learn-about-fin-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Arguments {}

export default class LearnAboutFinDropdown extends Component<Arguments> {
  @service declare appService: any;
  @service declare intl: IntlService;

  get label() {
    return this.intl.t('operator.fin.learn-about-fin-dropdown.title');
  }

  get learnDropdownList() {
    return [
      {
        items: [
          {
            component: 'content-editor/headers/fin/learn-about-fin-dropdown-item',
            componentShouldReplaceItem: true,
          },
        ],
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Headers::Fin::LearnAboutFinDropdown': typeof LearnAboutFinDropdown;
  }
}
