/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import StandardAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-editor-configuration';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';

export default class Summary extends Component {
  @service contentEditorService;

  get showClientPredicates() {
    return this.contentEditorService.ruleset.clientPredicateGroup.hasPredicates;
  }

  get audienceEditorConfiguration() {
    let container = getOwner(this);

    return new StandardAudienceEditorConfiguration({
      showClientPredicates: this.showClientPredicates,
      showAudiencePreview: false,
      showMatchBehavior: false,
      hideAddFilterGroupButton: true,
      hideAddRule: true,
      isDisabled: true,
      container,
    });
  }
}
