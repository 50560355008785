/* import __COLOCATED_TEMPLATE__ from './summary-body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  outboundWebhookHttpMethodLabels,
  outboundWebhookContentTypeLabels,
} from 'embercom/models/data/outbound-webhook/constants';

export default class WebhookSummaryComponent extends Component {
  @service declare contentEditorService: any;

  get webhook() {
    return this.contentEditorService.activeObject;
  }

  get httpMethod() {
    return outboundWebhookHttpMethodLabels[this.webhook.httpMethod];
  }

  get url() {
    return this.webhook.url;
  }

  get contentType() {
    return outboundWebhookContentTypeLabels[this.webhook.contentType];
  }

  get headers() {
    return this.webhook.headers;
  }

  get body() {
    return this.webhook.body;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Summaries::Content::Webhook::SummaryBody': typeof WebhookSummaryComponent;
    'content-editor/summaries/content/webhook/summary-body': typeof WebhookSummaryComponent;
  }
}
