/* import __COLOCATED_TEMPLATE__ from './multi-select.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { getAppPartnerOptionsData } from 'embercom/lib/developer-hub/app-partner-options';

export default class MultiSelect extends Component {
  area = this.args.area;
  availableOptions = this.args.availableOptions;
  existingOptions = this.args.appPartner.get(this.area);
  selectedOptions = this.setSelectedOptions(this.area, this.existingOptions);

  setSelectedOptions(area, existingOptions) {
    let selectedOptions = [];
    if (existingOptions && existingOptions.length) {
      existingOptions.forEach((option) => {
        selectedOptions.push(getAppPartnerOptionsData(option, area));
      });
    }
    return selectedOptions;
  }

  @action
  updateSelectedOptions(newSelection, value, operation) {
    let newSelectedOptions = [];
    newSelection.forEach((element) => {
      newSelectedOptions.push(element.value);
    });
    this.args.appPartner.set(this.area, newSelectedOptions);
  }
}
