/* import __COLOCATED_TEMPLATE__ from './draft-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { isEmpty } from '@ember/utils';
import { throttle, later, cancel } from '@ember/runloop';
import { computed } from '@ember/object';
import { alias, notEmpty, not, and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { fmtStyle, ternary } from '@intercom/pulse/lib/computed-properties';
import InboundActions from 'ember-component-inbound-actions/inbound-actions';
import ENV from 'embercom/config/environment';

export default Component.extend(InboundActions, {
  tagName: 'span',
  classNames: ['a__slow-fade-in'],
  'data-test-draft-component': true,
  attributeBindings: ['style', 'data-test-draft-component'],
  conversationsService: service(),
  updateTask: alias('controlsAPI.conversation.updateDraft'),
  hasStatusMessage: notEmpty('statusMessage'),
  hasChanged: false,
  hasNotChanged: not('hasChanged'),
  hasStatusAndHasNotChanged: and('hasStatusMessage', 'hasNotChanged'),
  timers: {},

  styleOpacity: ternary('hasStatusAndHasNotChanged', 1, 0),
  style: fmtStyle('opacity: %@', 'styleOpacity'),

  willDestroyElement() {
    this.send('cancelTimers');
    this._super(...arguments);
  },

  statusMessage: computed(
    'controlsAPI.composerHasContent',
    'controlsAPI.isUploading',
    'updateTask.isRunning',
    'updateTask.error',
    'updateTask.last.isFinished',
    function () {
      if (this.get('controlsAPI.isUploading')) {
        return 'Uploading…';
      }
      if (!this.get('controlsAPI.composerHasContent')) {
        return '';
      }
      if (this.get('updateTask.isRunning')) {
        return 'Saving…';
      }
      if (this.get('updateTask.last.error')) {
        return 'Failed!';
      }
      if (this.get('updateTask.last.isFinished')) {
        return 'Saved';
      }
    },
  ),

  updateDraft() {
    this.markAsChanged();
    if (this.get('controlsAPI.composerHasContent')) {
      this.timers.throttle = throttle(
        this,
        this.saveComposerAsDraft,
        ENV.APP._5000MS,
        false, // Execute on the trailing edge
      );
    }
    if (this._shouldDeleteDraft) {
      this.conversationsService.deleteDraft(this.get('controlsAPI.conversation'));
    }
  },

  saveComposerAsDraft() {
    let blocks = this.get('controlsAPI.composerComponent').getBlocks();
    if (isEmpty(blocks)) {
      return;
    }
    let actions = this.get('controlsAPI.composerComponent').macro?.actionsPayload || [];

    this.conversationsService.updateDraft(
      this.get('controlsAPI.conversation'),
      this.get('controlsAPI.activePaneIdentifier'),
      blocks,
      actions,
    );
    this.clearChangedStatus();
  },

  _shouldDeleteDraft: computed(
    'controlsAPI.conversation',
    'controlsAPI.composerComponent',
    'controlsAPI.composerHasContent',
    'controlsAPI.activePaneIdentifier',
    function () {
      let draft = this.conversationsService.getDraft(this.get('controlsAPI.conversation'));
      return (
        this.get('controlsAPI.composerComponent') &&
        !this.get('controlsAPI.composerHasContent') &&
        draft.get('subclass') === this.get('controlsAPI.activePaneIdentifier')
      );
    },
  ),

  markAsChanged() {
    if (!this.timers.markAsChanged && this.hasChanged === false) {
      this.timers.markAsChanged = later(
        this,
        function () {
          this.set('hasChanged', true);
        },
        ENV.APP._2000MS,
      );
    }
  },

  clearChangedStatus() {
    cancel(this.timers.markAsChanged);
    this.timers.markAsChanged = null;
    this.set('hasChanged', false);
  },

  actions: {
    updateDraft() {
      this.updateDraft();
    },

    cancelTimers() {
      cancel(this.timers.throttle);
      cancel(this.timers.markAsChanged);
      this.timers.markAsChanged = null;
    },
  },
});
