/* import __COLOCATED_TEMPLATE__ from './show.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { getHumanReadableTier } from 'embercom/lib/developer-hub/app-partner-options';

export default class Show extends Component {
  @tracked appPartner = this.args.appPackage.appPartner;
  tier = this.args.appPackage.appPartner.tier;

  humanReadableTier = getHumanReadableTier(this.tier);
}
