/* import __COLOCATED_TEMPLATE__ from './api-version.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { copy } from 'ember-copy';
import moment from 'moment-timezone';
import compareVersions from 'embercom/lib/compare-versions';
import { task } from 'ember-concurrency-decorators';

export default class ApiVersion extends Component {
  @service appService;
  @service intercomEventService;
  @service developerHub;
  @service router;
  @service notificationsService;

  @tracked appPackage = this.developerHub.appPackage;
  @tracked appPackageVersion = this.appPackage.apiVersion;
  initialApiVersionId = copy(this.appPackage.apiVersion.get('id'));
  app = this.appService.app;
  isNotEdit = !this.args.isEdit;

  @task
  *isWebhookDefinitionInvalid() {
    let definition = this.appPackage.webhookDefinition;
    if (!definition) {
      return false;
    }
    let { validations } = yield definition.validate();
    return (
      (validations.get('isInvalid') && this.appPackage.webhookDefinition.missingRequiredScopes) ||
      this.appPackage.webhookDefinition.unavaliableTopics(this.appPackage.apiVersion).length !== 0
    );
  }

  @task
  *saveAppPackageTask() {
    try {
      let isInvalidWebhooks = yield this.isWebhookDefinitionInvalid.perform();
      let webhookDefinition = this.appPackage.webhookDefinition;
      if (!isInvalidWebhooks) {
        yield this.appPackage.updateApiVersion();
        if (webhookDefinition) {
          this.appPackage.webhookDefinition = webhookDefinition;
          yield this.appPackage.updateWebhooks();
        }
        this.intercomEventService.trackAnalyticsEvent({
          object: 'app_package',
          app_package_id: this.appPackage.id,
          app_metadata: this.appPackage.changeRequest,
          action: 'saved',
        });
        this.router.transitionTo('apps.app.developer-hub.app-packages.app-package.api-version');
      } else {
        this.notificationsService.notifyError('To save your changes please update webhook topics');
      }
    } catch (e) {
      this.notificationsService.notifyError('There was a problem applying your changes.');
    }
  }

  get selectedApiVersion() {
    return this.apiVersion(this.appPackageVersion.get('id'));
  }

  get allVersions() {
    let allVersions = (this.args.allApiVersions || []).toArray();
    return allVersions.sort((a, b) => compareVersions(b.get('id'), a.get('id')));
  }

  get numberOfNewerApiVersions() {
    if (this.appPackageVersion.get('isUnstable')) {
      return 0; // Unstable versions are always the latest
    }

    return this.allVersions.filter((version) => {
      return (
        !version.get('experimental') &&
        !version.get('isUnstable') &&
        compareVersions(version.get('id'), this.appPackageVersion.get('id')) > 0
      );
    }).length;
  }

  get removedWebhookTopics() {
    let webhookDefinition = this.appPackage.webhookDefinition;
    if (
      this.appPackageVersion.get('id') !== this.initialApiVersionId &&
      Boolean(webhookDefinition)
    ) {
      return webhookDefinition.unavaliableTopics(this.selectedApiVersion);
    }
    return [];
  }

  get webhookSubscriptionContainsRemovedTopics() {
    let webhookDefinition = this.appPackage.webhookDefinition;
    return (
      this.removedWebhookTopics.length !== 0 ||
      (webhookDefinition && webhookDefinition.hasDirtyAttributes)
    );
  }

  get apiVersionsDropdown() {
    return this.allVersions.map((version) => ({
      isSelected: version.get('id') === this.appPackageVersion.get('id'),
      text: this.calculateDropdownItemText(version),
      value: version.get('name'),
    }));
  }

  apiVersion(versionId) {
    return this.allVersions.filterBy('id', versionId)[0];
  }

  calculateDropdownItemText(version) {
    let name = version.name;
    if (version.latest_public === true) {
      return `${name} (latest)`;
    } else if (version.experimental === true) {
      return `${name} (experimental)`;
    } else if (version.isUnstable) {
      return name;
    } else {
      return `${name} (${moment(version.date).format('YYYY-MM-DD')})`;
    }
  }

  @action
  updateApiVersion(selectedValue) {
    this.appPackageVersionId = selectedValue;
    this.developerHub.appPackage.apiVersion = this.apiVersion(selectedValue);
  }

  @action
  cancelEditAppPackage() {
    this.appPackage.rollbackAttributes();
    this.developerHub.appPackage.apiVersion = this.apiVersion(this.initialApiVersionId);
    this.router.transitionTo('apps.app.developer-hub.app-packages.app-package.api-version');
  }
}
