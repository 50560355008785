/* import __COLOCATED_TEMPLATE__ from './content-snippet.hbs'; */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import ContentWrapperComponent, { type ContentWrapperArgs } from './content-wrapper-component';
import { action } from '@ember/object';
import type ContentImportService from 'embercom/services/content-import-service';
import type ContentSnippet from 'embercom/models/content-service/content-snippet';
import { tracked } from '@glimmer/tracking';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import { EntityType } from 'embercom/models/data/entity-types';
import type IntlService from 'ember-intl/services/intl';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';

export default class ContentSnippetAdapter extends ContentWrapperComponent {
  @service declare appService: any;
  @service declare store: Store;
  @service declare contentImportService: ContentImportService;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare permissionsService: any;
  @tracked showContentSnippetEditForm = false;
  @tracked approvalButtonsDisabled = false;
  @tracked initialContentWrappers: Array<ContentWrapper>;
  @tracked contentSnippet?: ContentSnippet;

  reviewedCounter = 0;

  constructor(owner: unknown, args: ContentWrapperArgs) {
    super(owner, args);
    taskFor(this.loadContentSnippet).perform();
    this.initialContentWrappers = this.args.listApi.contentWrappers;
  }

  get id() {
    return this.args.contentId;
  }

  get isLoading() {
    return taskFor(this.loadContentSnippet).isRunning;
  }

  get title() {
    return this.contentSnippet?.titleIsBlank ? this.defaultTitle : this.contentSnippet?.title;
  }

  get defaultTitle(): string {
    return this.intl.t('ai-content-library.list.cells.title.untitled', {
      objectName: '',
    });
  }

  get createdByTeammate(): any | undefined {
    return this.contentSnippet?.createdByTeammate;
  }

  get createdByTeammateIsNull(): boolean {
    return !this.createdByTeammate;
  }

  get inReviewMode() {
    return this.contentSnippet?.isPendingReview;
  }

  get getEditButtonStyle() {
    return this.contentSnippet?.isPendingReview ? ('secondary' as const) : ('primary' as const);
  }

  get selectedContentWrapper(): ContentWrapper | undefined {
    return this.store.peekRecord(
      'content-service/content-wrapper',
      `${ContentWrapperType.AI_CONTENT}_${EntityType.ContentSnippet}_${this.id}`,
    ) as ContentWrapper | undefined;
  }

  get reviewedCount(): number {
    if (!this.selectedContentWrapper) {
      return 0;
    }

    let currentPosition = this.initialContentWrappers.indexOf(this.selectedContentWrapper) + 1;
    return currentPosition + this.reviewedCounter;
  }

  get disablePreviousContentButton(): boolean {
    if (this.selectedContentWrapper) {
      return this.initialContentWrappers.indexOf(this.selectedContentWrapper) === 0;
    }
    return false;
  }

  get disableNextContentButton(): boolean {
    if (this.selectedContentWrapper) {
      let currentPosition = this.initialContentWrappers.indexOf(this.selectedContentWrapper);
      return currentPosition === this.initialContentWrappers.length - 1;
    }
    return false;
  }

  get jsonBlocksHasContent(): boolean {
    return this.contentSnippet?.jsonBlocksHasContent;
  }

  get serializedJsonBlocks(): any | undefined {
    let serializedBlocks = this.contentSnippet?.jsonBlocks.serialize();

    if (!serializedBlocks) {
      return;
    }
    return serializedBlocks;
  }

  @task *loadContentSnippet(): TaskGenerator<void> {
    try {
      this.contentSnippet = yield this.store.findRecord('content-service/content-snippet', this.id);
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('ai-content-library.content-snippet.not-found'),
      );
      this.args.onClose();
    }
  }

  @action async deleteContentSnippet(): Promise<void> {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }

    let response = await this.contentSnippet?.destroyRecord();
    if (response) {
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-content-library.content-snippet.side-drawer.delete-success'),
      );
    } else {
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-content-library.content-snippet.side-drawer.delete-error'),
      );
    }
    this.args.reloadSummary();
    this.args.reloadPage();
    this.args.onClose();
    this.trackAnalyticsEvent('clicked', 'delete_button');
  }

  @action async showEditForm(): Promise<void> {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }

    this.showContentSnippetEditForm = true;
    this.trackAnalyticsEvent('clicked', 'edit_button');
  }

  // called when the model is saved through the edit form
  // the "view" drawer is still shown after this is called
  @action onSave(): void {
    this.args.reloadPage();
    this.args.reloadSummary();
    this.showContentSnippetEditForm = false;
  }

  @action close(): void {
    if (this.reviewedCounter > 0) {
      this.args.reloadPage();
    }
    this.updateAssociatedContentWrapper();
    this.showContentSnippetEditForm = false;
    this.args.onClose();
    this.trackAnalyticsEvent('clicked', 'close_button');
  }

  @action async rejectContentReview() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    await this.updateStatusAndLoadNext(Status.Rejected);
    this.trackAnalyticsEvent('clicked', 'reject_button');
  }

  @action async approveContentReview() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    await this.updateStatusAndLoadNext(Status.Approved);
    this.trackAnalyticsEvent('clicked', 'approve_button');
    this.notificationsService.notifyConfirmation(
      this.intl.t('ai-content-library.content-reviews.notifications.approval-successful'),
    );
  }

  @action showNext(): void {
    this.trackAnalyticsEvent('clicked', 'next_button');
    if (this.selectedContentWrapper) {
      let currentPosition = this.initialContentWrappers.indexOf(this.selectedContentWrapper);
      if (currentPosition < this.initialContentWrappers.length - 1) {
        this.args.loadContentForPosition(currentPosition + 1);
        taskFor(this.loadContentSnippet).perform();
      }
    }
  }

  @action showPrevious(): void {
    this.trackAnalyticsEvent('clicked', 'previous_button');
    if (this.selectedContentWrapper) {
      let currentPosition = this.initialContentWrappers.indexOf(this.selectedContentWrapper);
      if (currentPosition > 0) {
        this.args.loadContentForPosition(currentPosition - 1);
        taskFor(this.loadContentSnippet).perform();
      }
    }
  }

  async updateStatusAndLoadNext(status: Status) {
    let contentReviewRequest = await this.contentSnippet?.contentReviewRequest;

    if (contentReviewRequest) {
      this.args.reloadSummary();
      this.approvalButtonsDisabled = true;
      contentReviewRequest.status = status;
      await contentReviewRequest.save();

      this.showContentSnippetEditForm = false;
      this.approvalButtonsDisabled = false;

      if (this.selectedContentWrapper) {
        if (this.initialContentWrappers.length === 0) {
          this.args.onClose();
          return;
        }

        let currentPosition = this.initialContentWrappers.indexOf(this.selectedContentWrapper);
        this.reviewedCounter += 1;

        if (currentPosition < this.initialContentWrappers.length - 1) {
          this.args.loadNextContent(currentPosition);
          taskFor(this.loadContentSnippet).perform();
        } else if (currentPosition === this.initialContentWrappers.length - 1) {
          if (this.initialContentWrappers.length > 0) {
            this.resetContentList();
          } else {
            this.closeAndReloadPage();
          }
        }
      }
    }
  }

  private resetContentList(): void {
    this.args.loadContentForPosition(0); // load the first content
    taskFor(this.loadContentSnippet).perform();
    this.args.removeLastContent(); // remove last content in order to have correct number of list items

    if (this.initialContentWrappers.length === 0) {
      this.closeAndReloadPage();
    }
  }

  private closeAndReloadPage(): void {
    this.args.reloadPage();
    this.args.onClose();
  }

  private updateAssociatedContentWrapper(): void {
    let associatedListItemFragment = this.store.peekRecord(
      'content-service/content-wrapper',
      `${ContentWrapperType.AI_CONTENT}_${EntityType.ContentSnippet}_${this.id}`,
    ) as ContentWrapper | undefined;

    if (associatedListItemFragment && this.contentSnippet?.title) {
      associatedListItemFragment.title = this.contentSnippet.title;
    }
  }

  private trackAnalyticsEvent(action: string, object: string): void {
    let place = this.contentSnippet?.isPendingReview
      ? 'fin_content_suggestions'
      : 'fin_content_page';

    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'operator',
      context: 'snippet_sidedrawer_viewmode',
      place,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Adapters::ContentSnippet': typeof ContentSnippetAdapter;
  }
}
