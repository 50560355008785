/* import __COLOCATED_TEMPLATE__ from './assignee-sender-item.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import resendSenderEmailVerificationLink from 'embercom/lib/settings/resend-sender-email-verification-link';

export default class AssigneeSenderItem extends Component {
  @action
  resendVerificationLink() {
    let unverifiedCustomAddressId = this.args.componentAttrs.model.id;
    resendSenderEmailVerificationLink(unverifiedCustomAddressId);
  }
}
