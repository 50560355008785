/* import __COLOCATED_TEMPLATE__ from './custom-snooze-times-select-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import IntercomSelectComponent from 'embercom/components/intercom-select-component';

/* Temporary hack that makes sure intercom-select-component is positioned exactly where it's supposed to be and not repositioned
   using its _calculateDropdownPositioning(). This hack will probably not be needed anymore once
   https://github.com/intercom/embercom-core/pull/326#issuecomment-316684311 is merged. */
export default IntercomSelectComponent.extend({
  _calculateDropdownPositioning() {
    // Empty.
  },
});
