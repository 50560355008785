/* import __COLOCATED_TEMPLATE__ from './webhooks.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { get } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { task } from 'ember-concurrency';

export default Component.extend({
  isInEditMode: false,
  webhookDefinition: null,
  topics: null,
  isSetLiveMode: false,
  appService: service(),
  developerHub: service(),
  notificationsService: service(),
  appPackage: readOnly('developerHub.appPackage'),
  isModalOpen: false,
  topicPayload: {},
  topicName: '',
  toggleModal: action(function () {
    this.set('isModalOpen', !this.isModalOpen);
  }),
  getTopicPayload: task(function* (topic) {
    try {
      let params = {
        app_id: this.appService.app.id,
        topic_name: topic.name,
      };
      let response = yield get(
        `/ember/developer_hub/app_packages/${this.appPackage.id}/get_webhook_topic_payload_preview`,
        params,
      );
      this.toggleModal();
      this.set('topicName', topic.name);
      this.set('topicPayload', JSON.stringify(response, null, 2));
    } catch (error) {
      this.notificationsService.notifyError('No test payload preview implemented');
    }
  }),
});
