/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Summary extends Component {
  @service contentEditorService;

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get showAutomaticallyEnabled() {
    return !!this.ruleset.rulesetLinks.firstObject.object.clientData?.showAutomatically;
  }

  get isDisabled() {
    return !this.showAutomaticallyEnabled;
  }
}
