/* import __COLOCATED_TEMPLATE__ from './emoji-popover-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import intermoji from '@intercom/intermoji';

export default class EmojiPopoverContent extends Component {
  @service intercomEventService;

  @action
  didInsert() {
    this.intercomEventService.trackAnalyticsEvent({
      owner: 'inbox',
      action: 'clicked',
      object: 'emoji_inserter',
      place: 'inbox',
      context: 'emoji_inserter',
    });
  }

  @action
  selectEmoji(emojiIdentifier) {
    let unicode = intermoji.unicodeFromIdentifier(emojiIdentifier);
    this.args.controlsAPI.actions.paste(unicode);
    this.args.inserterAPI.actions.close();
  }

  @action
  onKeyDown(e) {
    if (e.key === 'Escape') {
      this.args.inserterAPI.actions.close();
    }
  }
}
