/* import __COLOCATED_TEMPLATE__ from './latest-conversations-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import Conversation from 'embercom/models/conversation';
import DidChangeAttrs from 'ember-did-change-attrs';

export default Component.extend(DidChangeAttrs, {
  attributeBindings: ['data-test-latest-conversations'],
  'data-test-latest-conversations': true,
  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  store: service(),
  conversation: null,

  didInsertElement() {
    this._super(...arguments);
    this.fetchAndSetLatestConversations.perform();
  },

  didChangeAttrsConfig: {
    attrs: ['userId', 'conversation'],
  },

  didChangeAttrs(changes) {
    this._super(...arguments);

    if (changes.userId) {
      this.fetchAndSetLatestConversations.perform();
    }
  },

  fetchAndSetLatestConversations: task(function* () {
    this.set('latestConversations', null);
    let response = yield Conversation.newSearch({
      app_id: this.get('app.id'),
      user_id: this.userId,
      assignee_identifier: this.get('admin.id'),
      ignorable_conversation_id: this.ignorableConversationId,
      scope: 'latest_conversations',
    });

    let responseConversations = response.conversations.map(({ conversation }) => conversation);
    this.store.pushPayload({ conversations: responseConversations });

    this.set(
      'latestConversations',
      responseConversations.map(({ id }) => this.store.peekRecord('conversation', id)),
    );
  }).restartable(),
});
