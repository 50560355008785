/* import __COLOCATED_TEMPLATE__ from './cti-app-container.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type CtiService from 'embercom/services/cti-service';
import { inject as service } from '@ember/service';

interface Args {
  appId: number;
}

// add mapping between the workspace id and amazon connect URL here
const amazonConnectMapping: Record<string, string> = {
  tx2p130c: 'team-channels-dev.my.connect.aws',
  hc51dwwz: 'team-channels-dev.my.connect.aws',
};

export default class CtiAppContainer extends Component<Args> {
  @service declare ctiService: CtiService;

  get isHidden() {
    return !this.ctiService.appContainerVisible;
  }

  @action
  initialize() {
    this.ctiService.initialize();
  }

  get amazonConnectUrl() {
    if (this.ctiService.app) {
      return amazonConnectMapping[this.ctiService.app.id];
    }

    return null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cti::CtiAppContainer': typeof CtiAppContainer;
    'cti/cti-app-container': typeof CtiAppContainer;
  }
}
