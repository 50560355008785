/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';

export default class Header extends Component {
  @service developerHub;
  @service notificationsService;
  @service router;
  @tracked appPartner = this.args.appPackage.appPartner;

  get isOnEdit() {
    return this.developerHub.isOnEditPage;
  }

  @task *isAppPartnerInvalid() {
    let { validations } = yield this.appPartner.validate();
    return validations.get('isInvalid');
  }

  @task *save() {
    try {
      let isInvalid = yield this.isAppPartnerInvalid.perform();
      if (isInvalid) {
        return;
      }
      yield this.args.appPackage.updateAppPartner();
      this.router.transitionTo('apps.app.developer-hub.app-packages.app-package.app-partner');
    } catch (e) {
      this.notificationsService.notifyError(
        'An error occurred while saving your changes. Please try again.',
      );
    }
  }

  @action cancelEdit() {
    this.args.appPackage.rollbackAttributes();
    this.router.transitionTo('apps.app.developer-hub.app-packages.app-package.app-partner');
  }
}
