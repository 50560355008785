/* import __COLOCATED_TEMPLATE__ from './admin-action-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-on-calls-in-components */
import { computed } from '@ember/object';
import { later } from '@ember/runloop';
import { on } from '@ember/object/evented';
import ConversationPartComponentBase from 'embercom/components/conversation/stream/conversation-part-component-base';
import ENV from 'embercom/config/environment';
import displayAdminAs from 'embercom/lib/inbox/display-admin-as';

let AdminActionComponent = ConversationPartComponentBase.extend({
  // Set shouldFadeInAction to false after the animation has run - prevents the
  // animation from running again when you go back into the conversation
  resetShouldFadeInAction: on('didInsertElement', function () {
    if (this.get('part.shouldFadeInAction')) {
      later(this, this._resetShouldFadeInAction, ENV.APP._400MS);
    }
  }),

  _resetShouldFadeInAction() {
    let part = this.part;
    if (part) {
      if (!(part.isDestroyed || part.isDestroying)) {
        part.set('shouldFadeInAction', false);
      }
    }
  },

  isActionTargetNameLowercase: computed('admin', 'part.assignee', function () {
    if (this.get('part.hasAssignee')) {
      return this.admin === this.get('part.assignee');
    }
    return false;
  }),

  authorName: computed('part.admin', 'admin', function () {
    if (this.get('part.admin.is_unknown')) {
      return displayAdminAs(this.admin, this.get('part.admin'), false, false, false);
    }

    return displayAdminAs(this.admin, this.get('part.admin'), false, false, true);
  }),
});

export default AdminActionComponent;
