/* import __COLOCATED_TEMPLATE__ from './card-list-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import $ from 'jquery';
import { Promise as EmberPromise } from 'rsvp';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { task, taskGroup } from 'ember-concurrency';
import { addEventListener } from 'ember-lifeline';
import Velocity from 'velocity';

const CARD_BUFFER = 200;
const CARD_HEADER_HEIGHT = 88;
const DEFAULT_CARD_HEIGHT = 412;
const MAX_CARD_HEIGHT = 560;
const MIN_CARD_HEIGHT = 450;
const SCROLL_OFFSET = -50;

export default Component.extend({
  scrollOffset: SCROLL_OFFSET,
  expandAndScrollGroup: taskGroup().enqueue(),
  showsConversationsAndMessages: false,
  expandConversationId: null,
  expandedCardId: null,

  // A bug in velocity means that some conversation cards should not be auto-scrolled
  // Right now that is ones in insights and in educate responses. https://github.com/julianshapiro/velocity/pull/564
  enableAutoScroll: false,

  collapsedHeight: 62,

  didInsertElement() {
    this._super(...arguments);
    Velocity(document.getElementById('js-velocity-dummy'), { opacity: 0.5 }, { duration: 1000 });
    this.setViewportHeight();
    addEventListener(this, window, 'resize', () => this.setViewportHeight());
    addEventListener(this, this.getViewportElement(), 'scroll', () => {
      if (this.get('expandCardTask.isRunning')) {
        this.scrollToCardTask.cancelAll();
      }
    });

    if (this.expandConversationId) {
      this.actions.expand.bind(this)(String(this.expandConversationId));
    }
  },

  setViewportHeight() {
    let viewportHeight = DEFAULT_CARD_HEIGHT + CARD_HEADER_HEIGHT + CARD_BUFFER;
    let viewport = this.getViewportElement();
    if (viewport) {
      viewportHeight = viewport.getBoundingClientRect().height;
    }
    this.set('viewportHeight', viewportHeight);
  },

  cardContentHeight: computed('viewportHeight', function () {
    let idealCardHeight = this.viewportHeight - CARD_BUFFER;
    let cardHeight = Math.min(MAX_CARD_HEIGHT, Math.max(MIN_CARD_HEIGHT, idealCardHeight));
    return cardHeight;
  }),

  getViewportElement() {
    return document.querySelector(this.viewportSelector);
  },

  getCardElement(conversationId) {
    return $(`[data-conversation-id='${conversationId}']`, this.element);
  },

  getExpandingArea(conversationId) {
    return $(
      `[data-conversation-id='${conversationId}'] .conversation__card__content-expanded`,
      this.element,
    );
  },

  expandCard(conversationId) {
    return new EmberPromise((resolve, reject) => {
      let $contentElement = this.getExpandingArea(conversationId);
      let cardContentHeight = this.cardContentHeight;
      $contentElement.velocity(
        {
          height: cardContentHeight,
        },
        {
          complete: resolve,
        },
      );
    });
  },

  scrollToCard(conversationId) {
    return new EmberPromise((resolve, reject) => {
      let viewportElement = this.getViewportElement();
      let $card = this.getCardElement(conversationId);
      $card.velocity('scroll', {
        offset: this.scrollOffset,
        container: viewportElement,
        complete: resolve,
      });
    });
  },

  expandCardTask: task(function* (conversationId) {
    try {
      yield this.expandCard(conversationId);
    } finally {
      let $contentElement = this.getExpandingArea(conversationId);
      $contentElement.velocity('finish');
    }
  }).group('expandAndScrollGroup'),

  scrollToCardTask: task(function* (conversationId) {
    try {
      yield this.scrollToCard(conversationId);
    } finally {
      let $card = $(this.getCardElement(conversationId));
      $card.velocity('stop');
    }
  }).group('expandAndScrollGroup'),

  cancelRunningTasks() {
    this.expandAndScrollGroup.cancelAll();
    this.expandCardTask.cancelAll();
    this.scrollToCardTask.cancelAll();
  },

  actions: {
    expand(conversationId) {
      this.cancelRunningTasks();
      let expandedCardId = this.expandedCardId;
      if (expandedCardId) {
        let expandedElement = this.getExpandingArea(expandedCardId);
        expandedElement.velocity({ height: this.collapsedHeight });
      }
      this.set('expandedCardId', conversationId);
      this.expandCardTask.perform(conversationId);
      if (this.enableAutoScroll) {
        this.scrollToCardTask.perform(conversationId);
      }
    },

    collapse() {
      this.cancelRunningTasks();
      let expandedCardId = this.expandedCardId;
      if (expandedCardId) {
        let expandedElement = this.getExpandingArea(expandedCardId);
        expandedElement.velocity({ height: this.collapsedHeight });
      }
      this.set('expandedCardId', null);
    },

    remove(conversationId) {
      this.cancelRunningTasks();
      let conversation = this.conversations.findBy('id', conversationId);
      this.conversations.removeObject(conversation);
      if (this.hideRepeatedDays) {
        this.hideRepeatedDays();
      }
    },
  },
});
