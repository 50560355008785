/* import __COLOCATED_TEMPLATE__ from './picker-opener.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { union, map } from '@ember/object/computed';
import { A } from '@ember/array';
import intermoji from '@intercom/intermoji';
import EmojiFavourites from 'embercom/components/mixins/emoji-favourites';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default Component.extend(EmojiFavourites, {
  tagName: '',

  emojiSize: 16,
  emojiImageClass: 'reaction-picker-popover__image',

  reactionIndex: undefined,

  defaultEmoji: A(['thumbs_up', '-1', 'sob', 'confused', 'neutral_face', 'blush', 'heart_eyes']),
  favouriteEmojiStorageKey: 'favouriteReactions',
  defaultAndFavouriteEmojiIdentifiers: union('favouriteEmoji', 'defaultEmoji'),
  defaultAndFavouriteEmoji: map('defaultAndFavouriteEmojiIdentifiers', function (identifier) {
    return this._generateEmoji(identifier, intermoji.unicodeFromIdentifier(identifier));
  }),

  groups: computed(function () {
    let representatives = intermoji.getGroupRepresentatives();
    return intermoji.prettyEmoticonsUnicodeGroups().map((unicodeEmoji, index) => ({
      name: representatives[index][1],
      emojis: unicodeEmoji.reduce((acc, unicodeEmoticon) => {
        if (intermoji.isEmojiBaseUnicode(unicodeEmoticon)) {
          return [
            ...acc,
            this._generateEmoji(intermoji.identifierFromUnicode(unicodeEmoticon), unicodeEmoticon),
          ];
        } else {
          return acc;
        }
      }, []),
    }));
  }),

  _generateEmoji(identifier, unicodeEmoticon) {
    return {
      display: this._getSafeDisplayableEmoji(unicodeEmoticon),
      identifier,
      title: `:${identifier}:`,
    };
  },

  _getSafeDisplayableEmoji(unicodeEmoticon) {
    let emoji = this.hasNativeSupport
      ? unicodeEmoticon
      : intermoji.fallbackImage(this.emojiSize, unicodeEmoticon, this.emojiImageClass);
    return sanitizeHtml(emoji);
  },

  hasNativeSupport: computed(function () {
    return intermoji.hasNativeSupport(document);
  }),

  selectEmoji: action(function (emojiIdentifier) {
    let updatedReaction = {
      index: this.reactionIndex,
      unicodeEmoticon: intermoji.unicodeFromIdentifier(emojiIdentifier),
    };
    this.updateReaction(updatedReaction);
    this.recordEmojiSelection(emojiIdentifier);
  }),
});
