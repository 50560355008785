/* import __COLOCATED_TEMPLATE__ from './bubble-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { and, readOnly, not, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  classNames: ['conversation__bubble'],
  attributeBindings: ['data-test-conversation-bubble'],
  'data-test-conversation-bubble': true,
  classNameBindings: [
    'displaysAsUserComment:o__user-comment',
    'isOperatorComment:o__operator-comment',
    'isAdminComment:o__admin-comment',
    'isNotPersisted:o__not-persisted',
    'isRedacted:o__redacted',
    'isHighlighted:o__highlighted',
  ],

  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),

  part: readOnly('bubbleGroup.part'),
  isAdminComment: readOnly('part.displaysAsAdminComment'),
  isOperatorComment: readOnly('part.isOperatorCommentPart'),
  isHighlighted: readOnly('bubbleGroup.isHighlighted'),
  isPersisted: readOnly('part.isPersisted'),
  isNotPersisted: not('isPersisted'),
  isRedacted: readOnly('part.isRedacted'),
  partDoesNotHaveMessengerCards: not('part.hasMessengerCards'),
  shouldDisplayTagging: and('isLastNonMessengerCardOnlyBubbleGroup', 'part.hasNonEmptyTaggings'),
  taggingsToDisplay: reads('part.nonEmptyTaggings'),
  shouldDisplayUpload: and('isLastNonMessengerCardOnlyBubbleGroup', 'part.hasUploads'),
  shouldDisplaySubject: and('partDoesNotHaveMessengerCards', 'part.hasSubject'),
  subject: computed('shouldDisplaySubject', 'part.subject', function () {
    return this.shouldDisplaySubject ? this.part.subject : '';
  }),
});
