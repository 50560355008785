/* import __COLOCATED_TEMPLATE__ from './app-package-changes.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly, not, equal, and, or } from '@ember/object/computed';

export default Component.extend({
  appService: service(),
  developerHub: service(),
  app: readOnly('appService.app'),
  approved: equal('appPackage.changeRequest.state', 'approved'),
  closed: equal('appPackage.changeRequest.state', 'closed'),
  inTerminalState: or('closed', 'approved'),
  notInTerminalState: not('inTerminalState'),
  shouldShowTabs: and('hasChanges', 'notInTerminalState'),
  showPending: true,
});
