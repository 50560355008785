/* import __COLOCATED_TEMPLATE__ from './create-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import type ContentSnippet from 'embercom/models/content-service/content-snippet';

interface Args {
  isOpen: boolean;
  isLoading?: boolean;
  contentSnippet: ContentSnippet;
  onSave: () => void;
  onClose: () => void;
}

export default class CreateSideDrawer extends Component<Args> {
  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::ContentSnippets::CreateSideDrawer': typeof CreateSideDrawer;
  }
}
