/* import __COLOCATED_TEMPLATE__ from './change-state.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { put } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  selectedWrappers: Array<
    ContentWrapper & { relations: any & { content_segment_ids: Array<number> } }
  >;
  unselectedWrappers: Array<
    ContentWrapper & { relations: any & { content_segment_ids: Array<number> } }
  >;
  isBulkSelection: boolean;
  listApi: AiContentLibraryApi;
  isDisabled: boolean;
  reloadPageAndSummary: () => void;
}

export default class ChangeState extends Component<Args> {
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare permissionsService: $TSFixMe;
  @tracked selectedStateValue = '';

  get contentToUpdate() {
    return this.args.selectedWrappers.map((wrapper) => {
      let object = wrapper.contents.firstObject!;
      return {
        entity_id: object.contentId,
        entity_type: object.contentType,
      };
    });
  }

  get unselectedTuples() {
    return this.args.unselectedWrappers.map((wrapper) => {
      let object = wrapper.contents.firstObject!;
      return {
        entity_id: object.contentId,
        entity_type: object.contentType,
      };
    });
  }

  get isLoading() {
    return taskFor(this.changeStateValue).isRunning;
  }

  @action async changeState(selectedValue: string) {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }

    this.selectedStateValue = selectedValue;
    await taskFor(this.changeStateValue).perform();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: selectedValue,
      section: 'operator',
      context: 'change_state_button',
      place: 'fin_content_page',
    });
    this.args.reloadPageAndSummary();
  }

  @restartableTask private *changeStateValue() {
    yield put(`/ember/content_service/contents/${this.selectedStateValue}`, {
      app_id: this.appService.app.id,
      contents_to_update: this.args.isBulkSelection ? null : this.contentToUpdate,
      unselected_tuples: this.args.isBulkSelection ? this.unselectedTuples : null,
      query: this.args.isBulkSelection
        ? this.args.listApi.searchParams || this.args.listApi.defaultSearchParams
        : null,
      is_bulk_selection: this.args.isBulkSelection,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::BulkActions::ChangeState': typeof ChangeState;
  }
}
