/* import __COLOCATED_TEMPLATE__ from './admin-duplicate-conversation-note-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import ConversationPartComponentBase from 'embercom/components/conversation/stream/conversation-part-component-base';
import { task } from 'ember-concurrency';
import { allSettled } from 'rsvp';
import { readOnly } from '@ember/object/computed';

export default ConversationPartComponentBase.extend({
  didInsertElement() {
    this._super(...arguments);
    this.loadConversations.perform();
  },

  conversations: readOnly('loadConversations.lastSuccessful.value'),

  loadConversations: task(function* () {
    let ids = this.part.event_data.conversations || [];
    let promises = ids.map(
      (id) =>
        this.store.peekRecord('conversation', id) ||
        this.store.findRecord('conversation', id, { backgroundReload: false }),
    );

    let records = yield allSettled(promises);

    return records.map((result) => (result.state === 'fulfilled' ? result.value : null));
  }),
});
