/* import __COLOCATED_TEMPLATE__ from './app.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';

export default class ContentEditorAppInserter extends Component {
  @service intercomEventService;
  @service appstoreService;

  get contextualData() {
    return {
      object_type: this.args.objectType,
      object_id: this.args.objectId,
      location: 'message',
    };
  }

  get objectName() {
    return objectNames[this.args.objectType];
  }

  @action
  dismissNotificationAndTrackEvent() {
    this.appstoreService.dismissAppInstallNotifications.perform('messages');
    let analyticsEvent = {
      action: 'opened',
      object: 'messenger_app_inserter',
      object_type: this.objectName,
    };
    analyticsEvent[`${underscore(this.objectName)}_id`] = this.args.objectId;
    this.intercomEventService.trackAnalyticsEvent(analyticsEvent);
  }

  @action
  insertMessengerCard(cardJSON) {
    this.args.inserterApi.createBlock('messengerCard', cardJSON);
  }
}
