/* import __COLOCATED_TEMPLATE__ from './standard-header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class StandardHeader extends Component {
  @service contentEditorService;

  @action
  async removeTag(tag) {
    await this.contentEditorService.ruleset.updateTaggingsTask.perform([], [tag]);
    this.contentEditorService.ruleset.reload();
  }
}
