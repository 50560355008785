/* import __COLOCATED_TEMPLATE__ from './add-source-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type ContentImportService from 'embercom/services/content-import-service';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { TrackedArray } from 'tracked-built-ins';
import { post } from 'embercom/lib/ajax';
import {
  isValidUrlWithProtocol,
  urlDomainsMatch,
  urlWithHttpPrefix,
} from 'embercom/lib/url-validator';
import { EntityType } from 'embercom/models/data/entity-types';
import { TriggerSources } from 'embercom/objects/content-service/content-imports/constants/trigger-sources';
import { action } from '@ember/object';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import type IntlService from 'embercom/services/intl';
import { isValidCssSelector } from 'embercom/lib/knowledge-hub/constants';
import type { addModalUpdateParams } from './advanced-settings-websync';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import type SyncSourceWrapper from 'embercom/models/knowledge-hub/sync-source-wrapper';

interface Args {
  modal: any;
  onModalClose: () => void;
  onNavigationBack?: () => void;
  showBackButton?: boolean;
  isNewSource?: boolean;
  source?: ContentImportSource | undefined | null;
  syncSource?: SyncSourceWrapper | undefined | null;
}

export default class AddSourceModal extends Component<Args> {
  @service declare contentImportService: ContentImportService;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare intl: IntlService;

  @tracked isNewSource: boolean = this.args.isNewSource ?? true;
  @tracked source = this.args.source || undefined;
  @tracked syncSource = this.args.syncSource || undefined;

  @tracked sourceUrl = this.source?.url || '';
  @tracked additionalUrls = this.source?.additionalUrls?.toString() || '';
  @tracked submissionErrorTranslationKey: string | undefined = undefined;
  @tracked openSectionId = '';
  @tracked excludedUrls = this.source
    ? new TrackedArray<string>([...this.source.excludeUrlGlobs])
    : new TrackedArray<string>([]);
  @tracked removeCssSelectors = this.source?.removeCssSelectors?.toString() || '';
  @tracked clickCssSelector = this.source?.clickCssSelector?.toString() || '';
  @tracked useSitemap = this.source?.useSitemap ?? true;
  @tracked waitForSelector = this.source?.waitForSelector?.toString() || '';

  get isUrlInvalid(): boolean {
    return !isValidUrlWithProtocol(this.sourceUrl);
  }

  get isAddingNewSource(): boolean {
    return (
      taskFor(this.addNewSource).isRunning ||
      taskFor(this.saveSource).isRunning ||
      taskFor(this.saveAndResyncSource).isRunning
    );
  }

  get isAdditionalUrlsInvalid(): boolean {
    if (this.sourceUrl === '') {
      return true;
    }

    if (this.additionalUrls !== '') {
      for (let additionalUrl of this.additionalUrls.split(',')) {
        additionalUrl = additionalUrl.trim();
        if (additionalUrl !== '') {
          // Ignore empty strings caused by trailing or stray commas
          if (
            !isValidUrlWithProtocol(additionalUrl) ||
            !isValidUrlWithProtocol(this.sourceUrl) ||
            !urlDomainsMatch(urlWithHttpPrefix(this.sourceUrl), urlWithHttpPrefix(additionalUrl))
          ) {
            return true;
          }
        }
      }
    }

    return false;
  }

  get additionalUrlsArray(): string[] {
    return this.additionalUrls
      .split(',')
      .map((url) => url.trim())
      .filter((url) => url !== '')
      .uniq();
  }

  get removeCssSelectorsArray(): string[] {
    return this.removeCssSelectors
      .split(',')
      .map((selector) => selector.trim())
      .filter((selector) => selector !== '')
      .uniq();
  }

  get excludedUrlGlobs(): string[] {
    return Array.from(this.excludedUrls).filter((glob) => glob !== '');
  }

  get isRemoveCssSelectorInvalid(): boolean {
    return this.removeCssSelectors !== '' && !isValidCssSelector(this.removeCssSelectors);
  }

  get isClickCssSelectorInvalid(): boolean {
    return this.clickCssSelector !== '' && !isValidCssSelector(this.clickCssSelector);
  }

  get isWaitForSelectorInvalid(): boolean {
    return this.waitForSelector !== '' && !isValidCssSelector(this.waitForSelector);
  }

  get formIsInvalid(): boolean {
    return (
      this.isUrlInvalid ||
      this.isAdditionalUrlsInvalid ||
      this.isRemoveCssSelectorInvalid ||
      this.isWaitForSelectorInvalid ||
      this.isClickCssSelectorInvalid
    );
  }

  get learnMoreUrl() {
    return 'https://www.intercom.com/help/en/articles/9357945-syncing-and-managing-public-urls';
  }

  private getSourceParams(): any {
    return {
      app_id: this.appService.app.id,
      url: this.sourceUrl,
      additional_urls: this.additionalUrlsArray,
      trigger_source: TriggerSources.AdminInitiated,
      content_type: EntityType.ExternalContent,
      admin_id: this.appService.app.currentAdmin.id,
      exclude_url_globs: this.excludedUrlGlobs,
      remove_element_css_selectors: this.removeCssSelectorsArray || null,
      click_element_css_selector: this.clickCssSelector || null,
      use_sitemap: this.useSitemap,
      wait_for_selector: this.waitForSelector || null,
    };
  }

  @task
  *addNewSource(): TaskGenerator<void> {
    this.trackAnalyticsEvent('clicked', 'import_source_button');

    if (!this.isUrlInvalid && !this.isAdditionalUrlsInvalid) {
      try {
        this.submissionErrorTranslationKey = undefined;
        yield post(`/ember/content_service/content_import_runs`, this.getSourceParams());
        this.notificationsService.notifyConfirmation(
          this.intl.t('knowledge-hub.sync-modal.source.sync-started'),
        );
        this.contentImportService.subscribeToContentImportRunStatusUpdates();
        yield this.contentImportService.fetchContentImportSources();
        this.sourceUrl = '';
        this.additionalUrls = '';
        this.removeCssSelectors = '';
        this.clickCssSelector = '';
        this.waitForSelector = '';
        this.additionalUrlsArray.clear();
        this.excludedUrls = new TrackedArray<string>([]);
        this.useSitemap = true;
        this.args.onModalClose();

        if (this.guideLibraryService.canUseGuideLibraryService) {
          this.guideLibraryService.subscribeToContentImportUpdates();
        }
      } catch (e) {
        this.submissionErrorTranslationKey =
          this.contentImportService.submissionErrorTranslationKey(e);
      }
    }
  }

  @task
  *saveSource(): TaskGenerator<void> {
    this.trackAnalyticsEvent('clicked', 'save_button');

    if (!this.isUrlInvalid && !this.isAdditionalUrlsInvalid && this.syncSource) {
      try {
        yield this.syncSource.update(this.getSourceParams());
        this.contentImportService.subscribeToContentImportRunStatusUpdates();
        yield this.contentImportService.fetchContentImportSources();
        this.args.onModalClose();
      } catch (e) {
        this.submissionErrorTranslationKey =
          this.contentImportService.submissionErrorTranslationKey(e);
      }
    }
  }

  @task
  *saveAndResyncSource(): TaskGenerator<void> {
    this.trackAnalyticsEvent('clicked', 'save_and_resync_button');

    if (!this.isUrlInvalid && !this.isAdditionalUrlsInvalid && this.syncSource) {
      try {
        yield this.syncSource.update(this.getSourceParams());
        taskFor(this.syncSource.resync).perform();

        this.contentImportService.subscribeToContentImportRunStatusUpdates();
        yield this.contentImportService.fetchContentImportSources();
        this.args.onModalClose();
      } catch (e) {
        this.submissionErrorTranslationKey =
          this.contentImportService.submissionErrorTranslationKey(e);
      }
    }
  }

  @action onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action trackInputAnalytics() {
    this.trackAnalyticsEvent('inputed', 'url_source_input');
  }

  @action trackAdditionalUrlsAnalytics() {
    this.trackAnalyticsEvent('inputed', 'additional_urls_input');
  }

  @action
  updateValues(params: addModalUpdateParams) {
    this.additionalUrls = params.additionalUrls;
    this.removeCssSelectors = params.removeCssSelectors;
    this.clickCssSelector = params.clickCssSelector;
    this.waitForSelector = params.waitForSelector;
    this.useSitemap = params.useSitemap;
  }

  @action
  addExcludedUrl() {
    this.excludedUrls.push('');
    this.trackAnalyticsEvent('clicked', 'add_url_glob_button');
  }

  @action
  updateExcludedUrl(index: number, event: InputEvent) {
    let glob = (event.target as HTMLInputElement).value;
    this.excludedUrls[index] = glob;
    this.trackAnalyticsEvent('inputed', 'url_glob_input');
  }

  @action
  deleteExcludedUrl(index: number) {
    this.excludedUrls.splice(index, 1);
    this.trackAnalyticsEvent('clicked', 'delete_url_glob_button');
  }

  @action
  toggleSitemap() {
    this.trackAnalyticsEvent('clicked', 'use_sitemap_switch');
  }

  private trackAnalyticsEvent(action: string, object: string): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      context: 'side_drawer',
      place: 'external_content',
      section: 'operator',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Sources::AddSourceModal': typeof AddSourceModal;
  }
}
