/* import __COLOCATED_TEMPLATE__ from './version-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';

export default class VersioningSidebar extends Component {
  @service contentEditorService;
  @service store;
  @service intercomEventService;
  @tracked rulesetVersions;

  constructor() {
    super(...arguments);
    this.fetchRulesetVersions.perform();
  }

  @task *fetchRulesetVersions() {
    let queryParams = { ruleset_id: this.ruleset.id };
    this.rulesetVersions = yield this.store.query('matching-system/ruleset-version', queryParams);
  }

  get statsViewModeConfig() {
    if (this.args.config.tabComponentConfigs) {
      return this.args.config.tabComponentConfigs.filter(
        (config) => config.view !== 'content' && config.isVisible,
      );
    } else {
      return [];
    }
  }

  get untrackedVersionsPresent() {
    let firstSetLiveAt = Math.min(...this.parentRulesetLinks.map((rl) => rl.wentLiveAt));
    let oldestVersionCreatedAt = Math.min(...this.rulesetVersions.map((r) => r.createdAt));
    let diff = Math.abs(firstSetLiveAt - oldestVersionCreatedAt);
    // since there can always be a race condition between when a version is created vs when
    // it was set live we use threshold of 1 min to determine if there are untracked versions
    // or not.
    let minutes = Math.floor(diff / 1000 / 60);
    return minutes > 1;
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get parentRulesetLinks() {
    return this.contentEditorService.parentRulesetLinks;
  }

  @action selectVersion(rulesetVersion) {
    this.contentEditorService.selectVersion(rulesetVersion);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'version_label',
      context: 'version_sidebar',
      place: 'content_editor',
      ruleset_id: this.contentEditorService.parentRulesetId,
      ruleset_version_id: rulesetVersion.id,
    });
  }
}
