/* import __COLOCATED_TEMPLATE__ from './dependent-content-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import type ContentDependency from 'embercom/models/matching-system/content-dependency';

interface ConfirmContext {
  body: string;
  humanReadableObjectName: string;
  dependencies: ContentDependency[];
}

interface Args {
  confirmContext: ConfirmContext;
}

interface Signature {
  Args: Args;
}

export default class DependentContentList extends Component<Signature> {
  get dependencyWarning() {
    return `You have other content which depends on this ${this.args.confirmContext.humanReadableObjectName}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'content-editor/dependent-content-list': typeof DependentContentList;
    'ContentEditor::DependentContentList': typeof DependentContentList;
  }
}
