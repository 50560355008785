/* import __COLOCATED_TEMPLATE__ from './switcher.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import toSentence from 'embercom/lib/to-sentence';
import { preferredDevices } from 'embercom/models/data/outbound/constants';

export default class Switcher extends Component {
  _allPossibleItems = [
    {
      text: 'Web',
      value: preferredDevices.web,
    },
    {
      text: 'iOS',
      value: preferredDevices.ios,
    },
    {
      text: 'Android',
      value: preferredDevices.android,
    },
  ];

  @service appService;
  @tracked currentSelectionLabel = this._updateCurrentSelectionDescription();

  previouslySelectedValue = this._selected;

  get _app() {
    return this.appService.app;
  }

  get _selected() {
    return this.args.content?.preferredDevices || [0];
  }

  get items() {
    let items = this._allPossibleItems;
    if (this.args.optionsToHide) {
      items = items.filter((item) => !this.args.optionsToHide.includes(item.value));
    }
    items = items.filter(
      (item) =>
        item.value === preferredDevices.web ||
        (item.value === preferredDevices.android && this._app.hasAndroidSdk) ||
        (item.value === preferredDevices.ios && this._app.hasIOSSdk),
    );
    items = items.map((item) => ({
      ...item,
      isSelected: this._selected ? this._selected.includes(item.value) : false,
    }));
    return items;
  }

  get appHasMultiplePlatforms() {
    return this._app.hasAndroidSdk || this._app.hasIOSSdk;
  }
  get isDisabled() {
    if (this.args.isDisabled) {
      return true;
    }

    if (this.items.length === 1 && this._selected?.length === 1) {
      return this.items.firstObject.value === this._selected.firstObject;
    }

    return false;
  }

  @action
  updatePreferedDevice(value) {
    if (this.isDestroying) {
      return;
    }

    if (isPresent(value)) {
      this._updateObjects(value);
    } else {
      // You must have at least one preferred device so we ignore the newly selected value if
      // the user has deselected all of the options.
      this._updateObjects(this.previouslySelectedValue);
    }

    this.currentSelectionLabel = this._updateCurrentSelectionDescription();
  }

  _updateCurrentSelectionDescription() {
    if (isPresent(this._selected)) {
      let selected = this._selected;
      return toSentence(
        selected.map((value) => this._allPossibleItems[value].text),
        this.args.conjuction || 'and',
        this.args.showAllConjunctions,
      );
    } else {
      return 'Preferred Devices';
    }
  }
  _preferredDevicesIsWebOnly() {
    return !(
      this.args.content.preferredDevices.includes(preferredDevices.ios) ||
      this.args.content.preferredDevices.includes(preferredDevices.android)
    );
  }

  _updateObjects(value) {
    this.args.content.preferredDevices = value;
    this.previouslySelectedValue = value;
    if (this._preferredDevicesIsWebOnly()) {
      this.args.content.sendPushNotification = false;
    }
    // This is a hack to force the validation to re-run. This should be removed when improved inbound bot is supported by mobile.
    this.args.content.visualBuilderObject?.groups?.firstObject?.steps?.lastObject?.notifyPropertyChange(
      'validations',
    );
  }
}
