/* import __COLOCATED_TEMPLATE__ from './use-tour-anywhere-summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Summary extends Component {
  @service contentEditorService;

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get tour() {
    return this.contentEditorService.activeObject;
  }

  get insertionEnabled() {
    return !!this.tour.insertable;
  }

  get isDisabled() {
    return !this.insertionEnabled;
  }
}
