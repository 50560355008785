/* import __COLOCATED_TEMPLATE__ from './answer-stat-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { makeStatsParametersForRulesetLink } from 'embercom/helpers/stats-system/stats-parameters';
import { tracked } from '@glimmer/tracking';

export default class AnswerStatList extends Component {
  @service contentEditorService;
  @service appService;

  @tracked statsParameters;

  constructor() {
    super(...arguments);

    this.statsParameters = makeStatsParametersForRulesetLink(
      this.ruleset,
      this.activeRulesetLink,
      this.contentEditorService.range,
      this.app.timezone,
    );
  }

  get app() {
    return this.appService.app;
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get activeRulesetLink() {
    return this.contentEditorService.activeRulesetLink;
  }

  @action
  changeFilter(_, filter) {
    this.contentEditorService.changeActiveFilterTo(filter);
  }

  @action updateDateRange(range) {
    this.contentEditorService.updateRange(range);
    this.statsParameters = makeStatsParametersForRulesetLink(
      this.ruleset,
      this.activeRulesetLink,
      range,
      this.app.timezone,
    );
  }
}
