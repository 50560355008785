/* import __COLOCATED_TEMPLATE__ from './show.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { and, readOnly } from '@ember/object/computed';
import { getPricingModelData } from 'embercom/lib/developer-hub/pricing-models';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),

  showPricingPageUrl: and('pricingModel', 'pricingModel.pricingPageRequired'),
  pricingModel: computed('appPackage.pricingModel', function () {
    return getPricingModelData(this.appPackage.pricingModel);
  }),
});
