/* import __COLOCATED_TEMPLATE__ from './standard-body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

const AB_TESTING_FEATURE_KEYS = {
  [objectTypes.customBot]: 'bot_workflows',
};

const CONTROL_GROUP_FEATURE_KEYS = {
  [objectTypes.customBot]: 'bot_workflows',
};

export default class StandardBody extends Component {
  @service contentEditorService;

  get objectType() {
    return this.contentEditorService.activeRulesetLink.objectType;
  }

  get abTestingFeatureKey() {
    return AB_TESTING_FEATURE_KEYS[this.objectType] || 'ab_testing';
  }

  get controlGroupFeatureKey() {
    return CONTROL_GROUP_FEATURE_KEYS[this.objectType] || 'advanced_ab';
  }
}
