/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';

export default class Header extends Component {
  get ruleset() {
    return this.args.config.ruleset;
  }

  get showAutomaticallyEnabled() {
    return !!this.ruleset.rulesetLinks.firstObject.object.clientData?.showAutomatically;
  }

  get isDisabled() {
    return !this.showAutomaticallyEnabled;
  }
}
