/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import {
  outboundWebhookHttpMethods,
  outboundWebhookHttpMethodLabels,
  outboundWebhookContentTypes,
  outboundWebhookContentTypeLabels,
} from 'embercom/models/data/outbound-webhook/constants';

export default class WebhookComponent extends Component {
  @service contentEditorService;
  @service store;

  supportedHttpMethods = [
    {
      text: outboundWebhookHttpMethodLabels[outboundWebhookHttpMethods.post],
      value: outboundWebhookHttpMethods.post,
    },
    {
      text: outboundWebhookHttpMethodLabels[outboundWebhookHttpMethods.put],
      value: outboundWebhookHttpMethods.put,
    },
  ];

  supportedContentTypes = [
    {
      text: outboundWebhookContentTypeLabels[outboundWebhookContentTypes.json],
      value: outboundWebhookContentTypes.json,
    },
    {
      text: outboundWebhookContentTypeLabels[outboundWebhookContentTypes.urlEncoded],
      value: outboundWebhookContentTypes.urlEncoded,
    },
  ];

  get webhook() {
    return this.contentEditorService.activeObject;
  }

  @action
  addKeyValue(model) {
    model.pushObject(
      this.store.createFragment('outbound-webhook-key-value', { key: '', value: '' }),
    );
  }

  @action
  deleteKeyValue(model, keyValue) {
    model.removeFragment(keyValue);
  }
}
