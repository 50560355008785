/* import __COLOCATED_TEMPLATE__ from './dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';

export default class DropdownItem extends Component {
  selectItem = () => {
    this.args.dropdown.hideDropdownOverlay();
    this.args.onSelectItem();
  };
}
