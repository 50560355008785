/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
export default class GenericTriageBody extends Component {
  @service contentEditorService;
  @service appService;
  @service store;
  @service intl;

  get behavior() {
    return this.contentEditorService.activeObject;
  }

  get description() {
    if (this.behavior.botMode === 'one_time') {
      return this.intl.t('ai-agent.profiles.content-editor.one-time-description');
    } else {
      return this.intl.t('ai-agent.profiles.content-editor.description');
    }
  }
}
