/* import __COLOCATED_TEMPLATE__ from './comment-extra-quick-actions-dropdown-overlay.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';

export default Component.extend({
  classNames: ['overlay'],

  didInsertElement() {
    this._super(...arguments);
    this.get('componentAttrs.dropdownOverlayVisibilityChanged')(true);
  },

  willDestroyElement() {
    this.get('componentAttrs.dropdownOverlayVisibilityChanged')(false);
    this._super(...arguments);
  },

  performQuickAction: action(async function (action, hideDropdownOverlay) {
    if (action) {
      await action();
    }
    hideDropdownOverlay();
  }),
});
