/* import __COLOCATED_TEMPLATE__ from './segment-settings.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';

import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import { action, set } from '@ember/object';
import type AiContentSegment from 'embercom/models/ai-content-segment';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { post } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { helper } from '@ember/component/helper';
import type AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  selectedWrappers: Array<
    ContentWrapper & { relations: any & { content_segment_ids: Array<number> } }
  >;
  unselectedWrappers: Array<
    ContentWrapper & { relations: any & { content_segment_ids: Array<number> } }
  >;
  isBulkSelection: boolean;
  listApi: AiContentLibraryApi;
  toggleTargetingDrawer: () => void;
  isDisabled: boolean;
}

export default class SegmentSettings extends Component<Args> {
  @service declare store: Store;
  @service declare appService: any;
  @service declare aiContentSegmentsService: AiContentSegmentsService;
  @service declare permissionsService: $TSFixMe;

  get segments() {
    return this.aiContentSegmentsService.segments;
  }

  segmentIsAppliedToSelection = helper(function ([segment, selectedWrappers]: [
    AiContentSegment,
    Array<ContentWrapper>,
  ]): boolean {
    return selectedWrappers.every((wrapper: any) =>
      wrapper.relations.content_segment_ids?.includes(Number(segment.id)),
    );
  });

  noSegmentsAppliedToSelection = helper(function ([selectedWrappers]: [
    Array<ContentWrapper>,
  ]): boolean {
    return selectedWrappers.every((wrapper: any) => !wrapper.relations.content_segment_ids?.length);
  });

  @tracked activeSegments: Array<AiContentSegment> = [];
  @tracked removingAudiences = false;

  get contentTuples() {
    return this.args.selectedWrappers.map((wrapper) => {
      let object = wrapper.contents.firstObject!;
      return {
        entity_id: object.contentId,
        entity_type: object.contentType,
      };
    });
  }

  get unselectedTuples() {
    return this.args.unselectedWrappers.map((wrapper) => {
      let object = wrapper.contents.firstObject!;
      return {
        entity_id: object.contentId,
        entity_type: object.contentType,
      };
    });
  }

  @action async removeAllSegments() {
    this.removingAudiences = true;
    let allSegmentIds = this.segments.map((segment) => Number(segment.id));

    await this.addOrRemoveSegments([], allSegmentIds, true);

    this.args.selectedWrappers.forEach((wrapper) => {
      if (!wrapper.relations.content_segment_ids) {
        set(wrapper.relations, 'content_segment_ids', []);
      }

      wrapper.relations.content_segment_ids.removeObjects(allSegmentIds);
      set(wrapper.relations, 'content_segment_ids', wrapper.relations.content_segment_ids.uniq());

      // As 'relations' is just a big hash, we must notify Ember about the internal changes
      wrapper.notifyPropertyChange('relations');
    });

    this.removingAudiences = false;
  }

  @action async applySegment(segment: AiContentSegment) {
    if (this.activeSegments.includes(segment)) {
      return;
    }
    this.activeSegments.pushObject(segment);

    let segmentIdsToAdd: Array<number> = [];
    let segmentIdsToDelete: Array<number> = [];

    if (
      this.args.selectedWrappers.every((wrapper) =>
        wrapper.relations.content_segment_ids?.includes(Number(segment.id)),
      )
    ) {
      segmentIdsToDelete = [Number(segment.id)];
    } else {
      segmentIdsToAdd = [Number(segment.id)];
    }

    await this.addOrRemoveSegments(segmentIdsToAdd, segmentIdsToDelete);

    if (segmentIdsToAdd.length) {
      this.args.selectedWrappers.forEach((wrapper) => {
        if (!wrapper.relations.content_segment_ids) {
          set(wrapper.relations, 'content_segment_ids', []);
        }

        wrapper.relations.content_segment_ids.pushObject(Number(segment.id));
        set(wrapper.relations, 'content_segment_ids', wrapper.relations.content_segment_ids.uniq());

        // As 'relations' is just a big hash, we must notify Ember about the internal changes
        wrapper.notifyPropertyChange('relations');
      });
    }

    if (segmentIdsToDelete.length) {
      this.args.selectedWrappers.forEach((wrapper) => {
        if (!wrapper.relations.content_segment_ids) {
          set(wrapper.relations, 'content_segment_ids', []);
        }

        wrapper.relations.content_segment_ids.removeObject(Number(segment.id));
        set(wrapper.relations, 'content_segment_ids', wrapper.relations.content_segment_ids.uniq());

        // As 'relations' is just a big hash, we must notify Ember about the internal changes
        wrapper.notifyPropertyChange('relations');
      });
    }

    this.activeSegments.removeObject(segment);
  }

  @action async addOrRemoveSegments(
    segmentIdsToAdd: Array<number>,
    segmentIdsToDelete: Array<number>,
    isAudienceReset = false,
  ) {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    await post('/ember/ai_content_segments/update_memberships', {
      app_id: this.appService.app.id,
      segment_ids_to_add: segmentIdsToAdd,
      segment_ids_to_delete: segmentIdsToDelete,
      content_tuples: this.args.isBulkSelection ? null : this.contentTuples,
      unselected_tuples: this.args.isBulkSelection ? this.unselectedTuples : null,
      query: this.args.isBulkSelection
        ? this.args.listApi.searchParams || this.args.listApi.defaultSearchParams
        : null,
      is_bulk_selection: this.args.isBulkSelection,
      is_audience_reset: isAudienceReset,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::SegmentSettings': typeof SegmentSettings;
  }
}
