/* import __COLOCATED_TEMPLATE__ from './duplicate-modal.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import {
  EVERYONE_TARGET,
  NEW_CONVERSATION_TARGET,
  NO_TRIGGER_TARGET,
  OUTBOUND_TARGET,
  CONVERSATION_STARTED_TARGET,
  NEW_COMMENT_TARGET,
  CONVERSATION_STATUS_CHANGED_TARGET,
  ADMIN_INACTIVITY_TARGET,
  USER_INACTIVITY_TARGET,
  NEW_PHONE_CALL_TARGET,
  ADMIN_NOTE_CREATED_TARGET,
  TICKET_CREATED_TARGET,
  TICKET_STATE_UPDATED_TARGET,
  WEBHOOK_RECEIVED_TARGET,
  CONVERSATION_ASSIGNMENT_CHANGED_TARGET,
} from 'embercom/lib/operator/custom-bots/constants';

import { CUSTOM_BOT_CONFIG } from 'embercom/objects/operator/configuration/configuration';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  onModalClose: () => void;
  isOpen: boolean;
}

type DuplicableTargets =
  | typeof EVERYONE_TARGET
  | typeof NEW_CONVERSATION_TARGET
  | typeof NO_TRIGGER_TARGET
  | typeof OUTBOUND_TARGET
  | typeof CONVERSATION_STARTED_TARGET
  | typeof NEW_COMMENT_TARGET
  | typeof CONVERSATION_STATUS_CHANGED_TARGET
  | typeof ADMIN_INACTIVITY_TARGET
  | typeof USER_INACTIVITY_TARGET
  | typeof NEW_PHONE_CALL_TARGET
  | typeof ADMIN_NOTE_CREATED_TARGET
  | typeof TICKET_CREATED_TARGET
  | typeof TICKET_STATE_UPDATED_TARGET
  | typeof WEBHOOK_RECEIVED_TARGET
  | typeof CONVERSATION_ASSIGNMENT_CHANGED_TARGET;

export default class DuplicateModal extends Component<Args> {
  @service declare appService: any;
  @service declare contentEditorService: any;
  @service declare notificationsService: any;
  @service declare router: RouterService;
  @service declare intl: IntlService;

  @tracked customBot: any;
  @tracked selectedTarget: DuplicableTargets;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.customBot = this.contentEditorService.activeObject;
    this.selectedTarget = this.customBot.target;
  }

  get app() {
    return this.appService.app;
  }

  get duplicationOptions() {
    let botTypeOptions: Array<{ text: string; value: DuplicableTargets }> = [];
    if (this.customBot.isPhoneCallTrigger) {
      botTypeOptions = [
        {
          text: this.intl.t('operator.custom-bot.duplicate-modal.type.new-phone-call'),
          value: NEW_PHONE_CALL_TARGET,
        },
      ];
    } else {
      if (this.customBot.isButton) {
        botTypeOptions = [
          {
            text: this.intl.t('operator.custom-bot.duplicate-modal.type.button'),
            value: EVERYONE_TARGET,
          },
        ];
      } else if (!this.customBot.isTriggerable) {
        botTypeOptions = [
          {
            text: this.intl.t('operator.custom-bot.duplicate-modal.type.outbound'),
            value: OUTBOUND_TARGET,
          },
          {
            text: this.intl.t('operator.custom-bot.duplicate-modal.type.button'),
            value: EVERYONE_TARGET,
          },
          {
            text: this.intl.t('operator.custom-bot.duplicate-modal.type.inbound'),
            value: NEW_CONVERSATION_TARGET,
          },
        ];
      }

      if ([TICKET_CREATED_TARGET, TICKET_STATE_UPDATED_TARGET].includes(this.customBot.target)) {
        botTypeOptions.push(
          {
            text: this.intl.t('operator.custom-bot.trigger.ticket-created.title'),
            value: TICKET_CREATED_TARGET,
          },
          {
            text: this.intl.t('operator.custom-bot.trigger.ticket-state-updated.title'),
            value: TICKET_STATE_UPDATED_TARGET,
          },
          {
            text: this.intl.t('operator.custom-bot.duplicate-modal.type.no-trigger'),
            value: NO_TRIGGER_TARGET,
          },
        );
      } else {
        botTypeOptions.push(
          {
            text: this.intl.t('operator.custom-bot.duplicate-modal.type.no-trigger'),
            value: NO_TRIGGER_TARGET,
          },
          {
            text: this.intl.t('operator.custom-bot.duplicate-modal.type.first-message'),
            value: CONVERSATION_STARTED_TARGET,
          },
          {
            text: this.intl.t('operator.custom-bot.duplicate-modal.type.new-comment'),
            value: NEW_COMMENT_TARGET,
          },
          {
            text: this.intl.t('operator.custom-bot.duplicate-modal.type.user-inactivity'),
            value: USER_INACTIVITY_TARGET,
          },
          {
            text: this.intl.t('operator.custom-bot.duplicate-modal.type.admin-inactivity'),
            value: ADMIN_INACTIVITY_TARGET,
          },
          {
            text: this.intl.t(
              'operator.custom-bot.duplicate-modal.type.conversation-state-changed',
            ),
            value: CONVERSATION_STATUS_CHANGED_TARGET,
          },
          {
            text: this.intl.t(
              'operator.custom-bot.duplicate-modal.type.conversation-assignment-changed',
            ),
            value: CONVERSATION_ASSIGNMENT_CHANGED_TARGET,
          },
        );

        if (!this.customBot.visualBuilderObject?.isCustomerFacing) {
          botTypeOptions.push({
            text: this.intl.t('operator.custom-bot.trigger.admin-note-created.title'),
            value: ADMIN_NOTE_CREATED_TARGET,
          });
        }

        if (this.customBot.target === WEBHOOK_RECEIVED_TARGET) {
          botTypeOptions.push({
            text: this.intl.t('operator.custom-bot.trigger.inbound-webhook-received.title'),
            value: WEBHOOK_RECEIVED_TARGET,
          });
        }
      }
    }
    return botTypeOptions.filter((bot) => CUSTOM_BOT_CONFIG[bot.value].visibleToApp(this.app));
  }

  outboundCustomBotTargets = [OUTBOUND_TARGET];

  get humanReadableObjectName() {
    return this.contentEditorService.activeRulesetLink.humanReadableObjectName;
  }

  get isDuplicating() {
    return taskFor(this.duplicateCustomBot).isRunning;
  }

  @action
  updateSelectedTarget(value: DuplicableTargets) {
    this.selectedTarget = value;
  }

  @task({ drop: true })
  *duplicateCustomBot() {
    let { objectType, matchBehavior } = CUSTOM_BOT_CONFIG[this.selectedTarget];
    try {
      if (this.selectedTarget === this.customBot.target) {
        yield this.contentEditorService.duplicateRuleset.perform();
      } else {
        yield this.contentEditorService.duplicateRuleset.perform(
          objectType,
          {
            duplicating: true,
            new_object_type: objectType,
            original_custom_bot_id: this.customBot.id,
            target: this.selectedTarget,
          },
          matchBehavior,
        );
      }
      this.args.onModalClose();
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('operator.custom-bot.duplicate-modal.error'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CustomBots::DuplicateModal': typeof DuplicateModal;
    'custom-bots/duplicate-modal': typeof DuplicateModal;
  }
}
