/* import __COLOCATED_TEMPLATE__ from './standard-ruleset-link-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class StandardRulesetLinkSwitcher extends Component {
  @service intercomEventService;
  @service contentEditorService;

  @action clickLearnMoreAboutAB() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'learn-more-about-ab-link',
      ruleset_id: this.contentEditorService.ruleset.id,
      ruleset_link_id: this.contentEditorService.activeRulesetLink.id,
    });
  }
}
