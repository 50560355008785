/* import __COLOCATED_TEMPLATE__ from './interblocks-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';

export default class InterblocksComponent extends Component {
  get bubbleGroup() {
    return this.args.bubbleGroup;
  }

  get partitionedBlocks() {
    let partitionedBlocks = this.bubbleGroup.blocks
      .reduce(this._partitionHtmlBlocks, [[]])
      .filter((blockList) => isPresent(blockList));

    return partitionedBlocks;
  }

  _partitionHtmlBlocks(accumulator, block) {
    if (block.type === 'html') {
      accumulator.push([block], []);
    } else {
      accumulator.get('lastObject').push(block);
    }
    return accumulator;
  }
}
