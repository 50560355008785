/* RESPONSIBLE TEAM: team-frontend-tech */
// PLEASE DON'T USE THIS COMPONENT
// use https://pulse.internal.intercom.io/components/expanding-input instead

/* eslint-disable @intercom/intercom/no-component-inheritance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { schedule } from '@ember/runloop';
import { empty } from '@ember/object/computed';
import { TextField } from '@ember/legacy-built-in-components';
import DidChangeAttrs from 'ember-did-change-attrs';
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';

export default TextField.extend(DidChangeAttrs, {
  classNameBindings: ['hasError:o__error'],
  additionalWidth: 0,
  minWidth: 165,
  maxWidth: 250,

  // I'd like to deprecate minWidth and maxWidth
  // since min and max width can and should be achieved through CSS rules

  didInsertElement() {
    this._super(...arguments);

    this.resizeToFit();
    this.applyBounds();
    if (this.shouldFocusNoSelect) {
      $(this.element).focus();
    }
    if (this.shouldFocus) {
      $(this.element).focus().select();
    }
  },

  didChangeAttrsConfig: {
    attrs: ['maxWidth', 'minWidth', 'additionalWidth', 'value'],
  },

  didChangeAttrs(changes) {
    if (changes.maxWidth || changes.minWidth) {
      this.applyBounds();
    }
    if (changes.additionalWidth || changes.value) {
      this.resizeToFit();
    }
  },

  isEmpty: empty('value'),

  applyBounds() {
    let element = this.element;
    element.style.minWidth = `${this.minWidth}px`;
    element.style.maxWidth = `${this.maxWidth}px`;
  },

  resizeToFit() {
    // we need to run the following once the value has been copied
    // to the element
    schedule('actions', () => {
      let element = this.element;
      let elementType = element.type;
      let valueIsEmpty = element.value === '';

      if (valueIsEmpty) {
        // temporarily set the element's value to the placeholder value
        // so that the element will be wide enough to display it
        // clear the element's type attribute, so the placeholder can contain
        // a value that's not actually valid
        // (e.g. type='number' placeholder='123...')
        element.type = '';
        element.value = element.placeholder;
      }

      element.style.width = 0;
      // we need 1 pixel extra 'padding' for the cursor - see issue #35333

      let scrollWidth = element.scrollWidth;
      if (UserAgentDetector.isEdge()) {
        // Non-ideal quick fix for https://github.com/intercom/intercom/issues/138542 in Microsoft Edge.
        scrollWidth = 200;
      }

      element.style.width = `${scrollWidth + this.additionalWidth + 1}px`;

      if (valueIsEmpty) {
        // revert our temporary swap-in of the placeholder
        element.type = elementType;
        element.value = '';
      }
    });
  },

  click(event) {
    if (event !== undefined) {
      event.stopPropagation();
    }
  },

  focusIn() {
    if (this.onFocus) {
      this.onFocus();
    }
  },

  focusOut() {
    if (this.onBlur) {
      this.onBlur();
    }
  },

  // I think everything below here possibly belongs in something more specific
  // e.g. remove the `this.get('integer')` and extend the whole component
  // as an expanding-integer-input-component

  keyPress(event) {
    if (this.integer) {
      let enteredChar = String.fromCharCode(event.charCode);
      if (!$.isNumeric(enteredChar)) {
        event.preventDefault();
      }
    }
  },
});
