/* import __COLOCATED_TEMPLATE__ from './custom-times-summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import moment from 'moment-timezone';

export default class CustomTimesSummary extends Component {
  get intervalsByDay() {
    return [0, 1, 2, 3, 4, 5, 6].map((day) => this.args.matchingTimetable.timeIntervalsForDay(day));
  }

  get timeStringsByDay() {
    return this.intervalsByDay
      .map((intervals, day) => this._generateMessageForDay(intervals, day))
      .filter((string) => string.length > 0);
  }

  _generateMessageForDay(intervals, day) {
    if (intervals.length === 0) {
      return '';
    }
    let dayString = this._dayIntToName(day);
    let intervalMessages = intervals.map((interval) =>
      this._generateIntervalMessage(interval, day),
    );
    return `${dayString} ${intervalMessages.join(' and ')}`;
  }

  _generateIntervalMessage(interval, day) {
    let startTime = this._adjustedMinutesToString(interval.adjustedStartMinute);
    let endTime = this._adjustedMinutesToString(interval.adjustedEndMinute);

    if (interval.startDay < day) {
      startTime = '12:00AM';
    }
    if (interval.endDay > day) {
      endTime = '12:00AM';
    }
    return `from ${startTime} to ${endTime}`;
  }

  _adjustedMinutesToString(minutes) {
    return moment.utc().startOf('day').add(minutes, 'minutes').format('h:mmA');
  }

  _dayIntToName(dayInt) {
    return moment()
      .day(dayInt + 1)
      .format('dddd');
  }
}
