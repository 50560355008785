/* import __COLOCATED_TEMPLATE__ from './help-center-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { inject as service } from '@ember/service';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  contentWrapper: ContentWrapper;
  allHelpCenters: HelpCenterSite[];
}

export default class HelpCenterCell extends Component<Args> {
  @service declare helpCenterService: any;

  get helpCenterIds(): number[] {
    return this.args.contentWrapper.contents.firstObject!.contentData?.help_center_ids ?? [];
  }

  get helpCenterNames(): string[] {
    let names = this.helpCenterIds.map(
      (id: number) =>
        this.args.allHelpCenters.find((helpCenter: HelpCenterSite) => helpCenter.id === String(id))
          ?.name ?? undefined,
    );
    return names.compact();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Cells::HelpCenterCell': typeof HelpCenterCell;
  }
}
