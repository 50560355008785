/* import __COLOCATED_TEMPLATE__ from './generic-event-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { map } from '@ember/object/computed';
import { computed } from '@ember/object';
import Component from '@ember/component';
import EventTokenizer from 'embercom/lib/events/event-tokenizer';

const SUPPORTED_TYPES = [
  'text',
  'admin',
  'user',
  'snoozed_until',
  'identifier',
  'assignment_rule',
  'assignment_strategy',
  'priority_rule',
  'conversation_sla_rule',
  'message',
  'assignment_rules',
  'unengaged_inbound_bot_introduction',
  'tag',
  'tag_rule',
  'team',
  'title',
];

export default Component.extend({
  tokens: computed('part.event_data.template', 'part.event_data.event_references', function () {
    let tokenizer = new EventTokenizer(
      this.get('part.event_data.template') || '',
      this.get('part.event_data.event_references'),
    );
    return tokenizer.tokenize();
  }),
  mappedComponentsToTokens: map('tokens', function (token) {
    let componentName;

    if (SUPPORTED_TYPES.includes(token.type)) {
      let typeWithDashes = token.type.replace(/_/g, '-');
      componentName = `conversation/stream/event-part-${typeWithDashes}-component`;
    } else {
      componentName = 'conversation/stream/event-part-unknown-entity-component';
    }

    return { token, component: componentName };
  }),
});
