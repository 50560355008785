/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class Summary extends Component {
  @service contentEditorService;
  @service intl;
  @tracked targetChannelsEmpty = isEmpty(
    this.contentEditorService.activeObject.targetChannels?.length,
  );

  get targetChannels() {
    if (this.targetChannelsEmpty) {
      return;
    }
    return this.contentEditorService.activeObject.targetChannels.map((channel) => {
      return this.intl.t('operator.workflows.channels.type', { channel });
    });
  }

  get targetChannelsLabel() {
    if (this.targetChannels.length > 3) {
      return this.intl.t('operator.workflows.channels.multiple-channels', {
        channelOne: this.targetChannels[0],
        remainingChannelCount: this.targetChannels.length - 1,
      });
    }
    return this.intl.formatList(this.targetChannels, {
      type: 'conjunction',
    });
  }

  get targetChannelsTooltip() {
    return this.intl.formatList(this.targetChannels, {
      type: 'conjunction',
    });
  }
}
