/* import __COLOCATED_TEMPLATE__ from './assignee-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { senderTypes } from 'embercom/models/data/outbound/constants';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { cached } from 'tracked-toolbox';
import { timeout } from 'ember-concurrency';

// This is constructed using sender_id:sender_type
// The id of the unassigned admin is 0 and the id of teammate sender type is also 0
const UNASSIGNED = '0:0';

export default class AssigneeDropdown extends Component {
  @service appService;
  @service intercomEventService;
  @service store;

  @tracked assigneeEmails = [];
  @tracked showAddCustomSenderModal = false;
  @tracked isVisible = true;

  constructor() {
    super(...arguments);
    if (this.args.showTeammateEmailAddresses && !this.app.emailSenderAddressCustomized) {
      this.loadAssigneeEmails.perform();
    }
    if (this.app.canUseDelayedAssigneeDropdown && this.app.assignableAdmins.length > 500) {
      this.isVisible = false;
      this.delayRender.perform();
    }
  }

  @task *loadAssigneeEmails() {
    this.assigneeEmails = yield this.store.findAll('assignee-email');
  }

  @task *delayRender() {
    yield timeout(1000);
    this.isVisible = true;
  }

  get app() {
    return this.appService.app;
  }

  @cached
  get selectedValue() {
    let selectedValueExists = this.entities.any((entity) =>
      entity.items.find((item) => item.value === this.args.selectedValue),
    );

    if (!selectedValueExists && this.args.allowUnassigned) {
      return UNASSIGNED;
    }

    return this.args.selectedValue;
  }

  get hasPermissionForCustomAddresses() {
    return this.app.currentAdmin.currentAppPermissions.can_send_from_custom_addresses;
  }

  @cached
  get customAddressGroup() {
    return {
      heading: 'Custom addresses',
      items: this._mapAssigneesToDropdownItems(this.app.get('customEmailAddresses')),
    };
  }

  @cached
  get teammateGroup() {
    let displayableTeammates = this.args.allowUnassigned
      ? this.app.assignableAdmins.filter((admin) => !admin.isTeam)
      : this.app.humanAdmins;
    return {
      heading: 'Teammates',
      items: this._mapAssigneesToDropdownItems(displayableTeammates),
    };
  }

  @cached
  get entities() {
    let assignees = [];
    if (!this.args.hideOwner) {
      assignees.push({
        heading: 'Dynamic',
        items: this._ownerItem(),
      });
    }

    if (this.args.isEmailMode) {
      assignees.push(this.customAddressGroup);
    }
    assignees.push(this.teammateGroup);

    if (!this.args.isEmailMode && !this.args.hideTeams) {
      assignees = assignees.concat({
        heading: 'Teams',
        items: this._mapAssigneesToDropdownItems(this.app.get('teams')),
      });
    }

    if (
      this.args.isEmailMode &&
      !assignees.any((entity) =>
        entity.items.find((item) => item.value === this.args.selectedValue),
      )
    ) {
      assignees = assignees.concat({
        heading: 'Deleted',
        items: [
          {
            text: 'Unknown',
            value: this.args.selectedValue || '',
            isDisabled: true,
          },
        ],
      });
    }

    return assignees;
  }

  @action
  toggleDisplayCustomSenderModal() {
    this.showAddCustomSenderModal = !this.showAddCustomSenderModal;
    if (this.showAddCustomSenderModal) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_custom_sender_address',
        context: 'from_messages_page',
      });
    }
  }

  _ownerItem() {
    return [
      {
        text: 'Owner',
        value: '-1',
        component: 'content-editor/panels/content/owner-assignee-dropdown',
        isDisabled: !this.app.hasDynamicAccountMessagesBillingFeature,
      },
    ];
  }

  _mapAssigneesToDropdownItems(assignees) {
    return assignees.map((assignee) => {
      let isUnverified = false;
      let isCustomEmailAddress = assignee.outboundType === senderTypes.customEmailAddress;
      let isRestrictedByPermission = false;

      if (isCustomEmailAddress) {
        isUnverified = !assignee.verified;
        isRestrictedByPermission = !this.hasPermissionForCustomAddresses;
      }

      let showEmail =
        assignee.outboundType === senderTypes.customEmailAddress ||
        this.args.showTeammateEmailAddresses;
      let showIfUnauthenticated =
        showEmail &&
        !(assignee.outboundType === senderTypes.admin && !this.app.emailSenderAddressCustomized);

      return {
        text: assignee.name,
        value: `${assignee.id}:${assignee.outboundType}`,
        avatarData: assignee.avatarData,
        isDisabled: isUnverified || isRestrictedByPermission,
        component: 'content-editor/panels/content/assignee-sender-item',
        componentAttrs: {
          model: assignee,
          modelDisplayName: assignee.name,
          modelDisplayEmail: showEmail && this._findEmail(assignee),
          avatarSize: 'xs',
          isUnauthenticated: showIfUnauthenticated && !this._isAuthenticated(assignee),
          isUnverified,
          isCustomEmailAddress,
          isRestrictedByPermission,
        },
      };
    });
  }

  _findEmail(assignee) {
    if (
      assignee.outboundType === senderTypes.customEmailAddress ||
      this.app.emailSenderAddressCustomized
    ) {
      return assignee.email;
    }

    let assigneeEmail = this.assigneeEmails.find(
      (assigneeEmail) => Number(assigneeEmail.assigneeId) === Number(assignee.id),
    );
    if (assigneeEmail) {
      return assigneeEmail.email;
    }

    // As assignee email records are lazily created, we may have teammates
    // that don't yet have an assignee email record. The code below attempts
    // to perform the same as AssigneeEmail#emailify on the backend.

    let localPart = assignee.name
      .normalize('NFKD')
      .replace(/[\u0300-\u036F]/g, '')
      .toLowerCase()
      .replace(/[^a-z0-9\.\_ ]/, '')
      .trim()
      .replace(' ', '.')
      .replace(/(\.){2,}/, '.')
      .replace(/(\.*)$/, '');

    localPart = localPart || 'support';

    return `${localPart}@${this.app.email_sender_domain}`;
  }

  _isAuthenticated(assignee) {
    return this._isCustomBounceValid(assignee) && this._isDmarcValid(assignee);
  }

  _isCustomBounceValid(assignee) {
    if (assignee.outboundType === senderTypes.customEmailAddress) {
      return assignee.customBounceValid;
    }

    if (assignee.outboundType !== senderTypes.admin || !this.app.emailSenderAddressCustomized) {
      return false;
    }

    return this.app.areCustomBounceSettingsValidForEmail(assignee.email);
  }

  _isDmarcValid(assignee) {
    if (assignee.outboundType === senderTypes.customEmailAddress) {
      return assignee.dmarcValid;
    }

    if (assignee.outboundType !== senderTypes.admin || !this.app.emailSenderAddressCustomized) {
      return false;
    }

    return this.app.isDmarcValidForEmail(assignee.email);
  }
}
