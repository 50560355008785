/* import __COLOCATED_TEMPLATE__ from './triggerable-bots-stat-list.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { makeStatsParametersForTriggerableBot } from 'embercom/helpers/stats-system/stats-parameters';
import { allChannels, CHANNEL_METADATA_MAPPING } from 'embercom/lib/operator/custom-bots/constants';

export default class StatList extends Component {
  @service contentEditorService;
  @service appService;
  @service intl;

  @tracked targetChannels = [];
  @tracked range = this.contentEditorService.range;

  get defaultChannels() {
    return allChannels(this.appService.app.enableTwitterIntegrationBots).map((channel) => {
      return {
        icon: CHANNEL_METADATA_MAPPING[channel].icon,
        value: channel !== 'web' ? CHANNEL_METADATA_MAPPING[channel].value : 'messenger',
        text: this.intl.t('operator.workflows.channels.type', {
          channel: CHANNEL_METADATA_MAPPING[channel].value,
        }),
        isSelected: this.targetChannels.includes(channel !== 'web' ? channel : 'messenger'),
      };
    });
  }

  get app() {
    return this.appService.app;
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get activeRulesetLink() {
    return this.contentEditorService.activeRulesetLink;
  }

  @action
  changeFilter(_, filter) {
    this.contentEditorService.changeActiveFilterTo(filter);
  }

  get statsParameters() {
    return this.makeStatsParameters();
  }

  makeStatsParameters() {
    return makeStatsParametersForTriggerableBot(
      this.ruleset,
      this.activeRulesetLink,
      this.range,
      this.app.timezone,
      this.targetChannels,
    );
  }

  @action
  filterByTargetChannel(channels) {
    this.targetChannels = channels;
  }

  @action
  updateDateRange(range) {
    this.contentEditorService.updateRange(range);
    this.range = range;
  }
}
