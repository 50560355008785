/* import __COLOCATED_TEMPLATE__ from './categories-list.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class CategoriesList extends Component {
  appStoreListing = this.args.appPackage.appStoreListing;
  publicAppStoreCategories = this.args.categories.filter((cat) => cat.publiclyVisible);

  @action
  updateCategories(newSelection, value, operation) {
    let selectedSlugs = newSelection.map((category) => category.get('slug'));
    this.appStoreListing.set('categories', selectedSlugs);
  }
}
