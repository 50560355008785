/* import __COLOCATED_TEMPLATE__ from './admin-note-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-jquery */
import { on } from '@ember/object/evented';
import $ from 'jquery';
import ConversationPartComponentBase from 'embercom/components/conversation/stream/conversation-part-component-base';

let AdminNoteComponent = ConversationPartComponentBase.extend({
  setCorrectHrefForMentions: on('didInsertElement', function () {
    let mentions = $('.entity_mention', this.element);
    let self = this;
    mentions.each(function () {
      let mention = self.$(this);
      let originalHref = mention.attr('href');
      if (originalHref) {
        let mentionedAdminId = originalHref.split('/').pop();
        mention.attr('href', `/a/apps/${self.get('app.id')}/admins/${mentionedAdminId}`);
      }
    });
  }),
});

export default AdminNoteComponent;
