/* import __COLOCATED_TEMPLATE__ from './tag-reply-quick-action.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable @intercom/intercom/no-legacy-modal */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import QuickActionBaseComponent from './quick-action-base-component';
import { inject as service } from '@ember/service';

export default QuickActionBaseComponent.extend({
  icon: 'tag',
  app: readOnly('appService.app'),
  modalService: service(),

  message: computed('part.is_initial_part', function () {
    return `Tag this ${this.part.is_initial_part ? 'message' : 'reply'}`;
  }),

  actions: {
    openTaggingModal(part) {
      this.openModal('inbox/inbox/conversations/modals/tagging', {
        taggable: part,
      });
    },
  },
});
