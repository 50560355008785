/* import __COLOCATED_TEMPLATE__ from './more-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  objectTypes,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { EVERYONE_PREDICATE } from 'embercom/lib/operator/custom-bots/constants';

export default class MoreDropdown extends Component {
  @service appService;
  @service contentEditorService;
  @service notificationsService;
  @service permissionsService;
  @service intl;
  @service router;

  @tracked showDuplicateModal = false;
  @tracked showCsvExportModal = false;
  @tracked showTagModal = false;

  get hasDuplicateModalComponent() {
    let { config } = this.args;
    return isPresent(config) && isPresent(config.duplicateModalComponent);
  }

  get app() {
    return this.appService.app;
  }

  get isCsvExportDisabled() {
    let contentReceiptStats = this.contentEditorService.activeRulesetLink.objectStats.findBy(
      'key',
      statisticKeys.receipts,
    );
    return !(contentReceiptStats && contentReceiptStats.value);
  }

  get hideMatchToolOption() {
    let configRulesetLinkObjectType = this.contentEditorService.configRulesetLink.objectType;
    let activeRulesetLinkObjectType = this.contentEditorService.activeRulesetLink.objectType;
    return (
      [
        objectTypes.triggerableCustomBot,
        objectTypes.inboundCustomBot,
        objectTypes.resolutionBotBehavior,
      ].includes(configRulesetLinkObjectType) ||
      [
        objectTypes.triggerableCustomBot,
        objectTypes.inboundCustomBot,
        objectTypes.resolutionBotBehavior,
      ].includes(activeRulesetLinkObjectType)
    );
  }

  get showProfileOption() {
    return (
      this.contentEditorService.activeRulesetLink.objectType === objectTypes.resolutionBotBehavior
    );
  }

  get isAddProfileOptionDisabled() {
    return (
      this.appService.app.canUseFeature('workflows_core_billing_feature') &&
      !this.appService.app.answerBotProduct &&
      !this.appService.app.canUseFeature('self-serve-support-disable-profile-creation-killswitch')
    );
  }

  @action
  createBehavior() {
    let rulesetParams = {
      app_id: this.appService.app.id,
      object_type: objectTypes.resolutionBotBehavior,
      match_behavior: matchBehaviors.transient,
      object_data: {},
      role_predicate_group: { predicates: [EVERYONE_PREDICATE] },
    };
    this.router.transitionTo('apps.app.content.new', rulesetParams);
  }

  @action
  afterTagSave() {
    this.notificationsService.notifyConfirmation(
      this.intl.t('outbound.content-editor.headers.more-dropdown.message-tagged'),
    );
    this.contentEditorService.ruleset.reload();
    this.showTagModal = false;
  }

  @action
  showCsvExport() {
    let requiredPermission = 'can_export_outbound_data';
    let hasPermission = this.permissionsService.currentAdminCan('can_export_outbound_data');

    let { objectType } = this.contentEditorService.configRulesetLink;

    if (hasPermission && objectType === objectTypes.survey && this.app.canManageSurveysData) {
      hasPermission = this.permissionsService.currentAdminCan('can_outbound__surveys_data__manage');
      requiredPermission = 'can_outbound__surveys_data__manage';
    }
    if (hasPermission) {
      this.showCsvExportModal = true;
    } else {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(requiredPermission);
    }
  }

  @action
  onClickDuplicate() {
    let { objectType } = this.contentEditorService.configRulesetLink;
    let { hasTests } = this.contentEditorService.ruleset;

    if (objectType === objectTypes.chat || objectType === objectTypes.post) {
      this.contentEditorService.showDuplicateModal();
    } else if (objectType === objectTypes.customBot && hasTests) {
      this.contentEditorService.duplicateRuleset.perform();
    } else if (this.hasDuplicateModalComponent) {
      this.showDuplicateModal = true;
    } else {
      this.contentEditorService.duplicateRuleset.perform();
    }
  }
}
