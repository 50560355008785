/* import __COLOCATED_TEMPLATE__ from './similar-conversations-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';
import DidChangeAttrs from 'ember-did-change-attrs';
import { action } from '@ember/object';

export default Component.extend(DidChangeAttrs, {
  attributeBindings: ['data-test-similar-conversations'],
  'data-test-similar-conversations': true,
  store: service(),
  appService: service(),
  intercomEventService: service(),
  hasLoadedConversations: false,

  didChangeAttrsConfig: {
    attrs: ['conversationId'],
  },

  didChangeAttrs(changes) {
    this._super(...arguments);

    if (changes.conversationId) {
      this.set('hasLoadedConversations', false);
    }
  },

  fetchAndSetSimilarConversations: task(function* () {
    this.set('similarConversations', null);
    this.set('hasLoadedConversations', true);

    let conversations = yield ajax({
      url: `/ember/conversations/similar_conversations`,
      type: 'GET',
      data: {
        conversation_id: this.conversationId,
        app_id: this.get('appService.app.id'),
      },
    });

    this.store.pushPayload({ conversations });
    this.set(
      'similarConversations',
      conversations.map(({ id }) => this.store.peekRecord('conversation', id)),
    );

    this.trackSimilarConversationsLoad(conversations.map((c) => c.id));
  }).restartable(),

  trackSimilarConversationsLoad(conversation_ids) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'similar_conversations',
      place: 'inbox',
      from_search: true,
      conversation_id: this.conversationId,
      conversation_ids,
    });
  },

  fetchSimilarConversations: action(function () {
    this.fetchAndSetSimilarConversations.perform();
  }),
});
