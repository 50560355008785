/* import __COLOCATED_TEMPLATE__ from './conversation-state-dropdown-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed, action } from '@ember/object';
import { readOnly, not, equal, and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
// eslint-disable-next-line @intercom/intercom/no-legacy-dropdown
import ComponentDropdownToggler from 'embercom/components/mixins/component-dropdown-toggler';

let statusToIconMap = {
  closed: 'check',
  snoozed: 'clock',
  opened: 'inbox',
};

export default Component.extend(ComponentDropdownToggler, {
  tagName: 'span',
  classNames: ['u__relative'],

  appService: service(),
  app: readOnly('appService.app'),

  title: null,
  outline: false,
  dropdownClass: 'o__down-left',
  displayAllState: false,
  displayOpenState: true,
  displayClosedState: true,
  displaySnoozedState: true,
  attributeBindings: ['data-test-state-dropdown'],
  'data-test-state-dropdown': true,
  statusIcon: computed('status', function () {
    return statusToIconMap[this.status];
  }),
  containerIsNotWide: not('isWide'),
  statusIsUndefined: equal('status', undefined),
  showStatusIcon: not('statusIsUndefined'),
  hideTitle: and('showStatusIcon', 'containerIsNotWide'),
  hideCount: false,
  formattedTotalCount: computed('totalCount', function () {
    if (this.totalCount) {
      return `${this.totalCount}`;
    }
  }),

  triggerChangeStatus: action(function (status) {
    this.changeStatus?.(status);
  }),
});
