/* import __COLOCATED_TEMPLATE__ from './sources.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    sources: Array<ContentImportSource>;
    toggleSourceConfigurationDrawer: () => void;
    reloadPage: () => void;
  };
}

const Sources = templateOnlyComponent<Signature>();
export default Sources;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Segments::Contents::Sources': typeof Sources;
  }
}
