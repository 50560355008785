/* import __COLOCATED_TEMPLATE__ from './file-upload-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type FileSource from 'embercom/models/content-service/file-source';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import ENV from 'embercom/config/environment';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import type Store from '@ember-data/store';
import { FIN_SUPPORTED_LANGUAGES, type Language } from 'embercom/lib/ai-content-library/constants';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import type ContentImportService from 'embercom/services/content-import-service';

interface Args {
  modal: any;
  fileSource?: FileSource;
  onModalClose: () => void;
  onNavigationBack?: () => void;
  context?: {
    onUploadSuccess?: () => void;
    onReuploadSuccess?: () => void;
    folder?: string;
  };
}

interface Upload {
  upload_id: number | string;
  file_name: string;
  file_type: string;
  locale: string;
  folder_id?: string;
}

interface DropDownOption {
  text: string;
  value: string;
}

const MAX_FILE_SIZE_IN_MB = 45;

export default class FileUploadModal extends Component<Args> {
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare notificationsService: any;
  @service declare contentImportService: ContentImportService;
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked fileUploadError?: string;
  @tracked uploadState = 0;
  @tracked uploading = false;
  @tracked file?: File;

  @tracked locale = undefined;
  @tracked isDragging = false;

  @ref('fileInput') fileInput?: HTMLInputElement;

  constructor(owner: unknown, args: any) {
    super(owner, args);
  }

  get app() {
    return this.appService.app;
  }

  get fileSizeLimit() {
    return MAX_FILE_SIZE_IN_MB;
  }

  @action
  async upload() {
    this.uploading = true;
    this.trackAnalyticsEvent(
      'clicked',
      this.args.fileSource ? 'reupload_button' : 'upload_button',
      {
        fileSource: this.args.fileSource,
      },
    );
    await taskFor(this.uploadFile).perform(this.file);
    this.uploading = false;
  }

  @task({ drop: true })
  *uploadFile(file: any): TaskGenerator<void> {
    try {
      yield this.postFile(file);
    } catch (error) {
      console.error('Error uploading file', error);
      this.notificationsService.notifyError(
        this.intl.t('ai-content-library.file-content.new-content-modal.upload-error'),
      );
    }
  }

  @action
  onUploadSuccess() {
    this.args.context?.onUploadSuccess?.();
    this.args.onModalClose();
    this.notificationsService.notifyConfirmation(
      this.intl.t('ai-content-library.file-content.new-content-modal.success'),
    );
    this.contentImportService.showFinIngestionJobStatusBanner();
  }

  get errorMessage() {
    if (this.file === null) {
      return '';
    }

    if (!this.fileHasValidExtension) {
      return this.intl.t('ai-content-library.file-content.new-content-modal.file-type-error');
    } else if (this.fileIsTooLarge) {
      return this.intl.t('ai-content-library.file-content.new-content-modal.file-size-error', {
        maxFileSize: this.fileSizeLimit,
      });
    }

    return '';
  }

  get fileHasValidExtension() {
    let fileName = this.file?.name;
    if (!fileName) {
      return false;
    }

    let regexp = new RegExp(`\.pdf$`, 'i');
    return fileName.match(regexp);
  }

  get fileIsTooLarge() {
    if (!this.file) {
      return false;
    }
    return this.file.size > this.fileSizeLimit * 1000000;
  }

  _setFile(file: any) {
    this.file = file;
  }

  @action
  pickFile() {
    if (ENV.environment === 'test') {
      return this._setFile({ name: 'test.csv' });
    }
    this.fileInput?.click();
  }

  @action
  removeFile() {
    this._setFile(null);
  }

  @action
  dragEnter(event: any) {
    event.preventDefault();
    this.isDragging = true;
  }

  @action
  dragOver(event: any) {
    this.dragEnter(event);
  }

  @action
  dragLeave(event: any) {
    event.preventDefault();
    this.isDragging = false;
  }

  @action
  drop(event: any) {
    event.preventDefault();
    this._setFile(event.dataTransfer.files[0]);
  }

  async postFile(file: any): Promise<void> {
    if (!this.locale) {
      this.notificationsService.notifyError();
      return;
    }

    let uploader = new EmbercomFileUploader(file, {
      policyUrl: `/apps/${this.appService.app.id}/uploads`,
    });
    let response = await uploader.upload();

    if (response.type === 'failure') {
      this.notificationsService.notifyError(response.reason);
      return;
    }

    let upload: Upload = {
      upload_id: uploader.policy.id,
      file_type: file.type,
      file_name: file.name,
      locale: this.locale,
    };

    if (this.args.context?.folder) {
      upload.folder_id = this.args.context.folder;
    }

    try {
      if (this.args.fileSource) {
        await this.updateFileSourceRecord(upload);
        this.args.context?.onReuploadSuccess?.();
      } else {
        await this.saveFileSourceRecord(upload);
        this.onUploadSuccess();
      }
    } catch (e) {
      if (e.jqXHR.responseJSON && e.jqXHR.responseJSON.errors) {
        this.notificationsService.notifyError(e.jqXHR.responseJSON.errors[0].message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('ai-content-library.file-content.new-content-modal.file-source-save-error'),
        );
      }
    }
  }

  async updateFileSourceRecord(upload: Upload) {
    let fileSource = this.args.fileSource;
    if (!fileSource) {
      return;
    }
    fileSource.setProperties({
      fileName: upload.file_name,
      fileType: upload.file_type,
      uploadId: upload.upload_id,
      locale: upload.locale,
    });
    await fileSource.save();
  }

  async saveFileSourceRecord(upload: Upload) {
    let fileSource = this.store.createRecord('content-service/file-source', {
      fileName: upload.file_name,
      fileType: upload.file_type,
      uploadId: upload.upload_id,
      locale: upload.locale,
      folderId: upload.folder_id,
    });
    await fileSource.save();
    return fileSource;
  }

  @action
  onFileChange() {
    if (!this.fileInput?.files) {
      return;
    }
    this._setFile(this.fileInput.files[0]);
  }

  get disableUpload(): boolean {
    return !this.file || this.uploading || this.errorMessage !== '' || !this.locale;
  }

  get languages(): Array<DropDownOption> {
    return FIN_SUPPORTED_LANGUAGES.map((language: Language) => {
      return {
        text: language.name,
        value: language.locale,
      };
    });
  }

  private trackAnalyticsEvent(action: string, object: string, metadata?: any): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'operator',
      context: 'file_upload_modal',
      place: 'fin_content_page',
      ...metadata,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::FileUploadModal': typeof FileUploadModal;
  }
}
