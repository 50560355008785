/* import __COLOCATED_TEMPLATE__ from './app-package-list.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';

export default Component.extend({
  columns: [
    {
      label: 'App',
      canExpandToFit: true,
      valueComponent: 'developer-hub/listing/app-package-details',
    },
    {
      label: 'Status',
      canExpandToFit: true,
      valueComponent: 'developer-hub/listing/app-package-state',
    },
    {
      label: 'Workspace',
      canExpandToFit: true,
      valueComponent: 'developer-hub/listing/app-package-owner-app',
    },
  ],
  rows: readOnly('appPackages'),
});
