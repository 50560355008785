/* import __COLOCATED_TEMPLATE__ from './displayed-attributes-dropdown-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
// eslint-disable-next-line @intercom/intercom/no-legacy-dropdown
import ComponentDropdownToggler from 'embercom/components/mixins/component-dropdown-toggler';
import { findBy, ternary } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';

export default Component.extend(ComponentDropdownToggler, {
  attributeService: service(),
  store: service(),
  tagName: 'span',
  toggleSelector: '.js__displayed-attributes-toggle',
  classNames: ['u__relative', 'u__left'],
  preventDropdownToggleFromWithin: true,
  attributeSettings: readOnly('attributeService.attributeSettings'),
  displayableUserAttributes: readOnly('attributeService.allUserAttributes'),
  displayableCompanyAttributes: readOnly('attributeService.allCompanyAttributes'),
  showCompanyAttributes: readOnly('app.companiesActive'),

  lastRequestAtAttribute: findBy('displayableUserAttributes', 'identifier', 'last_request_at'),
  userIdAttribute: findBy('displayableUserAttributes', 'identifier', 'user_id'),
  currentDisplayOn: ternary('isCompany', 'companies', 'users'),

  // For user presence we want to display the last_request_at attribute closer to the avatar so we adjust the order here
  adjustedDisplayableUserAttributes: computed('displayableUserAttributes.[]', function () {
    let attributes = this.displayableUserAttributes.rejectBy('attribute.type', 'role');
    let lastRequestAtIndex = attributes.indexOf(this.lastRequestAtAttribute);
    let newIndex = attributes.indexOf(this.userIdAttribute) + 1;
    attributes.splice(newIndex, 0, attributes.splice(lastRequestAtIndex, 1)[0]);
    return attributes;
  }),

  groupedDisplayableAttributes: computed(
    'adjustedDisplayableUserAttributes',
    'displayableCompanyAttributes',
    function () {
      let userAttributes = {
        type: 'User',
        list: this.adjustedDisplayableUserAttributes,
      };
      let companyAttributes = {
        type: 'Company',
        list: this.displayableCompanyAttributes,
      };
      if (this.isCompany) {
        return [companyAttributes];
      } else if (this.showCompanyAttributes) {
        return [userAttributes, companyAttributes];
      } else {
        return [userAttributes];
      }
    },
  ),
  actions: {
    toggleDisplayed(displayableAttribute) {
      let attributeSettings = this.attributeSettings;
      let attributeSetting = displayableAttribute.getAttributeSettingByDisplayOn(
        this.currentDisplayOn,
      );
      if (attributeSetting) {
        attributeSettings.removeObject(attributeSetting);
        attributeSetting.destroyRecord();
      } else {
        let newAttributeSetting = this.store.createRecord('display-attribute-setting', {
          app_id: this.get('app.id'),
          attribute: displayableAttribute.attribute.get('identifier'),
          display_on: this.currentDisplayOn,
        });
        attributeSettings.pushObject(newAttributeSetting);
        newAttributeSetting.save();
      }
    },
  },
});
