/* import __COLOCATED_TEMPLATE__ from './name.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { Scope } from 'embercom/objects/content-service/saved-views/constants/scopes';

export default class Name extends Component {
  @service intl;

  get viewName() {
    if (this.args.scope === Scope.OUTBOUND && this.args.savedView.savedViewData.translation) {
      return this.intl.t(
        `content-service.saved-views.${this.args.savedView.savedViewData.translation}`,
      );
    }

    return this.args.savedView.name;
  }
}
