/* import __COLOCATED_TEMPLATE__ from './date.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { type Predicate } from 'embercom/lib/knowledge-hub/constants';
import { debounce } from '@ember/runloop';
import ENV from 'embercom/config/environment';

// @ts-ignore
import PredicateGroup from 'predicates/models/predicate-group';
// @ts-ignore
import PredicateModel from 'predicates/models/predicate';
// @ts-ignore
import { copy } from 'ember-copy';

interface Args {
  predicates?: Array<Predicate>;
  datesFilterAttributes?: any;
  activeFilter?: string;
  displayStandAloneButton?: boolean;
  filterContent: (filter: string, predicates?: Array<Predicate>) => void;
  removeFromActiveFilter: (date?: string) => void;
  isInitiallyClosed?: boolean;
}

export default class Date extends Component<Args> {
  @service declare attributeService: any;
  @service declare store: Store;
  @tracked filterPredicateGroup: PredicateGroup;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.args.predicates) {
      this.filterPredicateGroup = this.store.createFragment('predicates/predicate-group', {
        predicates: PredicateGroup.convertRawPredicates(this.store, this.args.predicates),
      });
    } else {
      this.filterPredicateGroup = this.store.createFragment('predicates/predicate-group');
    }
  }

  get isOpen() {
    if (this.args.isInitiallyClosed) {
      return false;
    }
    return this.args.activeFilter === 'date';
  }

  get datesFilterAttributes() {
    if (this.args.datesFilterAttributes) {
      return this.args.datesFilterAttributes;
    }

    return this.attributeService._contentServiceDateFilterAttributes.map((data: any) =>
      this.store.createRecord('attribute', data),
    );
  }

  get attributeGroupList() {
    return [
      {
        attributes: this.datesFilterAttributes,
      },
    ];
  }

  get filtersAdded() {
    return this.filterPredicateGroup.predicates.map(
      (p: { attributeIdentifier: any }) => p.attributeIdentifier,
    );
  }

  get disableFilterPicker() {
    return this.filtersAdded.length > 0;
  }

  get disabledAttributeIdentifiers() {
    return this.datesFilterAttributes
      .filter((attr: any) => attr.disabled || this.filtersAdded.includes(attr.identifier))
      .map((attr: any) => attr.identifier);
  }

  get validDatePredicates() {
    let predicates = Array.from(
      PredicateModel.walkPredicates(this.filterPredicateGroup.predicates),
    ).filter(
      (predicate: any) =>
        (predicate.isRelativeDate || predicate.isAbsoluteDate) && predicate.isValid,
    );
    return predicates.map((pred: any) => pred.serialize());
  }

  @action
  onPredicatesUpdate(predicates: any) {
    this.filterPredicateGroup.predicates = copy(predicates);

    debounce(this, this.filterContentByDatePredicates, ENV.APP._250MS);
  }

  @action onPredicatesDelete() {
    this.args.removeFromActiveFilter();
  }

  filterContentByDatePredicates() {
    this.args.filterContent('predicates', this.validDatePredicates);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::Filters::Date': typeof Date;
  }
}
