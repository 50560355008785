/* import __COLOCATED_TEMPLATE__ from './title-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { humanReadableObjectNames } from 'embercom/models/data/matching-system/matching-constants';
import { isBlank } from '@ember/utils';

interface Args {
  contentWrapper: ContentWrapper;
  onClick: () => void;
}

export default class TitleCell extends Component<Args> {
  @service declare intl: IntlService;

  get title(): string {
    if (isBlank(this.args.contentWrapper.title)) {
      return this.defaultTitle;
    } else {
      return this.args.contentWrapper.title;
    }
  }

  get defaultTitle(): string {
    let humanReadableObjectName =
      humanReadableObjectNames[this.args.contentWrapper.contents.firstObject!.contentType];

    return this.intl.t('ai-content-library.list.cells.title.untitled', {
      objectName: humanReadableObjectName,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Cells::TitleCell': typeof TitleCell;
  }
}
