/* import __COLOCATED_TEMPLATE__ from './preview-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class PreviewButton extends Component {
  @service contentEditorService;
  @service intersectionService;

  constructor() {
    super(...arguments);
    this.intersectionService.registerEventHandler('getTourId', () => this.tour.id);
  }

  get tour() {
    return this.contentEditorService.activeObject;
  }

  get previewEnabled() {
    return this.tour.url && this.tour.hasSteps;
  }
}
