/* import __COLOCATED_TEMPLATE__ from './utm-tracking-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class UTMTrackingEditor extends Component {
  @service store;

  _initializeUtmSettings() {
    if (!this.args.entity.utmSettings) {
      this.args.entity.utmSettings = this.store.createFragment('content-service/utm-settings', {
        enabled: false,
        utmCampaign: '',
      });
    }
  }

  @action
  toggleUtmTrackingSwitch() {
    this._initializeUtmSettings();
    this.args.entity.utmSettings.enabled = !this.args.entity.utmSettings.enabled;
  }
}
