/* import __COLOCATED_TEMPLATE__ from './scheduled-state-change-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import {
  scheduledStateChangeStatuses,
  scheduledStateChangeTypes,
  timezoneTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

export default class ScheduledStateChangeEditor extends Component {
  @service store;
  @service appService;
  @tracked activationEnabled = isPresent(this.args.ruleset.scheduledActivation);
  @tracked deactivationEnabled = isPresent(this.args.ruleset.scheduledDeactivation);

  get hasScheduledActivation() {
    return isPresent(this.args.ruleset.scheduledActivation);
  }

  get hasScheduledDeactivation() {
    return isPresent(this.args.ruleset.scheduledDeactivation);
  }

  _todayInAppTimezone() {
    return moment.tz(this.appService.app.timezone);
  }

  _defaultSchedule() {
    return this._todayInAppTimezone().add(1, 'week').add(1, 'hour').startOf('hour').toDate();
  }

  _setScheduledStateChange(options) {
    options = {
      ...options,
      timezoneType: timezoneTypes.basedOnAppTimezone,
      changeStatus: scheduledStateChangeStatuses.scheduled,
    };
    let scheduledChange = this.store.createFragment(
      'matching-system/scheduledStateChange',
      options,
    );
    if (options.changeType === scheduledStateChangeTypes.activate) {
      this.args.ruleset.scheduledActivation = scheduledChange;
    } else {
      this.args.ruleset.scheduledDeactivation = scheduledChange;
    }
  }

  @action onScheduledActivationChange(newDate) {
    this.args.ruleset.scheduledActivation.changeAt = newDate;
  }

  @action onScheduledDeactivationChange(newDate) {
    this.args.ruleset.scheduledDeactivation.changeAt = newDate;
  }

  @action removeScheduledActivation() {
    if (this.hasScheduledActivation) {
      this.args.ruleset.scheduledActivation = null;
    }
  }

  @action removeScheduledDeactivation() {
    if (this.hasScheduledDeactivation) {
      this.args.ruleset.scheduledDeactivation = null;
    }
  }

  @action setScheduledActivation() {
    this._setScheduledStateChange({
      changeType: scheduledStateChangeTypes.activate,
      changeAt: this._defaultSchedule(),
    });
  }

  @action setScheduledDeactivation() {
    this._setScheduledStateChange({
      changeType: scheduledStateChangeTypes.deactivate,
      changeAt: this._defaultSchedule(),
    });
  }
}
