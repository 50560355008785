/* import __COLOCATED_TEMPLATE__ from './user-comment-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-jquery */
import { computed } from '@ember/object';
import $ from 'jquery';
import { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';
import { on } from '@ember/object/evented';
import { ternary, equalToProperty } from '@intercom/pulse/lib/computed-properties';
import ConversationPartComponentBase from 'embercom/components/conversation/stream/conversation-part-component-base';
import { addEventListener } from 'ember-lifeline';

export default ConversationPartComponentBase.extend({
  intercomEventService: service(),
  store: service(),
  attributeBindings: ['data-intercom-target'],
  'data-intercom-target': ternary('isLastUserComment', 'last-user-comment', false),
  isLastUserComment: equalToProperty('conversation.lastUserPartWithBody', 'part'),
  addUnauthenticatedAlertHoverListener: on('didInsertElement', function () {
    if ($('.js__impersonation-warning', this.element).length) {
      addEventListener(
        this,
        this.element.querySelector('.js__impersonation-warning'),
        'mouseover',
        () => {
          this.intercomEventService.trackEvent('unauthenticated-warning-hovered', {
            conversation_id: this.get('part.conversation_id'),
          });
        },
      );
    }
  }),
  userId: reads('part.participant.user_id'),
  user: computed('userId', function () {
    return this.store.peekRecord('user', this.userId);
  }),
});
