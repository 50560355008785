/* import __COLOCATED_TEMPLATE__ from './article-suggestion.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { bool, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import { fmtStyle, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  intercomEventService: service(),
  conversationsService: service(),
  paywallService: service(),
  appService: service(),
  app: readOnly('appService.app'),

  classNames: ['card', 'article-suggestion-card'],
  classNameBindings: ['isExpanded:o__expanded:o__collapsed', 'isPaywalled:o__paywalled'],

  attributeBindings: ['style'],

  isExpanded: true,
  collapsedHeight: 50,

  isConversation: bool('conversation'),

  init() {
    this._super(...arguments);
    this.setupAsCollapsable();
  },

  setupAsCollapsable() {
    this.set('isExpanded', false);
    this.didRender = () => {
      this.setFullHeight();
      this.setExpandTopOffset();
    };
  },

  style: ternaryToProperty('isExpanded', 'expandedStyle', 'emptyStyle'),
  expandedStyle: fmtStyle('margin-top: %@px; height: %@px', 'expandedTopOffset', 'fullHeight'),
  emptyStyle: '',

  setFullHeight() {
    let height = 0;
    $(this.element)
      .children()
      .each((_, e) => {
        height += $(e).outerHeight(true);
      });

    this.set('fullHeight', height);
  },

  setExpandTopOffset() {
    this.set('expandedTopOffset', this.fullHeight * -1 + this.collapsedHeight);
  },

  _trackEvents() {
    this.intercomEventService.trackAnalyticsEvent({
      object: this.article,
      action: 'added_to_reply',
      is_suggestion: true,
      target_user_type: this.get('article.targetUserType'),
      conversation_id: this.get('conversation.id'),
    });

    this.intercomEventService.trackEvent('sent_article_in_conversation', {
      action: 'sent',
      object: 'article',
      place: 'comment',
      owner: 'growth-sp',
    });
  },

  _doInsertion() {
    this._trackEvents();
    this.insertArticleIntoComposer.perform(this.article);
    this.clearSuggestions();
  },

  insert: action(function () {
    this.paywallService
      .paywall({ featureName: 'embedded_articles' })
      .then(() => {
        this._doInsertion();
      })
      .catch(this.paywallService.handleError);
  }),
});
