/* import __COLOCATED_TEMPLATE__ from './standard-series-header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class StandardSeriesHeader extends Component {
  @service seriesEditorService;
  @service contentEditorService;

  @action
  openEditMode() {
    let node = this.seriesEditorService.activeSeriesNode;
    this.seriesEditorService.closeRulesetEditor();
    this.seriesEditorService.changeMode('edit');
    this.seriesEditorService.registerActiveSeriesNode(node);
  }

  @action
  closeRulesetEditor() {
    this.seriesEditorService.closeRulesetEditor();
  }
}
