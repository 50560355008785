/* import __COLOCATED_TEMPLATE__ from './regenerate-button.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { readOnly } from '@ember/object/computed';
import { task } from 'ember-concurrency';

export default Component.extend({
  intercomEventService: service(),
  notificationsService: service(),
  developerHub: service(),
  intercomConfirmService: service(),
  appPackage: readOnly('developerHub.appPackage'),
  appPackageInstall: readOnly('row'),

  addAnalyticsEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'app_package',
      app_package_id: this.get('appPackage.id'),
      app_metadata: this.get('appPackage.changeRequest'),
      action,
    });
  },

  regenerateAccessTokenTask: task(function* () {
    let body = `Your existing token will be deleted. You’ll need to update your code with the new token.`;
    if (this.get('appPackage.isProtectedAppPackage')) {
      body = body.concat(
        ' This is a protected app package. Regenerating the token could have unintended side effects',
      );
    }
    let isConfirmed = yield this.intercomConfirmService.confirm({
      title: 'Regenerate your token?',
      confirmButtonText: 'Regenerate',
      cancleButtonText: 'Cancel',
      body,
      primaryButtonType: 'primary-destructive',
    });
    if (!isConfirmed) {
      return;
    }
    try {
      yield this.appPackageInstall.regenerateToken();
      this.addAnalyticsEvent('regenerated');
      this.notificationsService.notifyConfirmation(
        'Your access token was successfully regenerated.',
      );
    } catch (e) {
      this.notificationsService.notifyError('There was a problem regenerating your access token.');
    }
  }),
});
