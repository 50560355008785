/* import __COLOCATED_TEMPLATE__ from './audience-rules-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import AudienceRulesEditor from '../../standard/audience-rules-editor';
import { action } from '@ember/object';
import { copy } from 'ember-copy';
export default class SeriesAudienceRulesEditor extends AudienceRulesEditor {
  get node() {
    return this.args.ruleset.node;
  }

  @action
  onUpdatePredicates(predicates) {
    this.args.ruleset.set('predicateGroup.predicates', copy(predicates));
    if (this.node.isStarting) {
      this.args.ruleset.get('fetchAudiencePreviewTask').perform();
    }
  }

  @action
  onUpdateRolePredicates(rolePredicates) {
    this.args.ruleset.set('rolePredicateGroup.predicates', copy(rolePredicates));
    if (this.node.isStarting) {
      this.args.ruleset.get('fetchAudiencePreviewTask').perform();
    }
  }

  @action
  onUpdateDefaultPredicates(defaultPredicates) {
    this.args.ruleset.set('defaultPredicateGroup.predicates', copy(defaultPredicates));
    if (this.node.isStarting) {
      this.args.ruleset.get('fetchAudiencePreviewTask').perform();
    }
  }
}
