/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Args {
  /* This should be of type SeriesAudienceEditorConfiguration, but that file needs to be updated to TS first */
  /* see https://github.com/intercom/embercom/pull/75885#discussion_r1371495859 */
  config: $TSFixMe;
}

export default class Body extends Component<Args> {
  @service declare contentEditorService: any;

  get ruleset() {
    return this.contentEditorService.ruleset;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Panels::Audience::Body': typeof Body;
    'content-editor/panels/audience/body': typeof Body;
  }
}
