/* import __COLOCATED_TEMPLATE__ from './view-mode.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { objectCustomReportRoute } from 'embercom/models/data/matching-system/matching-constants';
import { outboundCustomReportTests } from 'embercom/models/data/outbound/constants';
export default class ViewMode extends Component {
  @service contentEditorService;
  @service appService;

  get app() {
    return this.appService.app;
  }

  get viewModeConfig() {
    let baseConfig = this.contentEditorService.editorConfiguration;
    if (this.contentEditorService.activeRulesetLink.isControlGroup) {
      return this.contentEditorService.activeObject.editorConfig(baseConfig).viewMode;
    } else {
      return baseConfig.viewMode;
    }
  }

  get customReportRoute() {
    return objectCustomReportRoute[
      this.contentEditorService.ruleset.rulesetLinks.firstObject.objectName
    ];
  }

  get canSeeSurveyReport() {
    return this.customReportRoute;
  }

  get showTest() {
    if (
      this.contentEditorService.ruleset.hasTests &&
      !this.contentEditorService.ruleset.hasControlGroup
    ) {
      return this.contentEditorService.activeRulesetLinkIndex;
    }

    return outboundCustomReportTests.noTest;
  }

  get hideAllTabs() {
    let tabConfigs = this.viewModeConfig.tabsConfiguration.tabComponentConfigs;
    let visibleTabsCount = tabConfigs.filter((tabConfig) => tabConfig.isVisible).length;

    return visibleTabsCount <= 1 && this.contentEditorService.activeObject.isCustomBot;
  }
}
