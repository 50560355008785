/* import __COLOCATED_TEMPLATE__ from './stream-and-controls.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly, not, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import InboundActions from 'ember-component-inbound-actions/inbound-actions';

export default Component.extend(InboundActions, {
  appService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),

  notReadOnly: not('readOnly'),
  notInbox: not('isInbox'),
  hideControls: or('readOnly', 'conversation.fromDeletedUser'),

  operatorInboxInterruptionAction: computed('admin.assign_conversations_on_reply', function () {
    if (this.admin?.assign_conversations_on_reply) {
      return 'assign it to you';
    } else {
      return 'unassign it';
    }
  }),

  actions: {
    changeToNotePane() {
      this.conversationControls.send('changeToNotePane');
    },

    focusComposerTab(tab) {
      this.conversationControls.send('focusComposerTab', tab);
    },

    scrollStreamToBottom() {
      if (!this.conversationStream) {
        return;
      }
      this.conversationStream.send('scrollToBottom');
    },
    resetTypingIndicators() {
      if (!this.conversationStream) {
        return;
      }
      this.conversationStream.send('resetTypingIndicators');
    },
  },
});
