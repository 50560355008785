/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { makeStatsParametersForEdgeSplit } from 'embercom/helpers/stats-system/stats-parameters';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

export default class SplitterSummaryComponent extends Component {
  @service contentEditorService;
  @tracked selectedEdgeSplit = this.edgeSplits.firstObject;
  @service intercomConfirmService;

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get splitter() {
    return this.contentEditorService.activeObject;
  }

  get edgeSplits() {
    return this.splitter.edgeSplits;
  }

  get edgeSplitSelectItems() {
    return this.edgeSplits.map((split) => {
      return { text: split.name, value: split };
    });
  }

  get winningSplitId() {
    return String(this.splitter.winningEdgeSplitId);
  }

  get statsParameters() {
    return makeStatsParametersForEdgeSplit(this.ruleset, this.selectedEdgeSplit);
  }

  @task
  *pickWinner(winningSplit) {
    let confirmed = yield this.intercomConfirmService.confirm({
      title: 'Are you sure?',
      body: `This Split Test will no longer be editable once a winner is declared.`,
    });

    if (confirmed) {
      this.splitter.winningEdgeSplitId = winningSplit.id;
      winningSplit.percentage = 100;
      this.splitter.edgeSplits.forEach((edgeSplit) => {
        if (edgeSplit.id !== winningSplit.id) {
          edgeSplit.percentage = 0;
        }
      });
      yield this.ruleset.save();
    }
  }
}
