/* import __COLOCATED_TEMPLATE__ from './content-review-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import type AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    contentType: number;
    contentId: number;
    listApi: AiContentLibraryApi;
    loadNextContent: (position: number) => void;
    loadContentForPosition: (position: number) => void;
    removeLastContent: () => void;
    onClose: () => void;
    reloadSummary: () => void;
    reloadPage: () => void;
  };
}

const ContentReviewModal = templateOnlyComponent<Signature>();
export default ContentReviewModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::ContentReviewModal': typeof ContentReviewModal;
  }
}
