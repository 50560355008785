/* import __COLOCATED_TEMPLATE__ from './messenger-app-inserter-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';

export default Component.extend({
  intercomEventService: service(),
  appService: service(),
  onboardingHomeExternalStepService: service(),
  app: readOnly('appService.app'),
  classNames: ['inbox__conversation-controls__inserter'],

  id: computed('partId', function () {
    let partId = this.partId;
    if (partId >= 0) {
      return `messenger-app-popover-${partId}`;
    }
    return null;
  }),

  actions: {
    insertMessengerCard(cardJSON) {
      this.get('controlsAPI.actions.insertBlock')('messengerCard', cardJSON);
    },
    onOpen(opener) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'messenger_app_inserter',
        conversation_id: this.get('controlsAPI.conversation.id'),
      });
    },
  },
});
