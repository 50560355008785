/* import __COLOCATED_TEMPLATE__ from './state-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
/*  */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { State } from 'embercom/models/data/matching-system/matching-constants';

interface Args {
  contentWrapper: ContentWrapper;
}

export default class StateCell extends Component<Args> {
  @service declare intl: IntlService;

  get text(): string {
    if (this.inUse) {
      return this.intl.t('ai-content-library.list.cells.states.in-use');
    } else {
      return this.intl.t('ai-content-library.list.cells.states.not-used');
    }
  }

  get inUse(): boolean {
    return this.args.contentWrapper.contents.firstObject!.state === State.Live;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Cells::StateCell': typeof StateCell;
  }
}
