/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { empty, or } from '@ember/object/computed';

export default Component.extend({
  nameIsEmpty: empty('appPackage.name'),
  descriptionIsEmpty: empty('appPackage.description'),
  hasCapabilities: or(
    'appPackage.inboxApp.enabled',
    'appPackage.messengerApp.availableInMessages',
    'appPackage.messengerApp.availableInConversations',
    'appPackage.messengerApp.availableInOperator',
    'appPackage.messengerApp.availableInMessengerHome',
  ),
});
