/* import __COLOCATED_TEMPLATE__ from './confirm-modal.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  options: {
    autofocus?: boolean;
    title?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    body?: string;
    bodyComponentName?: string;
    warning?: string;
    confirmContext?: any;
    invertButtonTypes?: boolean;
    primaryButtonType?: string;
    secondaryButtonType?: string;
    invertButtonActions?: boolean;
    distinguishCancelFromClose?: boolean;
    hideCancelButton?: boolean;
    shouldTrackAnalyticsEvents?: boolean;
    analyticsEventChannelName?: string;
  };
}

export default class ConfirmModal extends Component<Args> {
  @service intercomEventService: any;
  @service intl!: IntlService;
  @service intercomConfirmService: any;
  @service appService: any;

  get autofocus() {
    return this.args.options.autofocus ?? true;
  }

  get getPricingModelName() {
    if (this.appService.app.onPricing5) {
      return 'pricing5';
    }
    if (this.appService.app.hasValueBasedPricing2) {
      return 'vbp2';
    }
    if (this.appService.app.hasValueBasedPricing1) {
      return 'vbp1';
    }

    return 'other';
  }

  get shouldCancelButtonAutoFocus() {
    return this.autofocus && this.invertButtonTypes;
  }

  get shouldConfirmButtonAutoFocus() {
    return this.autofocus && !this.invertButtonTypes;
  }

  get title() {
    return this.args.options.title || this.intl.t('apps.app.confirm-modal.please-confirm');
  }

  get confirmButtonText() {
    return this.args.options.confirmButtonText || this.intl.t('apps.app.confirm-modal.ok');
  }

  get cancelButtonText() {
    return this.args.options.cancelButtonText || this.intl.t('apps.app.confirm-modal.cancel');
  }

  get body() {
    return this.args.options.body || this.intl.t('apps.app.confirm-modal.are-you-sure');
  }

  get bodyComponentName() {
    return this.args.options.bodyComponentName;
  }

  get warning() {
    return this.args.options.warning || '';
  }

  get confirmContext() {
    return this.args.options.confirmContext;
  }

  get invertButtonTypes() {
    return this.args.options.invertButtonTypes || false;
  }

  get primaryButtonType() {
    return this.args.options.primaryButtonType || 'primary';
  }

  get secondaryButtonType() {
    return this.args.options.secondaryButtonType || 'secondary';
  }

  get invertButtonActions() {
    return this.args.options.invertButtonActions || false;
  }

  get distinguishCancelFromClose() {
    return this.args.options.distinguishCancelFromClose || false;
  }

  get hideCancelButton() {
    return this.args.options.hideCancelButton || false;
  }

  get shouldTrackAnalyticsEvents() {
    return this.args.options.shouldTrackAnalyticsEvents || false;
  }

  get analyticsEventChannelName() {
    return this.args.options.analyticsEventChannelName || '';
  }

  get cancelButtonType() {
    return this.invertButtonTypes ? this.primaryButtonType : this.secondaryButtonType;
  }

  get confirmButtonType() {
    return this.invertButtonTypes ? this.secondaryButtonType : this.primaryButtonType;
  }

  @action confirm() {
    if (this.shouldTrackAnalyticsEvents) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'confirm',
        place: 'confirm-modal',
        channel_name: this.analyticsEventChannelName,
        pricing_model: this.getPricingModelName,
      });
    }

    if (this.invertButtonActions) {
      let rejection = this.distinguishCancelFromClose ? { canceled: true } : null;
      this.intercomConfirmService.reject(rejection);
    } else {
      this.intercomConfirmService.resolve();
    }
    this.intercomConfirmService.closeConfirm();
  }

  @action cancel() {
    if (this.shouldTrackAnalyticsEvents) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'cancel',
        place: 'confirm-modal',
        channel_name: this.analyticsEventChannelName,
        pricing_model: this.getPricingModelName,
      });
    }

    if (this.invertButtonActions) {
      this.intercomConfirmService.resolve();
    } else {
      let rejection = this.distinguishCancelFromClose ? { canceled: true } : null;
      this.intercomConfirmService.reject(rejection);
    }
    this.intercomConfirmService.closeConfirm();
  }

  @action closeModal() {
    if (this.shouldTrackAnalyticsEvents) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'close',
        place: 'confirm-modal',
        channel_name: this.analyticsEventChannelName,
        pricing_model: this.getPricingModelName,
      });
    }

    let rejection = this.distinguishCancelFromClose ? { closed: true } : null;
    this.intercomConfirmService.reject(rejection);
    this.intercomConfirmService.closeConfirm();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ConfirmModal: typeof ConfirmModal;
    'confirm-modal': typeof ConfirmModal;
  }
}
