/* import __COLOCATED_TEMPLATE__ from './show.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import reviewStateMachine from 'embercom/lib/developer-hub/review-state-machine';

export default class Show extends Component {
  @tracked showApplication = true;

  get shouldShowTabs() {
    let { displayChangeDiff } = reviewStateMachine(this.args.appPackage);
    return displayChangeDiff;
  }
}
