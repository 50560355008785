/* import __COLOCATED_TEMPLATE__ from './assignee-dropdown-with-fallback.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { senderTypes } from 'embercom/models/data/outbound/constants';

export default class AssigneeDropdownWithFallback extends Component {
  get isOwnerSelected() {
    return this.isOwnerWithAssigneeFallback || this.isOwnerWithCustomEmailAddressFallback;
  }

  get isOwnerWithAssigneeFallback() {
    return this.args.selectedValueType === senderTypes.owner;
  }

  get isOwnerWithCustomEmailAddressFallback() {
    return this.args.selectedValueType === senderTypes.ownerWithCustomEmailAddressFallback;
  }

  get selectedValue() {
    if (this.isOwnerSelected) {
      return '-1';
    } else if (this.args.selectedValueId) {
      return `${this.args.selectedValueId}:${this.args.selectedValueType}`;
    } else {
      return undefined;
    }
  }

  get selectedFallbackValue() {
    if (this.isOwnerSelected && this.args.selectedValueId) {
      let fallbackValueType = this.isOwnerWithAssigneeFallback
        ? senderTypes.admin
        : senderTypes.customEmailAddress;
      return `${this.args.selectedValueId}:${fallbackValueType}`;
    } else {
      return undefined;
    }
  }

  @action onSelectItem(newValue) {
    let [senderId, senderType] = newValue.split(':', 2);
    if (newValue === '-1') {
      if (this.args.selectedValueType === senderTypes.customEmailAddress) {
        this.args.onChangeType(senderTypes.ownerWithCustomEmailAddressFallback);
      } else {
        this.args.onChangeType(senderTypes.owner);
      }
    } else if (this.args.supportOldEditor) {
      this.args.onChangeId(newValue);
      this.args.onChangeType(Number(senderType));
    } else {
      this.args.onChangeId(Number(senderId));
      this.args.onChangeType(Number(senderType));
    }
  }

  @action onSelectFallbackItem(newValue) {
    let [senderId, senderType] = newValue.split(':', 2);
    if (this.args.supportOldEditor) {
      this.args.onChangeId(newValue);
      this.args.onChangeType(Number(senderType));
    } else {
      this.args.onChangeId(Number(senderId));
    }
    if (Number(senderType) === senderTypes.customEmailAddress) {
      this.args.onChangeType(senderTypes.ownerWithCustomEmailAddressFallback);
    } else {
      this.args.onChangeType(senderTypes.owner);
    }
  }
}
