/* import __COLOCATED_TEMPLATE__ from './fixed-schedule-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { recurringScheduleFrequencies } from 'embercom/models/data/matching-system/matching-constants';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { TIME_UNITS } from 'embercom/components/matching-system/audience-selector/over-messaging/send-frequency-limit';
import { isEmpty } from '@ember/utils';

interface Args {
  recurringSchedule: $TSFixMe;
}

interface Signature {
  Args: Args;
}

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MONTHS = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
} as const;

export default class FixedScheduleDropdown extends Component<Signature> {
  @tracked selectedDayOffset: number = this.recurringSchedule.dayOffset;
  @tracked selectedMonthOffset: keyof typeof MONTHS = this.recurringSchedule.monthOffset;
  @tracked selectedFrequency: number = this.recurringSchedule.frequency;

  get isWeeklySelected() {
    return this.selectedFrequency === recurringScheduleFrequencies.weekly;
  }

  get isMonthlySelected() {
    return this.selectedFrequency === recurringScheduleFrequencies.monthly;
  }

  get isYearlySelected() {
    return this.selectedFrequency === recurringScheduleFrequencies.yearly;
  }

  get maxDayOffsetInputValue() {
    let maxValue = 31;
    if (this.selectedMonthOffset === 2) {
      maxValue = 29;
    } else if ([4, 6, 9, 11].includes(this.selectedMonthOffset)) {
      maxValue = 30;
    }

    return maxValue;
  }

  get selectedDayOfWeek() {
    return DAYS[this.selectedDayOffset];
  }

  get selectedFrequencyText() {
    let text = `${TIME_UNITS[this.selectedFrequency]}`;
    if (this.isWeeklySelected && this.selectedDayOfWeek) {
      text += ` on ${this.selectedDayOfWeek}`;
    } else if (this.isMonthlySelected) {
      text += ` on day ${this.selectedDayOffset}`;
    } else if (this.isYearlySelected) {
      text += ` on ${MONTHS[this.selectedMonthOffset]} ${this.selectedDayOffset}`;
    }
    return text;
  }

  get recurringScheduleFrequencies() {
    return recurringScheduleFrequencies;
  }

  get recurringSchedule() {
    return this.args.recurringSchedule;
  }

  @action onSelectFrequency(frequency: number) {
    this.selectedFrequency = frequency;
    this.recurringSchedule.frequency = frequency;
    this.setFixedSchedule();
  }

  @action setFixedSchedule() {
    this.recurringSchedule.dayOffset = this.selectedDayOffset;

    if (this.isWeeklySelected) {
      if (this.recurringSchedule.dayOffset > 6) {
        this.recurringSchedule.dayOffset = 1;
        this.selectedDayOffset = 1;
      }
      this.recurringSchedule.monthOffset = null;
    } else if (this.isMonthlySelected) {
      this.recurringSchedule.monthOffset = null;
    } else if (isEmpty(this.recurringSchedule.monthOffset)) {
      this.recurringSchedule.monthOffset = 1;
    } else {
      this.recurringSchedule.monthOffset = this.selectedMonthOffset;
    }
  }

  @action onDaySelectionChange(day: number) {
    this.selectedDayOffset = day;
    this.recurringSchedule.dayOffset = day;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Panels::Schedule::FixedScheduleDropdown': typeof FixedScheduleDropdown;
  }
}
