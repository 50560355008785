/* import __COLOCATED_TEMPLATE__ from './past-conversation-modal.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { captureException } from '@sentry/browser';
import type IntlService from 'ember-intl/services/intl';
import { type ConversationContentAvailabilitySetting } from 'embercom/components/operator/fin/setup/content-sources';
import type ContentImportService from 'embercom/services/content-import-service';
import type FinConversationContent from 'embercom/services/fin-conversation-content';
import { post } from 'embercom/lib/ajax';
import { localCopy } from 'tracked-toolbox';

interface Args {
  modal: any;
  title?: string;
  onNavigationBack?: () => void;
  onNavigationNext?: (viewId?: string) => void;
  showBackButton?: boolean;
  onModalClose: () => void;
}

export default class PastConversationModal extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare contentImportService: ContentImportService;
  @service declare finConversationContent: FinConversationContent;
  @service declare notificationsService: any;

  @localCopy('contentImportService.finConversationContentSettings.enabled')
  declare conversationContentEnabled: boolean;
  @localCopy('contentImportService.finConversationContentSettings.immediatelyAvailableToFin')
  declare conversationContentImmediatelyAvailableToFin: boolean;
  @localCopy('contentImportService.finConversationContentSettings.adminIDs')
  declare conversationContentAdminIDs: number[];

  get htmlConversationContentAvailability(): ConversationContentAvailabilitySetting {
    return this.conversationContentImmediatelyAvailableToFin ? 'available' : 'not-available';
  }

  set htmlConversationContentAvailability(value: ConversationContentAvailabilitySetting) {
    this.conversationContentImmediatelyAvailableToFin = value === 'available';
  }

  get conversationContentAdminsLabel() {
    if (this.conversationContentAdminIDs.length > 0) {
      return this.intl.t(
        'operator.fin.setup.content-sources.conversation-content.admin-settings.label.selected',
        { num: this.conversationContentAdminIDs.length },
      );
    } else {
      return this.intl.t(
        'operator.fin.setup.content-sources.conversation-content.admin-settings.label.blank',
      );
    }
  }

  get conversationContentAdmins() {
    let selectedAdmins = this.conversationContentAdminIDs;
    let requiresInboxSeatAccess = this.appService.app.requiresInboxSeatAccess;

    let admins = requiresInboxSeatAccess
      ? this.appService.app.adminsWithInboxAccess
      : this.appService.app.humanAdmins;

    return admins.map((admin: any) => ({
      text: admin.name,
      value: Number(admin.id),
      isSelected: selectedAdmins.includes(Number(admin.id)),
    }));
  }

  get haveConversationContentSettingsChanged() {
    return (
      this.contentImportService.finConversationContentSettings &&
      this.conversationContentEnabled !==
        this.contentImportService.finConversationContentSettings.enabled
    );
  }

  get hasImmediatelyAvailableToFinChanged() {
    return (
      this.contentImportService.finConversationContentSettings &&
      this.conversationContentImmediatelyAvailableToFin !==
        this.contentImportService.finConversationContentSettings.immediatelyAvailableToFin
    );
  }

  get haveAdminsChanged() {
    if (this.contentImportService.finConversationContentSettings) {
      if (
        this.conversationContentAdminIDs.length !==
        this.contentImportService.finConversationContentSettings.adminIDs.length
      ) {
        return true;
      }

      for (let i = 0; i < this.conversationContentAdminIDs.length; i++) {
        if (
          this.conversationContentAdminIDs.includes(
            this.contentImportService.finConversationContentSettings.adminIDs[i],
          )
        ) {
          return true;
        }
      }

      return false;
    } else {
      return false;
    }
  }

  get shouldEnableSave() {
    return (
      this.haveConversationContentSettingsChanged ||
      this.hasImmediatelyAvailableToFinChanged ||
      this.haveAdminsChanged
    );
  }

  @action updateContentEnabled(isEnabled: boolean) {
    this.conversationContentEnabled = isEnabled;
  }

  @action updateConversationContentImmediatelyAvailableToFin(isImmediatelyAvailable: boolean) {
    this.conversationContentImmediatelyAvailableToFin = isImmediatelyAvailable;
  }

  @action conversationContentAdminsChanged(adminIDs: number[]) {
    this.conversationContentAdminIDs = adminIDs.map((id) => Number(id));
  }

  @action clearAllConversationContentAdmins() {
    this.conversationContentAdminIDs = [];
  }

  @action toggleConversationContent() {
    this.conversationContentEnabled = !this.conversationContentEnabled;
  }

  @action async updateConversationContentSettings(isEnabled: boolean) {
    if (isEnabled) {
      await this.enableConversationContent();
    } else {
      await this.disableConversationContent();
    }
  }

  @action async enableConversationContent() {
    try {
      await post('/ember/inbox/fin_conversation_content/enable', {
        app_id: this.appService.app.id,
      });
    } catch (e) {
      this.conversationContentEnabled = false;
      captureException(e);
      this.notificationsService.notifyError(
        this.intl.t('operator.fin.setup.content-sources.conversation-content.enable-failed'),
      );
    }
  }

  @action async disableConversationContent() {
    try {
      await post('/ember/inbox/fin_conversation_content/disable', {
        app_id: this.appService.app.id,
      });
    } catch (e) {
      this.conversationContentEnabled = true;
      captureException(e);
      this.notificationsService.notifyError(
        this.intl.t('operator.fin.setup.content-sources.conversation-content.disable-failed'),
      );
    }
  }

  @action async updateAdmins() {
    try {
      await post('/ember/inbox/fin_conversation_content/update_admins', {
        app_id: this.appService.app.id,
        admin_ids: this.conversationContentAdminIDs,
      });
    } catch (e) {
      this.conversationContentAdminIDs = this.contentImportService.finConversationContentSettings
        ? this.contentImportService.finConversationContentSettings.adminIDs
        : [];
      captureException(e);
      this.notificationsService.notifyError(
        this.intl.t('operator.fin.setup.content-sources.conversation-content.update-admins-failed'),
      );
    }
  }

  @action async updateImmediatelyAvailableToFin() {
    let endpoint = this.conversationContentImmediatelyAvailableToFin
      ? 'enable_immediately_available_to_fin'
      : 'disable_immediately_available_to_fin';

    let message = this.conversationContentImmediatelyAvailableToFin
      ? 'immediately-available'
      : 'not-immediately-available';

    try {
      await post(`/ember/inbox/fin_conversation_content/${endpoint}`, {
        app_id: this.appService.app.id,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          `operator.fin.setup.content-sources.conversation-content.${message}-toast-success`,
        ),
      );
    } catch (error) {
      captureException(error);
      this.notificationsService.notifyError(
        this.intl.t(
          `operator.fin.setup.content-sources.conversation-content.${message}-toast-failure`,
        ),
      );
    }
  }

  @action async onSave() {
    if (this.haveConversationContentSettingsChanged) {
      await this.updateConversationContentSettings(this.conversationContentEnabled);
    }

    if (this.hasImmediatelyAvailableToFinChanged) {
      await this.updateImmediatelyAvailableToFin();
    }

    if (this.haveAdminsChanged) {
      await this.updateAdmins();
    }

    await this.contentImportService.fetchFinConversationContentSettings();

    this.args.onModalClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::PastConversationModal': typeof PastConversationModal;
    'content-service/ai-content-library/past-conversation-modal': typeof PastConversationModal;
  }
}
