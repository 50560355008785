/* import __COLOCATED_TEMPLATE__ from './trigger-workflow-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import NoTriggerCustomBotWorkflowsResource from 'embercom/lib/operator/custom-bots/no-trigger-custom-bot-workflows-resource';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { useResource } from 'ember-resources';

export default class TriggerWorkflowEditor extends Component {
  @tracked workflowsResource = useResource(this, NoTriggerCustomBotWorkflowsResource, () => ({
    currentBotId: this.args.publicAPI.contentObject.id,
  }));

  constructor() {
    super(...arguments);

    this.workflowsResource.fetch.perform();
  }

  get hasWorkflows() {
    return this.workflowsResource.workflows.length > 0;
  }

  get isLoading() {
    return this.workflowsResource.isLoading;
  }

  get selectedValue() {
    return !this.isLoading && this.hasWorkflows ? this.args.step.triggerableWorkflowId : undefined;
  }

  get selectedWorkflow() {
    if (this.selectedValue) {
      return this.workflowsResource.workflows.find((wf) => wf.workflowId === this.selectedValue);
    }

    return undefined;
  }

  get hasError() {
    return this.args.publicAPI.shouldShowValidations && !this.args.step.triggerableWorkflowId;
  }

  @action
  async onOpen() {
    await this.workflowsResource.fetch.perform();

    // If the step has a workflow id that doesn't exist in the list of possible triggerable workflows,
    // ensure that no workflow is selected in the select component.
    // (This is defensive as embercom will crash if there is a selected value that isn't an option in our
    // select component).
    let possibleWorkflowIds = this.workflowsResource.workflows.map((wf) => wf.workflowId);
    if (!possibleWorkflowIds.includes(this.args.step.triggerableWorkflowId)) {
      this.args.publicAPI.actions.setTriggerableWorkflowId(this.args.step, undefined);
    }
  }

  @action
  onSelectItem(workflow) {
    this.args.publicAPI.actions.setTriggerableWorkflowId(this.args.step, workflow.workflowId);
  }
}
