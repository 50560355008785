/* import __COLOCATED_TEMPLATE__ from './preview-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import { isBlank, isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { isValidEmail } from 'embercom/lib/email';
import nonconcurrentAjax from 'embercom/lib/nonconcurrent-ajax';
import { action } from '@ember/object';
export default class PreviewButton extends Component {
  @tracked showPreviewModal = false;

  @service appService;
  @service contentEditorService;
  @service notificationsService;

  get emailAddresses() {
    return this.contentEditorService.previewEmailAddresses || '';
  }
  get app() {
    return this.appService.app;
  }

  get email() {
    return this.contentEditorService.activeObject;
  }

  get outboundEmailAddresses() {
    return this.emailAddresses.split(/,|\s+/).filter((emailAddress) => !isBlank(emailAddress));
  }

  get invalidEmailAddresses() {
    return this.outboundEmailAddresses.filter((emailAddress) => !isValidEmail(emailAddress));
  }

  async audienceCount() {
    let currentCount = this.contentEditorService.ruleset.audiencePreview?.count;
    if (Number.isInteger(currentCount)) {
      return currentCount;
    }

    await this.contentEditorService.ruleset.fetchAudiencePreview();
    return this.contentEditorService.ruleset.audiencePreview.count;
  }

  @action setPreviewUser(user) {
    this.contentEditorService.setPreviewUser(user);
  }

  @task
  *sendPreviewEmail() {
    try {
      if (isEmpty(this.invalidEmailAddresses)) {
        yield this.sendPreviewEmails();
        this.notificationsService.notifyConfirmation(`Your preview email has been sent`);
        this.showPreviewModal = false;
      } else {
        this.notificationsService.notifyError(
          `Some of your emails are invalid: ${this.invalidEmailAddresses.join(', ')}`,
        );
      }
    } catch (error) {
      if (error.jqXHR.status === 429) {
        this.notificationsService.notifyError(`Too many preview attempts, please try again later.`);
      } else {
        this.notificationsService.notifyResponseError(error, {
          default: `We couldn't send your preview email. Send us a message and we'll do our best to help out`,
        });
      }
      this.showPreviewModal = false;
    }
  }

  async sendPreviewEmails() {
    let payload = Object.assign(this.email.localizedEmailContents.firstObject.serialize(), {
      app_id: this.app.id,
      admin_id: this.app.currentAdmin.id,
      outbound_addresses: this.outboundEmailAddresses,
      email_id: this.email.id,
      show_unsubscribe_link: this.email.showUnsubscribeLink,
      audience_count: await this.audienceCount(),
      preview_user_id: this.contentEditorService.previewUser?.id,
      subscription_type_id: this.email.subscriptionTypeId,
      utm_settings: this.contentEditorService.ruleset.utmSettings?.serialize(),
      message_styles: {
        link_font_style: this.email.linkFontStyle,
        link_font_color: this.email.linkFontColor,
        link_font_weight: this.email.linkFontWeight,
        content_background_color: this.email.contentBackgroundColor,
        body_background_color: this.email.bodyBackgroundColor,
      },
    });

    return nonconcurrentAjax(this, {
      url: '/ember/preview_emails',
      type: 'POST',
      data: JSON.stringify(payload),
    });
  }
}
