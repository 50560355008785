/* import __COLOCATED_TEMPLATE__ from './completion-node.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';

export default class CompletionNode extends Component {
  get completionPercentage() {
    return (this.args.completions / this.args.views) * 100;
  }

  get roundCompletionPercentage() {
    return Math.round(this.completionPercentage) || 0;
  }
}
