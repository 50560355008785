/* import __COLOCATED_TEMPLATE__ from './additional-inserters.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ConversationControlsAdditionalInserters extends Component {
  @tracked selectedAdditionalInserter = null;

  @action
  updateSelectedAdditionalInserter(newSelection) {
    this.selectedAdditionalInserter = newSelection.name;
  }

  @action
  clearSelectedAdditionalInserter() {
    this.selectedAdditionalInserter = null;
  }
}
