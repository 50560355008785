/* import __COLOCATED_TEMPLATE__ from './submenu.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class Submenu extends Component {
  @service developerHub;
  @service appService;
  @service router;
  @service regionService;

  get isOnConfigure() {
    let { isOnBasicInfo, isOnApiVersion, isOnAuthentication, isOnCanvasKit, isOnWebhooks } =
      this.developerHub;
    return isOnBasicInfo || isOnApiVersion || isOnAuthentication || isOnCanvasKit || isOnWebhooks;
  }

  get isOnPublish() {
    let {
      isOnReview,
      isOnWorkspaces,
      isOnAppStoreListing,
      isOnGettingStartedGuide,
      isOnCustomerWorkspaces,
      isOnAppPartner,
    } = this.developerHub;

    return (
      isOnReview ||
      isOnWorkspaces ||
      isOnAppStoreListing ||
      isOnGettingStartedGuide ||
      isOnCustomerWorkspaces ||
      isOnAppPartner
    );
  }

  get isOnReports() {
    return this.developerHub.isOnReports;
  }

  get allowPublish() {
    return this.regionService.isUS || this.appService.app.isIntercom;
  }

  @action transitionToRoute(route) {
    this.router.transitionTo(route);
  }
}
