/* import __COLOCATED_TEMPLATE__ from './topics-table.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class TopicsTable extends Component {
  @service store;

  get tableData() {
    return this.args.webhookDefinition.topics
      .map((topic) => {
        let topicObject = this.store.peekRecord('developer-hub/webhook-topic', topic);
        return {
          name: topic,
          isUnavaliable: this.args.conflictingTopics.includes(topic),
          description: topicObject?.name,
          requiredScopes: topicObject?.requiredScopes,
          hasMissingScopes: topicObject?.missingRequiredScopes,
        };
      })
      .sort((a, b) => {
        if (a.isUnavaliable === b.isUnavaliable) {
          return 0;
        }
        return a.isUnavaliable ? -1 : 1;
      });
  }

  @action
  deleteTopic(deletedTopic) {
    this.args.webhookDefinition.topics = this.args.webhookDefinition.topics.filter((topic) => {
      return topic !== deletedTopic.name;
    });
  }
}
