/* import __COLOCATED_TEMPLATE__ from './cti-app-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type CtiService from 'embercom/services/cti-service';
import { inject as service } from '@ember/service';

export default class CtiAppButton extends Component {
  @service declare ctiService: CtiService;

  get buttonType() {
    return this.ctiService.activeCall ? 'activeCall' : 'call';
  }

  get buttonIcon() {
    return this.ctiService.appContainerVisible ? 'thin-down-arrow' : 'phone';
  }

  get isCtiAvailable() {
    return this.ctiService.ctiAvailable;
  }

  @action
  toggleContainerVisibility() {
    if (this.ctiService.appContainerVisible) {
      this.ctiService.hideAppContainer();
    } else {
      this.ctiService.showAppContainer();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cti::CtiAppButton': typeof CtiAppButton;
    'cti/cti-app-button': typeof CtiAppButton;
  }
}
