/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import Component from '@glimmer/component';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type AiContentSegment from 'embercom/models/ai-content-segment';
import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import Ruleset from 'embercom/models/matching-system/ruleset';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { tracked } from '@glimmer/tracking';
import { EVERYONE_PREDICATE } from 'embercom/lib/operator/custom-bots/constants';
import { action } from '@ember/object';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import { type EmberCPValidationsResult } from 'embercom/validations/typescript/ember-cp-validations-compat';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  onClose: () => void;
  sources: Array<ContentImportSource>;
  toggleSourceConfigurationDrawer: () => void;
  reloadPage: () => void;
}

export default class Editor extends Component<Args> {
  @service declare store: Store;
  @service declare appService: any;
  @service declare contentEditorService: any;
  @service declare aiContentSegmentsService: AiContentSegmentsService;
  @service declare permissionsService: $TSFixMe;
  @service declare intercomEventService: any;
  @service declare helpCenterService: any;

  @tracked selectedRuleset?: any;
  @tracked selectedSectionId = '';

  get segment(): AiContentSegment {
    return this.contentEditorService.activeObject as AiContentSegment;
  }

  get validations(): EmberCPValidationsResult {
    return this.segment.validations;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.aiContentSegmentsService.loadSegments();
  }

  get helpCenters(): HelpCenterSite[] {
    return this.helpCenterService.allSites;
  }

  @action exitEditMode() {
    this.aiContentSegmentsService.loadSegments();
    this.contentEditorService.unregister(this.selectedRuleset);
    this.selectedRuleset = undefined;
  }

  @action async onCancel() {
    await this.contentEditorService.cancelRulesetChanges.perform();

    this.exitEditMode();
  }

  @action async onSave() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    await this.contentEditorService.saveRuleset.perform();

    this.exitEditMode();
    this.trackAnalyticsEvent('clicked', 'save_button');
  }

  @action async onDelete() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    let segment = this.segment;
    await this.contentEditorService.deleteRuleset.perform({ transitionRoute: false });
    segment.unloadRecord();
    this.exitEditMode();
    this.trackAnalyticsEvent('clicked', 'delete_button');
  }

  get isLoading() {
    return (
      taskFor(this.aiContentSegmentsService.loadContentSegments).isRunning ||
      taskFor(this.selectSegment).isRunning ||
      taskFor(this.newSegment).isRunning
    );
  }

  @task *selectSegment(segment: AiContentSegment): TaskGenerator<void> {
    this.selectedRuleset = yield this.store.findRecord(
      'matching-system/ruleset',
      segment.rulesetId,
    );
    this.contentEditorService.register({
      ruleset: this.selectedRuleset,
      mode: 'edit',
    });
  }

  @task *newSegment(): TaskGenerator<void> {
    try {
      yield this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    let ruleset = yield Ruleset.createForType(this.store, {
      app_id: this.appService.app.id,
      object_type: objectTypes.aiContentSegment,
      match_behavior: matchBehaviors.transient,
      object_data: {},
      role_predicate_group: { predicates: [EVERYONE_PREDICATE] },
    });

    this.selectedRuleset = ruleset;
    this.contentEditorService.register({
      ruleset: this.selectedRuleset,
      mode: 'edit',
    });
    this.trackAnalyticsEvent('clicked', 'new_segment_button');
  }

  @action
  onOpenSectionChange(sectionId: string) {
    this.selectedSectionId = sectionId;
  }

  private trackAnalyticsEvent(action: string, object: string, metadata?: any): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'operator',
      context: 'manage_audience_sidedrawer',
      place: 'fin_content_page',
      ...metadata,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Segments::Editor': typeof Editor;
  }
}
