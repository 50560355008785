/* import __COLOCATED_TEMPLATE__ from './assignment.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { isNone } from '@ember/utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
// @ts-ignore
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';
import Admin from 'embercom/models/admin';
import AdminSummary from 'embercom/objects/inbox/admin-summary';
import TeamSummary from 'embercom/objects/inbox/team-summary';
import type Store from '@ember-data/store';

interface Assignment {
  assigneeId?: string;
  adminId?: string;
  teamId?: string;
}

interface Args {
  conversation: any;
  changeToNotePane: Function;
  assignee: Assignment;
  keyboardPriority: number;
}

export default class AssignmentComponent extends Component<Args> {
  @service conversationsService: any;
  @service intercomEventService: any;
  @service declare appService: any;
  @service declare store: Store;

  get app() {
    return this.appService.app;
  }

  get admin() {
    return this.app.currentAdmin;
  }

  get availableAdmins(): any[] {
    return this.args.conversation.assignedToOperator
      ? this.app.assignableAdminsAndOperator
      : this.app.assignableAdmins;
  }

  get isAdminAssignedToCurrentConversation(): boolean {
    return this.args.conversation.assignee === this.admin;
  }

  get canAdminReassignCurrentConversation(): boolean {
    return (
      this.args.conversation.assignee?.isTeam ||
      this.args.conversation.unassigned ||
      this.isAdminAssignedToCurrentConversation ||
      this.admin.currentAppPermissions.can_reassign_conversations
    );
  }

  get readOnly(): boolean {
    return !this.canAdminReassignCurrentConversation;
  }

  defaultTeammateAvatar = DefaultAvatars.admins[128];

  get assigneeValue(): string {
    let id = this.args.conversation.assignee_id;
    if (isNone(id) || id === '') {
      return '0';
    } else {
      return id;
    }
  }

  get adminAssigneeValue(): string {
    let id = this.args.conversation.admin_assignee_id;
    if (isNone(id) || id === '') {
      return '0';
    } else {
      return id;
    }
  }

  get teamAssigneeValue(): string {
    let id = this.args.conversation.team_assignee_id;
    if (isNone(id) || id === '') {
      return '0';
    } else {
      return id;
    }
  }

  trackAssignment({ assigneeId, adminId, teamId }: Assignment) {
    this.intercomEventService.trackAnalyticsEvent({
      assignee_id: assigneeId,
      admin_assignee_id: adminId,
      team_assignee_id: teamId,
      assignee_changed: assigneeId && assigneeId !== this.args.conversation.assignee_id,
      admin_assignee_changed: adminId && adminId !== this.args.conversation.admin_assignee_id,
      team_assignee_changed: teamId && teamId !== this.args.conversation.team_assignee_id,
      action: 'assigned_conversation',
      object: 'inbox_conversation_header',
      place: 'inbox',
      has_mcc: this.app.canAssignToTeamAndTeammate,
      conversation_id: this.args.conversation.id,
    });
  }

  @action assignConversation(assignment: Assignment | any) {
    if (this.app.canAssignToTeamAndTeammate) {
      let shouldCreatePart =
        assignment.adminId !== this.args.conversation.admin_assignee_id ||
        assignment.teamId !== this.args.conversation.team_assignee_id;
      if (shouldCreatePart) {
        this.trackAssignment(assignment);
        this.conversationsService.addPartAndSave(
          this.args.conversation,
          'assignment',
          [],
          null,
          assignment,
        );
        if (this.admin.id !== assignment.adminId) {
          this.args.changeToNotePane();
        }
      }
    } else if (assignment !== this.args.conversation.assignee_id) {
      let assignee = Admin.peekAndMaybeLoad(this.store, assignment);
      this.trackAssignment({ assigneeId: assignment });
      this.conversationsService.addPartAndSave(
        this.args.conversation,
        'assignment',
        [],
        null,
        assignee,
      );
      if (this.admin !== assignee) {
        this.args.changeToNotePane();
      }
    }
  }

  @action
  async commandKAssign(assignee: AdminSummary | TeamSummary) {
    if (assignee instanceof AdminSummary) {
      this.assignConversation({
        adminId: String(assignee.id),
        teamId: String(this.args.conversation.team_assignee_id),
      });
    } else if (assignee instanceof TeamSummary) {
      this.assignConversation({
        adminId: '0',
        teamId: String(assignee.id),
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Conversation::Shared::CardHeader::Assignment': typeof AssignmentComponent;
    'conversation/shared/card-header/assignment': typeof AssignmentComponent;
  }
}
