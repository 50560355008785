/* import __COLOCATED_TEMPLATE__ from './series-body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import {
  entryBehaviors,
  timezoneTypes,
} from 'embercom/models/data/matching-system/matching-constants';

interface Args {
  config: $TSFixMe;
}

interface Signature {
  Args: Args;
}

export default class Body extends Component<Signature> {
  @service declare contentEditorService: any;
  @service declare appService: any;
  @service declare seriesEditorService: any;

  timezoneTypes = timezoneTypes;
  entryBehaviors = entryBehaviors;

  get app() {
    return this.appService.app;
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get series() {
    return this.seriesEditorService.series;
  }

  @action toggleReEntry() {
    if (this.series.entryBehavior === entryBehaviors.singleEntry) {
      this.series.entryBehavior = entryBehaviors.multipleEntry;
    } else {
      this.series.entryBehavior = entryBehaviors.singleEntry;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Panels::Audience::SeriesBody': typeof Body;
    'content-editor/panels/audience/series-body': typeof Body;
  }
}
