/* import __COLOCATED_TEMPLATE__ from './match-behavior-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    ruleset: $TSFixMe;
  };
}

const MatchBehaviorSwitcher = templateOnlyComponent<Signature>();
export default MatchBehaviorSwitcher;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::AudienceWrappers::Standard::MatchBehaviorSwitcher': typeof MatchBehaviorSwitcher;
    'content-editor/audience-wrappers/standard/match-behavior-switcher': typeof MatchBehaviorSwitcher;
  }
}
