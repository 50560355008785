/* import __COLOCATED_TEMPLATE__ from './quiet-hours-toggle.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import TimesByHalfHour from 'embercom/models/data/times-by-half-hour';

export default class FrequencyEditor extends Component {
  @service contentEditorService;
  @service store;
  @tracked startTime;
  @tracked endTime;

  constructor() {
    super(...arguments);
    this.fetchQuietHours.perform();
  }

  @task *fetchQuietHours() {
    let smsOutboundSettings = yield this.store.findAll('sms-outbound-settings');
    if (smsOutboundSettings.length > 0) {
      let startAndEndInterval = smsOutboundSettings.firstObject.startAndEndInterval;
      this.startTime = TimesByHalfHour.find(
        (time) => time.value === startAndEndInterval['startMinute'],
      )?.text;
      this.endTime = TimesByHalfHour.find(
        (time) => time.value === startAndEndInterval['endMinute'],
      )?.text;
    }
  }

  get isSms() {
    return this.contentEditorService.configRulesetLink?.isSms;
  }

  get object() {
    return this.contentEditorService.configRulesetLink?.object;
  }
}
