/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { action } from '@ember/object';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import AttributeInfoResolver, {
  APP_NAME_ATTRIBUTE,
} from 'embercom/lib/common/template-attribute-resolver';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { generateShareableUrl } from 'embercom/models/checklists/checklist';
import { isBlank } from '@ember/utils';

class ComposerConfig extends BaseConfig {
  autoFocus = false;
  textDirection = 'ltr';
  allowedBlocks = ['paragraph'];
  allowedInline = [];
  allowTextAlignment = false;
  singleBlockMode = true;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  experimental = {
    hideInsertersOnMouseOut: true,
  };

  constructor({ resolver, placeholder }) {
    super();
    this.templating = { picker: 'common/attribute-picker', resolver };
    this.placeholder = placeholder;
  }
}

export default class ChecklistSharingBody extends Component {
  @service intl;
  @service contentEditorService;
  @service store;
  @service attributeService;
  @service appService;

  @tracked selectedSharingMethod = 'url';
  @tracked urlBlocksDoc;

  constructor() {
    super(...arguments);
    this.urlBlocksDoc = new BlocksDocument(this.renderableShareableUrl);
  }

  get app() {
    return this.appService.app;
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get rulesetId() {
    return this.ruleset.id;
  }

  get generatedLinkOrPlaceholder() {
    if (this.generatedShareableUrl) {
      return this.generatedShareableUrl;
    }

    return this.intl.t(
      'outbound.content-editor.panels.checklist-sharing.your-link-will-appear-here',
    );
  }

  get shareableUrlIsBlank() {
    return isBlank(this.shareableUrl);
  }

  get javascriptCodeSnippet() {
    return `Intercom('startChecklist', ${this.rulesetId});`;
  }

  get renderableShareableUrl() {
    return [
      {
        type: 'paragraph',
        text: this.ruleset.clientData.shareableUrl
          ? this.escapedHtmlText(this.ruleset.clientData.shareableUrl)
          : '',
      },
    ];
  }

  escapedHtmlText(text) {
    let pre = document.createElement('pre');
    let textNode = document.createTextNode(text);
    pre.appendChild(textNode);
    return pre.innerHTML;
  }

  get generatedShareableUrl() {
    let url = this.ruleset.clientData.shareableUrl;
    return generateShareableUrl(url, this.rulesetId);
  }

  get urlComposerConfig() {
    return new ComposerConfig({
      resolver: this.resolver,
      placeholder: this.intl.t('outbound.content-editor.panels.checklist-sharing.url-placeholder'),
    });
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.tourUrlComposerAttributes,
      manualAppAttributes: [APP_NAME_ATTRIBUTE],
    });
  }

  get shareableUrl() {
    return this.contentEditorService.ruleset.clientData.shareableUrl;
  }

  @action updateShareableUrl(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    let blockText = blockFragments.firstObject.text;
    let textContent = htmlToTextContent(blockText);
    blockText = textContent.trim();
    this.contentEditorService.ruleset.clientData.shareableUrl = blockText;
    this.urlBlocksDoc = blocksDoc;
  }
}
