/* import __COLOCATED_TEMPLATE__ from './dropdown-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
// eslint-disable-next-line @intercom/intercom/no-legacy-dropdown
import ComponentDropdownToggler from 'embercom/components/mixins/component-dropdown-toggler';

export default Component.extend(ComponentDropdownToggler, {
  title: 'title missing',
  tagName: 'span',
  classNames: ['u__relative', 'u__left', 'test__dropdown-component'],
});
