/* import __COLOCATED_TEMPLATE__ from './conversation-part-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { computed } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({
  tagName: '',

  supportStickers: computed('conversation.{isTwitter,isFacebook}', function () {
    return !this.get('conversation.isTwitter') && !this.get('conversation.isFacebook');
  }),

  componentPath: computed('part.{isUserPart,partType}', 'supportStickers', function () {
    let renderedPartType = this.get('part.partType');

    if (
      this.app?.canAssignToTeamAndTeammate &&
      this.part.partType === 'action' &&
      this.part.sub_type === 'assignment' &&
      this.part.event_data?.template
    ) {
      return 'conversation/stream/generic-event-component';
    }

    if (renderedPartType === 'eventWithoutBody') {
      return 'conversation/stream/generic-event-component';
    }

    if (this.part.isSideConversationRepliedEvent) {
      return 'conversation/stream/generic-event-component';
    }

    if (renderedPartType === 'sticker' && !this.supportStickers) {
      renderedPartType = 'comment';
    }

    let ownerType = this.get('part.isUserPart') ? 'user' : 'admin';
    return `conversation/stream/${ownerType}-${renderedPartType}-component`;
  }),
});
