/* import __COLOCATED_TEMPLATE__ from './show-until.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  matchBehaviors,
  objectNotificationPreferences,
} from 'embercom/models/data/matching-system/matching-constants';

export default class ShowUntil extends Component {
  @service contentEditorService;

  groupList = [
    {
      items: [
        {
          text: 'Seen',
          icon: 'visible',
          description: 'Show once, then not again.',
          value: objectNotificationPreferences.seen,
        },
        {
          text: 'Any interaction happens',
          icon: 'cursor',
          description: 'Show until engaged with, opened or dismissed.',
          value: objectNotificationPreferences.interactedWith,
        },
        {
          text: 'Engaged with',
          icon: 'button-pill',
          description: 'Show until engaged with. If dismissed, show again the next visit.',
          value: objectNotificationPreferences.engagedWith,
        },
      ],
    },
  ];

  get isCustomBotWithTrigger() {
    return (
      this.contentEditorService.configRulesetLink?.isOutboundCustomBot &&
      (this.contentEditorService.ruleset?.hasRulesetTriggers ||
        this.contentEditorService.ruleset?.hasRecurringSchedule)
    );
  }

  get selectedValue() {
    return this.contentEditorService.activeObject?.showUntil;
  }

  @action
  onSelectItem(value) {
    this.contentEditorService.activeObject.showUntil = value;
    this.contentEditorService.ruleset.matchBehavior = this.matchBehavior();

    // Match spacing periods don't work with bot re-notification so remove them.
    if (
      value === objectNotificationPreferences.interactedWith ||
      value === objectNotificationPreferences.engagedWith
    ) {
      this.contentEditorService.ruleset.matchSpacingPeriod = -1;
    }
  }

  matchBehavior() {
    let useMultiMatchBehavior = this.contentEditorService.ruleset.rulesetLinks.any(
      (rulesetLink) =>
        rulesetLink.object.showUntil === objectNotificationPreferences.interactedWith ||
        rulesetLink.object.showUntil === objectNotificationPreferences.engagedWith,
    );

    if (useMultiMatchBehavior) {
      return matchBehaviors.multi;
    }

    return matchBehaviors.single;
  }
}
