/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { isNone } from '@ember/utils';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  store: service(),

  classNameBindings: ['backgroundClass'],

  didInsertElement() {
    this._super(...arguments);
    if (this.get('app.currentAdminHasInboxAccess') && isNone(this.background)) {
      this.loadBackgroundFromTeammateAppSettings.perform();
    }
  },

  backgroundClass: computed('background', 'app.currentAdminHasInboxAccess', function () {
    if (this.get('app.currentAdminHasInboxAccess') && this.background) {
      return `conversation__outlet__inner__background o__${this.background}`;
    }
    return '';
  }),

  loadBackgroundFromTeammateAppSettings: task(function* () {
    let teammateAppSetting = yield this.store.findRecord(
      'admin-teammate-app-settings',
      this.get('admin.id'),
      { backgroundReload: false },
    );
    this.set('background', teammateAppSetting.get('adminMessengerBackground'));
  }).drop(),
});
