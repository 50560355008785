/* import __COLOCATED_TEMPLATE__ from './completion-stat-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { makeStatsParametersForRulesetLink } from 'embercom/helpers/stats-system/stats-parameters';

export default class CompletionStatList extends Component {
  @service contentEditorService;

  @action
  changeFilter(_, filter) {
    this.contentEditorService.changeActiveFilterTo(filter);
  }

  get statsParameters() {
    let ruleset = this.contentEditorService.ruleset;
    let rulesetLink = this.contentEditorService.activeRulesetLink;
    return makeStatsParametersForRulesetLink(ruleset, rulesetLink);
  }
}
