/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import type IntlService from 'embercom/services/intl';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { Scope, PERMISSIONS } from 'embercom/objects/content-service/saved-views/constants/scopes';
import type Store from '@ember-data/store';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';

interface Args {
  routeAfterDelete: string;
  scope: Scope;
}

export default class List extends Component<Args> {
  @service declare store: Store;
  @service router: any;
  @service notificationsService: any;
  @service declare intl: IntlService;
  @service permissionsService: any;
  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare intercomConfirmService: IntercomConfirmService;

  OUTBOUND_SCOPE = Scope.OUTBOUND;

  constructor(owner: unknown, args: any) {
    super(owner, args);
  }

  get app() {
    return this.appService.app;
  }

  get orderedViews() {
    let views = this.store.peekAll('content-service/saved-view').filter((view: any) => {
      return view.scope === this.args.scope;
    });
    return views?.sortBy('name');
  }

  @task
  *deleteView(savedView: any): TaskGenerator<void> {
    let confirmed = yield taskFor(this.confirmDeleteView).perform();

    if (!confirmed) {
      return;
    }

    try {
      yield savedView.destroyRecord();

      if (this.currentRouteViewId === savedView.id) {
        this.router.transitionTo(this.args.routeAfterDelete);
      }

      this.notificationsService.notifyConfirmation(
        this.intl.t('content-service.saved-views.deletion-modal.success'),
      );

      this.intercomEventService.trackAnalyticsEvent({
        action: 'deleted',
        object: 'saved_view',
      });
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('content-service.saved-views.deletion-modal.error'),
      );
    }
  }

  @task
  *confirmDeleteView(): TaskGenerator<void> {
    return yield this.intercomConfirmService.confirm({
      title: this.intl.t('content-service.saved-views.deletion-modal.title'),
      body: this.intl.t('content-service.saved-views.deletion-modal.body'),
      confirmButtonText: this.intl.t('content-service.saved-views.deletion-modal.confirm-button'),
    });
  }

  get currentRouteViewId() {
    return this.router.currentRoute?.attributes?.viewId;
  }

  get canEditSavedViews() {
    return this.permissionsService.currentAdminCan(PERMISSIONS[this.args.scope].edit);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::SavedViews::List': typeof List;
    'content-service/saved-views/list': typeof List;
  }
}
