/* import __COLOCATED_TEMPLATE__ from './mobile-preview-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class MobilePreviewButton extends Component {
  @service contentEditorService;
  @tracked showPreviewModal = false;

  @action
  togglePreviewModal() {
    this.showPreviewModal = !this.showPreviewModal;
  }
}
