/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { action } from '@ember/object';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import AttributeInfoResolver, {
  APP_NAME_ATTRIBUTE,
} from 'embercom/lib/common/template-attribute-resolver';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { generateShareableUrl, getSurveyUrlParam } from 'embercom/models/surveys/survey';
import SurveyEmbeddedTemplate from 'embercom/lib/survey-embedded-snippet-helper';
import { surveyFormats } from 'embercom/models/data/survey/constants';

class ComposerConfig extends BaseConfig {
  autoFocus = false;
  textDirection = 'ltr';
  allowedBlocks = ['paragraph'];
  allowedInline = [];
  allowTextAlignment = false;
  singleBlockMode = true;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  placeholder = 'Enter a URL';
  experimental = {
    hideInsertersOnMouseOut: true,
  };

  constructor({ resolver }) {
    super();
    this.templating = { picker: 'common/attribute-picker', resolver };
  }
}

export default class SurveySharingBody extends Component {
  @service intl;
  @service contentEditorService;
  @service store;
  @service attributeService;
  @service appService;

  @tracked selectedSharingMethod = 'url';
  @tracked urlBlocksDoc;

  constructor() {
    super(...arguments);
    this.urlBlocksDoc = new BlocksDocument(this.renderableShareableUrl);
  }

  get app() {
    return this.appService.app;
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get rulesetId() {
    return this.ruleset.id;
  }

  get javascriptCodeSnippet() {
    return `Intercom('startSurvey', ${this.rulesetId});`;
  }

  get copyLinkNotification() {
    this.intl.t('outbound.surveys.sharing.link.copy-link-confirmation');
  }

  get surveyUrlParam() {
    let urlHasOtherParams = this.ruleset.clientData.shareableUrl?.indexOf('?') > -1;
    return getSurveyUrlParam(urlHasOtherParams, this.rulesetId);
  }

  get survey() {
    return this.ruleset.rulesetLinks.firstObject.object;
  }

  get surveyFirstQuestion() {
    let firstQuestion = this.survey.steps.firstObject.orderedQuestions.firstObject;

    return firstQuestion;
  }

  get surveyHtmlSnippet() {
    if (this.canCreateHtmlSnippet && this.validEmbeddedUrl) {
      let generatedSurveySnippet = new SurveyEmbeddedTemplate({
        baseUrl: this.validEmbeddedUrl,
        survey: this.survey,
      });

      if (this.survey.format === surveyFormats.banner) {
        return generatedSurveySnippet.bannerFormatSurvey;
      }

      if (this.survey.format === surveyFormats.post) {
        return generatedSurveySnippet.postFormatSurvey;
      }
    }

    return this.intl.t('outbound.surveys.sharing.email-embedded.no-shareable-link');
  }

  get objectiveCCodeSnippet() {
    return `[Intercom presentSurvey:@"${this.rulesetId}"];`;
  }

  get swiftCodeSnippet() {
    return `Intercom.presentSurvey("${this.rulesetId}")`;
  }

  get androidCodeSnippet() {
    return `Intercom.client().displaySurvey("${this.rulesetId}")`;
  }

  get generatedShareableUrl() {
    let url = this.ruleset.clientData.shareableUrl;
    return generateShareableUrl(url, this.rulesetId);
  }

  get renderableShareableUrl() {
    return [
      {
        type: 'paragraph',
        text: this.ruleset.clientData.shareableUrl
          ? this.escapedHtmlText(this.ruleset.clientData.shareableUrl)
          : '',
      },
    ];
  }

  escapedHtmlText(text) {
    let pre = document.createElement('pre');
    let textNode = document.createTextNode(text);
    pre.appendChild(textNode);
    return pre.innerHTML;
  }

  get urlComposerConfig() {
    return new ComposerConfig({ resolver: this.resolver });
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.tourUrlComposerAttributes,
      manualAppAttributes: [APP_NAME_ATTRIBUTE],
    });
  }

  get shareableUrl() {
    return this.contentEditorService.ruleset.clientData.shareableUrl;
  }

  get canCreateHtmlSnippet() {
    let isFirstStepQuestionType = this.survey.firstStep.isOrdered;
    let doesStepHaveQuestion = Boolean(this.survey.firstStep.nonDeletedQuestions.length);

    if (!isFirstStepQuestionType || this.survey.hasIntroStep || !doesStepHaveQuestion) {
      return false;
    }

    return this.survey.firstStep.nonDeletedQuestions.firstObject.canBeEmbedded;
  }

  get validEmbeddedUrl() {
    return this.ruleset.clientData.shareableUrl;
  }

  get embeddableSnippetAnalyticsEvent() {
    return { place: 'survey_editor', object: 'survey_copy_embeddable_snippet' };
  }

  @action updateShareableUrl(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    let blockText = blockFragments.firstObject.text;
    let textContent = htmlToTextContent(blockText);
    blockText = textContent.trim();
    this.contentEditorService.ruleset.clientData.shareableUrl = blockText;
    this.urlBlocksDoc = blocksDoc;
  }
}
