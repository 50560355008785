/* import __COLOCATED_TEMPLATE__ from './frequency-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import {
  matchBehaviors,
  objectNotificationPreferences,
  recurringScheduleFrequencies,
  recurringScheduleTypes,
  type timezoneTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';

interface Args {
  ruleset: $TSFixMe;
  forceVisible?: boolean;
  timezoneType: ValueOf<typeof timezoneTypes>;
}

interface Signature {
  Args: Args;
}

export default class FrequencyEditor extends Component<Signature> {
  @service declare contentEditorService: any;
  @service declare store: Store;
  @service declare seriesEditorService: any;
  @service declare appService: any;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  get attributeBasedRecurringSchedule() {
    return this.recurringSchedule?.attributeBased;
  }

  get isBanner() {
    return this.contentEditorService.configRulesetLink?.isBanner;
  }

  get isOutboundCustomBot() {
    return this.contentEditorService.configRulesetLink?.isOutboundCustomBot;
  }

  get calendarBasedRecurringSchedule() {
    return this.recurringSchedule?.calendarBased;
  }

  get matchBehaviors() {
    return matchBehaviors;
  }

  get recurringSchedule() {
    return this.ruleset.recurringSchedule;
  }

  get ruleset() {
    return this.args.ruleset;
  }

  get rulesetTrigger() {
    return this.ruleset.rulesetTriggers.firstObject;
  }

  get trigger() {
    if (this.rulesetTrigger) {
      return this.rulesetTrigger;
    } else if (this.recurringSchedule) {
      return this.recurringSchedule;
    }
  }

  get series() {
    return this.seriesEditorService.series;
  }

  get triggerBased() {
    return isPresent(this.rulesetTrigger) || this.attributeBasedRecurringSchedule;
  }

  get calendarBasedMultiMatch() {
    let behavior: string | number = '';
    if (this.calendarBasedRecurringSchedule) {
      behavior = this.matchBehaviors.multi;
    }

    return behavior;
  }

  get fixedScheduleMultiMatch() {
    let behavior: string | number = '';
    if (!this.calendarBasedRecurringSchedule) {
      behavior = this.matchBehaviors.multi;
    }
    return behavior;
  }

  get showMaxMatchCount() {
    if (this.nonEventTriggeredBanner) {
      return false;
    }

    if (this.renotifiableCustomBot) {
      return false;
    }

    return !(this.recurringSchedule && this.calendarBasedRecurringSchedule);
  }

  get showSendFrequencyLimit() {
    if (this.nonEventTriggeredBanner) {
      return false;
    }

    if (this.renotifiableCustomBot) {
      return false;
    }

    return !this.recurringSchedule;
  }

  get nonEventTriggeredBanner() {
    return this.isBanner && !isPresent(this.rulesetTrigger);
  }

  get renotifiableCustomBot() {
    return (
      this.isOutboundCustomBot &&
      this.contentEditorService.activeObject?.showUntil !== objectNotificationPreferences.seen
    );
  }

  get fixedScheduleDisabledTooltipText() {
    if (this.renotifiableCustomBot) {
      return 'You can only select this option when "show bot until; seen" is selected';
    } else {
      return 'You can’t select this option when event rules are added';
    }
  }

  get frequecyEditorTextContent() {
    if (this.ruleset.isMemberOfSeries) {
      return {
        heading: this.intl.t('content-editor.panels.schedule.frequency-editor.series.heading'),
        singleMatch: this.intl.t(
          'content-editor.panels.schedule.frequency-editor.series.singleMatch',
        ),
        multiMatch: this.intl.t(
          'content-editor.panels.schedule.frequency-editor.series.multiMatch',
        ),
        multiMatchMaxMatch: this.intl.t(
          'content-editor.panels.schedule.frequency-editor.series.multiMatchMaxMatch',
        ),
        scheduleBased: this.intl.t(
          'content-editor.panels.schedule.frequency-editor.series.scheduleBased',
        ),
        calendarBasedRecurringSchedule: this.intl.t(
          'content-editor.panels.schedule.frequency-editor.series.calendarBasedRecurringSchedule',
        ),
      };
    } else {
      return {
        heading: this.intl.t('content-editor.panels.schedule.frequency-editor.outbound.heading'),
        singleMatch: this.intl.t(
          'content-editor.panels.schedule.frequency-editor.outbound.singleMatch',
        ),
        multiMatch: this.isBanner
          ? this.intl.t(
              'content-editor.panels.schedule.frequency-editor.outbound.multiMatchDismissable',
            )
          : this.intl.t('content-editor.panels.schedule.frequency-editor.outbound.multiMatch'),
        multiMatchMaxMatch: this.intl.t(
          'content-editor.panels.schedule.frequency-editor.outbound.multiMatchMaxMatch',
        ),
        scheduleBased: this.intl.t(
          'content-editor.panels.schedule.frequency-editor.outbound.scheduleBased',
        ),
        calendarBasedRecurringSchedule: this.intl.t(
          'content-editor.panels.schedule.frequency-editor.outbound.calendarBasedRecurringSchedule',
        ),
      };
    }
  }

  @action initializeFixedSchedule() {
    this.ruleset.matchBehavior = this.matchBehaviors.multi;
    this.addCalendarBasedRecurringSchedule();
    this.resetRulesetSpacingPeriod();
    this.ruleset.matchingTimetable = null;
  }

  @action addCalendarBasedRecurringSchedule() {
    this.ruleset.recurringSchedule = this.store.createFragment(
      'matching-system/recurring-schedule',
      {
        rulesetId: this.ruleset.id,
        scheduleType: recurringScheduleTypes.calendarBased,
        frequency: recurringScheduleFrequencies.weekly,
        dayOffset: 1,
        monthOffset: null,
      },
    );
  }

  @action setSpacingPeriod(periodInSeconds: number) {
    if (!this.ruleset.isDeleted) {
      this.ruleset.matchSpacingPeriod = periodInSeconds;
    }
  }

  @action setMaxMatchCount(maxMatchCount: number) {
    this.ruleset.maxMatchCount = maxMatchCount;
  }

  @action resetRulesetSpacingPeriod() {
    this.setSpacingPeriod(-1);
    this.setMaxMatchCount(-1);
  }

  @action resetFrequencyAndSchedule() {
    this.resetRulesetSpacingPeriod();
    if (this.calendarBasedRecurringSchedule) {
      this.deleteRecurringSchedule();
    }
  }

  @action setSendOnce() {
    this.resetFrequencyAndSchedule();
    this.ruleset.matchBehavior = this.matchBehaviors.single;
  }

  @action deleteRecurringSchedule() {
    this.ruleset.recurringSchedule = null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Panels::Schedule::FrequencyEditor': typeof FrequencyEditor;
  }
}
