/* import __COLOCATED_TEMPLATE__ from './completed-stat-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  makeStatsParametersForRulesetLink,
  makeStatsParametersForChecklistStep,
} from 'embercom/helpers/stats-system/stats-parameters';
import { tracked } from '@glimmer/tracking';

export default class CompletedStatList extends Component {
  @service contentEditorService;
  @service appService;

  @tracked statsParameters;

  constructor() {
    super(...arguments);

    this.statsParameters = this.createStatParameters(this.contentEditorService.range);
  }

  get checklist() {
    return this.contentEditorService.activeObject;
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get rulesetLink() {
    return this.contentEditorService.rulesetLink;
  }

  @action
  changeFilter(_, filter) {
    this.contentEditorService.changeActiveFilterTo(filter);
    this.statsParameters = this.createStatParameters(this.contentEditorService.range);
  }

  @action updateDateRange(range) {
    this.contentEditorService.updateRange(range);
    this.statsParameters = this.createStatParameters(range);
  }

  createStatParameters(range) {
    let activeFilter = Number(this.contentEditorService.activeFilter);

    if (activeFilter && activeFilter > 0) {
      let task = this.checklist.tasks.find((task) => Number(task.id) === activeFilter);

      return makeStatsParametersForChecklistStep(
        task,
        this.ruleset,
        this.rulesetLink,
        range,
        this.appService.app.timezone,
      );
    }

    return makeStatsParametersForRulesetLink(
      this.ruleset,
      this.rulesetLink,
      range,
      this.appService.app.timezone,
    );
  }
}
