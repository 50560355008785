/* import __COLOCATED_TEMPLATE__ from './button-bar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-jquery */
import Component from '@glimmer/component';
import $ from 'jquery';
import { debounce, scheduleOnce } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { classify } from '@ember/string';
import { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import { action } from '@ember/object';

const inserters = [
  {
    name: 'article',
    icon: 'article',
    descriptor: 'Add an article',
    hasPopoverContentComponent: true,
  },
  {
    name: 'saved-reply',
    icon: 'saved-reply',
    descriptor: 'Add a macro',
    hasPopoverContentComponent: true,
  },
  {
    name: 'emoji',
    icon: 'lwr-happy',
    descriptor: 'Add an emoji',
    hasPopoverContentComponent: true,
  },
  {
    name: 'gif',
    icon: 'gif',
    descriptor: 'Add a GIF',
    hasPopoverContentComponent: true,
  },
  {
    name: 'image',
    icon: 'picture',
    descriptor: 'Add an image',
    hasPopoverContentComponent: false,
  },
  {
    name: 'attachment',
    icon: 'attachment',
    descriptor: 'Attach a file',
    hasPopoverContentComponent: false,
  },
];

export default class ButtonBarComponent extends Component {
  @service appService;

  @tracked displayInserterOverflow = false;
  @tracked displayableInserterCount = 0;
  visibleButtonEmoji = true;

  get app() {
    return this.appService.app;
  }

  get inConversationCard() {
    return !this.args.controlsAPI.isInbox;
  }

  get replySelected() {
    return this.args.controlsAPI.activePaneIdentifier === 'comment';
  }

  get noteSelected() {
    return this.args.controlsAPI.activePaneIdentifier === 'note';
  }

  get visibleInserters() {
    return inserters.filter((inserter) => this[`visibleButton${classify(inserter.name)}`]);
  }

  get shownInserters() {
    return this.visibleInserters.slice(0, this.displayableInserterCount);
  }

  get hiddenInserters() {
    return this.visibleInserters.slice(this.displayableInserterCount);
  }

  get isNotSocial() {
    return this.args.controlsAPI.conversation.isNotSocial;
  }

  get visibleButtonArticle() {
    return (
      this.visibleButtonReplyConditions &&
      this.isNotSocial &&
      !this.args.controlsAPI.conversation.isDraft
    );
  }

  get visibleButtonSavedReply() {
    return (
      (this.replySelected || this.noteSelected) &&
      this.app.inboxIsActive &&
      this.app.currentAdminHasInboxAccess
    );
  }

  get noteOrNotSocial() {
    return this.noteSelected || this.isNotSocial;
  }

  get canSupportImage() {
    return (
      !this.args.controlsAPI.conversation.isFacebook &&
      !this.args.controlsAPI.conversation.isTwitter &&
      !this.args.controlsAPI.conversation.isInstagram &&
      !this.args.controlsAPI.conversation.isSms
    );
  }

  get noteOrCanSupportImage() {
    return this.noteSelected || this.canSupportImage;
  }

  get isWhatsAppConversation() {
    return this.args.controlsAPI.conversation.isWhatsapp;
  }

  get visibleButtonImage() {
    return this.app.teammateConversationAttachmentsEnabled && this.noteOrCanSupportImage;
  }

  get visibleButtonAttachment() {
    if (!this.app.teammateConversationAttachmentsEnabled) {
      return false;
    }

    return this.isWhatsAppConversation || this.noteOrNotSocial;
  }

  get visibleButtonGif() {
    return this.app.teammateConversationGifsEnabled && this.noteOrNotSocial;
  }

  get visibleButtonReplyConditions() {
    return this.replySelected && this.app.inboxIsActive && this.app.currentAdminHasInboxAccess;
  }

  get shouldAllowMessengerApps() {
    return (
      this.visibleButtonReplyConditions &&
      this.args.controlsAPI.conversation.isChannelMessengerOrEmail
    );
  }

  calculateLayout() {
    let totalWidth = $('.js__conversation-controls__info-area').width();
    let buttonsWidth = $('.js__conversation-controls-buttons').outerWidth(true);
    let inserterWidth = $('.inbox__conversation-controls__inserter').outerWidth(true);
    let visibleInserterWidth = this.visibleInserters.length * inserterWidth;
    let availableWidth = totalWidth - buttonsWidth - 2;

    if (availableWidth < visibleInserterWidth) {
      availableWidth -= inserterWidth;
      let displayableInserterCount = Math.floor(availableWidth / inserterWidth);
      this.displayInserterOverflow = true;
      this.displayableInserterCount = displayableInserterCount;
    } else {
      this.displayInserterOverflow = false;
      this.displayableInserterCount = this.visibleInserters.length;
    }
  }

  @action
  scheduleCalculateLayout() {
    scheduleOnce('afterRender', this, this.calculateLayout);
  }

  @action
  resize() {
    debounce(this, this.calculateLayout, ENV.APP._200MS);
  }
}
