/* import __COLOCATED_TEMPLATE__ from './composer-disabled-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class ComposerDisabledSelector extends Component {
  get composerDisabled() {
    return this.args.botData.composerDisabled;
  }

  @action
  onChange() {
    this.args.botData.composerDisabled = !this.args.botData.composerDisabled;
  }
}
