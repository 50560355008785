/* import __COLOCATED_TEMPLATE__ from './save-modal.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import shuffle from '@intercom/pulse/lib/shuffle';
// @ts-ignore
import intermoji from '@intercom/intermoji';

import { AVAILABLE_EMOJI } from 'embercom/lib/available-emoji';
import { Scope } from 'embercom/objects/content-service/saved-views/constants/scopes';
import { MaxLimit } from 'embercom/objects/content-service/saved-views/constants/max-limit';
import type SavedViewData from 'embercom/objects/content-service/saved-views/saved-view-data';
import type SavedView from 'embercom/models/content-service/saved-view.js';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';

interface Args {
  activeView?: SavedView;
  scope: Scope;
  savedViewData: SavedViewData;
  onClose: Function;
  position: string;
}

export default class SaveButton extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @service notificationsService: any;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  @tracked name = '';
  @tracked avatarEmoji = this._randomEmoji();

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.args.activeView) {
      this.name = this.viewName;
      this.avatarEmoji = this.args.activeView.avatarEmoji;
    }
  }

  get app() {
    return this.appService.app;
  }

  get isValid() {
    return this.name.trim() !== '';
  }

  get viewName() {
    let view = this.args.activeView;
    if (this.args.scope === Scope.OUTBOUND && view && view.savedViewData.translation) {
      return this.intl.t(`content-service.saved-views.${view.savedViewData.translation}`);
    }

    return view?.name;
  }

  @action
  async updateView() {
    if (!this.args.activeView) {
      this.notificationsService.notifyError(
        this.intl.t('content-service.saved-views.save-modal.update.error-not-found'),
      );
      return;
    }

    let activeView = this.args.activeView;

    try {
      activeView.name = this.name;
      activeView.avatarEmoji = this.avatarEmoji;
      activeView.savedViewData = this.args.savedViewData.data;

      await activeView.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('content-service.saved-views.save-modal.update.success'),
      );

      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'saved_view',
      });

      this.args.onClose({ success: true, savedView: activeView });
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('content-service.saved-views.save-modal.update.error-saving'),
      );
      this.args.onClose({ success: false });
    }
  }

  @action
  async saveNewView() {
    let savedView;

    try {
      savedView = this.store.createRecord('content-service/saved-view', {
        name: this.name,
        scope: this.args.scope,
        avatarEmoji: this.avatarEmoji,
        savedViewData: this.args.savedViewData.data,
      });

      await savedView.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('content-service.saved-views.save-modal.success'),
      );

      this.intercomEventService.trackAnalyticsEvent({
        action: 'saved',
        object: 'saved_view',
        position: this.args.position,
      });

      this.args.onClose({ success: true, savedView });
    } catch (err) {
      if (
        this.args.scope === Scope.OUTBOUND &&
        err.jqXHR?.responseJSON?.errors?.length &&
        err.jqXHR.responseJSON.errors[0].message === 'Saved views limit reached'
      ) {
        this.notificationsService.notifyError(
          this.intl.t('content-service.saved-views.save-modal.limit-reached', {
            limit: MaxLimit.OUTBOUND,
          }),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('content-service.saved-views.save-modal.error'),
        );
      }
      savedView.unloadRecord();
      this.args.onClose({ success: false });
    }
  }

  @action selectAvatarEmoji(emojiIdentifier: string) {
    let unicodeEmoji = intermoji.unicodeFromIdentifier(emojiIdentifier);
    let twemojiCompatibleEmoji = intermoji.getSupportedTwemoji(unicodeEmoji);
    this.avatarEmoji = twemojiCompatibleEmoji;
  }

  @action cancel() {
    this.args.onClose({ success: false });
  }

  _randomEmoji() {
    return shuffle(AVAILABLE_EMOJI)[0];
  }

  get titleKey() {
    return this.args.activeView
      ? 'content-service.saved-views.save-modal.update.title'
      : 'content-service.saved-views.save-modal.title';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::SavedViews::SaveModal': typeof SaveButton;
    'content-service/saved-views/save-modal': typeof SaveButton;
  }
}
