/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { seriesWaitTypes } from 'embercom/models/data/matching-system/matching-constants';
import moment from 'moment-timezone';

export default class WaitComponent extends Component {
  @service store;
  @service appService;
  @service contentEditorService;
  @tracked selectedValue = seriesWaitTypes.relative;
  @tracked selectedDate;
  @tracked days;
  @tracked hours;
  @tracked minutes;

  relative = seriesWaitTypes.relative;
  absolute = seriesWaitTypes.absolute;

  constructor(...args) {
    super(...args);
    this.selectedDate = this._startOfNextHour().add(1, 'day').toDate();
    this._populatePanel();
  }

  _populatePanel() {
    if (this.wait.type === seriesWaitTypes.relative) {
      [this.days, this.hours, this.minutes] = this.toDaysHoursMinutes(this.wait.timeDelay);
    } else if (this.wait.type === seriesWaitTypes.absolute) {
      if (this.wait.timeDelay > 0) {
        this.selectedDate = new Date();
        this.selectedDate.setTime(this.wait.timeDelay * 1000);
      }
    }
  }

  toDaysHoursMinutes(seconds) {
    let days = Math.floor(seconds / (3600 * 24));
    let hours = Math.floor((seconds % (3600 * 24)) / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    return [days, hours, minutes];
  }

  get todayInAppTimezone() {
    return moment.tz(this.appService.app.timezone);
  }

  _startOfNextHour() {
    return this.todayInAppTimezone.add(1, 'hour').startOf('hour');
  }

  @action
  updateTimeDelay() {
    if (this.wait.type === seriesWaitTypes.relative) {
      this.wait.timeDelay = this.relativeTime;
    } else if (this.wait.type === seriesWaitTypes.absolute) {
      this.wait.timeDelay = this.absoluteTime(this.selectedDate);
    }
  }

  @action
  onSelectionChange(value) {
    this.wait.type = value;
    this.updateTimeDelay();
  }

  get wait() {
    return this.contentEditorService.activeObject;
  }

  absoluteTime(date) {
    return Math.floor(date.getTime() / 1000);
  }

  @action
  onDateChange(date) {
    this.selectedDate = date;
    this.wait.timeDelay = this.absoluteTime(date);
  }

  get relativeTime() {
    let days = this.days || 0;
    let hours = this.hours || 0;
    let minutes = this.minutes || 0;
    let days_to_seconds = days * 24 * 60 * 60;
    let hours_to_seconds = hours * 60 * 60;
    let minutes_to_seconds = minutes * 60;
    return days_to_seconds + hours_to_seconds + minutes_to_seconds;
  }
}
