/* import __COLOCATED_TEMPLATE__ from './report-tab-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { hash } from 'rsvp';
import { task } from 'ember-concurrency-decorators';
import { type Task, type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type ReportingService from 'embercom/services/reporting-service';
import Range from 'embercom/models/reporting/range';
import type Router from '@ember/routing/router-service';
import type Store from '@ember-data/store';

type ModelType = Record<'surveyTitle' | 'outboundSurveyId' | 'settings' | 'contents', any>;

export default class ReportComponent extends Component {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare reportingService: ReportingService;

  @service declare contentEditorService: any;
  @service declare appService: any;

  loadingModel: Task<ModelType, any> = taskFor(this.modelTask);

  constructor(owner: unknown, args: any) {
    super(owner, args);
    let range = Range.createFromParams(null, null, this.appService.app.timezone);
    this.reportingService.set('range', range);
    this.loadingModel.perform();
  }

  @task
  *modelTask(): TaskGenerator<ModelType> {
    let settings = yield this.store.peekAll('reporting/settings')?.firstObject ||
      this.store.queryRecord('reporting/settings', {});

    return yield hash({
      surveyTitle:
        this.ruleset.clientData.title || this.intl.t('reporting.surveys.empty-survey-title'),
      outboundSurveyId: this.ruleset.id,
      settings,
      contents: this.ruleset.rulesetLinks.reject((rulesetLink: any) => rulesetLink.isControlGroup),
    });
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get activeRulesetLink() {
    return this.contentEditorService.activeRulesetLink;
  }

  get activeRulesetReport() {
    if (this.loadingModel.last?.value) {
      return this.loadingModel.last.value.contents.filter(
        (report: any) => report.id === this.activeRulesetLink.id,
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Summaries::Content::Survey::ReportTabComponent': typeof ReportComponent;
  }
}
