/* import __COLOCATED_TEMPLATE__ from './standard-stat-tab.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { statisticKeys, statisticTypes } from 'embercom/models/data/outbound/constants';

const COLOR_MAP = {
  [statisticKeys.receipts]: 'outbound__recipients-stat',
  [statisticKeys.clicks]: 'outbound__clicks-stat',
  [statisticKeys.goals]: 'outbound__goal-stat',
  [statisticKeys.replies]: 'outbound__replies-stat',
  [statisticKeys.opens]: 'outbound__opens-stat',
  [statisticKeys.completions]: 'outbound__opens-stat',
};

export default class StandardStatTab extends Component {
  @service contentEditorService;

  statisticTypes = statisticTypes;

  get colorClass() {
    return COLOR_MAP[this.args.config.statistic.key];
  }

  get selectedVersion() {
    return this.contentEditorService.selectedVersion;
  }

  get selectedVersionStats() {
    let versionStats =
      this.selectedVersion.contentStats.filter(
        (object) => object.content_id === this.contentEditorService.activeObjectId,
      ).firstObject || {};
    let { stats: contentStats = [] } = versionStats;

    return contentStats.findBy('key', this.args.config.statisticKey);
  }

  get statistic() {
    if (this.args.statistic) {
      return this.args.statistic;
    } else if (this.selectedVersion) {
      return this.selectedVersionStats;
    } else {
      return this.args.config.statistic;
    }
  }
}
