/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';

export default class Header extends Component {
  get title() {
    return this.typeMap[0];
  }

  get subtitle() {
    return this.typeMap[1];
  }

  get icon() {
    return this.typeMap[2];
  }

  get typeMap() {
    switch (this.args.type) {
      case 'reply-button':
        return ['Continue bot', 'Reply buttons', 'arrow'];
      case 'custom-object':
        return ['Continue bot', 'Data selection buttons', 'arrow'];
      case 'conditional-branches':
        return ['Continue bot', 'Conditions', 'arrow'];
      case 'answer-bot':
        return ['End bot', `Resolution Bot and follow up actions`, 'auto-message'];
      case 'follow-up':
        return ['End bot', 'Follow up actions', 'auto-message'];
      case 'trigger-workflow':
        return ['End bot', 'Handover to another bot', 'auto-message'];
      case 'create-ticket':
        return ['End bot', 'Create a ticket', 'auto-message'];
      default:
        throw new Error(`Unknown end state type '${this.args.type}'`);
    }
  }
}
