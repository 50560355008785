/* import __COLOCATED_TEMPLATE__ from './subscription-type.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { post } from 'embercom/lib/ajax';
import { objectTypes, objectNames } from 'embercom/models/data/matching-system/matching-constants';

export default class SubscriptionType extends Component {
  @service appService;
  @service intercomEventService;
  @service store;
  @service logService;
  @service intl;

  @tracked subscriptionTypes = [];
  @tracked translationSettings;
  @tracked optInAudienceCount;

  constructor() {
    super(...arguments);
    this.loadSubscriptionTypes.perform();
    this.fetchOptInAudienceCount.perform();
  }

  get items() {
    let optInSubscriptionTypeOptions = [];
    let optOutSubscriptionTypeOptions = [];

    this.subscriptionTypes
      .filter(
        (subscriptionType) =>
          subscriptionType.isLive ||
          subscriptionType.isDraft ||
          subscriptionType.id === this.args.object.subscriptionTypeId,
      )
      .forEach((subscriptionType) => {
        if (!subscriptionType.translationForTeammate) {
          this.logService.log({
            message: 'subscription type error',
            subscriptionType: subscriptionType.serialize(),
          });
        }
        let optionDetails = {
          text: subscriptionType.translationForTeammate?.name || 'Translation not found',
          value: subscriptionType.id,
          component: 'content-editor/panels/content/subscription-type/subscription-type-item',
          componentAttrs: {
            isDraft: subscriptionType.isDraft,
            isArchived: subscriptionType.isArchived,
          },
          isDisabled: subscriptionType.isDraft,
          tooltipText:
            subscriptionType.isDraft &&
            "This subscription type is a draft. To use it, click on 'Manage subscription types' and set it live.",
        };
        if (subscriptionType.isOptIn) {
          optInSubscriptionTypeOptions.push(optionDetails);
        } else if (subscriptionType.isOptOut) {
          optOutSubscriptionTypeOptions.push(optionDetails);
        }
      });
    let subscriptionTypeOptions = [
      {
        heading: 'Opt-in',
        items: optInSubscriptionTypeOptions,
      },
      {
        heading: 'Opt-out',
        items: optOutSubscriptionTypeOptions,
      },
    ];

    if (this.args.objectType !== objectTypes.sms) {
      subscriptionTypeOptions = [
        {
          items: [{ text: 'No subscription type', value: null }],
        },
        ...subscriptionTypeOptions,
      ];
    }
    return subscriptionTypeOptions;
  }

  @task *loadSubscriptionTypes() {
    let allSubscriptionTypes = yield this.store.findAll('outbound-subscriptions/subscription-type');
    this.subscriptionTypes = allSubscriptionTypes.filter((subscriptionType) =>
      subscriptionType.permittedContentTypes.includes(this.args.objectType),
    );

    if (this.args.objectType === objectTypes.sms) {
      if (!this.args.object.subscriptionTypeId) {
        this.args.object.subscriptionTypeId = this.subscriptionTypes[0].id;
      }
    }
  }

  @action
  onSelectItem(subscriptionTypeId) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'subscription_type',
      subscription_type_id: subscriptionTypeId,
    });

    this.args.object.subscriptionTypeId = subscriptionTypeId;
    this.args.object.showUnsubscribeLink = true;
    this.fetchOptInAudienceCount.perform();
  }

  @task
  *fetchOptInAudienceCount() {
    if (this.args.object.subscriptionType?.isOptIn) {
      let params = {
        app_id: this.appService.app.id,
        predicates: [
          {
            attribute: 'opted_in_subscription_type_ids',
            type: 'subscription_type',
            comparison: 'in',
            value: this.args.object.subscriptionTypeId,
          },
        ],
      };
      let response = yield post('/ember/users/count.json', params);
      this.optInAudienceCount = response.count;
    }
  }

  get showOptInAudienceWarning() {
    return (
      this.args.object.subscriptionType?.isOptIn &&
      this.optInAudienceCount === 0 &&
      !this.fetchOptInAudienceCount.isRunning
    );
  }

  get optInAudienceWarningText() {
    let objectName = objectNames[this.args.objectType];
    return this.intl.t(
      `content-editor.panels.content.subscription-type.opt-in-audience-warning.${objectName}`,
      {
        htmlSafe: true,
      },
    );
  }
}
