/* import __COLOCATED_TEMPLATE__ from './edit-permissions.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { alias, filter, filterBy, readOnly, sort } from '@ember/object/computed';

let manageWebhooks = 'manage_webhooks';

export default Component.extend({
  appService: service(),
  developerHub: service(),
  app: readOnly('appService.app'),
  sortingOrder: Object.freeze(['orderId']),
  workspaceData: filterBy('oauthScopes', 'scopeType', 'workspace_data'),
  workspaceDataWithoutWebhooks: filter('workspaceData', function (workspacePermission) {
    return (
      workspacePermission.id !== manageWebhooks ||
      this.get('developerHub.appPackage.oauthClient.scopes').includes(manageWebhooks)
    );
  }),
  sortedWorkspaceData: sort('workspaceDataWithoutWebhooks', 'sortingOrder'),
  peopleAndConversationData: filterBy('oauthScopes', 'scopeType', 'people_and_conversation_data'),
  sortedPeopleAndConversationData: sort('peopleAndConversationData', 'sortingOrder'),
  oauthClient: alias('appPackage.changeRequest.appPackageConfig.oauthClient'),
});
