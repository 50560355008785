/* import __COLOCATED_TEMPLATE__ from './element-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { isEmpty } from '@ember/utils';
import ENV from 'embercom/config/environment';
import isValidUrl from 'embercom/lib/url-validator';

export default class ElementSelector extends Component {
  @service appService;
  @service store;
  @service intersectionService;

  @tracked isManuallySelectingDomain = false;
  @tracked isDomainModalOpen = false;
  @tracked selectedDomain = null;
  @tracked pingDomains;

  constructor() {
    super(...arguments);
    this.intersectionService.registerEventHandler('selector-chosen', (data) => {
      this.setClientPredicatesAndSelector(data);
      return true;
    });
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.intersectionService.unregisterEventHandler('selector-chosen');
  }

  get pingDomainOptions() {
    return this.pingDomains.map(function (domain) {
      return { text: domain, value: domain };
    });
  }

  get isValidDomain() {
    return isValidUrl(this.selectedDomain);
  }

  get isEmptySelector() {
    return isEmpty(this.args.customBot.botData.selector);
  }

  @task({ drop: true })
  *fetchDomainsAndOpenModal() {
    if (isEmpty(this.pingDomains)) {
      let pingDomains = yield this.store.query('ping-domain', { include_subdomains: true });
      this.pingDomains = pingDomains.mapBy('domain').uniq();
      if (ENV.environment === 'development') {
        this.pingDomains.pushObjects(['http://intercom.test', 'http://intercom-js.test']);
      }
    }
    this.selectedDomain = this.pingDomains.firstObject;
    this.isDomainModalOpen = true;
  }

  @action
  closeChooseDomainModal() {
    this.isManuallySelectingDomain = false;
    this.isDomainModalOpen = false;
  }

  @action
  clearClientPredicatesAndSelector() {
    this.setClientPredicatesAndSelector({ selector: null, url: null });
    this.isManuallySelectingDomain = false;
  }

  @action
  openSite() {
    this.openIntersection(this.selectedDomain);
    this.isDomainModalOpen = false;
  }

  @action
  setClientPredicatesAndSelector(data) {
    this.args.ruleset.clientPredicateGroup.removeAll(() => true);

    if (data.url) {
      this.args.ruleset.clientPredicateGroup.add({
        attribute: 'client_attributes.last_visited_url',
        comparison: 'contains',
        type: 'string',
        value: this.cleanUrl(data.url),
      });
    }
    this.args.customBot.botData.selector = data.selector;
  }

  @action
  cleanUrl(url) {
    return url
      .replace(/^http.?:/, '')
      .replace(/#+$/, '')
      .replace(/\?*$/, '')
      .replace(/\/*$/, '');
  }

  @action
  openIntersection(url) {
    return this.intersectionService.openWindow(url, 'custom-bot-element-selector');
  }
}
