/* import __COLOCATED_TEMPLATE__ from './audience-rules-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { copy } from 'ember-copy';

export default class AudienceRulesEditor extends Component {
  @action
  onUpdatePredicates(predicates) {
    this.args.ruleset.set('predicateGroup.predicates', copy(predicates));
    if (this.args.config.showAudiencePreview) {
      this.args.ruleset
        .get('fetchAudiencePreviewTask')
        .perform(this.args.config.ignoredAttributeTypesForPreview);
    }
  }

  @action
  onUpdateRolePredicates(rolePredicates) {
    this.args.ruleset.set('rolePredicateGroup.predicates', copy(rolePredicates));
    if (this.args.config.showAudiencePreview) {
      this.args.ruleset
        .get('fetchAudiencePreviewTask')
        .perform(this.args.config.ignoredAttributeTypesForPreview);
    }
  }

  @action
  onUpdateDefaultPredicates(defaultPredicates) {
    this.args.ruleset.set('defaultPredicateGroup.predicates', copy(defaultPredicates));
    if (this.args.config.showAudiencePreview) {
      this.args.ruleset
        .get('fetchAudiencePreviewTask')
        .perform(this.args.config.ignoredAttributeTypesForPreview);
    }
  }
}
