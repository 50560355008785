/* import __COLOCATED_TEMPLATE__ from './change-language.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import type AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';
import { FIN_SUPPORTED_LANGUAGES, type Language } from 'embercom/lib/ai-content-library/constants';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { put } from 'embercom/lib/ajax';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  selectedWrappers: Array<
    ContentWrapper & { relations: any & { content_segment_ids: Array<number> } }
  >;
  unselectedWrappers: Array<
    ContentWrapper & { relations: any & { content_segment_ids: Array<number> } }
  >;
  isBulkSelection: boolean;
  listApi: AiContentLibraryApi;
  isDisabled: boolean;
  reloadPageAndSummary: () => void;
}

interface LanguageOption {
  text: string;
  value: string;
}

export default class ChangeLanguage extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare permissionsService: $TSFixMe;

  @tracked activeLanguages: Array<LanguageOption> = [];

  get languageOptions(): Array<LanguageOption> {
    return FIN_SUPPORTED_LANGUAGES.map((language: Language) => {
      return {
        text: language.name,
        value: language.locale,
      };
    });
  }

  get contentTuples() {
    return this.args.selectedWrappers.map((wrapper) => {
      let object = wrapper.contents.firstObject!;
      return {
        entity_id: object.contentId,
        entity_type: object.contentType,
      };
    });
  }

  get unselectedTuples() {
    return this.args.unselectedWrappers.map((wrapper) => {
      let object = wrapper.contents.firstObject!;
      return {
        entity_id: object.contentId,
        entity_type: object.contentType,
      };
    });
  }

  @action async changeLanguage(language: LanguageOption) {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }

    if (this.activeLanguages.includes(language)) {
      return;
    }
    this.activeLanguages.pushObject(language);

    await put('/ember/knowledge_base/bulk_actions/bulk_update_language', {
      app_id: this.appService.app.id,
      language_code: language.value,
      contents_to_update: this.args.isBulkSelection ? null : this.contentTuples,
      unselected_tuples: this.args.isBulkSelection ? this.unselectedTuples : null,
      query: this.args.isBulkSelection
        ? this.args.listApi.searchParams || this.args.listApi.defaultSearchParams
        : null,
      is_bulk_selection: this.args.isBulkSelection,
    });

    this.activeLanguages.removeObject(language);
    this.args.reloadPageAndSummary();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::BulkActions::ChangeLanguage': typeof ChangeLanguage;
  }
}
