/* import __COLOCATED_TEMPLATE__ from './summary-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import { SECONDS_IN } from 'embercom/components/matching-system/audience-selector/over-messaging/send-frequency-limit';
import { pluralize } from 'ember-inflector';
import { recurringScheduleFrequencies } from 'embercom/models/data/matching-system/matching-constants';

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MONTHS = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

// unlike `timezoneFallbackMappings` in the intl service, this is a hardcoded
// mapping from old names to new names for specific timezones which have
// manual value maps elsewhere in the intercom and embercom repos for
// compatibility reasons. This allows us to display the new name without
// needing to update all dependencies (eg. moment) here to understand the
// mapping
const timezoneNameOverrides = {
  'Europe/Kiev': 'Europe/Kyiv',
};

export default class SummaryComponent extends Component {
  @service contentEditorService;
  @service appService;

  get verb() {
    return this.args.verb ? this.args.verb : 'send';
  }

  get activationMessage() {
    if (!this.contentEditorService.ruleset.scheduledActivation) {
      return `Start ${this.verb}ing immediately`;
    } else {
      return `Start ${this.verb}ing on ${this.formattedDate(
        this.contentEditorService.ruleset.scheduledActivation.changeAt,
      )}`;
    }
  }

  get deactivationMessage() {
    if (!this.contentEditorService.ruleset.scheduledDeactivation) {
      return `Never stop ${this.verb}ing`;
    } else {
      return `Stop ${this.verb}ing on ${this.formattedDate(
        this.contentEditorService.ruleset.scheduledDeactivation.changeAt,
      )}`;
    }
  }

  get timetableMessage() {
    let matchingTimetable = this.contentEditorService.ruleset.matchingTimetable;
    if (!matchingTimetable) {
      return 'Any day, any time';
    } else if (matchingTimetable.isInsideOfficeHoursType) {
      return 'During office hours';
    } else if (matchingTimetable.isOutsideOfficeHoursType) {
      return 'Outside office hours';
    } else {
      return 'Custom times';
    }
  }

  get sendFrequencyMessage() {
    let text;
    let spacingPeriod = this.contentEditorService.ruleset.matchSpacingPeriod;
    if (this.contentEditorService.ruleset.isMatchBehaviorSingle) {
      text = `Send once, first time the person matches the rules`;
    } else if (this.contentEditorService.ruleset.isMatchBehaviorMulti) {
      let recurringSchedule = this.contentEditorService.ruleset.recurringSchedule;
      if (recurringSchedule && recurringSchedule.calendarBased) {
        let frequency;
        switch (recurringSchedule.frequency) {
          case recurringScheduleFrequencies.weekly:
            frequency = `week on ${DAYS[recurringSchedule.dayOffset]}`;
            break;
          case recurringScheduleFrequencies.monthly:
            frequency = `month on day ${recurringSchedule.dayOffset}`;
            break;
          case recurringScheduleFrequencies.yearly:
            frequency = `year on ${MONTHS[recurringSchedule.monthOffset]} ${
              recurringSchedule.dayOffset
            }`;
            break;
          default:
            frequency = `unsupported frequency`;
            break;
        }
        text = `Send every ${frequency}`;
      } else {
        let sendFrequency =
          spacingPeriod > -1
            ? `once every ${this.formattedMatchSpacingPeriod(spacingPeriod)}`
            : `every time`;
        text = `Send ${sendFrequency} the person matches the rules`;
      }
    }

    return text;
  }

  get quietHoursMessage() {
    if (this.contentEditorService.configRulesetLink?.isSms) {
      let sms = this.contentEditorService.configRulesetLink.object;
      if (sms.quietHoursEnabled) {
        return 'Avoiding quiet hours';
      }
    }
  }

  formattedDate(date) {
    let timezone = this.appService.app.timezone;

    if (timezoneNameOverrides[timezone]) {
      timezone = timezoneNameOverrides[timezone];
    }

    let dayTime = moment(date)
      .tz(timezone)
      .format(dateAndTimeFormats.fullDateAndTimeWithoutTimezoneWithDayName);
    let timezoneName = this.formattedTimezoneName(timezone);
    let timezoneOffset = this.formattedUtcOffset(timezone);
    return `${dayTime} ${timezoneName} time (${timezoneOffset})`;
  }

  formattedTimezoneName(timezone) {
    return timezone.slice(timezone.lastIndexOf('/') + 1).replace(/_/g, ' ');
  }

  formattedUtcOffset(timezone) {
    return moment
      .tz(timezone)
      .format('[GMT]Z')
      .replace(/0(\d:)/, '$1')
      .replace(':00', '');
  }

  formattedMatchSpacingPeriod(spacingPeriod) {
    let timeUnitsDescending = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];
    let selectedDuration = 1;
    let selectedUnit = 'hour';

    // Convert seconds into the largest unit of time that it divides evenly by
    // e.g. 86400 seconds would convert to `1 day` instead of `24 hours`
    for (let unit of timeUnitsDescending) {
      let conversion = SECONDS_IN[`${unit}`];
      if (spacingPeriod % conversion === 0) {
        selectedDuration = spacingPeriod / conversion;
        selectedUnit = unit;
        break;
      }
    }

    return pluralize(selectedDuration, selectedUnit);
  }
}
