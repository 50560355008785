/* import __COLOCATED_TEMPLATE__ from './saved-reply-popover-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { isNone, isPresent } from '@ember/utils';
import { computed } from '@ember/object';
import { reads, gt, filter } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import SavedReplySearch from 'embercom/controllers/mixins/conversations/saved-reply-search';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default Component.extend(SavedReplySearch, {
  appService: service(),
  attributeService: service(),
  intercomEventService: service(),
  router: service(),
  permissionsService: service(),
  classNames: ['saved-replies__popover'],

  isLoading: reads('replies.isPending'),
  hasSavedReplies: gt('replies.length', 0),
  privateMacros: filter('filteredReplies', (reply) => reply.visibility === 'owner'),
  sharedMacros: filter('filteredReplies', (reply) => reply.visibility !== 'owner'),
  selectableItems: computed('privateMacros', 'sharedMacros', function () {
    return this.privateMacros.concat(this.sharedMacros);
  }),

  highlightIndex: 0,
  highlightedReplyId: null,
  highlightedReply: computed('highlightedReplyId', function () {
    return this.selectableItems.find((item) => item.id === this.highlightedReplyId);
  }),
  lastPrivateMacroIndex: computed('privateMacros', function () {
    return this.privateMacros.length - 1;
  }),
  analyticsContext: 'saved_reply_inserter',

  macroTypeFilter: computed('controlsAPI.activePaneIdentifier', function () {
    if (this.controlsAPI.activePaneIdentifier === 'note') {
      return 'note';
    } else if (this.controlsAPI.conversation?.isDraft) {
      return 'opener';
    } else {
      return 'reply';
    }
  }),

  replies: computed(
    'controlsAPI.app.{visibleSavedReplies,visibleSavedReplies.@each.types}',
    function () {
      return this.controlsAPI.app.visibleSavedReplies.filter(
        ({ types }) => types.length === 0 || types.includes(this.macroTypeFilter),
      );
    },
  ),

  savedReplies: computed('controlsAPI.app.{savedReplies,savedReplies.@each.types}', function () {
    return this.controlsAPI.app.savedReplies.filter(
      ({ types }) => types.length === 0 || types.includes(this.macroTypeFilter),
    );
  }),

  keyDown(e) {
    switch (e.which) {
      case 40: // arrow down
        this.send('highlightNext');
        e.preventDefault();
        break;
      case 38: // arrow up
        this.send('highlightPrevious');
        e.preventDefault();
        break;
      case 13: // return
        this.send('selectHighlightedItem');
        break;
      case 27: // esc
        this.get('inserterAPI.actions.close')();
        break;
    }
  },

  didInsertElement() {
    this._super(...arguments);
    this.intercomEventService.trackAnalyticsEvent({
      owner: 'inbox',
      action: 'clicked',
      object: 'saved_reply_inserter',
      place: 'inbox',
      context: 'saved_reply_inserter',
      has_saved_replies: this.hasSavedReplies,
      conversation_id: this.controlsAPI.conversation?.id,
    });
    if (this.selectableItems.length > 0) {
      this.set('highlightedReplyId', this.selectableItems[0].id);
    }
  },

  didDestroyElement() {
    this._super(...arguments);
    this.intercomEventService.trackAnalyticsEvent({
      owner: 'inbox',
      action: 'closed',
      object: 'saved_reply_inserter',
      place: 'inbox',
      context: 'saved_reply_inserter',
    });
  },

  _changeHighlight(index) {
    this.set('highlightIndex', index);
    let reply = this.selectableItems.objectAt(this.highlightIndex);
    this.set('highlightedReplyId', reply?.id);
  },

  _onQueryUpdate() {
    let newIndex = this.selectableItems.findIndex((reply) => reply.id === this.highlightedReplyId);
    if (newIndex > -1) {
      this.set('highlightIndex', newIndex);
    } else if (this.selectableItems.length > 0) {
      this.set('highlightIndex', 0);
      this.set('highlightedReplyId', this.selectableItems[0].id);
    } else {
      this.set('highlightedReplyId', null);
    }
  },

  actions: {
    openManageSavedReplies(mode) {
      let url = this.router.urlFor('apps.app.settings.helpdesk.macros');

      if (typeof mode === 'string') {
        url = `${url}?mode=${mode}`;
      }

      safeWindowOpen(url, '_blank');
    },
    createSavedReply() {
      this.get('controlsAPI.actions.createSavedReply')();
      this.get('inserterAPI.actions.close')();
    },
    selectItem(savedReply) {
      let actionTypes = savedReply.actions?.map((a) => a.type) || [];
      let insertedFrom =
        this.controlsAPI.activePaneIdentifier === 'comment'
          ? 'saved_reply_inserter'
          : 'saved_reply_note_inserter';
      let place = this.controlsAPI.isInbox ? 'inbox' : 'bulk-modal';
      this.intercomEventService.trackAnalyticsEvent({
        owner: 'inbox',
        action: 'added_to_reply',
        object: 'saved_reply',
        place,
        context: 'saved_reply_inserter',
        assign: actionTypes.any(
          (a) => a === 'assign-conversation' || a === 'assign-conversation-to-owner',
        ),
        close: actionTypes.any((a) => a === 'close-conversation'),
        snooze: actionTypes.any((a) => a === 'snooze-conversation'),
        tag: actionTypes.any((a) => a === 'add-tag-to-conversation'),
        priority: actionTypes.any((a) => a === 'change-conversation-priority'),
        cda: actionTypes.any((a) => a === 'set-conversation-data-attribute'),
        has_text: (savedReply.blocks?.length || 0) > 0,
        saved_reply_id: savedReply.id,
        inserted_from: insertedFrom,
        conversation_id: this.controlsAPI.conversation?.id,
        visibility: savedReply.visibility,
      });
      this.get('controlsAPI.actions.insertSavedReply')(savedReply);
      this.get('inserterAPI.actions.close')();
      this.get('controlsAPI.actions.focusComposer')();
    },
    highlightNext() {
      let items = this.selectableItems;
      let highlightIndex = this.highlightIndex;
      if (highlightIndex < items.length - 1) {
        this._changeHighlight(highlightIndex + 1);
      }
    },
    highlightPrevious() {
      let highlightIndex = this.highlightIndex;
      if (isPresent(this.selectableItems) && highlightIndex > 0) {
        this._changeHighlight(highlightIndex - 1);
      }
    },
    setHighlight(index) {
      this._changeHighlight(index);
    },
    selectHighlightedItem() {
      let highlightIndex = this.highlightIndex;
      let highlightedItem = this.selectableItems.objectAt(highlightIndex);
      if (isNone(highlightedItem)) {
        return;
      }
      this.send('selectItem', highlightedItem);
    },
  },
});
