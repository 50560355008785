/* import __COLOCATED_TEMPLATE__ from './admin-notification-channels-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { isEmpty, isPresent } from '@ember/utils';
import { computed } from '@ember/object';
import { alias, reads, gt, mapBy, notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import ConversationPartComponentBase from 'embercom/components/conversation/stream/conversation-part-component-base';

export default ConversationPartComponentBase.extend({
  realTimeEventService: service(),
  init() {
    this._super(...arguments);
    if (this.get('participant.userDetailsDidUpdate')) {
      this.part.reload();
      this.set('participant.userDetailsDidUpdate', false);
    }
    this.realTimeEventService.on('UserDetails', this, 'handleUserDetailsEvent');
  },
  willDestroyElement() {
    this.realTimeEventService.off('UserDetails', this, 'handleUserDetailsEvent');
    this._super(...arguments);
  },
  participant: alias('conversation.main_participant'),
  blocks: reads('part.blocks'),
  block: reads('blocks.firstObject'),
  channels: reads('block.channels'),
  title: reads('block.title'),
  moreThanOneChannel: gt('channels.length', 1),
  channelValues: mapBy('channels', 'value'),
  channelValue: computed('channelValues', function () {
    return this.channelValues.find((value) => !isEmpty(value));
  }),
  hasReplyChannel: notEmpty('channelValue'),

  handleUserDetailsEvent(event) {
    let isParticipant = this.get('participant.id') === event.id;
    let hasEmail = isPresent(event.email) && event.email !== this.get('participant.email');
    let hasPhoneNumber =
      isPresent(event.user_phone_number) &&
      event.user_phone_number !== this.get('participant.phone');
    let channelAdded = hasEmail || hasPhoneNumber;
    if (isParticipant && channelAdded) {
      this.part.reload();
    }
  },
});
