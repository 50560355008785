/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class ResolutionBotBehaviorSummary extends Component {
  @service contentEditorService;
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get translationNamespace() {
    return 'ai-agent.profiles.bot-mode';
  }

  get botDescription() {
    if (this.contentEditorService.ruleset.isDeleted) {
      return undefined;
    }
    switch (this.contentEditorService.ruleset.rulesetLinks.firstObject.object.botMode) {
      case 'bot_only':
        return this.intl.t(`${this.translationNamespace}.looping-bot-only`);

      case 'one_time':
        return this.intl.t('ai-agent.profiles.bot-mode.one-time');

      case 'looping':
        return this.intl.t(`${this.translationNamespace}.looping`);

      case 'disabled':
        return this.intl.t('ai-agent.profiles.bot-mode.disabled');
    }
  }
}
