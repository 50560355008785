/* import __COLOCATED_TEMPLATE__ from './view-reply-sheet.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ViewReplySheet extends Component {
  @service contentEditorService;
  @service session;
  @service appService;

  constructor() {
    super(...arguments);
    this.session.switchWorkspace(this.app.id);
    this.session.getTeammate(this.app.id);
  }

  get app() {
    return this.appService.app;
  }

  @action
  closeConversation() {
    this.contentEditorService.updateConversationId(null);
  }
}
