/* import __COLOCATED_TEMPLATE__ from './tour-inserter.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { readOnly } from '@ember/object/computed';
import { notEmpty } from '@ember/object/computed';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  store: service(),
  classNames: ['u__left'],
  tourInserterAppInstalled: notEmpty('tourInserterApp'),

  init() {
    this._super(...arguments);
    this.fetchTourInserterApp.perform();
  },

  fetchTourInserterApp: task(function* () {
    let tourInserterApps = yield this.store.query('messenger-app', {
      app_package_id: 'intercom-tours',
    });
    this.set('tourInserterApp', tourInserterApps.firstObject);
  }),
});
