/* import __COLOCATED_TEMPLATE__ from './users-accordion.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { and, or, not, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default Component.extend({
  appService: service(),
  store: service(),
  app: readOnly('appService.app'),

  shouldDisplayInputs: or(
    'messengerApp.availableInConversations',
    'messengerApp.availableInMessengerHome',
    'messengerApp.availableInOperator',
    'messengerApp.availableInMessages',
  ),

  hasNonEmptyValues: or(
    'shouldDisplayInputs',
    'messengerApp.initializeUrl',
    'messengerApp.submitUrl',
    'messengerApp.configureUrl',
    'messengerApp.submitSheetUrl',
  ),

  showConfirmModal: false,
  saveButtonEnabled: and('messengerApp.hasDirtyAttributes', 'hasNonEmptyValues'),
  disabledAllCapabilites: not('shouldDisplayInputs'),
  notPersistedModel: not('messengerApp.isNew'),
  shouldShowDeleteButton: and('notPersistedModel', 'disabledAllCapabilites', 'isPrivateApp'),

  deleteMessengerAppAndCloseModal: action(function () {
    this.deleteMessengerAppTask();
    this.set('showConfirmModal', false);
  }),
});
