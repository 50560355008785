/* import __COLOCATED_TEMPLATE__ from './advanced-option-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnly from '@ember/component/template-only';

interface Signature {
  Args: {
    htmlSrc: string;
  };
}

const AdvancedOptionTooltip = templateOnly<Signature>();

export default AdvancedOptionTooltip;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Sources::AdvancedOptionTooltip': typeof AdvancedOptionTooltip;
  }
}
