/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

export default class VersionSummary extends Component {
  @service contentEditorService;

  get wait() {
    return this.contentEditorService.activeObject;
  }

  get formattedTimeDelay() {
    let delayDuration = moment.duration(this.wait.timeDelay, 'seconds');
    return delayDuration.format('d [days], h [hours], m [minutes]', { trim: 'all', minValue: 1 });
  }
}
