/* import __COLOCATED_TEMPLATE__ from './comment-quick-actions-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { bind } from '@ember/runloop';
import $ from 'jquery';
import { computed } from '@ember/object';
import { notEmpty, readOnly, not, and, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { BROWSER_SUPPORTS_TOUCH } from 'embercom/lib/screen-awareness';

export default Component.extend({
  appService: service(),
  hasContext: true,
  enterContextElementSelector: '.conversation__bubble-container',
  exitContextElementSelector: '.conversation__bubble-container',
  showOnlyWhenHoveringInsideContext: true,
  classNames: ['conversation__bubble__tools', 'u__relative'],
  classNameBindings: ['showOnlyWhenHoveringInsideContext:u__hidden'],

  mainParticipantHasEmail: notEmpty('conversation.main_participant.email'),

  partHasOnlyMessengerCard: readOnly('part.hasOnlyMessengerCard'),
  partDoesNotHaveMessengerCards: not('part.hasMessengerCards'),
  hasSaveReplyAction: and(
    'canUseSavedReplies',
    'part.isAdminPart',
    'partDoesNotHaveMessengerCards',
  ),
  hasGithubAction: readOnly('appService.app.hasActiveGithubIntegration'),
  hasSalesforceAction: and('appService.app.hasSalesforceIntegration', 'mainParticipantHasEmail'),

  hasTagAction: or('part.isInitialPartByAdmin', 'part.isUserPartOrReply'),
  tagAction: computed('hasTagAction', 'partHasOnlyMessengerCard', function () {
    if (this.hasTagAction && !this.partHasOnlyMessengerCard) {
      return 'conversation/stream/quick-actions/tag-reply-quick-action';
    }

    return null;
  }),

  extraQuickActions: computed(
    'hasSaveReplyAction',
    'part.canBeRedacted',
    'hasGithubAction',
    'hasSalesforceAction',
    function () {
      let actions = [];

      if (this.hasGithubAction) {
        actions.push('integrations/github/conversation-parts-quick-action-item');
      }
      if (this.hasSalesforceAction) {
        actions.push('integrations/salesforce/conversation-parts-quick-action-item');
      }
      if (this.get('part.canBeRedacted')) {
        actions.push('conversation/stream/quick-actions/redact-reply-quick-action');
      }

      actions.push('conversation/stream/quick-actions/copy-permalink-quick-action');

      if (this.hasSaveReplyAction) {
        actions.push('conversation/stream/quick-actions/save-reply-quick-action');
      }

      return actions;
    },
  ),

  didInsertElement() {
    this._super(...arguments);
    let enterContextElement;
    let exitContextElement;
    let toggleElementDisplay = this.showOnlyWhenHoveringInsideContext && !BROWSER_SUPPORTS_TOUCH;
    if (this.hasContext) {
      enterContextElement = $(this.element).parents(this.enterContextElementSelector).eq(0);
      exitContextElement = $(this.element).parents(this.exitContextElementSelector).eq(0);
      if (enterContextElement.length === 0 || exitContextElement.length === 0) {
        this.set('hasContext', false);
      } else {
        this.set('enterContextElement', enterContextElement);
        this.set('exitContextElement', exitContextElement);
        exitContextElement.on(
          `mouseleave.${this.elementId}`,
          bind(this, function () {
            if (toggleElementDisplay) {
              if (this.dropdownOverlayVisible) {
                this.mouseLeftWhileOpen = true;
              } else {
                $(this.element).addClass('u__hidden');
              }
            }
          }),
        );
        if (toggleElementDisplay) {
          enterContextElement.on(
            `mouseenter.${this.elementId}`,
            bind(this, function () {
              this.mouseLeftWhileOpen = false;
              $(this.element).removeClass('u__hidden');
            }),
          );
        }
      }
    }
  },

  willDestroyElement() {
    if (this.hasContext && this.enterContextElement && this.exitContextElement) {
      this.exitContextElement.off(`mouseleave.${this.elementId}`);
      this.enterContextElement.off(`mouseenter.${this.elementId}`);
    }
    this.set('enterContextElement', null);
    this.set('exitContextElement', null);
    this._super(...arguments);
  },

  actions: {
    dropdownOverlayVisibilityChanged(visible) {
      this.set('dropdownOverlayVisible', visible);
      if (!visible && $(this.element) && this.mouseLeftWhileOpen) {
        this.mouseLeftWhileOpen = false;
        $(this.element).addClass('u__hidden');
      }
    },
  },
});
