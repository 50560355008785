/* import __COLOCATED_TEMPLATE__ from './send-and-change-state.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import $ from 'jquery';
import { on } from '@ember/object/evented';
import { computed } from '@ember/object';
import Component from '@ember/component';
// eslint-disable-next-line @intercom/intercom/no-legacy-dropdown
import ComponentDropdownToggler from 'embercom/components/mixins/component-dropdown-toggler';
import SnoozeOptions from 'embercom/components/mixins/snooze-options';
import platform from 'embercom/lib/browser-platform';
import { SNOOZED_UNTIL_MAP } from 'embercom/lib/inbox/constants';
import { BROWSER_SUPPORTS_TOUCH } from 'embercom/lib/screen-awareness';
import { task, timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import moment from 'moment-timezone';

export default Component.extend(ComponentDropdownToggler, SnoozeOptions, {
  tagName: 'span',
  dropDownTogglerSelectors:
    '.js__inbox__send-and-change-state__opener,.js__inbox__send-and-change-state__opener *,.js__inbox__send-and-change-state__dropdown,.js__inbox__send-and-change-state__dropdown *',
  dropDownItemSelectors:
    '.js__inbox__send-and-change-state__opener,.js__inbox__send-and-change-state__opener *,.inbox__dropdown__item.dropdown__list-item,.inbox__dropdown__item.dropdown__list-item *',
  classNames: ['inbox__send-and-change-state'],
  classNameBindings: ['disabled:o__disabled'],
  closeShortcut: computed(function () {
    return platform.isMac ? '⌘+Shift+Enter' : 'CTRL+Shift+Enter';
  }),
  options: SNOOZED_UNTIL_MAP,
  type: 'comment',
  showSendingLabel: computed('sendInProgress', 'sendInProgressType', function () {
    return (
      this.sendInProgress &&
      (this.sendInProgressType === 'send_and_close' ||
        this.sendInProgressType === 'send_and_snooze')
    );
  }),

  init() {
    this._super(...arguments);
    this.initSnoozeProperties();
    this.set('today', moment());
  },
  _click: on('click', function (event) {
    if (this.disabled || this.get('upgradePrompt.isActive')) {
      return;
    }
    if ($(event.target).is(this.dropDownTogglerSelectors)) {
      if (!$(event.target).is(this.dropDownItemSelectors)) {
        return;
      }
      this._super(event);
    } else {
      this.send('closeDropdown');
    }
  }),
  openSubcontent() {
    this.set('showingSubcontent', true);
    this.closeSubcontentAfter1sec.cancelAll();
  },
  closeSubcontentImmediately() {
    this.set('showingSubcontent', false);
  },
  closeSubcontent() {
    this.closeSubcontentAfter1sec.perform();
  },
  closeSubcontentAfter1sec: task(function* () {
    yield timeout(ENV.APP._1000MS);
    this.set('showingSubcontent', false);
  }),
  actions: {
    openSubcontentOnMouseEnter() {
      if (BROWSER_SUPPORTS_TOUCH) {
        return;
      }
      this.openSubcontent();
    },
    closeSubcontentOnMouseLeave() {
      if (BROWSER_SUPPORTS_TOUCH) {
        return;
      }
      this.closeSubcontent();
    },
    openOrCloseSubcontentImmediatelyOnClick() {
      if (!BROWSER_SUPPORTS_TOUCH) {
        return;
      }

      if (this.showingSubcontent) {
        this.closeSubcontentImmediately();
      } else {
        this.openSubcontent();
      }
    },
    customSnoozeConversationAndCloseDropdown(type) {
      if (this.disableSnoozeButton) {
        return;
      }

      let selectedDayMoment = this.selectedDayMoment;
      let selectedTimeInSecondsSinceMidnight = this.selectedTimeInSecondsSinceMidnight;

      let rawSnoozedUntilTime = selectedDayMoment
        .startOf('day')
        .add(selectedTimeInSecondsSinceMidnight, 'seconds');
      let utcOffset = rawSnoozedUntilTime.utcOffset();
      let correctedSecondsSinceMidnight = selectedTimeInSecondsSinceMidnight;

      // If the timezone has changed between midnight and the time to snooze until, the snooze timer will be off by the difference
      // We need to manipulate the snoozed until time to get the correct value
      if (selectedDayMoment.startOf('day').utcOffset() !== utcOffset) {
        let offsetDiff = selectedDayMoment.startOf('day').utcOffset() - utcOffset;
        let offsetValueInSeconds = Math.abs(offsetDiff) * 60;

        // Positive: we moved backward and adding the original selectedTimeInSecondsSinceMidnight will stop short of the desired snooze time
        // Negative: we moved forward and adding the original selectedTimeInSecondsSinceMidnight sets the timer later than the desired snooze time
        if (offsetDiff > 0) {
          correctedSecondsSinceMidnight += offsetValueInSeconds;
        } else {
          correctedSecondsSinceMidnight -= offsetValueInSeconds;
        }
      }

      let timezoneCorrectedSnoozedUntilTime = selectedDayMoment
        .startOf('day')
        .add(correctedSecondsSinceMidnight, 'seconds')
        .toISOString();

      this.sendAndCustomSnooze(type, timezoneCorrectedSnoozedUntilTime);
      this.closeSubcontentImmediately();
      this.send('closeDropdown');
    },
  },
});
