/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty } from '@ember/utils';
import PredicateGroup from 'predicates/models/predicate-group';

export default class ProductTourComponent extends Component {
  @service contentEditorService;
  @service intersectionService;
  @service store;
  temporaryTour = null;

  constructor() {
    super(...arguments);
    this.intersectionService.registerEventHandler('getTemporaryTour', () =>
      this._onGetTemporaryTour(),
    );
    this.intersectionService.registerEventHandler(
      'setTemporaryTour',
      (data) => (this.temporaryTour = data),
    );
    this.intersectionService.registerEventHandler('tour-updated', () => this._onTourUpdated());
    this.intersectionService.registerEventHandler('tour-build-url-updated', (data) => {
      this.temporaryTour = data;
      let url = new URL(JSON.parse(data).url);
      this.intersectionService.updateTargetDomain(url);
    });
  }

  get tour() {
    return this.contentEditorService.activeObject;
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  _onGetTemporaryTour() {
    if (!this.temporaryTour) {
      return this.temporaryTour;
    }
    let tempTour = this.temporaryTour;
    this.temporaryTour = null;
    return tempTour;
  }

  _onTourUpdated() {
    let tourUrlWasEmptyBeforeUpdate = isEmpty(this.tour.url);
    this.ruleset.reload().then(() => {
      if (tourUrlWasEmptyBeforeUpdate) {
        this._initializeClientPredicatesWithLastVisitedUrl();
        this._initializeInsertableUrl();
      }
    });
    return true;
  }

  _initializeClientPredicatesWithLastVisitedUrl() {
    let ruleset = this.ruleset;
    if (!ruleset.isMemberOfSeries && isEmpty(ruleset.clientPredicateGroup.predicates)) {
      ruleset.clientPredicateGroup = this.store.createFragment('predicates/predicate-group', {
        predicates: PredicateGroup.convertRawPredicates(this.store, [
          {
            type: 'string',
            attribute: 'client_attributes.last_visited_url',
            comparison: 'eq',
            value: this.tour.url,
          },
        ]),
      });
    }
  }

  _initializeInsertableUrl() {
    if (isBlank(this.tour.insertableUrl)) {
      this.tour.insertableUrl = this.tour.url;
    }
  }
}
