/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

type Args = {
  hasAiAnswersLive: boolean;
};
export default class FinHeader extends Component<Args> {
  @service declare contentEditorService: any;
  @service declare usageLimitsService: any;
  @service declare appService: any;

  get showAiAnswersBanner() {
    if (this.args.hasAiAnswersLive !== undefined) {
      return this.args.hasAiAnswersLive;
    }

    return (
      this.contentEditorService.activeObject?.useAiAnswers &&
      this.contentEditorService.activeRulesetLink?.isLive
    );
  }

  get hardLimitBreached() {
    return this.usageLimitsService.finHardLimitBreached;
  }

  get usageLimitArticleLink() {
    if (this.appService.app.usesResolutionsWithCustomAnswers) {
      return 'https://www.intercom.com/help/en/articles/8443037-how-to-manage-your-fin-usage';
    } else {
      return 'https://www.intercom.com/help/en/articles/8060969-get-notified-of-your-fin-usage';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Headers::Fin::Header': typeof FinHeader;
    'content-editor/headers/fin/header': typeof FinHeader;
  }
}
