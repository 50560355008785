/* import __COLOCATED_TEMPLATE__ from './avatar.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ChatChromeAvatarComponent extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get appColor() {
    return this.app.base_color;
  }

  get sender() {
    return this.args.chatContent.sender;
  }

  get senderInitial() {
    return this.args.chatContent.senderName.slice(0, 1);
  }

  get isFromTeam() {
    return this.sender.isTeam;
  }

  get hasAvatar() {
    return this.sender?.avatar?.image_urls?.square_50 && this.sender.has_photo_avatar;
  }

  get teamMembers() {
    this.sender.teamMembers.slice(0, 3);
  }

  get team() {
    let team = [];
    if (this.isFromTeam) {
      let adminIds = this.sender.member_ids.slice(0, 3);
      team = adminIds.map((adminId) => {
        let admin = this.app.admins.findBy('id', adminId.toString());
        if (!admin) {
          return null;
        }

        let url = admin?.avatar?.image_urls?.square_50 || '';

        return {
          url: url.includes('default-avatars') ? '' : url,
          first_name: admin.first_name,
        };
      });
    }
    return team.compact();
  }
}
