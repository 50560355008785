/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import type AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';

export interface ContentWrapperArgs {
  contentType: number;
  contentId: number;
  listApi: AiContentLibraryApi;
  loadNextContent: (position: number) => void;
  loadContentForPosition: (position: number) => void;
  removeLastContent: () => void;
  onClose: () => void;
  reloadSummary: () => void;
  reloadPage: () => void;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class ContentWrapperComponent extends Component<ContentWrapperArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Adapters::ContentWrapperComponent': typeof ContentWrapperComponent;
    'content-service/ai-content-library/adapters/content-wrapper-component': typeof ContentWrapperComponent;
  }
}
