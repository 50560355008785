/* import __COLOCATED_TEMPLATE__ from './card-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { set } from '@ember/object';
import $ from 'jquery';
import { later, schedule, scheduleOnce } from '@ember/runloop';
import { computed, observer, action } from '@ember/object';
import { readOnly, not, and, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import DidChangeAttrs from 'ember-did-change-attrs';
import InboundActions from 'ember-component-inbound-actions/inbound-actions';
import ENV from 'embercom/config/environment';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';
import { runTask } from 'ember-lifeline';
import TransitionInformation from 'embercom/lib/inbox/transition-information';

export default Component.extend(DidChangeAttrs, InboundActions, {
  notificationsService: service(),
  conversationsService: service(),
  appService: service(),
  inboxApi: service(),
  session: service(),
  attributeBindings: ['data-conversation-id', 'data-intercom-target'],
  'data-conversation-id': readOnly('conversation.id'),
  'data-intercom-target': ternary('isInbox', 'conversation-stream-wrapper', ''),
  classNameBindings: [
    'notInbox:card',
    'isHoverable:o__hoverable',
    'isInbox:conversation__view__stream-wrapper',
    'isInbox:conversation__inbox__current-conversation',
    'notInbox:conversation__card',
    'notInboxInList:mb-6',
    'notInboxInList:conversation__card__in-list',
    'new:a__new-activity-feed-conversation',
  ],
  didChangeAttrsConfig: {
    attrs: ['conversation'],
  },
  cardAnimationTime: 1500,
  inList: true,
  new: false,
  isInbox: false,
  isFeed: false,
  isExpanded: false,
  isCollapsable: true,
  readOnly: false,
  canBeMessage: false,
  includedObjectViews: [],
  linkedTickets: [],
  notReadOnly: not('readOnly'),
  isCollapsed: not('isExpanded'),
  notInbox: not('isInbox'),
  notInboxInList: and('notInbox', 'inList'),
  viewportSelector: '.pl_profile_outer_container',
  shouldReduceCardHeight: not('conversation.isLastPartARuleAssignment'),
  isReducedHeightCard: and('conversation.isLastPartAdminAction', 'shouldReduceCardHeight'),
  shouldBeHere: not('shouldntBeHere'),
  isHoverable: and('isCollapsed', 'isNotInitialChannelPush', 'shouldBeHere'),
  isNotInitialChannelPush: not('conversation.isInitialChannelPush'),
  conversationRedirectTo: ternary('isFeed', 'feed', 'inbox'),
  shouldDisplayTransitionInformation: and('transitionInformation', 'isCollapsed', 'shouldntBeHere'),

  formattedToolTipDate: computed('conversation.sorting_updated_at', function () {
    return moment(this.conversation.sorting_updated_at).format(
      dateAndTimeFormats.fullDateAndTimeWithoutTimezone,
    );
  }),
  ticketStateStyling: computed('conversation.ticket_state', function () {
    let ticketStates = {
      submitted: 'ticket-state__submitted-pill',
      in_progress: 'ticket-state__in-progress-pill',
      waiting_on_customer: 'ticket-state__waiting-on-customer-pill',
      resolved: 'ticket-state__resolved-pill',
    };

    return ticketStates[this.conversation?.ticket_state];
  }),
  linkedParentTicket: reads('linkedTickets.firstObject'),
  includeTicketData: computed('includedObjectViews', function () {
    return this.includedObjectViews?.includes('ticket');
  }),
  ticketMainParticipant: computed(
    'conversation.mainParticipant',
    'linkedParentTicket.userSummary',
    'includeTicketData',
    function () {
      if (!this.includeTicketData) {
        return null;
      }
      return this.linkedParentTicket?.userSummary || this.conversation.mainParticipant;
    },
  ),
  ticketMainParticipantDisplayAs: reads('ticketMainParticipant.displayAs'),
  showTicketTypeInformation: computed('conversation.requestType', 'includeTicketData', function () {
    return this.conversation.requestType && !this.includeTicketData;
  }),

  conversation: null,

  init() {
    this._super(...arguments);

    this.subscribeRealtime(this.conversation);
    this.session.switchWorkspace(this.appService.app.id);
    this.loadLinkedTickets();
  },

  didChangeAttrs(changes) {
    this._super(...arguments);

    if (changes.conversation) {
      this.set('transitionInformation', null);
      this.unsubscribeRealtime(changes.conversation.previous);
      this.subscribeRealtime(changes.conversation.current);
    }
  },

  async loadLinkedTickets() {
    if (this.includeTicketData) {
      let { tickets: ticketsData } = await this.inboxApi.getLinkedTickets(this.conversation.id);
      set(this, 'linkedTickets', ticketsData);
    }
  },

  subscribeRealtime(conversation) {
    this.conversationsService.subscribeRealtime(conversation);
  },

  unsubscribeRealtime(conversation) {
    this.conversationsService.unsubscribeRealtime(conversation);
  },

  didInsertElement() {
    this._super(...arguments);
    if (!this['new']) {
      runTask(
        this,
        () => {
          this.set('new', false);
        },
        ENV.APP._400MS,
      );
    }
  },

  willDestroyElement() {
    this.unsubscribeRealtime(this.conversation);
    this._super(...arguments);
  },

  isExpandedChanged: observer({
    dependentKeys: ['isExpanded'],

    fn() {
      if (this.isExpanded) {
        later(this, this.hideCollapsedHeader, this.cardAnimationTime);
      } else {
        schedule('afterRender', this, function () {
          $('.inbox__card__header-collapsed', this.element).css('zIndex', 1);
        });
      }
    },

    sync: false,
  }),

  isClosedOrExpandedChanged: observer({
    dependentKeys: ['shouldntBeHere', 'isExpanded'],

    fn() {
      if (this.shouldntBeHere) {
        this.set('transitionInformation', new TransitionInformation(this.conversation));

        if (!this.isExpanded) {
          runTask(
            this,
            () => {
              this.send('remove');
            },
            ENV.APP.auto_transition_timeout,
          );
        }
      }
    },

    sync: false,
  }),

  shouldntBeHere: computed(
    'parentStateContext',
    'conversation.isOpen',
    'conversation.isSnoozed',
    'conversation.isNotClosed',
    function () {
      if (this.parentStateContext === 'opened') {
        return !this.get('conversation.isOpen');
      } else if (this.parentStateContext === 'snoozed') {
        return !this.get('conversation.isSnoozed');
      } else if (this.parentStateContext === 'closed') {
        return this.get('conversation.isNotClosed');
      }

      return false;
    },
  ),

  hideCollapsedHeader() {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }
    scheduleOnce('afterRender', this, this._hideCollapsedHeader);
  },

  _hideCollapsedHeader() {
    if (this.isExpanded && !(this.isDestroying || this.isDestroyed)) {
      $('.inbox__card__header-collapsed', this.element).css('zIndex', -1);
    }
  },

  collapse: action(function () {
    if (this.isCollapsable && this.notInbox) {
      this.setProperties({
        'conversation.isCurrentlyActive': false,
      });
    }
    this.onCollapse?.();
  }),

  actions: {
    expand() {
      this.onExpand?.(this.get('conversation.id'));
      this.conversation.markMentionsAsRead();
      if (!this.get('conversation.isInitialChannelPush')) {
        this.setProperties({
          'conversation.isCurrentlyActive': true,
          isLoading: true,
        });
      }
      if (typeof this.trackEventDetails === 'function') {
        this.trackEventDetails(this.conversation);
      }
    },

    onConversationReopened() {
      if (this.shouldntBeHere) {
        this.collapse();
      }
    },
    onConversationClosed() {
      this.collapse();
    },
    onConversationSnoozed() {
      this.collapse();
    },
    reload() {
      this.set('isLoading', true);
    },
    changeToNotePane() {
      this.streamAndControls.send('changeToNotePane');
    },
    focusComposerTab(tab) {
      this.streamAndControls.send('focusComposerTab', tab);
    },
    notifyError(message) {
      this.notificationsService.notifyError(message);
    },
    remove() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      let duration = ENV.APP._300MS;
      let height = $(this.element).outerHeight();
      let scale = 0.92;

      runTask(
        this,
        () => {
          let $card = $('.conversation__card:has(.o__expand)');
          let $viewport = $(this.viewportSelector);
          let offset = $card.length ? $card.offset().top - $viewport.offset().top : 0;

          $(this.element).velocity(
            { marginBottom: -height },
            {
              duration: (duration * 2) / 3,
              delay: (duration * 1) / 3,
              queue: false,
              progress: () => {
                if ($card.length) {
                  $card[0].scrollIntoView();
                  $viewport[0].scrollTop -= offset;
                }
              },
            },
          );

          $(this.element).velocity(
            { scaleX: scale, scaleY: scale },
            {
              duration,
              queue: false,
              easing: 'easeIn',
            },
          );

          let dateAgoSelector = '.js__feed__date-ago-header';
          let $dateAgoHeader = $(this.element).prev(dateAgoSelector);
          let daysAgo = $dateAgoHeader.attr('data-days-ago');
          if (
            $dateAgoHeader.is(':visible') &&
            $(`${dateAgoSelector}[data-days-ago='${daysAgo}']`).length === 1
          ) {
            $dateAgoHeader.velocity('slideUp', { duration });
          }

          runTask(
            this,
            () => {
              this.onRemove?.(this.get('conversation.id'));
            },
            duration,
          );
        },
        ENV.APP._2000MS,
      );
    },
  },
});
