/* import __COLOCATED_TEMPLATE__ from './state-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { AiContentState } from 'embercom/lib/ai-content-library/constants';
import { action } from '@ember/object';

interface Args {
  doSearch: (state?: string) => void;
  selectedStateValue: string | null;
  activeFilter?: string;
  removeFilter: (state?: string) => void;
}

export default class StateFilter extends Component<Args> {
  @service declare intl: IntlService;

  get isOpen() {
    return this.args.activeFilter === 'state';
  }

  get states() {
    return [
      {
        text: this.intl.t('ai-content-library.list.cells.states.all'),
        value: null,
      },
      {
        text: this.intl.t('ai-content-library.list.cells.states.in-use'),
        value: String(AiContentState.USED_BY_FIN),
      },
      {
        text: this.intl.t('ai-content-library.list.cells.states.not-used'),
        value: String(AiContentState.NOT_USED_BY_FIN),
      },
    ];
  }

  get selectedState() {
    let state = this.states.find((state) => state.value === this.args.selectedStateValue);
    return !state || !state.value
      ? this.intl.t('ai-content-library.list.cells.states.all-states')
      : this.intl.t('ai-content-library.list.cells.states.state-is', {
          state: state.text,
        });
  }

  @action removeFilter() {
    this.args.doSearch();
    this.args.removeFilter();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Filters::StateFilter': typeof StateFilter;
  }
}
