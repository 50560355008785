/* import __COLOCATED_TEMPLATE__ from './preview-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Args {}

interface Signature {
  Args: Args;
}

export default class PreviewButton extends Component<Signature> {
  @service declare contentEditorService: any;
  @service declare appService: any;
  @service declare intl: IntlService;
  @tracked showPreviewModal = false;

  get post(): $TSFixMe | undefined {
    return this.contentEditorService.activeObject;
  }

  get canPreviewPost() {
    return this.contentEditorService.isViewMode || !this.post.hasUnsavedChanges;
  }

  get previewErrorMessage(): string | undefined {
    if (!(this.contentEditorService.isViewMode || !this.post.hasUnsavedChanges)) {
      return this.intl.t('outbound.posts.preview.save-post');
    }

    return undefined;
  }

  get modalInfoTip() {
    return `${this.intl.t('outbound.posts.preview.preview-from-website-tip')} ${this.intl.t(
      'outbound.posts.preview.post-format-tip',
    )}`;
  }

  @action
  togglePreviewModal() {
    this.showPreviewModal = !this.showPreviewModal;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Headers::Post::PreviewButton': typeof PreviewButton;
    'content-editor/headers/post/preview-button': typeof PreviewButton;
  }
}
