/* import __COLOCATED_TEMPLATE__ from './article-content.hbs'; */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import ContentWrapperComponent, { type ContentWrapperArgs } from './content-wrapper-component';
import { action } from '@ember/object';
import { AiContentState } from 'embercom/lib/ai-content-library/constants';
import { post } from 'embercom/lib/ajax';
import { type State } from 'embercom/models/data/matching-system/matching-constants';
import { tracked } from '@glimmer/tracking';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import { EntityType } from 'embercom/models/data/entity-types';
import type IntlService from 'ember-intl/services/intl';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';
import { type FinAvailability } from 'embercom/lib/ai-content-library/constants';
import { isLocaleSupported } from 'embercom/components/content-service/ai-content-library/helpers';
import type ArticleContent from 'embercom/models/articles/article-content';
import { ContentAction } from 'embercom/objects/knowledge-hub/localized-knowledge-content';

const EDIT_ARTICLE_ROUTE = 'apps.app.articles.articles.article-content.edit';

export default class ArticleContentAdapter extends ContentWrapperComponent {
  @service declare store: Store;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare router: any;
  @service declare intercomEventService: any;
  @service declare permissionsService: any;
  @tracked openSectionId = 'details';

  articleContent?: ArticleContent;

  constructor(owner: unknown, args: ContentWrapperArgs) {
    super(owner, args);
    taskFor(this.loadArticleContent).perform();
  }

  @action onUpdate(): void {
    console.info('onUpdate ArticleContent');
  }

  get id() {
    return this.args.contentId;
  }

  get isLoading() {
    return taskFor(this.loadArticleContent).isRunning;
  }

  get updatedAt() {
    return String(this.articleContent?.updatedAt);
  }

  @task *loadArticleContent(): TaskGenerator<void> {
    try {
      this.articleContent = yield this.store.findRecord('articles/article-content', this.id);
      if (this.articleContent) {
        yield this.articleContent.loadRelatedContent(ContentAction.VIEW);
      }
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('ai-content-library.article-content.not-found'),
      );
      this.args.onClose();
    }
  }

  get isUsedByFin() {
    return this.isLanguageSupported && this.articleContent?.finState === AiContentState.USED_BY_FIN;
  }

  get toggleIsDisabled() {
    return !this.isLanguageSupported || this.articleContent?.eligibleForChatbot === false;
  }

  get isLanguageSupported() {
    if (!this.articleContent) {
      return false;
    }

    return isLocaleSupported(this.articleContent.locale);
  }

  get renderableJsonBlocks() {
    return this.articleContent
      ? this.articleContent.jsonBlocks.filter(
          (block) => block.type !== 'image' && block.type !== 'video',
        )
      : [];
  }

  get canEditArticle() {
    return (
      this.permissionsService.currentAdminCan(CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION) &&
      this.articleContent?.isEditable
    );
  }

  @action async toggleFinAvailability() {
    let response = (await post(`/ember/article_contents/${this.id}/toggle_fin_availability`, {
      app_id: this.appService.app.id,
    })) as {
      fin_state: AiContentState;
      fin_availability: FinAvailability;
    };

    this.articleContent!.finState = response.fin_state;
    this.articleContent!.finAvailability = response.fin_availability;

    let associatedListItemFragment = this.store.peekRecord(
      'content-service/content-wrapper',
      `${ContentWrapperType.AI_CONTENT}_${EntityType.ArticleContent}_${this.id}`,
    ) as ContentWrapper | undefined;

    if (associatedListItemFragment) {
      associatedListItemFragment.contents.firstObject!.state =
        response.fin_state as unknown as State;
    }

    this.args.reloadSummary();
  }

  @action onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action editArticle() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'article_edit_button',
      section: 'operator',
      context: 'article_side_drawer',
      place: 'fin_content_page',
    });
    let url: string = this.router.urlFor(EDIT_ARTICLE_ROUTE, this.articleContent);
    safeWindowOpen(url, '_blank');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Adapters::ArticleContent': typeof ArticleContentAdapter;
  }
}
