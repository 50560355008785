/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { matchingLocations } from 'embercom/models/data/matching-system/matching-constants';

export default class Footer extends Component {
  get ruleset() {
    return this.args.ruleset;
  }

  get rulesetTrigger() {
    return this.args.ruleset.rulesetTriggers.firstObject;
  }

  _setNewMatchingLocations() {
    let newMatchingLocations = [];
    if (this.rulesetTrigger) {
      newMatchingLocations = [matchingLocations.EVENT_TRIGGER];
    }
    return newMatchingLocations;
  }

  _removeRulesetTrigger() {
    if (this.rulesetTrigger) {
      this.ruleset.rulesetTriggers.removeObject(this.rulesetTrigger);
    }
  }

  @action
  setShowAutomatically(showAutomaticallyEnabled) {
    let newMatchingLocations;
    if (showAutomaticallyEnabled) {
      newMatchingLocations = this._setNewMatchingLocations();
    } else {
      this._removeRulesetTrigger();
      newMatchingLocations = [matchingLocations.TRIGGER_FROM_CODE];
    }
    this.ruleset.rulesetLinks.forEach((rl) => (rl.matchingLocations = newMatchingLocations));
  }
}
