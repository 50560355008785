/* import __COLOCATED_TEMPLATE__ from './pane-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { camelize } from '@ember/string';
import { alias, and, equal, not, or, readOnly, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import logLegacyComposerError from 'embercom/lib/log-legacy-composer-error';
import Metrics from 'embercom/models/metrics';
import SavedReply from 'embercom/models/saved-reply';
import { ProsemirrorComposerWrapper } from 'embercom/lib/inbox/prosemirror-composer-wrapper';

export default Component.extend({
  intercomEventService: service(),
  notificationsService: service(),
  onboardingHomeExternalStepService: service(),
  appService: service(),
  store: service(),
  savedReplyInsertionsService: service(),
  app: readOnly('appService.app'),
  classNames: ['u__relative', 'inbox__conversation-composer__wrapper'],
  classNameBindings: ['disabled:o__disabled'],
  classesForComposer: computed(
    'replySelected',
    'controlsAPI.conversation.showSocialComposerBanner',
    function () {
      let composerClasses = 'composer-inbox composer-style-basic o__fit conversation__text';
      if (!this.replySelected) {
        composerClasses += ' o__note';
      }
      if (this.replySelected && this.controlsAPI.conversation.showSocialComposerBanner) {
        composerClasses += ' hidden';
      }
      return composerClasses;
    },
  ),

  baseComposerPresetName: ternary('controlsAPI.isInbox', 'inbox', 'conversation_card'),
  composerPresetType: ternary('noteSelected', 'note', 'reply'),
  composerAllowUploadsType: ternary(
    'app.teammateConversationAttachmentsEnabled',
    '',
    'disable-uploads',
  ),
  isFacebookConversation: readOnly('controlsAPI.conversation.isFacebook'),
  isTwitterConversation: readOnly('controlsAPI.conversation.isTwitter'),
  isWhatsappConversation: readOnly('controlsAPI.conversation.isWhatsapp'),
  composerPreset: computed(
    'baseComposerPresetName',
    'composerPresetType',
    'composerAllowUploadsType',
    'isFacebookConversation',
    'isTwitterConversation',
    'noteSelected',
    function () {
      if (this.isFacebookConversation && !this.noteSelected) {
        return 'facebookMessage';
      }
      if (this.isTwitterConversation && !this.noteSelected) {
        return 'twitterDirectMessage';
      }
      return camelize(
        [this.baseComposerPresetName, this.composerPresetType, this.composerAllowUploadsType].join(
          '_',
        ),
      );
    },
  ),

  replySelected: equal('controlsAPI.activePaneIdentifier', 'comment'),
  replyNotSelected: not('replySelected'),
  noteSelected: equal('controlsAPI.activePaneIdentifier', 'note'),
  isDisabled: alias('controlsAPI.conversation.sortedParts.lastObject.isSaving'),
  pastedImage: null,
  isFacebookConversationReply: and('isFacebookConversation', 'replySelected'),
  isTwitterConversationReply: and('isTwitterConversation', 'replySelected'),
  isWhatsappConversationReply: and('isWhatsappConversation', 'replySelected'),
  shouldHideConversationControlsActions: or(
    'isFacebookConversationReply',
    'isTwitterConversationReply',
  ),
  shouldDisplayConversationControlsActions: not('shouldHideConversationControlsActions'),
  shouldDisplayActions: reads('app.canUseMacros'),
  savedRepliesAreAvailable: and(
    'app.savedReplies.length',
    'shouldDisplayConversationControlsActions',
  ),

  composerPlaceholder: computed(
    'controlsAPI.isInbox',
    'app.inboxIsActive',
    'noteSelected',
    'isTwitterConversationReply',
    'isFacebookConversationReply',
    'isWhatsappConversationReply',
    'savedRepliesAreAvailable',
    function () {
      if (!this.get('controlsAPI.isInbox') && !this.get('app.inboxIsActive')) {
        return 'Type your response here.';
      }
      if (this.noteSelected) {
        return "Type @ to mention a teammate and they'll be notified.";
      }
      if (this.isTwitterConversationReply) {
        return 'Replying by Twitter DM';
      }
      if (this.isFacebookConversationReply) {
        return 'Replying by Facebook message';
      }
      if (this.isWhatsappConversationReply) {
        return 'Replying by WhatsApp message';
      }
      if (this.savedRepliesAreAvailable) {
        return `Type # to add a macro`;
      }
      return '';
    },
  ),

  insertSavedReply(savedReplyId, markerId) {
    let conversationId = this.get('controlsAPI.conversation.id');
    SavedReply.getRendered(savedReplyId, this.get('app.id'), conversationId).then((data) => {
      this.intercomEventService.trackEvent('saved-reply-typeahead-inserted', {
        id: savedReplyId,
        conversation_id: conversationId,
      });
      this.savedReplyInsertionsService.recordInsertion(
        this.app,
        this.admin,
        { id: savedReplyId },
        conversationId,
      );
      this.intercomEventService.trackAnalyticsEvent({
        owner: 'inbox',
        action: 'added_to_reply',
        object: 'saved_reply',
        place: 'inbox',
        context: 'saved_reply_typeahead',
        saved_reply_id: savedReplyId,
        inserted_from: 'saved_reply_typeahead',
        conversation_id: conversationId,
      });
      this.get('controlsAPI.composerComponent').send('insertBlocksAtMarker', data.blocks, markerId);
    });
  },

  setupProsemirrorComposerWrapper() {
    let macros = {};
    if (this.app.canUseMacros) {
      macros['comment'] = this.store.createRecord('composer-macro');
      macros['note'] = this.store.createRecord('composer-macro');
      // eslint-disable-next-line @intercom/intercom/use-es5-getters
      if (this.get('conversationIsDraft')) {
        macros['comment'].conversationIsDraft = true;
        macros['note'].conversationIsDraft = true;
      }
    }
    this.set(
      'prosemirrorComposerWrapper',
      new ProsemirrorComposerWrapper(this, macros, this.enableComposerTemplating),
    );
    this.set('composerComponent', this.prosemirrorComposerWrapper);
  },

  onComposerError: action(function (error) {
    if (!error.recovered) {
      this.notificationsService.notifyError(
        'Hmm, it looks like something went wrong with the composer.',
        -1,
      );
    }
    logLegacyComposerError('Inbox', error);
    Metrics.capture({ increment: ['errors_composer_inbox'] });
  }),

  actions: {
    updateComposerIsUploading(value) {
      if (this.isDestroying) {
        return;
      }
      this.set('composerIsUploading', value);
    },
    onErrorUploading(error) {
      this.notificationsService.notifyError(error.message);
      logLegacyComposerError('Inbox', error);
    },

    onTypeAheadInserted(params) {
      if (params.type === 'savedReply') {
        this.insertSavedReply(params.value.id, params.markerId);
      }
    },
  },
});
