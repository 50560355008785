/* import __COLOCATED_TEMPLATE__ from './audience-rules-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import PredicateGroup from 'predicates/models/predicate-group';
import { copy } from 'ember-copy';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';
import ENV from 'embercom/config/environment';
import { debounce, throttle, schedule } from '@ember/runloop';

const ROLE_VALUE = {
  user: 'user_role',
  lead: 'contact_role',
  visitor: 'visitor_role',
};

export default class AudienceRulesEditor extends Component {
  @service appService;
  @service attributeService;
  @service realTimeEventService;
  @service store;
  @service intl;

  @tracked predicateGroup = this.args.ruleset.predicateGroup;
  @tracked rolePredicateGroup = this.args.ruleset.rolePredicateGroup;

  get app() {
    return this.appService.app;
  }

  allUserRoles = ['user', 'lead', 'visitor'];

  get previewTaskIsRunning() {
    return this.args.ruleset.fetchAudiencePreviewTask.isRunning;
  }

  get supportedPredicateAttributes() {
    return this.args.config.editorConfiguration.workflowMatchingTargetingAttributeIdentifiers;
  }

  get userSelectedValue() {
    return (
      this.args.ruleset.predicateGroup.get('userPredicate.userType') ||
      this.predicateGroup.userPredicate.userType
    );
  }

  get tagPredicates() {
    return this.predicateGroup.predicates.filter((predicate) => predicate?.type === 'manual_tag');
  }

  get attributePickerLabel() {
    return this.args.attributePickerLabel
      ? this.args.attributePickerLabel
      : this.args.config.editorConfiguration.targetingAttributePickerLabel;
  }

  constructor() {
    super(...arguments);

    this.realTimeEventService.subscribeTopics([
      'csv-import-user-tag-progress',
      'tagging-status-updated',
    ]);
    this.realTimeEventService.on(
      'CsvImportUserTagProgress',
      this,
      'handleCSVImportUserTagProgressEvent',
    );
    this.realTimeEventService.on('TaggingStatusUpdated', this, 'handleTaggingStatusUpdatedEvent');
  }

  @action
  reinitPredicates() {
    this.predicateGroup = copy(this.args.ruleset.predicateGroup);
    this.rolePredicateGroup = copy(this.args.ruleset.rolePredicateGroup);
    this.resetUserRolePredicates();
  }

  _createRolePredicateGroup(userRoles) {
    if (this.args.config.allowedUserRoles) {
      userRoles = userRoles.filter((userRole) =>
        this.args.config.allowedUserRoles.includes(userRole),
      );
    }
    let roleValues = userRoles.map((role) => {
      if (!(role in ROLE_VALUE)) {
        throw new Error(`Unknown role ${role}`);
      }
      return ROLE_VALUE[role];
    });
    let predicates = [
      {
        attribute: 'role',
        type: 'role',
        comparison: 'in',
        value: roleValues,
      },
    ];
    return this.store.createFragment('predicates/predicate-group', {
      predicates: PredicateGroup.convertRawPredicates(this.store, predicates),
    });
  }

  @action
  resetUserRolePredicates() {
    if (
      this.args.config.editorConfiguration.supportsUserTargeting &&
      isEmpty(this.rolePredicateGroup?.predicates)
    ) {
      schedule('afterRender', () => {
        // If you change the default role predicate below, you must also change in the Ruleset model in the backend.
        this.rolePredicateGroup = this._createRolePredicateGroup(['user']);
        this.onUpdateRolePredicates(this.rolePredicateGroup.predicates);
      });
    } else if (
      !this.args.config.editorConfiguration.supportsUserTargeting &&
      isPresent(this.rolePredicateGroup?.predicates)
    ) {
      schedule('afterRender', () => {
        this.rolePredicateGroup = this.store.createFragment('predicates/predicate-group', {
          predicates: [],
        });
        this.onUpdateRolePredicates(this.rolePredicateGroup.predicates);
      });
    }
  }

  get _validAttributeList() {
    let validAttributes;
    if (this.targetingVisitorsOnly) {
      validAttributes = this.attributeService.visitorMessagingAttributesNoPageTargetting;
    } else {
      validAttributes = this.app.companiesActive
        ? this.attributeService.filterableUserAttributesForMessaging.concat(
            this.attributeService.filterableCompanyAttributesForMessaging,
          )
        : this.attributeService.filterableUserAttributesForMessaging;
    }
    return validAttributes.map((x) => x.identifier).concat('role');
  }

  get additionalAttributes() {
    return this.args.config.additionalAttributes || [];
  }

  get targetingVisitorsOnly() {
    if (this.predicateGroup.userType) {
      return this.predicateGroup.userType === 'visitor';
    }
    let userTypes = this.rolePredicateGroup.userPredicate.userTypes;
    return userTypes.length === 1 && userTypes[0] === 'visitor';
  }

  get targetingUserOnly() {
    if (this.predicateGroup.userType) {
      return this.predicateGroup.userType === 'user';
    }
    let userTypes = this.rolePredicateGroup.userPredicate.userTypes;
    return userTypes.length === 1 && userTypes[0] === 'user';
  }

  get selectedUserTypes() {
    return (
      this.args.ruleset.rolePredicateGroup.get('userPredicate.userTypes') ||
      this.rolePredicateGroup.userPredicate?.userTypes
    );
  }

  get groupAttributePickerDisabledIdentifiers() {
    let identifierToReasonMapping = {};

    if (this.contentEventsDisabled) {
      this.attributeService.attributeGroupListForContentEvents
        .filter((group) => group.heading !== 'Series')
        .forEach((group) => {
          group.attributes.forEach((attribute) => {
            // eslint-disable-next-line @intercom/intercom/no-bare-strings
            let reason = `Currently you can only add ${group.heading} data to non-starting rules`;
            identifierToReasonMapping[attribute.id] = reason;
          });
        });
    }

    return identifierToReasonMapping;
  }

  get contentEventsDisabled() {
    return this.args.ruleset.isMemberOfSeries && this.args.ruleset.node.isStarting;
  }

  _isValid(p) {
    return this._validAttributeList.includes(p.attributeIdentifier) || p.isLogicalType;
  }

  _removeInvalidPredicates() {
    this.predicateGroup.removeAll((p) => !this._isValid(p));
  }

  @action
  setDefaultRolePredicateIfNecessary() {
    if (this.args.config.isDisabled) {
      return;
    } else if (isEmpty(this.args.ruleset.rolePredicateGroup.predicates)) {
      this.onUpdateRolePredicates(this.rolePredicateGroup.predicates);
    }
  }

  @action
  switchUserRoleOnRolePredicateGroup(userRoles) {
    let rolePredicateGroup = this._createRolePredicateGroup(userRoles);
    this.rolePredicateGroup = rolePredicateGroup;
    this._removeInvalidPredicates();
    this.onUpdateRolePredicates(rolePredicateGroup.predicates);
  }

  @action
  onUpdatePredicates(predicates) {
    this.args.ruleset.set('predicateGroup.predicates', copy(predicates));
    if (this.args.config.showAudiencePreview) {
      this.args.ruleset
        .get('fetchAudiencePreviewTask')
        .perform(this.args.config.ignoredAttributeTypesForPreview);
    }
  }

  @action
  onUpdateRolePredicates(rolePredicates) {
    this.args.ruleset.set('rolePredicateGroup.predicates', copy(rolePredicates));
    if (this.args.config.showAudiencePreview) {
      this.args.ruleset
        .get('fetchAudiencePreviewTask')
        .perform(this.args.config.ignoredAttributeTypesForPreview);
    }
  }

  @action
  onUpdateDefaultPredicates(defaultPredicates) {
    this.args.ruleset.set('defaultPredicateGroup.predicates', copy(defaultPredicates));
    if (this.args.config.showAudiencePreview) {
      this.args.ruleset
        .get('fetchAudiencePreviewTask')
        .perform(this.args.config.ignoredAttributeTypesForPreview);
    }
  }

  activeTagPredicate(tagId) {
    return this.tagPredicates.find((predicate) => predicate.value === tagId);
  }

  handleCSVImportUserTagProgressEvent(event) {
    let tagId = String(event.manual_tag_id);

    if (!this.activeTagPredicate(tagId)) {
      return;
    }

    if (event.progress > 20 && !event.complete) {
      throttle(this, this.refreshAudiencePreviewForCSVImportStatus, ENV.APP._500MS);
    }

    if (event.complete) {
      debounce(this, this.refreshAudiencePreviewForCSVImportStatus, ENV.APP._50MS);
    }
  }

  handleTaggingStatusUpdatedEvent(event) {
    let tagId = String(event.id);
    if (!this.activeTagPredicate(tagId)) {
      return;
    }

    let eventData = { tagId };
    throttle(this, this.refreshAudiencePreviewForTagStatus, eventData, ENV.APP._750MS);
  }

  refreshAudiencePreviewForCSVImportStatus() {
    if (!this.previewTaskIsRunning) {
      this.args.ruleset.fetchAudiencePreviewTask.perform();
    }
  }

  async refreshAudiencePreviewForTagStatus(event) {
    let tag = this.app.tags.findBy('id', event.tagId);
    await tag.reload();

    if (!this.previewTaskIsRunning) {
      this.args.ruleset.fetchAudiencePreviewTask.perform();
    }
  }
}
