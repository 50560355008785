/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import Component from '@glimmer/component';

export default class BotIntroHeader extends Component {
  @service contentEditorService;
  @service intl;

  get description() {
    if (this.contentEditorService.activeObject.botIntroEligibleAndEnabled) {
      return this.botIntroHeaderForEnabled;
    } else {
      if (this.contentEditorService.activeObject.botMode === 'one_time') {
        return this.intl.t('ai-agent.profiles.bot-intro.disabled-in-one-time');
      } else if (!this.contentEditorService.activeObject.isTargetingIntercomChannels) {
        return this.intl.t('ai-agent.profiles.bot-intro.not-applicable');
      }
      return this.intl.t('ai-agent.profiles.bot-intro.disabled');
    }
  }

  get botIntroHeaderForEnabled() {
    let availableLocalizations = this.contentEditorService.activeObject.botIntroLocalizations;
    let enabledLocalizations = this.contentEditorService.activeObject.enabledLocalizationsList.map(
      (localization) => capitalize(this.intl.t(`app.lib.languages.language-code.${localization}`)),
    );

    if (availableLocalizations.length === 1 && enabledLocalizations.length === 1) {
      return this.intl.t('ai-agent.profiles.bot-intro.enabled');
    } else {
      return this.intl.t('ai-agent.profiles.bot-intro.enabled-localizations', {
        list: this.intl.formatList(enabledLocalizations, { style: 'short', type: 'unit' }),
      });
    }
  }
}
