/* import __COLOCATED_TEMPLATE__ from './save-reply-quick-action.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import QuickActionBaseComponent from './quick-action-base-component';
import { inject as service } from '@ember/service';

export default QuickActionBaseComponent.extend({
  icon: 'saved-reply',
  message: 'Save this reply',

  permissionsService: service(),

  actions: {
    saveThisReply(part) {
      if (this.appService.app.currentAdmin.currentAppPermissions.can_manage_saved_replies) {
        this.createSavedReply(part.blocks);
      } else {
        this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
          'can_manage_saved_replies',
        );
      }
    },
  },
});
