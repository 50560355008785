/* import __COLOCATED_TEMPLATE__ from './card-action-statement-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import displayAdminAs from 'embercom/lib/inbox/display-admin-as';
import { CONVERSATION_PRIORITIES } from 'embercom/lib/inbox/constants';
import { inject as service } from '@ember/service';

export default class CardActionStatementComponent extends Component {
  PRIORITY_VALUE = CONVERSATION_PRIORITIES.priority;

  @service appService;

  get app() {
    return this.appService.app;
  }

  get lastPart() {
    return this.args.conversation.lastPartExcludingOperator;
  }

  get userName() {
    return this.args.conversation.main_participant?.display_as;
  }

  get adminName() {
    let currentAdmin = this.app.currentAdmin;
    let actionStatementAdmin = this.lastPart?.isAdminPart
      ? this.lastPart.admin
      : this.args.conversation.assignee;

    if (actionStatementAdmin?.is_unknown) {
      return displayAdminAs(currentAdmin, actionStatementAdmin, false, false, false);
    }

    return displayAdminAs(currentAdmin, actionStatementAdmin, false, false, true);
  }

  get authorName() {
    if (!this.lastPart) {
      return null;
    }

    if (this.lastPart.shouldOverrideAdminAvatar) {
      return this.lastPart.from_address.name;
    }

    return this.lastPart.admin?.firstNameOrNameOrEmail;
  }

  get assigneeName() {
    if (this.args.conversation.unassigned) {
      return 'Unassigned';
    }

    if (!this.args.conversation.assignee) {
      return `${this.app.name} Teammate`;
    }

    return displayAdminAs(
      this.app.currentAdmin,
      this.args.conversation.assignee,
      this.lastPart?.isReflexiveAssignment,
      false,
      true,
    );
  }

  get linkTarget() {
    return this.args.openInNewTab ? '_blank' : '_self';
  }

  get entityType() {
    return this.args.conversation.requestType ? 'ticket' : 'conversation';
  }
}
