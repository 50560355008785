/* import __COLOCATED_TEMPLATE__ from './confetti-rain.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { A } from '@ember/array';
import Component from '@ember/component';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import { readOnly, and, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),

  forceUseConfetti: false,
  forceUseConfettiDisabled: not('forceUseConfetti'),
  useSnowflakes: and('app.canSeeSnowfall', 'forceUseConfettiDisabled'),

  animationBaseDuration: 500,

  colors: A([
    '#e6525a',
    '#ff4081',
    '#f26c73',
    '#b3a3ed',
    '#ba68c8',
    '#7e61ea',
    '#1271c4',
    '#1f8ceb',
    '#b9dcfa',
    '#4dd0e1',
    '#26a69a',
    '#66bb6a',
    '#6ce4b3',
    '#b8f150',
    '#ffe645',
    '#ffd37a',
    '#ffab7a',
  ]),
  snowflakes: A(['❄', '❅', '❆']),
  confettiCount: ternary('useSnowflakes', 20, 40),

  confetti: computed('confettiCount', 'colors.[]', 'useSnowflakes', function () {
    let useSnowflakes = this.useSnowflakes;
    let confettiCount = this.confettiCount;

    let confetti = A();
    for (let i = 0; i < confettiCount; i++) {
      let confetto = {
        animationDuration: `${this.animationBaseDuration + Math.random() * 1500}ms`,
        color: this._randomColor(),
        left: `${(100 / confettiCount) * i}vw`,
      };
      if (useSnowflakes) {
        confetto.flake = this._randomSnowflake();
      }
      confetti.pushObject(confetto);
    }
    return confetti;
  }),

  _randomSnowflake() {
    let snowflakes = this.snowflakes;
    let randomIndex = Math.floor(Math.random() * snowflakes.get('length'));
    return snowflakes.objectAt(randomIndex);
  },

  _randomColor() {
    let colors = this.colors;
    let randomIndex = Math.floor(Math.random() * colors.get('length'));
    return colors.objectAt(randomIndex);
  },
});
