/* import __COLOCATED_TEMPLATE__ from './reaction-picker.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import intermoji from '@intercom/intermoji';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default class ReactionPickerComponent extends Component {
  get reactions() {
    let emberEscape = Em.Handlebars.Utils.escapeExpression;
    return this.args.content.reactionSet.map((reaction) => ({
      index: reaction.index,
      unicodeEmoticon: reaction.unicodeEmoticon,
      display: sanitizeHtml(
        intermoji.wrapUnicodeEmojiInTitledSpans(
          32,
          emberEscape(reaction.unicodeEmoticon),
          'reaction-picker-popover__image o__large',
        ),
      ),
    }));
  }

  @action
  updateReaction(newReaction) {
    let reactionSet = this.args.content.reactionSet;
    let updatedReactions = reactionSet.map((reaction, index) => {
      return index === newReaction.index ? newReaction : reaction;
    });
    if (newReaction.index === undefined) {
      newReaction.index = reactionSet.length;
      updatedReactions.pushObject(newReaction);
    }
    this.args.content.reactionSet = updatedReactions;
  }

  @action
  deleteReaction(reactionIndex) {
    let reactionSet = this.args.content.reactionSet;

    let previousReaction = reactionSet.findBy('index', reactionIndex);
    reactionSet.removeObject(previousReaction);
    reactionSet.forEach((reaction, index) => (reaction.index = index));
    this.args.content.reactionSet = reactionSet;
  }
}
